/**
 * Flyp Technologies Inc. - Flipbook v4
 * 
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/zoomer.js - Flipbook.Zoomer
 * @author Robert J. Secord, B.Sc.
 */
import Flipbook from './core';
import Q from '/app/libs/promise/q';
import Shared from './shared_util';

/**
 * Flipbook Carousel-Zoomer
 *
 * @class Zoomer
 * @classdesc Application Carousel-Zoomer Controller
 * @namespace Flipbook
 * @return {Object} The Class Instance
 * @constructor
 * @mixin
 */
Flipbook.Zoomer = function() {};


/* **************************************************************************************** */
/* * Public Properties                                                                    * */
/* **************************************************************************************** */

// Data for Touch Events on Carousel Zoomer
//  (This data is available accross entire Carousel Code [Carousel, Slider, Zoomer, Scrubber]) 
Flipbook.Zoomer.prototype.zoom = {
    'scale' : 1,
    'translate' : {'x': 0, 'y': 0},
    
    'last' : {
        'scale'     : 1, 
        'translate' : {'x': 0, 'y': 0},
    },
    
    'pinch' : {
        'initiated' : false, 
        'direction' : 0, // 1=Zoom In, -1=Zoom Out
        'start'     : {'x': 0, 'y': 0}
    },
    
    'pan' : {
        'initiated' : false, 
        'moved'     : false, 
        'time'      : {'start': 0, 'end': 0},
        'start'     : {'x': 0, 'y': 0},
        'point'     : {'x': 0, 'y': 0},
        'velocity'  : {'x': 0, 'y': 0}
    }
};


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Event: Pinch
 *   Handles Zooming In or Out
 *     Zooming In from Slider Mode switches to Zoomer Mode (scale > 1)
 *     Zooming Out from Zoomer Mode switches to Slider Mode (scale = 1)
 *     Zooming Out from Slider Mode switches to Scrubber Mode (scale < 1)
 *     Zooming In from Scrubber Mode switches to Slider Mode (scale = 1)
 *   Moving while Pinching causes Panning
 *
 * @public
 * @this Flipbook.Carousel
 * @param {Object} e The Event Data from HammerJS
 * @return undefined
 */
Flipbook.Zoomer.prototype.zoomerHandlePinch = function(e) {
    var initialPoint = {'x': 0, 'y': 0};
    var pagePos = {'x': 0, 'y': 0};
    var pageOrientation = Flipbook.getPageOrientation(this.app);
    var scaleDiff = 0;
    var axis = this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_LEFT ? 'x' : 'y';
    var totalSheets = this.totalSheets[this.app.viewport.orientation];
    var afterPinch = $.proxy(function() { this.app.state.animating = false; }, this);
    var afterPinchRelease = $.proxy(function() { this.bounceToBoundary(); }, this);
    
    // Disable browser scrolling
    e.gesture.preventDefault();
    
    // Prevent Pinching While Sliding, Scrubbing or Panning
    if (this.slide.initiated || this.scrub.initiated || this.zoom.pan.initiated) { return true; }
    
    // Prevent Pinching when Flipbook State is Animating or Shifted
    if (this.app.state.animating || this.app.state.shifted) { return true; }

    // Ignore pinch on empty space
    // 
    // NOTE: The math is wrong for this and is preventing clicks on overlay elements in random scenarios..
    //
//        if (!this.zoom.pinch.initiated && this.zoom.scale >= 1) {
//            // Pinch Point
//            initialPoint.x = e.gesture.startEvent.center.pageX;
//            initialPoint.y = e.gesture.startEvent.center.pageY;
//            
//            // Determine Page Box Position
//            pagePos.x = this.page.offset.x + (this.zoom.translate.x * this.zoom.scale);
//            pagePos.y = this.page.offset.y + (this.zoom.translate.y * this.zoom.scale);
//            
//            // First Page on Right Side of Sheet
//            if (this.current.twopage && !this.app.config.titleData.always_open && this.current.sheet === 0) {
//                pagePos.x += this.page.size.current.x;
//            }
//            
//            // Prevent Pinching outside of Page Box
//            if (initialPoint.x < pagePos.x || initialPoint.x > pagePos.x + this.page.size.current.x ||  // X-Axis
//                initialPoint.y < pagePos.y || initialPoint.y > pagePos.y + this.page.size.current.y) {  // Y-Axis
//                e.gesture.stopDetect();
//                return true;
//            }
//        } 
    
    // Handle Event
    switch(e.type) {
        case 'pinch':
            this.zoom.pinch.initiated = true;
            
            // Get Start Point of Touch Event
            if (this.zoom.pinch.start.x === 0 && this.zoom.pinch.start.y === 0) {
                
                // Reset Pinch Direction
                this.zoom.pinch.direction = 0;
                
                // Get Center Coordinate of Pinch
                this.zoom.pinch.start.x = e.gesture.center.pageX;
                this.zoom.pinch.start.y = e.gesture.center.pageY;
                
                // Account for Page Offset
                this.zoom.pinch.start.x -= this.page.offset.x;
                this.zoom.pinch.start.y -= this.page.offset.y;
                
                // Account for Previous Scaling
                this.zoom.pinch.start.x /= this.zoom.last.scale;
                this.zoom.pinch.start.y /= this.zoom.last.scale;
            }
            
            // Adjust Scale based on Current Scale
            this.zoom.scale = this.zoom.last.scale * e.gesture.scale;
            if (this.zoom.scale < this.app.config.sheet.minScale[pageOrientation]) { this.zoom.scale = this.app.config.sheet.minScale[pageOrientation]; }
            if (this.zoom.scale > this.app.config.sheet.maxScale[pageOrientation]) { this.zoom.scale = this.app.config.sheet.maxScale[pageOrientation]; }

            // Force Direction
            if (this.zoom.last.scale === 1) {
                if (this.zoom.pinch.direction === 0) {
                    scaleDiff = this.zoom.scale - this.zoom.last.scale;
                    if (Math.abs(scaleDiff) > 0.03) {
                        this.zoom.pinch.direction = (scaleDiff > 0) ? 1 : -1;
                    }
                }
                if ((this.zoom.pinch.direction < 0 && this.zoom.scale > 1) || (this.zoom.pinch.direction > 0 && this.zoom.scale < 1)) { this.zoom.scale = 1; }
            }
            
            // Get the Translation Amount of the Pinch (Also Account for Panning)
            this.zoom.pan.point.x = e.gesture.center.pageX;
            this.zoom.pan.point.y = e.gesture.center.pageY;
            this.calculateSheetTranslation(this.zoom.pinch.start, e.gesture.center);
            
            // Pinch on Zoomed Page
            if (this.zoom.last.scale > 1) {
                
                // Animate Pinch
                this.sheets[this.current.sheet].$pageBox.css({'scale': this.zoom.scale, 'translate': [this.zoom.translate.x, this.zoom.translate.y]});
                this.app.overlayer.$carousel.pagebox.css({'scale': this.zoom.scale, 'translate': [this.zoom.translate.x, this.zoom.translate.y]});
                
                // Switch to Slider Mode
                if (this.zoom.scale < 1.2) {
                    e.gesture.stopDetect();  // release event not fired
                    this.zoom.translate.x = this.zoom.translate.y = 0;
                    this.app.state.animating = true;
                    this.app.overlayer.$carousel.pagebox.transition({'scale': 1, 'translate': [this.zoom.translate.x, this.zoom.translate.y]}, 200);
                    this.sheets[this.current.sheet].$pageBox.transition({'scale': 1, 'translate': [this.zoom.translate.x, this.zoom.translate.y]}, 200, $.proxy(function() {
                        this.resetPinch({'mode': Flipbook.CAROUSEL_SLIDER, 'scale': 1, 'reset': true}).then(afterPinch);
                    }, this));
                }
            }
            
            // Pinch on Preview Scrubber
            else if (this.zoom.last.scale < 1) {
                
                // Animate Pinch
                this.$container.css({'scale': this.zoom.scale});
                this.app.overlayer.$carousel.pagebox.css({'scale': 1});
                
                // Switch to Slider Mode
                if (this.zoom.scale > 0.5) {
                    e.gesture.stopDetect();  // release event not fired
                    this.app.state.animating = true;
                    this.$container.transition({'scale': 1}, 200, $.proxy(function() {
                        this.resetPinch({'mode': Flipbook.CAROUSEL_SLIDER, 'scale': 1, 'reset': true}).then(afterPinch);
                    }, this));
                }
            }
            
            // Pinch on Standard Page
            else {
                
                // Zooming In
                if (this.zoom.scale >= 1) {
                    this.$container.css({'scale': 1});
                    this.sheets[this.current.sheet].$pageBox.css({'scale': this.zoom.scale, 'translate': [this.zoom.translate.x, this.zoom.translate.y]});
                    this.app.overlayer.$carousel.pagebox.css({'scale': this.zoom.scale, 'translate': [this.zoom.translate.x, this.zoom.translate.y]});
                } 
                
                // Zooming Out
                else {
                    this.zoom.translate.x = this.zoom.translate.y = 0;
                    this.$container.css({'scale': this.zoom.scale});
                    this.sheets[this.current.sheet].$pageBox.css({'scale': 1, 'translate': [this.zoom.translate.x, this.zoom.translate.y]});
                    this.app.overlayer.$carousel.pagebox.css({'scale': 1});
                    
                    if (this.zoom.scale < 0.7) {
                        // Switch to Scrubber Mode
                        e.gesture.stopDetect(); // release event not fired
                        this.app.state.animating = true;
                        this.$container.transition({'scale': this.app.config.sheet.minScale[this.app.viewport.orientation]}, 200, $.proxy(function() {
                            this.resetPinch({'mode': Flipbook.CAROUSEL_SCRUBBER, 'scale': this.app.config.sheet.minScale[this.app.viewport.orientation], 'reset': true}).then(afterPinch);
                        }, this));
                    }
                }
            }
            break;
            
        case 'release':
            if (!this.zoom.pinch.initiated) { return true; }
            e.gesture.stopDetect();
            
            if (this.zoom.scale > 1) {
                // Check if we have Zoomed In
                if (this.zoom.scale !== this.zoom.last.scale) {
                    // Update Scale of Large Image
                    this.sheets[this.current.sheet].clearLargeImage().displayLargeImage();
                    
                    // Track Flipbook Zoom Event
                    if (this.zoom.scale > this.zoom.last.scale) {
                        if (this.app.config.toolbar.enabled) {
                            this.zoom.pinch.start[axis] -= this.app.config.toolbar.size[this.app.viewport.breakpoint][this.app.config.toolbar.position].closed / 2;
                        }
                        this.app.stats.pageZoomed(this.zoom.pinch.start);
                    }
                }
                
                // Zoomed in; Update Mode & Scale
                this.resetPinch({'mode': Flipbook.CAROUSEL_ZOOMER, 'scale': this.zoom.scale, 'reset': true}).then(afterPinchRelease);
            }
            else {
                // Bounce Back to Current Scale
                this.$container.transition({'scale': this.zoom.last.scale}, 200);
                this.resetPinch({'reset': true}).then(afterPinchRelease);
            }
            break;
    }
    return true;
};

/**
 * Event: Drag
 *   Handles Panning on a Zoomed-In Sheet.
 *   Should have Panning Scroll-Inertia for Smooth Feel.
 *   Should Bounce to Boundaries of Sheet when Panned Outside of Sheet Bounds.
 *
 * @public
 * @abstracted
 * @this Flipbook.Carousel
 * @param {Object} e The Event Data from HammerJS
 * @return undefined
 */
Flipbook.Zoomer.prototype.zoomerHandleDrag = function(e) {
    var page = {'x': 0, 'y': 0}, delta = {'x': 0, 'y': 0};
    var bounceBack = false;
    var touch = Flipbook.hasTouch(this.app);
    
    // Disable browser scrolling
    if (touch) {
        e.gesture.preventDefault();
    } else {
        e.preventDefault();
    }
    e.stopPropagation();
    
    // Prevent Panning While Sliding, Scrubbing or Pinching 
    if (this.slide.initiated || this.scrub.initiated || this.zoom.pinch.initiated) { return true; }
    
    // Prevent Panning when Flipbook State is Animating or Shifted
    if (this.app.state.animating || this.app.state.shifted) { return true; }

    // Must be a Single-Touch Event, with Scale > 1  
    if (this.zoom.scale <= 1) { return true; }
    
    switch(e.type) {
        case 'dragstart':
        case 'mousedown':
            if (this.zoom.pan.initiated) { return; }
            this.zoom.pan.initiated = true;
            this.zoom.pan.moved = false;
            
            // Get Center Coordinate of Touch for Current Point
            page.x = touch ? e.gesture.startEvent.center.pageX : e.pageX;
            page.y = touch ? e.gesture.startEvent.center.pageY : e.pageY;
            this.zoom.pan.start.x = this.zoom.pan.point.x = page.x;
            this.zoom.pan.start.y = this.zoom.pan.point.y = page.y;
            
            // Set Transition Duration to Zero so Animation is not delayed while sliding/moving
            this.sheets[this.current.sheet].$pageBox[0].style[Modernizr.prefixed('transitionDuration')] = '0s';
            this.app.overlayer.$carousel.pagebox[0].style[Modernizr.prefixed('transitionDuration')] = '0s';
            break;
            
        case 'drag':
        case 'mousemove':
            if (!this.zoom.pan.initiated) { return; }

            // Calculate New Position Delta
            page.x = touch ? e.gesture.center.pageX : e.pageX;
            page.y = touch ? e.gesture.center.pageY : e.pageY;
            delta.x = (page.x - this.zoom.pan.point.x) / this.zoom.scale;
            delta.y = (page.y - this.zoom.pan.point.y) / this.zoom.scale;
            
            // Track Current Touch Point
            this.zoom.pan.point.x = page.x;
            this.zoom.pan.point.y = page.y;
            
            // Check if moving outside boundaries, and reduce scroll
            if (this.zoom.translate.x + delta.x > this.page.bounds.x1 || this.zoom.translate.x + delta.x < this.page.bounds.x2) { delta.x /= 2; }
            if (this.zoom.translate.y + delta.y > this.page.bounds.y1 || this.zoom.translate.y + delta.y < this.page.bounds.y2) { delta.y /= 2; }
            
            // Adjust Translation
            this.zoom.translate.x += delta.x;
            this.zoom.translate.y += delta.y;
            
            // Check if Moved
            if (this.zoom.last.translate.x !== this.zoom.translate.x || this.zoom.last.translate.y !== this.zoom.translate.y) {
                this.zoom.pan.moved = true;
                this.sheets[this.current.sheet].$pageBox.css({'translate': [this.zoom.translate.x, this.zoom.translate.y]});
                this.app.overlayer.$carousel.pagebox.css({'translate': [this.zoom.translate.x, this.zoom.translate.y]});
            }
            break;
            
        case 'dragend':
        case 'mouseup':
            if (!this.zoom.pan.initiated) { return; }
            this.zoom.pan.initiated = false;
            if (touch) { e.gesture.stopDetect(); } // release event not fired
            
            if (this.zoom.pan.moved) {
                // Apply Momentum
                this.applyMomentum(e);
            }
            break;
    }
};

/**
 * Event: Tap/DoubleTap
 *   Automatically Zooms-In to Center-Point, or Zooms-Out from Center-Point
 *
 * @public
 * @abstracted
 * @this Flipbook.Carousel
 * @param {Object} e The Event Data from HammerJS
 * @return undefined
 */
Flipbook.Slider.prototype.zoomerHandleTap = function(e) {
    var centerPoint = {'pageX': 0, 'pageY': 0};
    var touch = Flipbook.hasTouch(this.app);
    
    if (this.app.state.animating || this.app.state.shifted) { return; }
    if (!/doubletap|dblclick/i.test(e.type)) { return; }

    e.stopPropagation();
    
    // Get Center Point
    centerPoint.pageX = touch ? e.gesture.center.pageX : e.pageX;
    centerPoint.pageY = touch ? e.gesture.center.pageY : e.pageY;

    // Adjust Scale
    this.zoom.scale = 1; 
    
    // Zoom to Scale
    this.autoZoom(centerPoint);
};

/**
 * Event: KeyDown
 *   Pans the Zoomed-In Sheet in the Direction of the Arrow-Key Pressed
 *
 * @public
 * @abstracted
 * @this Flipbook.Carousel
 * @param {Object} e The Event Data from jQuery
 * @return undefined
 */
Flipbook.Zoomer.prototype.zoomerKeyPress = function(e) {
    if (this.app.state.animating || this.app.state.shifted) { return; }
    e.preventDefault();
    
    // Debug Message
    Flipbook.log({'msg': 'zoomer-key-press', 'args': {'key': e.which}});
    
    switch (e.which) {
        // Panning:
        case Flipbook.ARROW_KEY_UP:
        case Flipbook.NUMPAD_KEY_8: // Up Arrow
        case Flipbook.WII_UP:
            this.app.nontouch.performPan('up', this.app.config.nontouch.panDistance, 200);
            break;
        case Flipbook.ARROW_KEY_RIGHT:
        case Flipbook.NUMPAD_KEY_6: // Right Arrow
        case Flipbook.WII_RIGHT:
            this.app.nontouch.performPan('right', this.app.config.nontouch.panDistance, 200);
            break;
        case Flipbook.ARROW_KEY_DOWN:
        case Flipbook.NUMPAD_KEY_2: // Down Arrow
        case Flipbook.WII_DOWN:
            this.app.nontouch.performPan('down', this.app.config.nontouch.panDistance, 200);
            break;
        case Flipbook.ARROW_KEY_LEFT:
        case Flipbook.NUMPAD_KEY_4: // Left Arrow
        case Flipbook.WII_LEFT:
            this.app.nontouch.performPan('left', this.app.config.nontouch.panDistance, 200);
            break;
            
        // Zooming:
        case Flipbook.PLUS_KEY:
        case Flipbook.EQUALS_KEY: // Plus
        case Flipbook.PGUP_KEY:
        case Flipbook.NUMPAD_KEY_9: // PGUP
        case Flipbook.WII_PLUS:
            this.app.nontouch.performZoom('in', this.app.config.nontouch.zoomScale);
            break;
        case Flipbook.MINUS_KEY:
        case Flipbook.DASH_KEY: // Minus
        case Flipbook.PGDN_KEY:
        case Flipbook.NUMPAD_KEY_3: // PGDN
        case Flipbook.WII_MINUS:
            this.app.nontouch.performZoom('out', this.app.config.nontouch.zoomScale);
            break;
        case Flipbook.Z_KEY:
            this.zoom.scale = 1; 
            this.autoZoom({'pageX': 0, 'pageY': 0});
            break;
    }
};

/**
 * Resets all Zoom Data to Slider Mode with a Scale of 1
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Zoomer.prototype.resetZoom = function() {
    // Debug Message
    Flipbook.log('zoomer-reset-zoom');
    
    // Reset Zoom Data
    this.zoom.scale = 1;
    this.zoom.translate.x = this.zoom.translate.y = 0;
    this.zoom.pinch.direction = 0;
    
    // Reposition Pagebox
    this.$container.css({'scale': this.zoom.scale});
    this.sheets[this.current.sheet].$pageBox[0].style[Modernizr.prefixed('transitionDuration')] = '0s';
    this.app.overlayer.$carousel.pagebox[0].style[Modernizr.prefixed('transitionDuration')] = '0s';
    this.sheets[this.current.sheet].$pageBox.css({'scale': this.zoom.scale, 'translate': [this.zoom.translate.x, this.zoom.translate.y]});
    this.app.overlayer.$carousel.pagebox.css({'scale': this.zoom.scale, 'translate': [this.zoom.translate.x, this.zoom.translate.y]});
    this.resetPinch({'mode': Flipbook.CAROUSEL_SLIDER, 'scale': this.zoom.scale, 'reset': true});
    
    // Store Translation Coordinates
    this.zoom.last.translate = $.extend({}, this.zoom.translate);
    return this;
};

/**
 * Reset Pinch Data based on Options passed in
 *   Primary method for changing View Mode;  Pinch data must get Reset when Mode changes.
 *
 * @public
 * @this Flipbook.Carousel
 * @param {Object} (options) Optional.  The data to reset based on the Pinch State.
 * @return {Object} A Promise
 */
Flipbook.Zoomer.prototype.resetPinch = function(options) {
    var deferred = Q.defer();
    var oldMode = this.viewMode;
    var onComplete = $.proxy(function() {
        this.updatePageMetrics(this.zoom.scale);
        deferred.resolve('complete');
    }, this);
    
    // Debug Message
    Flipbook.log('zoomer-reset-pinch');
    
    // Reset Scale
    if (options.scale !== undefined) {
        this.zoom.scale = this.zoom.last.scale = options.scale;
    }
    
    // Reset Pinch Data
    if (options.reset !== undefined && options.reset) {
        this.zoom.pinch.initiated = false;
        
        // Store Last Translation Coordinates
        this.zoom.last.translate = $.extend({}, this.zoom.translate);
        
        // Reset Start Coordinate
        this.zoom.pinch.start.x = this.zoom.pinch.start.y = 0;
        this.zoom.pinch.direction = 0;
    }
    
    // Reset View Mode
    if (options.mode !== undefined) {
        this.viewMode = options.mode;
    }
    if (this.viewMode !== oldMode) {
        this.toggleViewMode(oldMode).then(onComplete);
    } else {
        onComplete();
    }
    return deferred.promise;
};

/**
 * 
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Zoomer.prototype.applyTranslation = function(duration, bounceBackOnly) {
    var bounceBack = false;
    var onComplete = $.proxy(function() {
        // Update Zoomed Image
        this.updateZoomedImage();
        
        // Check Current Zoom Levels for Enabling/Disabling Controls
        if (!Flipbook.hasMultiTouch(this.app)) {
            this.app.nontouch.checkZoomLevels().checkPanPosition();
        }
    }, this);
    
    // Ensure Page is within Boundaries
    if (this.zoom.translate.x > this.page.bounds.x1) { this.zoom.translate.x = this.page.bounds.x1; bounceBack = true; }
    if (this.zoom.translate.x < this.page.bounds.x2) { this.zoom.translate.x = this.page.bounds.x2; bounceBack = true; }
    if (this.zoom.translate.y > this.page.bounds.y1) { this.zoom.translate.y = this.page.bounds.y1; bounceBack = true; }
    if (this.zoom.translate.y < this.page.bounds.y2) { this.zoom.translate.y = this.page.bounds.y2; bounceBack = true; }
    
    // Store Translation Coordinates
    this.zoom.last.translate = $.extend({}, this.zoom.translate);
    
    // Move Pagebox into Place
    if (!bounceBackOnly || (bounceBackOnly && bounceBack)) {
        this.app.state.animating = true;
        this.app.overlayer.$carousel.pagebox.transition({'translate': [this.zoom.translate.x, this.zoom.translate.y]}, duration);
        this.sheets[this.current.sheet].$pageBox.transition({'translate': [this.zoom.translate.x, this.zoom.translate.y]}, duration, $.proxy(function() {
            this.app.state.animating = false;
            onComplete();
        }, this));
    } else {
        onComplete();
    }
    return this;
};

/**
 * Applies Momentum while Panning a Zoomed-In Sheet
 *   Should account for Velocity of Touch-Event to determine Distance and Speed of Panning.
 *   Should not exceed Bounds of Zoomed-In Sheet, or at least should bounce-back within bounds.
 *   
 *   Occurs after a Drag Event, not on Pinch Events
 *
 * @public
 * @this Flipbook.Carousel
 * @param {Object} eventData The Event Data from HammerJS
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Zoomer.prototype.applyMomentum = function(eventData) {
    var duration = 300;
    var axis = {'x': true, 'y': true};
    var distance = {'x': 0, 'y': 0};
    
    // Debug Message
    Flipbook.log('zoomer-apply-momentum');

    // Animation Flickers on Kindle Fire due to poor CSS Transition Support, duration of 0 fixes problem, but feels clunky.
    if (Shared.AMAZON_SILK) {
        duration = 0;
    }
    
    if (Flipbook.hasTouch(this.app) && eventData.gesture.deltaTime < (duration * 1.5)) {
        // Calculate relative distance moved
        distance.x = (this.zoom.pan.point.x - this.zoom.pan.start.x) * (this.zoom.scale / this.app.config.sheet.maxScale[this.app.viewport.orientation]); 
        distance.y = (this.zoom.pan.point.y - this.zoom.pan.start.y) * (this.zoom.scale / this.app.config.sheet.maxScale[this.app.viewport.orientation]);

        // Calculate relative duration
        //duration = Math.max(eventData.gesture.deltaTime * eventData.gesture.velocityX, eventData.gesture.deltaTime * eventData.gesture.velocityY) * 1.2;

        // Restrict Momentum to a Single Axis
        if (this.zoom.translate.x > this.page.bounds.x1 || this.zoom.translate.x < this.page.bounds.x2) { axis.x = false; }
        if (this.zoom.translate.y > this.page.bounds.y1 || this.zoom.translate.y < this.page.bounds.y2) { axis.y = false; }
        
        // Adjust Position of Page
        if (axis.x) { this.zoom.translate.x += Math.abs(distance.x * eventData.gesture.velocityX) * (distance.x < 0 ? -1 : 1); }
        if (axis.y) { this.zoom.translate.y += Math.abs(distance.y * eventData.gesture.velocityY) * (distance.y < 0 ? -1 : 1); }
    }
    
    // Apply Translation and Update Zoomed Image
    return this.applyTranslation(duration, false);
};

/**
 * Bounces the Zoomed-In Sheet back to the Sheet Boundaries when the Sheet is Panned outside of Bounds.
 *   Allows for Haptic feedback when trying to Pan beyond Sheet Boundaries.
 *   
 *   Occurs after a Pinch Event, not on Drag Events
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Zoomer.prototype.bounceToBoundary = function() {
    // Only Bounce if we have Zoomed In
    if (this.zoom.scale <= 1) { return this; }
    
    // Debug Message
    Flipbook.log('zoomer-bounce-back');
    
    // Apply Translation and Update Zoomed Image
    return this.applyTranslation(200, true);
};
