/**
 * Flyp Technologies Inc. - Flipbook v4
 * 
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/overlays/base.js - Flipbook.OverlayBase
 * @author Robert J. Secord, B.Sc.
 */
import Flipbook from '../core';
import Q from '/app/libs/promise/q';

/**
 * Flipbook OverlayBase
 *
 * @class OverlayBase
 * @classdesc Application Overlay Base Class
 * @namespace Flipbook
 * @param {Object} overlayer A reference to the Overlays Control
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.OverlayBase = function(overlayer, options) {

    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize    = null;
    var buildElements = null;
    var attachEvents  = null;


    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    this.app            = null;
    this.overlayer      = null;
    this.overlayName    = null;
    this.overlayOptions = null;
    

    /* **************************************************************************************** */
    /* * Private Methods/Members Definitions                                                  * */
    /* **************************************************************************************** */
    /**
     * Initialize the Overlay
     *
     * @private
     * @this Flipbook.OverlayBase
     * @return {Object} A reference to the OverlayBase Object for Method Chaining
     * @constructs
     */
    initialize = $.proxy(function(overlayer, options) {
        if (overlayer === undefined) { return; }

        // Store Reference to Overlayer & App Controls
        this.overlayer = overlayer;
        this.app       = overlayer.app;
        
        this.overlayOptions = $.extend(true, {}, Flipbook.OverlayBase.defaultOverlayOptions, options);
        
        // Set Tool Name
        this.overlayName = this.overlayOptions.name;
        
        // Debug Message
        Flipbook.log({'msg': 'overlay-initialize', 'args': {'overlay': this.overlayName}});
        
        return this;
    }, this);

    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize(overlayer, options);
};
// End of Flipbook.OverlayBase


/* ******************************************************************************************** */
/* * Abstract Methods                                                                         * */
/* ******************************************************************************************** */

/**
 * Abstract Base Method; Called when ...
 *   - note: override is optional, but MUST return a Promise
 *
 * @public
 * @abstract
 * @this Flipbook.OverlayBase
 * @return {Object} A Promise
 */
Flipbook.OverlayBase.prototype.resize = function() { return Q(true); };

/**
 * Abstract Base Method; Called when ...
 *   - note: override is optional, but MUST return a Promise
 *
 * @public
 * @abstract
 * @this Flipbook.OverlayBase
 * @return {Object} A Promise
 */
Flipbook.OverlayBase.prototype.add = function() { return Q(true); };

/**
 * Abstract Base Method; Called when ...
 *   - note: override is optional, but MUST return a Promise
 *
 * @public
 * @abstract
 * @this Flipbook.OverlayBase
 * @return {Object} A Promise
 */
Flipbook.OverlayBase.prototype.remove = function() { return Q(true); };

/**
 * Abstract Base Method; Called when ...
 *   - note: override is optional, but MUST return a Promise
 *
 * @public
 * @abstract
 * @this Flipbook.OverlayBase
 * @return {Object} A Promise
 */
Flipbook.OverlayBase.prototype.update = function() { return Q(true); };

/**
 * Abstract Base Method; Called when ...
 *   - note: override is optional, but MUST return a Promise
 *
 * @public
 * @abstract
 * @this Flipbook.OverlayBase
 * @return {Object} A Promise
 */
Flipbook.OverlayBase.prototype.flash = function() { return Q(true); };

/**
 * Abstract Base Method; Called when ...
 *
 * @public
 * @abstract
 * @this Flipbook.OverlayBase
 * @return undefined
 */
Flipbook.OverlayBase.prototype.toggleViewMode = function() {};


/* ******************************************************************************************** */
/* * Public Helper Methods                                                                    * */
/* ******************************************************************************************** */

/**
 * addTemplate
 * 
 * Helper: Add Overlay Template to Target Container as specified in Overlay Options
 *
 * @public
 * @this Flipbook.OverlayBase
 * @return {Mixed} - jQuery Element Object of the Template, or the Template HTML String.
 */
Flipbook.OverlayBase.prototype.addTemplate = function(templateTags) {
    // Append Template to Target and Return Element
    return Flipbook.buildFromTemplate({'template': this.overlayOptions.template, 'appendTo': this.getTargetPagebox(), 'returnAs': 'element', 'tags': templateTags});
};

/**
 * getTargetOverlay
 * 
 * Helper: Gets the Target Overlay Container as specified in Overlay Options
 *
 * @public
 * @this Flipbook.OverlayBase
 * @return {Object} - jQuery Element Object of the Overlay Container
 */
Flipbook.OverlayBase.prototype.getTargetOverlay = function() {
    return (this.overlayOptions.target === Flipbook.OVERLAY_TARGET_VIEWPORT) ? this.overlayer.$viewport.overlay : this.overlayer.$carousel.overlay;
};

/**
 * getTargetPagebox
 * 
 * Helper: Gets the Target Pagebox Container as specified in Overlay Options
 *
 * @public
 * @this Flipbook.OverlayBase
 * @return {Object} - jQuery Element Object of the Pagebox Container
 */
Flipbook.OverlayBase.prototype.getTargetPagebox = function() {
    return (this.overlayOptions.target === Flipbook.OVERLAY_TARGET_VIEWPORT) ? this.overlayer.$viewport.pagebox : this.overlayer.$carousel.pagebox;
};


/* ******************************************************************************************** */
/* * Static Public Members                                                                    * */
/* ******************************************************************************************** */

Flipbook.OverlayBase.defaultOverlayOptions = {
    'enabled'   : false,
    'name'      : 'unnamed',
    'template'  : '#tpl-std-overlay',
    'target'    : Flipbook.OVERLAY_TARGET_VIEWPORT,
    'classname' : ''
};
