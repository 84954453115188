import { augmentJquery } from '../hubs/legacyCodePollyfills/legacyCodePollyfills';
import 'jquery-migrate';
import $ from 'jquery';
import _ from 'lodash';
import Hammer from 'hammerjs';
import imagesLoaded from 'imagesloaded';

Hammer.utils.each(['on', 'off'], (method) => {
  Hammer.utils[method] = function applyEventManager(element, type, handler) {
    $(element)[method](type, function hammerEvent($ev) {
      // append the jquery fixed properties/methods
      const data = $.extend({}, $ev.originalEvent, $ev);
      if (data.button === undefined) {
        data.button = $ev.which - 1;
      }
      handler.call(this, data);
    });
  };
});

Hammer.Instance.prototype.trigger = function trigger(gesture, eventData) {
  let el = $(this.element);
  if (el.has(eventData.target).length) {
    el = $(eventData.target);
  }

  return el.trigger({
    gesture: eventData,
    type: gesture,
  });
};

$.fn.hammer = function hammer(options) {
  return this.each(function hammerify() {
    const el = $(this);
    const inst = el.data('hammer');

    if (!inst) {
      el.data('hammer', new Hammer(this, options || {}));
    } else if (inst && options) {
      Hammer.utils.extend(inst.options, options);
    }
  });
};

/**
 * Repalces jquery.cookie library with a pollyfill using the js-cookie
 * library, the modern equivalent of jquery.cookie
 */
augmentJquery.withCookie($);

/**
 * adds jquery.fn.imagesLoaded to jquery, allowing you to listen for image load events
 */
imagesLoaded.makeJQueryPlugin($);

// Re-add all the things to the global scope for legacy reasons
window.imagesLoaded = imagesLoaded;
window.$ = window.jQuery = window.$j = window.jquery = $; // eslint-disable-line no-multi-assign
window._ = _;

/**
 * Adds $.fn.transition, an alternative to $.fn.animate that
 * uses css transitions (applied programatically)
 * instead of modifying css frame by frame. Seems to still work with
 * jquery 3.x, not actively supported anymore.
 */
require('../../../libs/transition/jquery.transit.js');

/**
 * A promise library, no longer needed but will take some work to remove.
 */
require('../../../libs/promise/q.js');

/**
 * Dependency free smooth scroll library
 */
require('../../../libs/iscroll5/iscroll.js');

/**
 * "Mobile boiler plate" - outdated functions for helping with mobile development
 */
require('../../../libs/mbp/js/helper.js');

/**
 * Initialize libs object
 */
require('./flipbook_libs.js');
