/**
 * Flyp Technologies Inc. - Flipbook v4
 *
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/tools/sharing.js - Flipbook.SharingTool
 * @author Robert J. Secord, B.Sc.
 */
import 'jquery-migrate';
import $ from 'jquery';
import Flipbook from '../core';

/**
 * Flipbook Sharing Tool
 *
 * @class SharingTool
 * @classdesc Application Sharing Tool
 * @namespace Flipbook
 * @inherits Flipbook.ToolsBase
 * @param {Object} toolbar A reference to the Toolbar Control
 * @param {Object} toolData Configuration Settings for the Tool
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.SharingTool = function(toolbar, toolData) {
    // Call Parent Constructor
    Flipbook.ToolsBase.apply(this, [toolbar, toolData]);
    
    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize;
    var attachEvents;


    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    this.shareUrl = '';
    this.currentShareType = '';
    this.pageSelectDisplayed = false;
    

    /* **************************************************************************************** */
    /* * Private Methods/Members Definitions                                                  * */
    /* **************************************************************************************** */
    /**
     * Initialize the Sharing Tool
     *
     * @private
     * @this Flipbook.SharingTool
     * @return {Object} A reference to the SharingTool Object for Method Chaining
     * @constructs
     */
    initialize = $.proxy(function() {
        // Build Share URL
        this.shareUrl = this.app.config.urls.server + 
            Flipbook.getUrlType(this.app) + '/' +
            this.app.config.issueData.id + this.app.config.issueData.issueSlug +'/';

        attachEvents();
        return this;
    }, this);
    
    
    /**
     * Attach Events to the Share Elements
     *
     * @private
     * @this Flipbook.SharingTool
     * @return undefined
     */
    attachEvents = $.proxy(function() {
        // Iterate over Paging Links that are not already Hooked
        this.toolbar.$shareContainer.find('.sharing-tools > a').each($.proxy(function(idx, link) {
            var $link = $(link);
            
            // Attach Click/Tap Event to Paging Links
            Flipbook.onClickTap(this.app, $link, this.handleLinkClick, this, this.toolName + 'ShareLink' + idx);
        }, this));
    }, this);
    
    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize();
};
Flipbook.SharingTool.prototype = new Flipbook.ToolsBase();
Flipbook.SharingTool.prototype.constructor = Flipbook.SharingTool;
// End of Flipbook.SharingTool


/* ******************************************************************************************** */
/* * Overridden Base Methods                                                                  * */
/* ******************************************************************************************** */

/**
 * Override Abstract Base Method; Called when the Tool Panel is Opened
 *
 * @public
 * @this Flipbook.SharingTool
 * @return undefined
 */
Flipbook.SharingTool.prototype.panelOpened = function() {
    var $sharingTools = null;
    var $content = '';
    
    if (this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_TOP) {
        // Copy Sharing Tools HTML to Panel
        $sharingTools = this.toolbar.$container.find('.' + this.toolOptions.toolsContainer);
        if (!$sharingTools.length) { return; }
        
        // Populate Content Panel with Sharing Tools
        $content = Flipbook.buildFromTemplate({'template': this.toolOptions.sharePanelTpl, 'returnAs': 'element'});
        this.panelReplaceHtml($content, true).then($.proxy(function(promiseValue) {
            this.getPanel().find(this.app.config.toolbar.contentPanel.scroll.content + '-wrapper').append($sharingTools.clone(true));
        }, this));
    }
};

/**
 * Override Abstract Base Method; Called when the Tool Panel is Closed
 *
 * @public
 * @abstract
 * @this Flipbook.SharingTool
 * @return undefined
 */
Flipbook.SharingTool.prototype.panelClosed = function() {
    this.pageSelectDisplayed = false;
    this.markActiveTool();
};

/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Handles the Click/Tap Event on Sharing Links
 *
 * @public
 * @this Flipbook.SharingTool
 * @return {Boolean} Event bubble flag
 */
Flipbook.SharingTool.prototype.handleLinkClick = function(e) {
    var $el = $(e.currentTarget);
    var $sharingTools = null;
    var totalSheets = this.getTotalSheets();
    var currentSheet = this.getCurrentSheet();
    var currentPage = Flipbook.getPageFromSheet(this.app, currentSheet);
    var displayPage = currentPage + this.app.config.titleData.page_num_offset;
    var $panelContent = null;
    var $page1, $page2, img1, img2;
    var imgHeight = Math.round((120 / this.app.config.issueData.w1) * this.app.config.issueData.h1);
    
    var pageClick = function(e) {
        var $pageEl = $(e.currentTarget);
        var pageNum = parseInt($pageEl.attr('data-page'), 10) || 0;
        
        // Close Toolbar and Restore Content
        this.restoreContent();
        
        // Display Share Popup for Selected Page
        this.displaySharePopup(pageNum);
    };
    
    // Get Sharing Type
    this.currentShareType = $el.attr('data-type');
    if (!this.currentShareType) { return; }
    this.currentShareType = this.currentShareType.toLowerCase();
    
    // If the Sheet has 2 Pages, ask the user which one to share
    if (Flipbook.sheetHasTwoPages(this.app, totalSheets, currentSheet)) {
        
        // Mark Active Share Tool
        this.markActiveTool(this.currentShareType);
        
        // Display Page Selector (if not already displayed)
        if (!this.pageSelectDisplayed) {
        
            // Parse Page Images from Carousel
            img1 = $('#sheet' + currentSheet + '-page1 > .page-image').css('background-image');
            img2 = $('#sheet' + currentSheet + '-page2 > .page-image').css('background-image');
            img1 = img1.replace(/\-w\-\d+.jpg/i, '-w-120.jpg');
            img2 = img2.replace(/\-w\-\d+.jpg/i, '-w-120.jpg');
            
            // Build Panel Content from Template
            $panelContent = Flipbook.buildFromTemplate({'template': this.toolOptions.twoPagePanelTpl, 'returnAs': 'element'});
            
            // Find Elements within Content Panel
            $page1 = $panelContent.find('.page1');
            $page2 = $panelContent.find('.page2');

            // Add Page Images to Panel Content
            $page1.attr('data-page', currentPage).find('.img-wrapper').css({'height': imgHeight, 'background-image': img1});
            $page2.attr('data-page', currentPage + 1).find('.img-wrapper').css({'height': imgHeight, 'background-image': img2});

            // Add Captions to Page Images
            if (currentPage === 0) {
                $page1.find('span').html('cover');
            } else {
                $page1.find('span').html('page ' + displayPage);
            }
            $page2.find('span').html('page ' + (displayPage + 1));
            
            // Attach Click/Tap Events to Pages
            Flipbook.onClickTap(this.app, $page1, pageClick, this, 'SharingTool');
            Flipbook.onClickTap(this.app, $page2, pageClick, this, 'SharingTool');
            
            // Show the Panel
            if (this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_TOP) {
                this.panelAppendHtml($panelContent, this.app.config.toolbar.contentPanel.scroll.content + '-wrapper');
            } else {
                this.hidePanel().then($.proxy(function(promiseValue) {
                    this.showPanel().then($.proxy(function(promiseValue) {
                        this.panelReplaceHtml($panelContent, true);
                    }, this));
                }, this));
            }
            
            // Set Flag for Page Display State 
            this.pageSelectDisplayed = true;
        }
    } 
    
    // Only 1 Page on Sheet, Display Share Popup
    else {
        // Close Toolbar and Restore Content
        this.restoreContent();
        
        // Display Share Popup for Current Page
        this.displaySharePopup(currentPage);
    }
    
    return false;
};

/**
 * Displays a Popup Window for Sharing the Flipbook
 *
 * @public
 * @this Flipbook.SharingTool
 * @param {Number} currentPage - The current page being viewed (not offset by page-offset)
 * @return undefined
 */
Flipbook.SharingTool.prototype.displaySharePopup = function(currentPage) {
    var displayPage = currentPage + this.app.config.titleData.page_num_offset;
    var pageUrl = encodeURI(this.shareUrl + currentPage);
    var shareUrl = '';
    var shareMsg = '';
    var asEmail = (Flipbook.isMobile.any(this.app) && this.currentShareType === 'email');
    
    // Build Sharing Message
    shareMsg = this.app.config.titleData.title + ' - ' +  this.app.config.issueData.name + ' ';
    shareMsg += (currentPage > 0) ? '(page ' + displayPage + ')' : '(cover)';

    // Build Sharing URL
    switch (this.currentShareType) {
        case 'facebook':
            shareUrl = 'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(pageUrl) + '&t=' + encodeURIComponent(shareMsg);
            break;
                
        case 'twitter':
            shareUrl = 'http://twitter.com/share?url=' + encodeURIComponent(pageUrl) + '&text=' + encodeURIComponent(shareMsg);
            break;
                
        case 'linkedin':
            shareUrl = 'https://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(pageUrl) + '&summary=' + encodeURIComponent(shareMsg);
            break;
                
        case 'google':
            shareUrl = 'https://plus.google.com/share?url=' + encodeURIComponent(pageUrl);
            break;
                
        case 'email':
            shareUrl = 'mailto:?subject=A%20Flipbook%20has%20been%20shared%20with%20you!&body=' + encodeURIComponent(shareMsg + '\n\n') + encodeURIComponent(pageUrl);
            break;
    }
    
    // Open Popup for Sharing
    Flipbook.openUrl(this.app, shareUrl, {'type': asEmail ? 'email' : 'web'});
    
    // Track the Social Sharing Event
    this.trackSocialShareEvent(this.currentShareType, this.app.config.stats.id, Flipbook.getRealPageId(this.app, currentPage));
};

/**
 * Marks the Active Sharing Tool as Active
 *
 * @public
 * @this Flipbook.SharingTool
 * @param {string} activeTool The Currently Active Share Tool
 * @return undefined
 */
Flipbook.SharingTool.prototype.markActiveTool = function(activeTool) {
    // Mark Active Share Tool
    var i, $tool, $sharingTools = $('.' + this.app.config.toolbar.tools.SharingTool.toolsContainer);
    for (i = 0; i < $sharingTools.length; i++) {
        $tool = $($sharingTools[i]);
        if (activeTool !== undefined && activeTool.length) {
            $tool.addClass('active');
            $tool.find('.active').removeClass('active');
            $tool.find('[data-type=' + this.currentShareType + ']').addClass('active');
        } else {
            $tool.removeClass('active');
            $tool.find('.active').removeClass('active');
        }
    }
};
