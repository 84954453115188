/**
 * Flyp Technologies Inc. - Flipbook v4
 * 
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/slider.js - Flipbook.Slider
 * @author Robert J. Secord, B.Sc.
 */

import Flipbook from './core';

/**
 * Flipbook Carousel-Slider
 *
 * @class Slider
 * @augments Flipbook.Carousel
 * @classdesc Application Carousel-Slider Controller
 * @namespace Flipbook
 * @return {Object} The Class Instance
 * @constructor
 * @mixin
 */
Flipbook.Slider = function() {};


/* **************************************************************************************** */
/* * Public Properties                                                                    * */
/* **************************************************************************************** */

// Data for Touch Events on Carousel Slider
//  (This data is available accross entire Carousel Code [Carousel, Slider, Zoomer, Scrubber]) 
Flipbook.Slider.prototype.slide = {
    'initiated' : false, 
    'moved'     : false, 
    'start'     : {'x': 0, 'y': 0}, 
    'point'     : {'x': 0, 'y': 0}, 
    'position'  : {'x': 0, 'y': 0}, 
    'direction' : {'x': 0, 'y': 0}, 
    'snap'      : 0
};


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Initializes the Buffer for Slider Mode
 *    Clears all Unbuffered Sheets.
 *    Loads Visible Sheet First (if not already loaded).
 *    Loads all other Buffered Sheets (if not already loaded).
 *    
 * @public
 * @abstracted
 * @this Flipbook.Carousel
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Slider.prototype.sliderInitBuffer = function() {
    var i;
    var startIdx; 
    var bufferSize = this[this.viewMode + 'BufferSize'][this.app.viewport.orientation];
    var totalSheets = this.sheets.length;
    
    // Debug Message
    Flipbook.log('slider-init-buffer');

    // Get Starting Index for Loading Sheets
    startIdx = this.getSheetStartIndex();

    // Clear & Hide all unbuffered Sheets by default
    for (i = 0; i < totalSheets; i++) {
        if (i < startIdx || i >= startIdx + bufferSize) {
            this.sheets[i].clearSheet();
        }
    }

    // Populate Buffered Sheets (Load Focused Sheet First)
    this.loadSheet(this.current.sheet, true);
    for (i = 0; i < bufferSize; i++) {
        if (startIdx + i !== this.current.sheet) {
            this.loadSheet(startIdx + i, false);
        }
    }
    return this;
};

/**
 * Updates the Buffer for Slider Mode
 *    Loads 1 Sheet ahead and Unloads 1 Sheet behind in relation to the Slide Direction and Buffer Size.
 *      Sample Buffer:  5, 6, 7, 8, 9
 *      Current Sheet: 7
 *      If Move to Sheet 8: Unload 5, Load 10
 *      If Move to Sheet 6: Unload 9, Load 4
 *
 * @public
 * @abstracted
 * @this Flipbook.Carousel
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Slider.prototype.sliderUpdateBuffer = function() {
    var i;
    var bufferSize = this[this.viewMode + 'BufferSize'][this.app.viewport.orientation];
    var totalSheets = this.totalSheets[this.app.viewport.orientation];
    var midIndex = Math.round(bufferSize / 2) - 1;
    
    // Debug Message
    Flipbook.log('slider-update-buffer');
    
    // Check if Buffering is required
    if (totalSheets > bufferSize) {
        // Moving Forward
        if (this.slide.direction.x < 0) {
            // Only buffer sheets if not at the beginning or end of Flipbook
            if (this.current.sheet > midIndex && this.current.sheet < (totalSheets - midIndex)) {
                // Unload Sheets outside of Buffer
                this.unloadSheet(this.current.sheet - midIndex - 1);
                
                // Load Sheet Content
                this.loadSheet(this.current.sheet + midIndex, false);
            }
        }
        
        // Moving Backward
        else {
            // Only buffer sheets if not at the beginning or end of Flipbook
            if (this.current.sheet >= midIndex && this.current.sheet < (totalSheets - midIndex - 1)) {
                // Unload Sheets outside of Buffer
                this.unloadSheet(this.current.sheet + midIndex + 1);
                
                // Load Sheet Content
                this.loadSheet(this.current.sheet - midIndex, false);
            }
        }
    }
    return this;
};

/**
 * Event: Drag
 *   Handles Sliding to Next/Previous Sheet (1 Sheet at a Time in Slider Mode)
 *
 * @public
 * @abstracted
 * @this Flipbook.Carousel
 * @param {Object} e The Event Data from HammerJS
 * @return undefined
 */
Flipbook.Slider.prototype.sliderHandleDrag = function(e) {
    var deltaX, thetaX, newX;
    var distance;
    var touch = Flipbook.hasTouch(this.app);
    
    // Disable browser scrolling
    if (touch) {
        e.gesture.preventDefault();
    } else {
        e.preventDefault();
    }
    e.stopPropagation();
    
    // Prevent Sliding While Scrubbing, Pinching or Panning
    if (this.scrub.initiated || this.zoom.pinch.initiated || this.zoom.pan.initiated) { return true; }
    
    // Prevent Sliding when Flipbook State is Animating or Shifted
    if (this.app.state.animating || this.app.state.shifted) { return true; }

    // Must be a Single-Touch Event, with a Scale of 1
    if (this.zoom.scale !== 1) { return true; }
    
    switch(e.type) {
        case 'dragstart':
        case 'mousedown':
            if (this.slide.initiated) { return; }

            // Setup Vars
            this.slide.initiated = true;
            this.slide.moved = false;
            this.slide.start.x = touch ? e.gesture.center.pageX : e.pageX;
            this.slide.point.x = touch ? e.gesture.center.pageX : e.pageX;
            this.slide.direction.x = 0;
            
            // Set Transition Duration to Zero so Animation is not delayed while sliding/moving
            this.$scrollDiv[0].style[Modernizr.prefixed('transitionDuration')] = '0s';
            break;
            
        case 'drag':
        case 'mousemove':
            if (!this.slide.initiated) { return; }

            // Calculate New Position/Distance Moved
            deltaX = (touch ? e.gesture.center.pageX : e.pageX) - this.slide.point.x; // used to update position while dragging (relative to last animation point)
            thetaX = (touch ? e.gesture.center.pageX : e.pageX) - this.slide.start.x; // used to update direction of drag (relative to drag start point)
            newX = this.slide.position.x + deltaX;
            
            // Prevent moving beyond a single sheet at one time
            if (distance >= this.containerSize.width) {
                // Force-End the Touch Event
                this.sliderDragFinished(this.containerSize.width - 1);
                return false;
            }
            
            // Track current position/distance
            this.slide.moved = true;
            this.slide.point.x = touch ? e.gesture.center.pageX : e.pageX;
            this.slide.direction.x = thetaX > 0 ? 1 : thetaX < 0 ? -1 : 0;
            
            // Fix New X based on Position
            // - prevents pushing first/last page off-screen
            if (newX > 0 || newX < this.current.maxscroll) {
                newX = this.slide.position.x + (deltaX / 2);
            }
            
            // Set New Position of Slider
            this.slide.position.x = newX;
            this.setPosition(newX);
            break;
            
        case 'dragend':                
        case 'mouseup':
            this.slide.initiated = this.slide.moved;
            if (!this.slide.initiated) { return; }
            if (touch) { e.gesture.stopDetect(); } // prevent release event
            
            // Calculate Distance Moved
            distance = Math.abs((touch ? e.gesture.center.pageX : e.pageX) - this.slide.start.x);

            // Touch Event has Ended
            this.sliderDragFinished(distance);
            break;
    }
};

/**
 * Finalizes Drag Events; Called when Drag is Finished
 *   Ensures we moved the Slider by a minimum distance, otherwise snaps back to previous position.
 *
 * @public
 * @this Flipbook.Carousel
 * @param {Number} distance The distance in pixels traversed through touch/drag
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Slider.prototype.sliderDragFinished = function(distance) {
    var speed;
    
    // Debug Message
    Flipbook.log('carousel-drag-finished');
    
    // Touch Event has Ended
    this.slide.initiated = false;

    // Move out of bounds (snap back)
    if (this.slide.position.x > 0 || this.slide.position.x < this.current.maxscroll) {
        // Set Distance to just less than Snap Threshold so that Snap-Back has smooth animation
        distance = this.containerSize.width * (this.app.config.carousel.snapThresholdPct - 0.01);
    }

    // Check if we have not exceeded the snap threshold (restore old position)
    if (distance < this.slide.snap) {
        speed = Math.floor(300 * distance / this.slide.snap);
        this.moveToSheet(this.current.sheet, speed);
        return this;
    }

    // Exceeded snap threshold, move to new position
    return this.sliderCheckPosition();
};

/**
 * Check the Position of the Carousel
 *   Checks where we stopped Dragging, and centers on the New Sheet in Focus.
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A Promise
 */
Flipbook.Slider.prototype.sliderCheckPosition = function() {
    var speed = 0;
    var newSheet = 0;
    
    // Debug Message
    Flipbook.log('slider-check-position');
    
    // Determine Direction to Move
    if (this.slide.direction.x > 0) {
        newSheet = -Math.ceil(this.slide.position.x / this.containerSize.width);
    } else {
        newSheet = -Math.floor(this.slide.position.x / this.containerSize.width);
    }
    
    // Determine animation speed based on distance moved
    speed = Math.floor(500 * Math.abs(this.slide.position.x - (-newSheet * this.containerSize.width)) / this.containerSize.width);

    // Update ScrollDiv to New Position
    return this.moveToSheet(newSheet, speed);
};

/**
 * Event: Tap/DoubleTap
 *   Automatically Zooms-In to Center-Point, or Zooms-Out from Center-Point
 *
 * @public
 * @abstracted
 * @this Flipbook.Carousel
 * @param {Object} e The Event Data from HammerJS
 * @return undefined
 */
Flipbook.Slider.prototype.sliderHandleTap = function(e) {
    var point = {'pageX': 0, 'pageY': 0};
    var shiftKey = false;
    var offsetX = 0;
    var offsetY = 0;
    
    if (this.app.state.animating || this.app.state.shifted) { return; }

    e.stopPropagation();
    //e.preventDefault();
    
    // Debug Message
    Flipbook.log('carousel-click-tap');
    
    // Double Tap/Click
    if (/doubletap|dblclick/i.test(e.type)) { 
        // Get Event Data
        // Mouse Click:
        if (e.type === 'dblclick') {
            // Get Shift Key
            shiftKey = e.shiftKey;
            
            // Get Point of Tap Event
            point.pageX = e.pageX;
            point.pageY = e.pageY;
        } 
        // Tap Gesture:
        else {
            // Get Shift Key
            shiftKey = e.gesture.srcEvent.shiftKey;
            
            // Get Point of Tap Event
            point.pageX = e.gesture.center.pageX;
            point.pageY = e.gesture.center.pageY;
        }
        
        // Zoom Out: Switch to Scrubber
        if (shiftKey) {
            this.app.forceScrubberMode();
        } 
        
        // Zoom In
        else {
            offsetX = this.page.offset.x;
            offsetY = this.page.offset.y;
            if (this.app.config.toolbar.enabled && this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_LEFT) {
                offsetX += this.app.config.toolbar.size[this.app.viewport.breakpoint][this.app.config.toolbar.position].closed / 2;
            }
            if (this.app.config.toolbar.enabled && this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_TOP) {
                offsetY += this.app.config.toolbar.size[this.app.viewport.breakpoint][this.app.config.toolbar.position].closed / 2;
            }
            
            // Get Point of Tap Event
            point.pageX += (this.zoom.translate.x - offsetX);
            point.pageY += (this.zoom.translate.y - offsetY);
            
            // Adjust Scale
            this.zoom.scale = this.app.config.sheet.maxScale[Flipbook.getPageOrientation(this.app)]; 
            
            // Zoom to Scale
            this.autoZoom(point);
        }
        
        // Prevent Click on Parent Element
        //if (!Flipbook.hasTouch(this.app)) {
            this.slide.initiated = false;
            this.scrub.initiated = false;
        //}
    } 
    
    // Single Tap/Click
    else {
        // Display Pagebar
        if (this.app.config.pagebar.enabled) {
            this.app.pagebar.showTip(1000, this.app.config.pagebar.hideDelay);
        }
        
        // Toggle Navigation Controls
        this.app.navigation.tapped();
    }
};

/**
 * Event: KeyDown
 *   Slides the Slider 1 Sheet at a time, in the direction of the Arrow Key pressed.
 *
 * @public
 * @abstracted
 * @this Flipbook.Carousel
 * @param {Object} e The Event Data from jQuery
 * @return undefined
 */
Flipbook.Slider.prototype.sliderKeyPress = function(e) {
    if (this.app.state.animating || this.app.state.shifted) { return; }
    if (/input|textarea/i.test($(e.target).prop('tagName'))) { return; }
    e.preventDefault();
    
    // Debug Message
    Flipbook.log('carousel-key-press');
    
    switch (e.which) {
        case Flipbook.ARROW_KEY_UP:
        case Flipbook.ARROW_KEY_LEFT:
        case Flipbook.NUMPAD_KEY_4: // Left Arrow
        case Flipbook.NUMPAD_KEY_8: // Up Arrow
        case Flipbook.NUMPAD_KEY_9: // PGUP
        case Flipbook.PGUP_KEY:
        case Flipbook.WII_UP:
        case Flipbook.WII_LEFT:
            this.sliderMovePrev();
            break;
        case Flipbook.ARROW_KEY_RIGHT:
        case Flipbook.ARROW_KEY_DOWN:
        case Flipbook.NUMPAD_KEY_2: // Down Arrow
        case Flipbook.NUMPAD_KEY_6: // Right Arrow
        case Flipbook.NUMPAD_KEY_3: // PGDN
        case Flipbook.PGDN_KEY:
        case Flipbook.WII_DOWN:
        case Flipbook.WII_RIGHT:
            this.sliderMoveNext();
            break;
        case Flipbook.Z_KEY:
            this.zoom.scale = this.app.config.sheet.maxScale[Flipbook.getPageOrientation(this.app)]; 
            this.autoZoom({'pageX': this.page.size.current.x / 2, 'pageY': this.page.size.current.y / 2});
            break;
    }
};

/**
 * Slides the Carousel to the First Sheet
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A Promise
 */
Flipbook.Slider.prototype.sliderMoveFirst = function() {
    var newSheet = 0;
    
    // Debug Message
    Flipbook.log('carousel-move-first');
    
    // Determine new sheet to display
    if (this.current.sheet === newSheet) {
        return this.sliderBounceBack(true);
    }
    this.slide.direction.x = 1;

    // Update ScrollDiv to New Position
    return this.moveToSheet(newSheet, this.app.config.carousel.defaultSlideSpeed);
};

/**
 * Slides the Carousel to the Last Sheet
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A Promise
 */
Flipbook.Slider.prototype.sliderMoveLast = function() {
    var newSheet = this.totalSheets[this.app.viewport.orientation] - 1;
    
    // Debug Message
    Flipbook.log('carousel-move-last');
    
    // Determine new sheet to display
    if (this.current.sheet === newSheet) {
        return this.sliderBounceBack(false);
    }
    this.slide.direction.x = -1;

    // Update ScrollDiv to New Position
    return this.moveToSheet(newSheet, this.app.config.carousel.defaultSlideSpeed);
};

/**
 * Slides the Carousel to the Next Sheet
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A Promise
 */
Flipbook.Slider.prototype.sliderMoveNext = function() {
    var newSheet = 0;
    
    // Debug Message
    Flipbook.log('carousel-move-next');
    
    // Determine new sheet to display
    newSheet = this.current.sheet + 1;
    if (newSheet >= this.totalSheets[this.app.viewport.orientation]) {
        newSheet = this.totalSheets[this.app.viewport.orientation] - 1;
        return this.sliderBounceBack(false);
    }
    this.slide.direction.x = -1;

    // Update ScrollDiv to New Position
    return this.moveToSheet(newSheet, this.app.config.carousel.defaultSlideSpeed);
};

/**
 * Slides the Carousel to the Previous Sheet
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A Promise
 */
Flipbook.Slider.prototype.sliderMovePrev = function() {
    var newSheet = 0;
    
    // Debug Message
    Flipbook.log('carousel-move-prev');
    
    // Determine new sheet to display
    newSheet = this.current.sheet - 1;
    if (newSheet < 0) {
        newSheet = 0;
        return this.sliderBounceBack(true);
    }
    this.slide.direction.x = 1;

    // Update ScrollDiv to New Position
    return this.moveToSheet(newSheet, this.app.config.carousel.defaultSlideSpeed);
};

/**
 * Bounces the Slider back within Bounds when Moved beyond First or Last Sheet in Carousel.
 *   Allows for Haptic feedback when trying to Slide beyond Carousel Boundaries.
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Slider.prototype.sliderBounceBack = function(forward) {
    var x = [100, 0], speed = 200;
    var startOptions = {}, endOptions = {};
    if (!forward) { x = [this.current.maxscroll - 100, this.current.maxscroll]; }
    
    startOptions[Flipbook.TRANSITION_MODIFIER] = x[0];
    endOptions[Flipbook.TRANSITION_MODIFIER] = x[1];
    
    // Animate Scroll Div to Bounce Back
    this.app.state.animating = true;
    this.$scrollDiv.transition(startOptions, speed).transition(endOptions, speed, $.proxy(function() {
        this.app.state.animating = false;
    }, this));
    return this;
};
