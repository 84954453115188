/**
 * Flyp Technologies Inc. - Flipbook v4
 *
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/overlays/notes.js - Flipbook.NotesOverlay
 * @author Robert J. Secord, B.Sc.
 */
import 'jquery-migrate';
import $ from 'jquery';
import Flipbook from '../core';
import IScroll from '/app/libs/iscroll5/iscroll';
import Shared from '../shared_util';
import Q from '/app/libs/promise/q';

/**
 * Flipbook Notes Overlay Controller
 *
 * @class NotesOverlay
 * @classdesc Application Notes Overlay
 * @namespace Flipbook
 * @inherits Flipbook.OverlayBase
 * @param {Object} overlayer A reference to the Overlayer Control
 * @param {Object} overlayOptions Configuration Settings for the Overlay
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.NotesOverlay = function(overlayer, overlayOptions) {
    // Call Parent Constructor
    Flipbook.OverlayBase.apply(this, [overlayer, overlayOptions]);
    
    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize    = null;

    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    
    // Notes Data
    this.notesData = null;
    
    // New Note Button
    this.$newNotesButton = null;
    
    // Overlay Notes
    this.$overlayNoteEls = [];

    /* **************************************************************************************** */
    /* * Private Methods/Members Definitions                                                  * */
    /* **************************************************************************************** */
    /**
     * Initialize the Notes Overlay
     *
     * @private
     * @this Flipbook.NotesOverlay
     * @return {Object} A reference to the NotesOverlay Object for Method Chaining
     * @constructs
     */
    initialize = $.proxy(function() {
        // Copy Notes Data Locally
        this.notesData = _.map(this.app.parsedNotesData.note, 'Annotation'); // using the `_.property` callback shorthand
        return this;
    }, this);
    
    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize();
};
Flipbook.NotesOverlay.prototype = new Flipbook.OverlayBase();
Flipbook.NotesOverlay.prototype.constructor = Flipbook.NotesOverlay;
// End of Flipbook.NotesOverlay


/* ******************************************************************************************** */
/* * Overridden Base Methods                                                                  * */
/* ******************************************************************************************** */

/**
 * Override Abstract Base Method; Called when ...
 *
 * @public
 * @this Flipbook.NotesOverlay
 * @return {Object} A Promise
 */
Flipbook.NotesOverlay.prototype.resize = function() {
    return Q.Promise($.proxy(function(resolve, reject, notify) {
        // Debug Message
        Flipbook.log('overlay-resize-notes');
        
        // Build New-Note Button
        if (!this.$newNotesButton) {
            this.$newNotesButton = $('<div class="new-note-button"><span></span></div>').appendTo(this.getTargetPagebox());
            Flipbook.onClickTap(this.app, this.$newNotesButton, this.handleNewNoteEvent, this, 'OverlayNote');
        }
        
        // Update Sizes
        this.calculateSizes();
        
        // Resize Complete; Resolve Promise
        resolve(true);
    }, this));
};

/**
 * Override Abstract Base Method; Called when ...
 *
 * @public
 * @this Flipbook.NotesOverlay
 * @return {Object} A Promise
 */
Flipbook.NotesOverlay.prototype.add = function() {
    return Q.Promise($.proxy(function(resolve, reject, notify) {
        var i, j, noteCount = 0;
        var $noteContainer;
        var pageId;
        var notes = [];
        var filterFunc = function(pid) {
            return function(obj) { return obj.page_id === pid; };
        };

        // Debug Message
        Flipbook.log('overlay-add-notes');
        
        // Iterate Current Pages
        for (i = 0; i < this.app.stats.currentPageIds.length; i++) {
            pageId = this.app.stats.currentPageIds[i];
            
            // Find Notes for Current Page
            notes = _.filter(this.notesData, filterFunc(pageId));
            for (j = 0; j < notes.length; j++) {
                // Create New Note Element
                if (noteCount >= this.$overlayNoteEls.length) {
                    // Create Note Element
                    $noteContainer = this.addNoteElement();
                    
                    // Add to Internal Notes Array for later Reuse
                    this.$overlayNoteEls.push($noteContainer);
                }
                
                // Apply Style & Attributes to Note
                this.$overlayNoteEls[noteCount]
                    .attr('data-id', notes[j].id)
                    .attr('data-pageid', notes[j].page_id)
                    .attr('data-pagefl', notes[j].page_fl)
                    .attr('data-coords', notes[j].coordinate_x + ',' + notes[j].coordinate_y)
                    .attr('data-pagespread', i)
                    .css({'display' : 'block'});
                
                if (notes[j].note === null) {
                    notes[j].note = '';
                }
                
                // Update Note Header
                this.$overlayNoteEls[noteCount].find('.note-header .note-created').empty().html('created: ' + notes[j].created);
                
                // Update Note Contents
                this.$overlayNoteEls[noteCount].find('.note-body .view-note .note-scroller').empty().html('<p>' + notes[j].note + '</p>');
                this.$overlayNoteEls[noteCount].find('.note-body .edit-note .note-textarea').val(notes[j].note);
                
                // Reposition Note
                this.repositionNote(this.$overlayNoteEls[noteCount]);
                
                // Count Notes Added to Page
                noteCount++;
            }
        }
        
        // Overlay Added; Resolve Promise
        resolve({'type': 'Note', 'count': noteCount});
    }, this));
};

/**
 * Override Abstract Base Method; Called when ...
 *
 * @public
 * @this Flipbook.NotesOverlay
 * @return {Object} A Promise
 */
Flipbook.NotesOverlay.prototype.remove = function() {
    return Q.Promise($.proxy(function(resolve, reject, notify) {
        var noteCount = this.getTargetPagebox().find('.fb-annotation:visible').length;
        
        // Debug Message
        Flipbook.log('overlay-remove-notes');
        
        // Hide Note Elements
        for (var i = 0, n = this.$overlayNoteEls.length; i < n; i++) {
            this.removeNoteElement(this.$overlayNoteEls[i]);
        }
        
        // Overlay Removed; Resolve Promise
        resolve({'type': 'Note', 'count': noteCount});
    }, this));
};

/**
 * Override Abstract Base Method; Called when ...
 *
 * @public
 * @this Flipbook.NotesOverlay
 * @return {Object} A Promise
 */
Flipbook.NotesOverlay.prototype.update = function() {
    return Q.Promise($.proxy(function(resolve, reject, notify) {
        // Hide Note Elements
        for (var i = 0, n = this.$overlayNoteEls.length; i < n; i++) {
            this.repositionNote(this.$overlayNoteEls[i]);
        }
        
        // Resolve with Count of Visible Notes
        resolve({'type': 'Note', 'count': this.getTargetPagebox().find('.fb-annotation:visible').length});
    }, this));
};

/**
 * Override Abstract Base Method; Called when ...
 *
 * @public
 * @this Flipbook.NotesOverlay
 * @return undefined
 */
Flipbook.NotesOverlay.prototype.toggleViewMode = function() {
    var visibleButton = (this.app.carousel.viewMode !== Flipbook.CAROUSEL_SCRUBBER && this.app.carousel.viewMode !== Flipbook.CAROUSEL_ZOOMER);
    if (this.$newNotesButton) {
        this.$newNotesButton.css({'display': (visibleButton) ? 'block' : 'none'});
    }
};


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Calculate the Size/Position of the Note Elements based on the Size of the Page
 *
 * @public
 * @this Flipbook.NotesOverlay
 * @return undefined
 */
Flipbook.NotesOverlay.prototype.calculateSizes = function() {
    var i, $pageData;
    var pageWidth, pageHeight, scaleRatio;
    var pageDisplayWidth = this.app.config.sheet.pageSize[this.app.viewport.orientation].w;
    
    // Debug Message
    Flipbook.log('overlay-calc-size-notes');
    
    // Calculate Sizes of Page Notes
    for (i = 0; i < this.notesData.length; i++) {
        $pageData = this.app.config.$issueSettings.find('pages > page[realid=' + this.notesData[i].page_id + ']');
        if (!$pageData.length) { continue; }
        
        // Get the Original Page Size
        pageWidth = (_.parseInt($pageData.attr('width'), 10) || 0);
        pageHeight = (_.parseInt($pageData.attr('height'), 10) || 0);
        
        // Get the Scale Ratio for the Original Page to the Display Page
        scaleRatio = pageDisplayWidth / pageWidth;
        
        // Store Scaled Sizing for Note
        this.notesData[i].scaled = {
            'x' : Math.round((_.parseInt(this.notesData[i].coordinate_x, 10) / 100) * pageWidth * scaleRatio),
            'y' : Math.round((_.parseInt(this.notesData[i].coordinate_y, 10) / 100) * pageHeight * scaleRatio)
        };
    }
};

/**
 * 
 * 
 * @public
 * @this Flipbook.NotesOverlay
 * @return undefined
 */
Flipbook.NotesOverlay.prototype.repositionNote = function($noteEl, point) {
    var noteId;
    var noteData;
    var notePageSpread;
    var position = {'left': 0, 'top': 0};
    var size = {'width': 0, 'height': 0};
    var pageWidth = this.app.config.sheet.pageSize[this.app.viewport.orientation].w;
    var pageHeight = this.app.config.sheet.pageSize[this.app.viewport.orientation].h;
    
    if (!$noteEl.length) { return; }
    
    // Get Data for Note
    if (point === undefined) {
        noteId = $noteEl.attr('data-id');
        noteData = _.find(this.notesData, function(obj) { return obj.id === noteId; });
        
        // Determine Note Position (for a Closed Note)
        position.top = noteData.scaled.y;
        position.left = noteData.scaled.x;
    } else {
        position.top = point.y;
        position.left = point.x;
    }
    
    // Determine Correct Left Position
    notePageSpread = _.parseInt($noteEl.attr('data-pagespread'), 10);
    if (notePageSpread > 0 || (this.app.carousel.current.sheet === 0 && Flipbook.isFirstPageOnRight(this.app))) {
        position.left += pageWidth;
    }
    
    // Reposition Opened Note
    if ($noteEl.hasClass(this.app.config.overlayer.overlays.NotesOverlay.openClass)) {
        size.width = $noteEl.outerWidth();
        size.height = $noteEl.outerHeight();

        if (position.left + size.width > (pageWidth * this.app.stats.currentPageIds.length)) {
            position.left -= (position.left + size.width - (pageWidth * this.app.stats.currentPageIds.length));
        }
        if (position.top + size.height > pageHeight) {
            position.top -= (position.top + size.height - pageHeight);
        }
    }
    
    // Set Note Position
    $noteEl.css(position);
};

/**
 * Handles the Event when a New Note is Added to a Page
 * 
 * @public
 * @this Flipbook.NotesOverlay
 * @return undefined
 */
Flipbook.NotesOverlay.prototype.handleNewNoteEvent = function(e) {
    var touch = Flipbook.hasTouch(this.app);

    if(Flipbook.isWindowsOS()) {
        touch = false;
    }

    var pageWidth = this.app.config.sheet.pageSize[this.app.viewport.orientation].w;
    var pageHeight = this.app.config.sheet.pageSize[this.app.viewport.orientation].h;
    var $overlay = this.app.modal.getOverlay();
    var namespace = 'OverlayNote';
    var toolbarSize = 0;
    var pagespread = 0;
    
    // Close any other Opened Notes
    $('.fb-annotation').removeClass(this.app.config.overlayer.overlays.NotesOverlay.openClass);
    
    // Alert User of Required Action
    this.app.modal.content('tap anywhere on a page to create a note, or click outside the page(s) to cancel', {'width':320, 'height':40, 'padding': 10, 'align': 'center', 'pointerEvents': 'none'});
    
    // Wait for User to Click/Tap an area of the screen
    Flipbook.onClickTap(this.app, $overlay, function(e) {
        var $note, point, pagesOnSheet = this.app.stats.currentPageIds.length;
        
        // Remove Event Handler for Click/Tap until next time a Note is Added
        Flipbook.offClickTap(this.app, $overlay, namespace);
        
        // Determine Point User Clicked/Tapped
        point = {
            'x' : touch ? e.gesture.center.pageX : e.pageX,
            'y' : touch ? e.gesture.center.pageY : e.pageY
        };
        
        // Adjust Point based on Page Offset
        point.x -= this.app.carousel.page.offset.x;
        point.y -= this.app.carousel.page.offset.y;
        
        // Adjust Point based on Toolbar Position
        if (this.app.config.toolbar.enabled) {
            if (this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_LEFT) {
                toolbarSize = this.app.config.toolbar.size[this.app.viewport.breakpoint][Flipbook.TOOLBAR_POS_LEFT].closed;
                point.x -= (toolbarSize / 2);
            } else {
                toolbarSize = this.app.config.toolbar.size[this.app.viewport.breakpoint][Flipbook.TOOLBAR_POS_TOP].closed;
                point.y -= toolbarSize;
            }
        }
        
        // Click within bounds?
        if (point.x < 0 || point.y < 0 || point.x > (pageWidth * pagesOnSheet) || point.y > pageHeight) {
            return;
        }
        
        // Ensure point does not overflow Page
        if (point.x > ((pageWidth * pagesOnSheet) - 15)) { point.x -= (point.x - ((pageWidth * pagesOnSheet) - 15)); }
        if (point.y > (pageHeight - 15)) { point.y -= (point.y - (pageHeight - 15)); }
        
        // Create Note Element
        $note = this.addNoteElement();
        $note.attr('data-id', 0).css({'display' : 'block', 'top' : point.y, 'left': point.x});
        
        // Account for Page on Right Side
        if (point.x > pageWidth) {
            pagespread = 1;
            point.x -= pageWidth;
        }
        $note.attr('data-pagespread', pagespread);
        
        // Get Page ID and FL
        if (this.app.carousel.current.sheet === 0 && Flipbook.isFirstPageOnRight(this.app)) { pagespread = 0; }
        $note.attr('data-pageid', this.app.stats.currentPageIds[pagespread]);
        $note.attr('data-pagefl', this.app.stats.currentPageFLs[pagespread]);
        
        // Enable Edit Mode
        $note.addClass(this.app.config.overlayer.overlays.NotesOverlay.openClass);
        $note.addClass(this.app.config.overlayer.overlays.NotesOverlay.editClass);
        
        // Reposition Note
        this.repositionNote($note, point);
        
        // Calculate Percentage of Point
        point.x = Math.round(point.x / pageWidth * 100);
        point.y = Math.round(point.y / pageHeight * 100);
        $note.attr('data-coords', point.x + ',' + point.y);
        
        // Add to Internal Notes Array for later Reuse
        this.$overlayNoteEls.push($note);
    }, this, namespace);
};

/**
 * Adds a Bookmark Element to the Page
 * 
 * @public
 * @this Flipbook.NotesOverlay
 * @return {Element} The Bookmark Element
 */
Flipbook.NotesOverlay.prototype.addNoteElement = function() {
    // Create Link
    var $note = this.addTemplate();
    
    // Create Scrollbar Object
    var $scroll = new IScroll($note.find('.view-note')[0], {
        'scrollbars'            : 'custom',
        'mouseWheel'            : true,
        'interactiveScrollbars' : true,
        'shrinkScrollbars'      : 'clip',
        'fadeScrollbars'        : false
    });

    // Attach Keypress Event to Note
    var $textarea = $note.find('.note-textarea');
    if ($textarea.length) {
        $textarea.on('keyup.Flipbook.OverlayNote', $.proxy(function(e) {
            return this.limitChars($note, e);
        }, this));
        
        Flipbook.onClickTap(this.app, $textarea, function() { $textarea.focus(); }, this, 'OverlayNote');
    }
    
    // Attach Click/Tap Event to Note
    Flipbook.onClickTap(this.app, $note, this.handleNoteClick($note, $scroll), this, 'OverlayNote');
    
    return $note;
};

/**
 * 
 * 
 * @public
 * @this Flipbook.NotesOverlay
 * @return undefined
 */
Flipbook.NotesOverlay.prototype.removeNoteElement = function($noteEl) {
    if (typeof $noteEl === 'string') { $noteEl = $($noteEl); }
    if (!$noteEl.length) { return; }
    $noteEl
        .css({'display': 'none'})
        .removeAttr('style')
        .removeClass(this.app.config.overlayer.overlays.NotesOverlay.openClass)
        .removeClass(this.app.config.overlayer.overlays.NotesOverlay.editClass);
};

/**
 * Handles Click Event on Notes
 *
 * @public
 * @this Flipbook.NotesOverlay
 * @return undefined
 */
Flipbook.NotesOverlay.prototype.handleNoteClick = function($noteEl, $scrollEl) {
    return $.proxy(function(e) {
        var noteId = $noteEl.attr('data-id');
        var $targetElement = $(e.target);
        var targetAction = '';
        
        // Get Target Action
        if (/span/i.test($targetElement.prop('tagName'))) {
            $targetElement = $targetElement.parent();
        }
        targetAction = $targetElement.attr('data-action');
        
        if (targetAction !== undefined) {
            e.stopPropagation();
        }
        e.preventDefault();
        
        // Debug Message
        Flipbook.log({'msg': 'overlay-note-clicked', 'args': {'id': noteId, 'action': targetAction}});
        
        // Close Button Clicked
        if (/close/i.test(targetAction)) {
            this.closeNote($noteEl);
        }
        
        // Edit Button Clicked
        else if (/edit/i.test(targetAction)) {
            this.editNote($noteEl);
        }
        
        // Cancel Button Clicked
        else if (/cancel/i.test(targetAction)) {
            this.cancelNote($noteEl);
        }
        
        // Save Button Clicked
        else if (/save/i.test(targetAction)) {
            this.saveNote($noteEl);
        }
        
        // Delete Button Clicked
        else if (/delete/i.test(targetAction)) {
            this.deleteNote($noteEl);
        }
        
        // Note Clicked
        else {
            // Open Note if not Opened
            if (!$noteEl.hasClass(this.app.config.overlayer.overlays.NotesOverlay.openClass)) {
                // Close any other Opened Notes
                $('.fb-annotation').removeClass(this.app.config.overlayer.overlays.NotesOverlay.openClass);

                // Add Opened Class
                $noteEl.addClass(this.app.config.overlayer.overlays.NotesOverlay.openClass);
                
                // Reposition Note
                this.repositionNote($noteEl);
            }
        }
        
        // Refresh Scroller Element
        Shared.onNextEventLoop(function() {
            $scrollEl.refresh();
        });
        
        return false;
    }, this);
};

/**
 * Event Handler: Close Note
 * 
 * @public
 * @this Flipbook.NotesOverlay
 * @return undefined
 */
Flipbook.NotesOverlay.prototype.closeNote = function($noteEl) {
    var noteId = 0;
    if (!$noteEl.length) { return; }
    
    // Get Note ID
    noteId = _.parseInt($noteEl.attr('data-id'), 10);
    
    // Discard Note if Never Saved
    if (noteId < 1) {
        this.removeNoteElement($noteEl);
    } else {
        // Remove Opened Class
        $noteEl.removeClass(this.app.config.overlayer.overlays.NotesOverlay.openClass);
        
        // Disable Edit Mode
        $noteEl.removeClass(this.app.config.overlayer.overlays.NotesOverlay.editClass);
        
        // Reposition Note
        this.repositionNote($noteEl);
    }
};

/**
 * Event Handler: Close Note
 * 
 * @public
 * @this Flipbook.NotesOverlay
 * @return undefined
 */
Flipbook.NotesOverlay.prototype.editNote = function($noteEl) {
    var noteText = '';
    if (!$noteEl.length) { return; }
    
    // Ensure Textarea has Latest Text
    noteText = $noteEl.find('.note-body .view-note .note-scroller p').html();
    $noteEl.find('.note-body .edit-note .note-textarea').val(noteText);
    
    // Update Character Limit Display
    this.limitChars($noteEl);
    
    // Enable Edit Mode
    $noteEl.addClass(this.app.config.overlayer.overlays.NotesOverlay.editClass);
};

/**
 * Event Handler: Close Note
 * 
 * @public
 * @this Flipbook.NotesOverlay
 * @return undefined
 */
Flipbook.NotesOverlay.prototype.cancelNote = function($noteEl) {
    var noteId = 0;
    if (!$noteEl.length) { return; }
    
    // Get Note ID
    noteId = _.parseInt($noteEl.attr('data-id'), 10);
    
    // Discard Note if Never Saved
    if (noteId < 1) {
        this.removeNoteElement($noteEl);
    }
    
    // Disable Edit Mode
    else {
        $noteEl.removeClass(this.app.config.overlayer.overlays.NotesOverlay.editClass);
    }
};

/**
 * Event Handler: Close Note
 * 
 * @public
 * @this Flipbook.NotesOverlay
 * @return undefined
 */
Flipbook.NotesOverlay.prototype.saveNote = function($noteEl) {
    var saveUrl = this.app.config.urls.server + this.app.config.overlayer.overlays.NotesOverlay.saveUrl;
    var noteId = $noteEl.attr('data-id');
    var pageId = $noteEl.attr('data-pageid');
    var pageFl = $noteEl.attr('data-pagefl');
    var noteCoords = $noteEl.attr('data-coords').split(',');
    var note = $noteEl.find('.note-textarea').val();
    var saveData;
    
    if (!$noteEl.length) { return; }

    /**
     * Inline Function; After the Note is Saved; 
     *    - Add Data to Internal Array and Resort
     * 
     * @private
     * @this local
     * @param {Object} data The Saved Note Data
     * @return undefined
     */
    var saveSuccess = $.proxy(function(data) {
        // Ensure Valid Note
        if (data.note === null) { data.note = ''; }
        
        // Update Note Data
        $noteEl.attr('data-id', data.id);
        
        // Update Note Header
        $noteEl.find('.note-header .note-created').empty().html('created: ' + data.created);
        
        // Update Note Contents
        $noteEl.find('.note-body .view-note .note-scroller').empty().html('<p>' + data.note + '</p>');
        $noteEl.find('.note-body .edit-note .note-textarea').val(data.note);
        
        // Disable Loading State
        $noteEl.removeClass(this.app.config.overlayer.overlays.NotesOverlay.loadClass);
        
        // Disable Edit Mode
        $noteEl.removeClass(this.app.config.overlayer.overlays.NotesOverlay.editClass);
        
        // Remove Old Note from Internal Array
        _.remove(this.notesData, function(obj) { return obj.id === data.id; });
        
        // Add New Note to Internal Array and Resort
        this.notesData.push(data);
        this.notesData = _.sortBy(this.notesData, function(obj) { return _.parseInt(obj.page_fl, 10); });
        
        // Update Sizes of Notes
        this.calculateSizes();
    }, this);

    /**
     * Inline Function; After a Note Fails to Save;
     *   - Alert user of failed attempt
     * 
     * @private
     * @this local
     * @return undefined
     */
    var saveFailure = $.proxy(function() {
        // Disable Loading State
        $noteEl.removeClass(this.app.config.overlayer.overlays.NotesOverlay.loadClass);
        
        // Display Error
        this.app.modal.alert('Failed to Save Note!  Please try again.', {'header' : 'Save Failed!', 'width': 350, 'height': 40});
    }, this);
    
    // !~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Begin: saveNote()
    
    // Ensure Valid Note
    if (note.trim().length < 1) {
        this.app.modal.alert('Sorry, you can\'t leave a note blank. Enter your note and try again, or click \'cancel\'.', {'header' : 'Save Failed!', 'width': 350, 'height': 50});
        return;
    }
    
    // Display Loading State
    $noteEl.addClass(this.app.config.overlayer.overlays.NotesOverlay.loadClass);
    
    // Save Bookmark via Ajax
    saveData = {
        'data': JSON.stringify({
            'id'            : noteId, 
            'type'          : 'note', 
            'issue_id'      : this.app.config.issueData.id, 
            'page_id'       : pageId, 
            'page_fl'       : pageFl, 
            'coordinate_x'  : noteCoords[0], 
            'coordinate_y'  : noteCoords[1], 
            'note'          : note
        })
    };
    Flipbook.qAjax({'url': saveUrl, 'data': saveData}).then(saveSuccess, saveFailure);
};

/**
 * Event Handler: Close Note
 * 
 * @public
 * @this Flipbook.NotesOverlay
 * @return undefined
 */
Flipbook.NotesOverlay.prototype.deleteNote = function($noteEl) {
    var deleteUrl = this.app.config.urls.server + this.app.config.overlayer.overlays.NotesOverlay.deleteUrl;
    var noteId = $noteEl.attr('data-id');
    var pageId = $noteEl.attr('data-pageid');

    /**
     * Inline Function; After the Note is Deleted
     *    - Remove Data from Internal Array
     *    - Remove Note Element from Page
     * 
     * @private
     * @this local
     * @return undefined
     */
    var deleteSuccess = $.proxy(function() {
        // Remove Note Overlay Element from Page
        this.removeNoteElement('.fb-annotation[data-id="' + noteId + '"]');
        
        // Remove from Internal Array
        _.remove(this.notesData, function(obj) { return obj.id === noteId; });
    }, this);

    /**
     * Inline Function; After a Note Fails to Delete;
     *   - Alert user of failed attempt
     * 
     * @private
     * @this local
     * @return undefined
     */
    var deleteFailure = $.proxy(function() {
        this.app.modal.alert('Failed to Remove Note!  Please try again.', {'header' : 'Update Failed!', 'width': 350, 'height': 40});
    }, this);
    
    // !~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Begin: deleteNote()
    if (!$noteEl.length) { return; }
    
    // Update Delete URL
    deleteUrl += noteId + '/' + this.app.config.issueData.id + '/' + pageId;
    
    // Confirm Deletion
    this.app.modal.confirm('Are you sure you want to delete this Note?', {'header' : 'Delete Note?', 'width': 350, 'height': 50}).then(function() {
        // Delete Bookmark
        Flipbook.qAjax({'url': deleteUrl}).then(deleteSuccess, deleteFailure);
    });
};

/**
 * Event Handler: Limit Characters of Note
 * 
 * @public
 * @this Flipbook.NotesOverlay
 * @return undefined
 */
Flipbook.NotesOverlay.prototype.limitChars = function($noteEl, e) {
    if (!$noteEl.length) { return false; }

    var $textareaEl = $noteEl.find('.note-textarea');
    var $charsDisplay = $noteEl.find('.note-chars-left');
    var note = $textareaEl.val();
    var max = this.app.config.overlayer.overlays.NotesOverlay.maxLength;
    var remaining = Math.max(0, max - note.length);
    
    // Update Display
    $charsDisplay.html(this.app.config.overlayer.overlays.NotesOverlay.charsLeft + ' ' + remaining);
    
    // Limit Chars
    if (remaining <= 0) {
        if (e) { e.preventDefault(); }
        $textareaEl.val(note.substring(0, max));
    }
};
