/**
 * Flyp Technologies Inc. - Flipbook v4
 * 
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/nontouch.js - Flipbook.NonTouchControls
 * @author Robert J. Secord, B.Sc.
 */
import 'jquery-migrate';
import $ from 'jquery';
import Flipbook from './core';
import Shared from './shared_util';

/**
 * Flipbook NonTouchControls
 *
 * @class NonTouchControls
 * @classdesc Application Non-Touch Controls
 * @namespace Flipbook
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.NonTouchControls = function(app, $container) {

    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize        = null;
    var buildElements     = null;
    var attachEvents      = null;
    var buildZoomControls = null;
    var buildPanControls  = null;
    var buildCloseControl = null;

    
    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    this.app                = app;
    this.$container         = $container;
    this.$closeContainer    = null;
    this.$zoomContainer     = null;
    this.$panContainer      = null;
    

    /* **************************************************************************************** */
    /* * Private Methods/Members                                                              * */
    /* **************************************************************************************** */
    /**
     * Initialize the Non-Touch Controls
     *
     * @private
     * @this Flipbook.NonTouchControls
     * @return undefined
     * @constructs
     */
    initialize = $.proxy(function() {
        // Debug Message
        Flipbook.log('nontouch-init');

        // Build the Non-Touch Control Elements
        buildElements();
        
        // Attach Events to Non-Touch Controls
        attachEvents();
    }, this);
    
    /**
     * Build the Non-Touch Control Elements
     *
     * @private
     * @this Flipbook.NonTouchControls
     * @return undefined
     */
    buildElements = $.proxy(function() {
        // We don't provide an interface for Non-Touch Controls 
        //  when the device has Multi-Touch capabilities. However,
        //  the NonTouchControls object is still created in order
        //  to provide programmatic use of performZoom() and performPan()
        if (Flipbook.hasMultiTouch(this.app)) { return; }
        
        // Debug Message
        Flipbook.log('nontouch-build-elements');
        
        // Profiler
        Shared.Profiler.start('Flipbook:NonTouchControls->buildElements');
        
        // Build the Zoom/Pan/Close Controls
        buildZoomControls();
        buildPanControls();
        buildCloseControl();
        
        // Profiler
        Shared.Profiler.end('Flipbook:NonTouchControls->buildElements');
    }, this);
    
    /**
     * Build the Non-Touch Zoom Control Elements
     *
     * @private
     * @this Flipbook.NonTouchControls
     * @return undefined
     */
    buildZoomControls = $.proxy(function() {
        // Build Zoom Controls from Template
        Flipbook.buildFromTemplate({'template': this.app.config.nontouch.template.replace('{{control}}', 'zoom'), 'appendTo': this.$container});
        
        // Store Reference to Element Container
        this.$zoomContainer = $('.nontouch-zoom-container');
        
    }, this);
    
    /**
     * Build the Non-Touch Pan Control Elements
     *
     * @private
     * @this Flipbook.NonTouchControls
     * @return undefined
     */
    buildPanControls = $.proxy(function() {
        // Build Pan Controls from Template
        Flipbook.buildFromTemplate({'template': this.app.config.nontouch.template.replace('{{control}}', 'pan'), 'appendTo': this.$container});
        
        // Store Reference to Element Container
        this.$panContainer = $('.nontouch-pan-container');
        
    }, this);
    
    /**
     * Build the Non-Touch Close Control Elements
     *
     * @private
     * @this Flipbook.NonTouchControls
     * @return undefined
     */
    buildCloseControl = $.proxy(function() {
        // Build Close Control from Template
        Flipbook.buildFromTemplate({'template': this.app.config.nontouch.template.replace('{{control}}', 'close'), 'appendTo': this.$container});
        
        // Store Reference to Element Container
        this.$closeContainer = $('.nontouch-close-container');
        
    }, this);

    /**
     * Attach Events to the Non-Touch Control Elements
     *   
     * @private
     * @this Flipbook.NonTouchControls
     * @return undefined
     */
    attachEvents = $.proxy(function() {
        var $el;
        var ns = 'NonTouchControls';

        // We dont provide an interface for Non-Touch Controls 
        //  when the device has Multi-Touch capabilities.
        if (Flipbook.hasMultiTouch(this.app)) { return; }
        
        // Debug Message
        Flipbook.log('nontouch-attach-events');
        
        // Close Control
        if (this.$closeContainer.length) {
            // Zoom In
            $el = this.$closeContainer.find('.nontouch-close');
            if ($el.length) { Flipbook.onClickTap(this.app, $el, this.closeEvent, this, ns); }
        }
        
        // Zoom Controls
        if (this.$zoomContainer.length) {
            // Zoom In
            $el = this.$zoomContainer.find('.nontouch-zoom-in');
            if ($el.length) { Flipbook.onClickTap(this.app, $el, this.zoomEvent('in'), this, ns); }
            
            // Zoom Out
            $el = this.$zoomContainer.find('.nontouch-zoom-out');
            if ($el.length) { Flipbook.onClickTap(this.app, $el, this.zoomEvent('out'), this, ns); }
        }
        
        // Pan Controls
        if (this.$panContainer.length) {
            // Pan Up
            $el = this.$panContainer.find('.nontouch-pan-up');
            if ($el.length) { Flipbook.onClickTap(this.app, $el, this.panEvent('up'), this, ns); }
            
            // Pan Right
            $el = this.$panContainer.find('.nontouch-pan-right');
            if ($el.length) { Flipbook.onClickTap(this.app, $el, this.panEvent('right'), this, ns); }
            
            // Pan Down
            $el = this.$panContainer.find('.nontouch-pan-down');
            if ($el.length) { Flipbook.onClickTap(this.app, $el, this.panEvent('down'), this, ns); }
            
            // Pan Left
            $el = this.$panContainer.find('.nontouch-pan-left');
            if ($el.length) { Flipbook.onClickTap(this.app, $el, this.panEvent('left'), this, ns); }
            
            // Pan Center
            $el = this.$panContainer.find('.nontouch-pan-center');
            if ($el.length) { Flipbook.onClickTap(this.app, $el, this.panEvent('center'), this, ns); }
        }
        
        
    }, this);


    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    initialize();
    
    return this;
};
// End of Flipbook.NonTouchControls


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * 
 *      
 * @public
 * @this Flipbook.NonTouchControls
 * @return {Object} A reference to the NonTouchControls Object for Method Chaining
 */
Flipbook.NonTouchControls.prototype.hide = function() {
    // We dont provide an interface for Non-Touch Controls 
    //  when the device has Multi-Touch capabilities.
    if (Flipbook.hasMultiTouch(this.app)) { return; }
    
    // Debug Message
    Flipbook.log('nontouch-hide');
    
    // Hide All Zoom Controls
    if (this.$panContainer.length) { this.$panContainer.addClass('invisible'); }
    if (this.$zoomContainer.length) { this.$zoomContainer.addClass('invisible'); }
    if (this.$closeContainer.length) { this.$closeContainer.addClass('invisible'); }
    
    return this;
};

/**
 * 
 *      
 * @public
 * @this Flipbook.NonTouchControls
 * @return {Object} A reference to the NonTouchControls Object for Method Chaining
 */
Flipbook.NonTouchControls.prototype.show = function() {
    // We dont provide an interface for Non-Touch Controls 
    //  when the device has Multi-Touch capabilities.
    if (Flipbook.hasMultiTouch(this.app)) { return; }
    
    // Debug Message
    Flipbook.log('nontouch-show');
    
    // Hide All Zoom Controls
    if (this.$panContainer.length) { this.$panContainer.removeClass('invisible'); }
    if (this.$zoomContainer.length) { this.$zoomContainer.removeClass('invisible'); }
    if (this.$closeContainer.length) { this.$closeContainer.removeClass('invisible'); }
    
    // Check Current Zoom Levels for Enabling/Disabling Controls
    return this.checkZoomLevels();
};

/**
 * 
 *      
 * @public
 * @this Flipbook.NonTouchControls
 * @return {Object} A reference to the NonTouchControls Object for Method Chaining
 */
Flipbook.NonTouchControls.prototype.performZoom = function(zoomDirection, scaleMod) {
    // Perform Zoom
    switch (zoomDirection) {
        case 'in':
            // Adjust Scale
            this.app.carousel.zoom.scale += scaleMod;
            if (this.app.carousel.zoom.scale > this.app.config.sheet.maxScale[Flipbook.getPageOrientation(this.app)]) {
                this.app.carousel.zoom.scale = this.app.config.sheet.maxScale[Flipbook.getPageOrientation(this.app)];
            } 
            break;
            
        case 'out':
            // Adjust Scale
            this.app.carousel.zoom.scale -= scaleMod;
            if (this.app.carousel.zoom.scale < 1) {
                this.app.carousel.zoom.scale = 1;
            } 
            break;
    }
    
    // Zoom to Scale
    this.app.carousel.autoZoom({
        'pageX' : 0, //(this.app.viewport.width / 2) + this.app.carousel.zoom.translate.x - this.app.carousel.page.offset.x,
        'pageY' : 0  //(this.app.viewport.height / 2) + this.app.carousel.zoom.translate.y - this.app.carousel.page.offset.y
    });
    
    // Check Current Zoom Levels for Enabling/Disabling Controls
    return this.checkZoomLevels().checkPanPosition();
};

/**
 * 
 *      
 * @public
 * @this Flipbook.NonTouchControls
 * @return {Object} A reference to the NonTouchControls Object for Method Chaining
 */
Flipbook.NonTouchControls.prototype.performPan = function(panDirection, duration) {
    var midx = -Math.round((Math.abs(this.app.carousel.page.bounds.x2) - Math.abs(this.app.carousel.page.bounds.x1) + this.app.carousel.page.offset.x) / 2);
    var midy = -Math.round((Math.abs(this.app.carousel.page.bounds.y2) - Math.abs(this.app.carousel.page.bounds.y1) + this.app.carousel.page.offset.y) / 2);
    var distance;
    
    if (/up|down/i.test(panDirection)) {
        distance = (this.app.viewport.height - 10) / this.app.carousel.zoom.scale;
    } else {
        distance = (this.app.viewport.width - 10) / this.app.carousel.zoom.scale;
    }
    
    // Perform Panning
    switch (panDirection) {
        case 'up':
            this.app.carousel.zoom.translate.y += distance;
            break;
            
        case 'right':
            this.app.carousel.zoom.translate.x -= distance;
            break;
            
        case 'down':
            this.app.carousel.zoom.translate.y -= distance;
            break;
            
        case 'left':
            this.app.carousel.zoom.translate.x += distance;
            break;
            
        case 'center':
            this.app.carousel.zoom.translate.x = midx;
            this.app.carousel.zoom.translate.y = midy;
            break;
    }
    
    // Apply Translation and Update Zoomed Image
    this.app.carousel.applyTranslation(duration, false);

    // Check Current Zoom Levels for Enabling/Disabling Controls
    return this.checkZoomLevels().checkPanPosition();
};

/**
 * 
 *      
 * @public
 * @this Flipbook.NonTouchControls
 * @return {Object} A reference to the NonTouchControls Object for Method Chaining
 */
Flipbook.NonTouchControls.prototype.checkZoomLevels = function() {
    var $el = null;

    // We dont provide an interface for Non-Touch Controls 
    //  when the device has Multi-Touch capabilities.
    if (Flipbook.hasMultiTouch(this.app)) { return; }
    
    if (this.app.carousel.zoom.scale === this.app.config.sheet.maxScale[Flipbook.getPageOrientation(this.app)]) {
        $el = this.$zoomContainer.find('.nontouch-zoom-in');
        if ($el.length) { $el.addClass('disabled'); }
    } else {
        $el = this.$zoomContainer.find('.nontouch-zoom-in');
        if ($el.length) { $el.removeClass('disabled'); }
    }
    return this;
};

/**
 * 
 *      
 * @public
 * @this Flipbook.NonTouchControls
 * @return {Object} A reference to the NonTouchControls Object for Method Chaining
 */
Flipbook.NonTouchControls.prototype.checkPanPosition = function() {
    var $el = null;
    var midx = 0, midy = 0;
    
    // We dont provide an interface for Non-Touch Controls 
    //  when the device has Multi-Touch capabilities.
    if (Flipbook.hasMultiTouch(this.app)) { return; }
    
    // Calculate Mid-Point of Sheet
    midx = -Math.round((Math.abs(this.app.carousel.page.bounds.x2) - Math.abs(this.app.carousel.page.bounds.x1) + this.app.carousel.page.offset.x) / 2);
    midy = -Math.round((Math.abs(this.app.carousel.page.bounds.y2) - Math.abs(this.app.carousel.page.bounds.y1) + this.app.carousel.page.offset.y) / 2);
    
    if (this.app.carousel.zoom.translate.y === this.app.carousel.page.bounds.y1) {
        $el = this.$panContainer.find('.nontouch-pan-up');
        if ($el.length) { $el.addClass('disabled'); }
    } else {
        $el = this.$panContainer.find('.nontouch-pan-up');
        if ($el.length) { $el.removeClass('disabled'); }
    }
    
    if (this.app.carousel.zoom.translate.y === this.app.carousel.page.bounds.y2) {
        $el = this.$panContainer.find('.nontouch-pan-down');
        if ($el.length) { $el.addClass('disabled'); }
    } else {
        $el = this.$panContainer.find('.nontouch-pan-down');
        if ($el.length) { $el.removeClass('disabled'); }
    }
    
    if (this.app.carousel.zoom.translate.x === this.app.carousel.page.bounds.x1) {
        $el = this.$panContainer.find('.nontouch-pan-left');
        if ($el.length) { $el.addClass('disabled'); }
    } else {
        $el = this.$panContainer.find('.nontouch-pan-left');
        if ($el.length) { $el.removeClass('disabled'); }
    }
    
    if (this.app.carousel.zoom.translate.x === this.app.carousel.page.bounds.x2) {
        $el = this.$panContainer.find('.nontouch-pan-right');
        if ($el.length) { $el.addClass('disabled'); }
    } else {
        $el = this.$panContainer.find('.nontouch-pan-right');
        if ($el.length) { $el.removeClass('disabled'); }
    }
    
    if (this.app.carousel.zoom.translate.x === midx && this.app.carousel.zoom.translate.y === midy) {
        $el = this.$panContainer.find('.nontouch-pan-center');
        if ($el.length) { $el.addClass('disabled'); }
    } else {
        $el = this.$panContainer.find('.nontouch-pan-center');
        if ($el.length) { $el.removeClass('disabled'); }
    }
    return this;
};

/**
 * 
 *      
 * @public
 * @this Flipbook.NonTouchControls
 * @return undefined
 */
Flipbook.NonTouchControls.prototype.closeEvent = function(e) {
    // Debug Message
    Flipbook.log('nontouch-zoom-close');
    
    // Reset Zoom Mode
    this.app.carousel.resetZoom();
};

/**
 * 
 *      
 * @public
 * @this Flipbook.NonTouchControls
 * @return undefined
 */
Flipbook.NonTouchControls.prototype.zoomEvent = function(zoomDirection) {
    return function(e) {
        var $el = null;

        // Check if Control is Enabled
        $el = this.$zoomContainer.find('.nontouch-zoom-' + zoomDirection);
        if ($el.length && $el.hasClass('disabled')) { return; }
        
        // Debug Message
        Flipbook.log('nontouch-zoom-' + zoomDirection);
        
        // Perform Zoom
        this.performZoom(zoomDirection, this.app.config.nontouch.zoomScale);
    };
};

/**
 * 
 *      
 * @public
 * @this Flipbook.NonTouchControls
 * @return undefined
 */
Flipbook.NonTouchControls.prototype.panEvent = function(panDirection) {
    return function(e) {
        var $el = null;

        // Check if Control is Enabled
        $el = this.$panContainer.find('.nontouch-pan-' + panDirection);
        if ($el.length && $el.hasClass('disabled')) { return; }
        
        // Debug Message
        Flipbook.log('nontouch-pan-' + panDirection);
        
        // Perform Pan
        this.performPan(panDirection, 200);
        
    };
};
