/**
 * Flyp Technologies Inc. - Flipbook v4
 * 
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/toolbar.js - Flipbook.Toolbar
 * @author Robert J. Secord, B.Sc.
 */
import 'jquery-migrate';
import $ from 'jquery';
import Flipbook from './core';
import IScroll from '/app/libs/iscroll5/iscroll';
import Q from '/app/libs/promise/q';
import Shared from './shared_util';

/**
 * Flipbook Toolbar
 *
 * @class Toolbar
 * @classdesc Application Toolbar Controller
 * @namespace Flipbook
 * @param {Object} app        - A reference to the App Object
 * @param {Object} $container - A jQuery Element Object; the Container that the Toolbar will be Appended to
 * @return {Object}           - The Class Instance
 * @constructor
 */
Flipbook.Toolbar = function(app, $container) {

    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize;
    var buildElements;
    var attachEvents;
    var buildScrollbar;
    var getToolElements;
    var loadTools;


    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    /**
     * A reference to the Application Object
     * 
     * @member {Object} app
     * @see Flipbook.App
     * @protected
     */
    this.app = app;

    /**
     * A jQuery Element Handle to the Container Element which holds the Toolbar
     *   - Provided by Shifter Component
     * 
     * @member {Object} $container
     * @see Flipbook.App
     * @protected
     */
    this.$container = $container;

    /**
     * A jQuery Element Handle to the Toolbar Container Element
     * 
     * @member {Object} $toolbar
     * @protected
     */
    this.$toolbar = null;

    /**
     * A jQuery Element Handle to the Toolbar Content Panel Container Element
     * 
     * @member {Object} $contentPanel
     * @protected
     */
    this.$contentPanel = null;

    /**
     * A jQuery Element Handle to the Body Element of the Toolbar Content Panel Element
     * 
     * @member {Object} $contentPanelBody
     * @protected
     */
    this.$contentPanelBody = null;

    /**
     * A jQuery Element Handle to the Loading Element of the Toolbar Content Panel Element
     * 
     * @member {Object} $contentPanelLoading
     * @protected
     */
    this.$contentPanelLoading = null;

    /**
     * A jQuery Element Handle to the Error Element of the Toolbar Content Panel Element
     * 
     * @member {Object} $contentPanelError
     * @protected
     */
    this.$contentPanelError = null;

    /**
     * A jQuery Element Handle to the Mobile Open-Button Element of the Toolbar Element
     * 
     * @member {Object} $mobileOpener
     * @protected
     */
    this.$mobileOpener = null;
    
    /**
     * A jQuery Element Handle to the Logo Container Element
     * 
     * @member {Object} $logoContainer
     * @protected
     */
    this.$logoContainer = null;

    /**
     * A jQuery Element Handle to the Search Container Element
     * 
     * @member {Object} $searchContainer
     * @protected
     */
    this.$searchContainer = null;

    /**
     * A Handle to the Share Container Element
     * 
     * @member {Object} $shareContainer
     * @protected
     */
    this.$shareContainer = null;

    /**
     * A jQuery Element Handle to the Tools Container Element
     *   - The Tools Container Element holds the Various Tools except Search & Share
     *   - The Tools Container has a Scrollable DIV in case there are too many tools to display in the space available
     * 
     * @member {Object} $toolsContainer
     * @protected
     */
    this.$toolsContainer = null;

    /**
     * A jQuery Element Handle to the Tools Container Scrollable Element
     *   - The Tools Container Element holds the Various Tools except Search & Share
     *   - The Tools Container has a Scrollable DIV in case there are too many tools to display in the space available
     * 
     * @member {Object} $toolsScrollContainer
     * @protected
     */
    this.$toolsScrollContainer = null;
    
    /**
     * The IScroll Component for the Scrollable Tools Container
     * 
     * @member {Object} toolsScrollbar
     * @protected
     */
    this.toolsScrollbar = null;

    /**
     * The IScroll Component for the Scrollable Content Panel
     * 
     * @member {Object} contentPanelScrollbar
     * @protected
     */
    this.contentPanelScrollbar = null;

    /**
     * A Content Observer Component for Observing Changes to Element Contents
     *   - Fires an Event when Content Changes
     * 
     * @member {Object} contentObserver
     * @protected
     */
    this.contentObserver = null;

    /**
     * Array of all the Tool Objects Loaded into the Toolbar
     * 
     * @member {Array} tools
     * @protected
     */
    this.tools = [];
    
    /**
     * Reference to the Active Tool Object
     *   - this gets set by the Tool's Base Class on showPanel() and cleared on hidePanel()
     *   
     * @member {Object} activeTool
     * @protected
     */
    this.activeTool = null;
    
    // State Flags
    this.mobileToolbar = false;
    this.toolbarOpen = false;
    this.contentPanelOpen = false;
    this.contentPanelScrollable = false;
    

    /* **************************************************************************************** */
    /* * Private Methods/Members Definitions                                                  * */
    /* **************************************************************************************** */
    /**
     * Initialize the Toolbar Control
     *
     * @private
     * @this Flipbook.Toolbar
     * @return {Object} A reference to the Toolbar Object for Method Chaining
     * @constructs
     */
        
    initialize = $.proxy(function() {
        // Debug Message
        Flipbook.log('toolbar-init');
        
        // Build the Toolbar Elements
        buildElements();
        attachEvents();
        
        // Load the Tools
        loadTools();
        
        // Build the Tools Scrollbar
        buildScrollbar();

        return this;
    }, this);

    /**
     * Build the Toolbar Elements
     *
     * @private
     * @this Flipbook.Toolbar
     * @return undefined
     */
    buildElements = $.proxy(function() {
        // Debug Message
        Flipbook.log('toolbar-build-elements');
        
        // Profiler
        Shared.Profiler.start('Flipbook:Toolbar->buildElements');
        
        // Build Toolbar from Template
        Flipbook.buildFromTemplate({'template': this.app.config.toolbar.template, 'appendTo': this.$container});
        Flipbook.buildFromTemplate({'template': this.app.config.toolbar.contentPanel.template, 'appendTo': this.app.shifter.$content});
        
        // Get Handles to Toolbar Elements
        this.$toolbar = this.$container.find('.toolbar-container');
        this.$mobileOpener = this.$container.find('.toolbar-mobile-opener');
        this.$contentPanel = this.app.shifter.$content.find('.toolbar-content-panel');
        
        // Set Background Color of Toolbar
        this.$toolbar.css({'background-color': this.app.config.titleData.flypbook_banner_color});
        
        // Get Handles to Tools
        getToolElements();
        
        // Profiler
        Shared.Profiler.end('Flipbook:Toolbar->buildElements');
    }, this);

    /**
     * Attach Events to the Toolbar Elements
     *
     * @private
     * @this Flipbook.Toolbar
     * @return undefined
     */
    attachEvents = $.proxy(function () {
        // Debug Message
        Flipbook.log('toolbar-attach-events');
        
        // Open Toolbar Menu
        Flipbook.onClickTap(this.app, this.$toolbar, function(e) {
            if (this.app.config.toolbar.position !== Flipbook.TOOLBAR_POS_TOP) {
                this.shiftContent();
            }
        }, this, 'Toolbar');
        
        // Mobile Toolbar Opener
        //  - not shown when Toolbar Position = Top
        Flipbook.onClickTap(this.app, this.$mobileOpener, function(e) {
            if (this.toolbarOpen) {
                this.restoreContent();
            } else { 
                this.shiftContent();
            }
        }, this, 'Toolbar');
        
        // Close Toolbar Content Panel
        Flipbook.onClickTap(this.app, this.$contentPanel.find('.panel-close'), $.proxy(function() { 
            // When the Toolbar is displayed to the Left, closing a Panel directly shouldn't
            //   close the Toolbar or Tool.
            // However, when the Toolbar is Displayed at the Top, the Toolbar doesn't expand,
            //   so closing the Panel directly should close off the Tool.
            if (this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_TOP) {
                this.restoreContent();
            } else {
                this.hidePanel();
            }
        }, this), this, 'Toolbar');
        
        //Client Logo Website Link
        if (this.app.config.titleData.website.length) {
            this.$logoContainer.css({'cursor': 'pointer'});
            Flipbook.onClickTap(this.app, this.$logoContainer, function(e) {
                Flipbook.root.open(this.app.config.titleData.website);
            }, this, 'Toolbar');
        }
    }, this);
    
    /**
     * Get a Handle to the various Toolbar Elements
     *
     * @private
     * @this Flipbook.Toolbar
     * @return undefined
     */
    getToolElements = $.proxy(function() {
        // Get Logo Element
        this.$logoContainer = this.$toolbar.find('.toolbar-logo');
        
        // Get Search Element
        this.$searchContainer = this.$toolbar.find('.toolbar-search');
        
        // Get Share Element
        this.$shareContainer = this.$toolbar.find('.toolbar-share');
        
        // Get Tools Element
        this.$toolsContainer = this.$toolbar.find('.toolbar-tools-container');
        this.$toolsScrollContainer = this.$toolbar.find('.toolbar-tools-scroller');
        
        // Get Content Panel Body
        this.$contentPanelBody = this.$contentPanel.find('.panel-body');
        
        // Get Content Panel Loading Screen
        this.$contentPanelLoading = this.$contentPanel.find('.panel-loading');

        // Get Content Panel Error Screen
        this.$contentPanelError = this.$contentPanel.find('.panel-error');
        
    }, this);
    
    /**
     * Loads all Tools into the Toolbar that have registered themselves with Flipbook
     *
     * @private
     * @this Flipbook.Toolbar
     * @return undefined
     */
    loadTools = $.proxy(function() {
        // Debug Message
        Flipbook.log('toolbar-load-all-tools');
        
        // Profiler
        Shared.Profiler.start('Flipbook:Toolbar->loadTools');
        
        // Find All Tools
        $.each(this.app.config.toolbar.tools, $.proxy(function(toolName, toolData) {
            var tool = null;
            
            // Check if Tool is Enabled
            if (!Flipbook.isComponentEnabled(this.app, toolData.enabled)) { return; }
            
            // Check if Tool Exists
            if (Flipbook[toolName] === undefined) {
                Flipbook.log({'msg': 'toolbar-load-tool-failed', 'args': {'tool': toolName}, 'type': Shared.LVL_ERROR});
                return;
            }
            
            // Set Toolname
            toolData.name = toolName;
            
            // Create New Tool
            tool = new Flipbook[toolName](this, toolData);
            
            // Add Tool to Array
            this.tools.push(tool);
        }, this));
        
        // Profiler
        Shared.Profiler.end('Flipbook:Toolbar->loadTools');
    }, this);
    
    /**
     * Builds the Scrollbar Component for the Toolbar Panel
     *
     * @private
     * @this Flipbook.Toolbar
     * @return undefined
     */
    buildScrollbar = $.proxy(function() {
        var color, colorDark;
        var toolCount = this.$toolbar.find('.toolbar-tool').length;
        if (toolCount < 2) { return; }
        
        // Create Scrollbar Object
        this.toolsScrollbar = new IScroll(this.$toolsContainer[0], {
            'scrollbars'            : 'custom',
            'mouseWheel'            : true,
            'interactiveScrollbars' : true,
            'shrinkScrollbars'      : 'clip',
            'fadeScrollbars'        : true,
            'scrollX'               : this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_TOP,
            'scrollY'               : this.app.config.toolbar.position !== Flipbook.TOOLBAR_POS_TOP
        });
        
    }, this);


    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize();
};
// End of Flipbook.Toolbar


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Resets all Styles on the Toolbar
 *      
 * @public
 * @this Flipbook.Toolbar
 * @return {Object} A reference to the Toolbar Object for Method Chaining
 */
Flipbook.Toolbar.prototype.resetToolStyles = function() {
    var showSelector = '';
    var hideSelector = '';
    
    // Hide Toolbar Separators when there are no tools
    var toolCount = this.$toolbar.find('.toolbar-tool').length;
    this.$toolbar.find('.toolbar-section-spacer').css({'visibility': toolCount ? 'visible' : 'hidden'});
    
    // Reset Width of Toolbar Tools
    this.$toolsContainer.find('.toolbar-tool .icon').removeAttr('style');
    
    // Expand Search Tool
    this.$searchContainer
        .find('div:first-child').removeAttr('style').end()
        .find('.input').removeAttr('style').end()
        .find('.icon').removeAttr('style');
    
    // Expand Share Tool
    if (this.$shareContainer !== null) {
        if (Flipbook.isComponentEnabled(this.app, this.app.config.toolbar.tools.SharingTool.enabled) && this.$shareContainer.find('.sharing-tools > a').length) {
            this.$shareContainer
                .find('.icon').removeAttr('style').end()
                .find('.sharing-tools').removeAttr('style');
        } else {
            this.$shareContainer.remove();
            this.$shareContainer = null;
        }
    }
    
    // Expand Tools
    this.$toolsContainer
        .find('.toolbar-tool > .tool').removeAttr('style');
    
    return this;
};

/**
 * Resize the Toolbar Elements
 *      
 * @public
 * @this Flipbook.Toolbar
 * @return {Object} A reference to the Toolbar Object for Method Chaining
 */
Flipbook.Toolbar.prototype.resize = function () {
    var toolbarBreakPoint = 767; 
    var toolbarOpenSize = this.app.config.toolbar.size[this.app.viewport.breakpoint][this.app.config.toolbar.position].open;
    // On screens under 767 we stack the toolbar
    var singleLineToolbar = $(window).width() > toolbarBreakPoint;
    var hasLogo = this.app.config.titleData.has_logo || this.app.config.titleData.favicon;
    // max logo width allowed
    var maxLogoSize = 136;
    var toolbarClosedSize = this.app.config.toolbar.size[this.app.viewport.breakpoint][this.app.config.toolbar.position].closed;
    // on mobile we stack the toolbar and provide some additional space
    var toolbarHeight = singleLineToolbar ? toolbarClosedSize : hasLogo ? (toolbarClosedSize * 2) + 8 : toolbarClosedSize + 4;
    var toolsContainerSize = 0;
    var $searchIcon = null;
    var animOpts = {};

    // Debug Message
    Flipbook.log('toolbar-resize');
    
    // Check for Mobile Toolbar (No Mobile Toolbar when Toolbar Position is Top)
    this.mobileToolbar = (this.app.config.toolbar.mobileMenu.indexOf(this.app.viewport.breakpoint) > -1);
    if (this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_TOP) {
        this.mobileToolbar = false;
    }
    
    // Reset Tool Styles
    this.resetToolStyles();
    
    // Top Positioned Toolbar
    if (this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_TOP) {
        // Update Size of Toolbar
        this.$container.css({
            width: this.app.viewport.width,
            height: toolbarHeight,
            left: 0,
        });
    
        // Update Top Position of Content Panel
        this.$contentPanel.css({
            'top' : toolbarClosedSize
        });
        
        // Set Width of Toolbar Tools
        this.$toolsContainer.find('.toolbar-tool').css({'width': toolbarClosedSize});
        this.$toolsContainer.find('.toolbar-tool .icon').css({'width': toolbarClosedSize});
        
        // Get width of Toolbar Sections
        this.$container.find('.toolbar-section:not(.toolbar-tools):not(.toolbar-logo), .toolbar-section-spacer').each(
            $.proxy(function (idx, el) {
                toolsContainerSize += $(el).outerWidth(true) || 0;
            }, this)
        );
        
        // Get Padding of Toolbar-Tools Section
        toolsContainerSize += parseInt(this.$toolsContainer.parent().css('padding-left'), 10) + parseInt(this.$toolsContainer.parent().css('padding-right'), 10);

        // On singleLineToolbar adjust toolbar for the logo
        if (singleLineToolbar && hasLogo) {
            // Using a const value here as to not have to wait for the image to load to set the toolbar
            toolsContainerSize += maxLogoSize;
        } 

        // Get Difference of Container-Width to Viewport-Width
        toolsContainerSize = (this.app.viewport.width - toolsContainerSize);
        
        // Update Width of Tools Container
        this.$toolsContainer.css({'width': toolsContainerSize});

        // Update Width of Tools Scroll Div
        this.$toolsScrollContainer.css({'width': (toolbarClosedSize * this.$toolsContainer.find('.toolbar-tool').length)});
    } 
    
    // Left Positioned Toolbar
    else {
        // Update Size of Toolbar
        this.$container.css({
            'width'  : this.mobileToolbar ? toolbarOpenSize : toolbarClosedSize, 
            'height' : this.app.viewport.height
        });
        
        // Update Top Position of Content Panel
        this.$contentPanel.css({'top': 0});
        
        // Set Initial Position of Toolbar
        animOpts[Flipbook.TRANSITION_MODIFIER] = this.mobileToolbar ? -toolbarOpenSize : 0;
        this.$container.transition(animOpts, 0);
        
        // Set Margin of Search Tool
        if (!this.mobileToolbar) {
            $searchIcon = this.$searchContainer.find('.icon');
            if ($searchIcon.length) {
                $searchIcon.css({'margin-right': (toolbarClosedSize - $searchIcon.width()) / 2 });
            }
        }
        
        // Set Width of Toolbar Tools
        this.$toolsContainer.find('.toolbar-tool').css({'width': toolbarOpenSize});
        if (!this.mobileToolbar) {
            this.$toolsContainer.find('.toolbar-tool .icon').css({'width': toolbarClosedSize});
        }
        
        // Get height of Toolbar Sections
        this.$container.find('.toolbar-section:not(.toolbar-tools), .toolbar-section-spacer').each($.proxy(function(idx, el) {
            toolsContainerSize += $(el).outerHeight(true) || 0;
        }, this));
        
        // Get Padding of Toolbar-Tools Section
        toolsContainerSize += parseInt(this.$toolsContainer.parent().css('padding-top'), 10) + parseInt(this.$toolsContainer.parent().css('padding-bottom'), 10);
        
        // Get Difference of Container-Height to Viewport-Height
        toolsContainerSize = (this.app.viewport.height - toolsContainerSize);

        // Update Height of Tools Container
        this.$toolsContainer.css({'height': toolsContainerSize});
    }
    
    // For Mobile Toolbar, we move the Toolbar offscreen and Display a Hamburger Menu
    this.$mobileOpener.css({'display': this.mobileToolbar ? 'block' : 'none'});
    
    // Update Scrollbar
    if (this.toolsScrollbar !== null) {
        Shared.onNextEventLoop(function() {
            this.toolsScrollbar.refresh();
        }, this);
    }
    
    return this;
};

/**
 * Shows the Toolbar
 *   - We show/hide the Toolbar completely when in Zoom or Scrub Mode
 *      
 * @public
 * @this Flipbook.Toolbar
 * @param {number} [speed] The speed of the Animation when showing the Toolbar.  Optional; Defaults to 0.
 * @return {Object} A reference to the Toolbar Object for Method Chaining
 */
Flipbook.Toolbar.prototype.show = function(speed) {
    if (!this.app.config.toolbar.enabled) { return; }
    
    // Display Toolbar 
    this.$container.css({'display':'block'}).transition({'opacity': 1}, speed || 0);
    return this;
};

/**
 * Hides the Toolbar
 *   - We show/hide the Toolbar completely when in Zoom or Scrub Mode
 *      
 * @public
 * @this Flipbook.Toolbar
 * @param {number} [speed] The speed of the Animation when hiding the Toolbar.  Optional; Defaults to 0.
 * @return {Object} A reference to the Toolbar Object for Method Chaining
 */
Flipbook.Toolbar.prototype.hide = function(speed) {
    // Hide Toolbar 
    this.$container.transition({'opacity': 0}, speed || 0, $.proxy(function() {
        this.$container.css({'display':'none'});
    }, this));
    return this;
};

/**
 * Expands the Toolbar
 *  - This is for Left Positioned Toolbars Only
 *  - Expands the Width of the Toolbar to show Tool Labels as well as Icons
 * 
 * @public
 * @this Flipbook.Toolbar
 * @return {Object} A Promise
 */
Flipbook.Toolbar.prototype.expand = function() {
    var deferred = Q.defer();
    var toolbarOpenSize = this.app.config.toolbar.size[this.app.viewport.breakpoint][this.app.config.toolbar.position].open;
    var toolbarClosedSize = this.app.config.toolbar.size[this.app.viewport.breakpoint][this.app.config.toolbar.position].closed;
    var toolbarSpeed = this.app.config.toolbar.speed;
    var animOpts = {};
    
    var onComplete = $.proxy(function() {
        // Set State Flag
        this.toolbarOpen = true;
        
        // Mark as Expanded 
        this.$container.addClass('expanded');
        this.$mobileOpener.addClass('expanded');
        
        // Resolve Promise
        deferred.resolve('expanded'); 
    }, this);
    
    // Mobile Menu
    if (this.mobileToolbar) {
        // Ensure Sharing Tools are Shown
        if (this.$shareContainer !== null) {
            this.$shareContainer.find('.sharing-tools').css({'display':'block'});
        }
        
        // Expand Toolbar Container 
        animOpts[Flipbook.TRANSITION_MODIFIER] = 0;
        this.$container.transition(animOpts, toolbarSpeed, onComplete);
    }
    
    // Non-Mobile Menu
    else {
        // Top Positioned Toolbar doesn't Expand
        if (this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_TOP) {
            onComplete();
            return deferred.promise;
        }
        
        // Expand Toolbar Container 
        this.$container.transition({'width': toolbarOpenSize}, toolbarSpeed, onComplete);
        
        // Expand Search Tool
        this.$searchContainer
            .find('div:first-child').transition({'width': toolbarOpenSize - toolbarClosedSize}, toolbarSpeed).end()
            .find('.input').transition({'width': Math.max(toolbarOpenSize - (toolbarClosedSize * 2), 250), 'opacity': 1}, toolbarSpeed);
        
        // Expand Share Tool
        if (this.$shareContainer !== null) {
            this.$shareContainer
                .find('.icon').transition({'opacity': 0}, toolbarSpeed).end()
                .find('.sharing-tools').css({'display':'block'}).transition({'opacity': 1}, toolbarSpeed);
        }
        
        // Expand Logo
        if (this.app.config.titleData.has_logo) {
            this.$logoContainer
                .find('.logo').css({'display': 'block', 'opacity': 0}).transition({'opacity': 1}, toolbarSpeed);            
        }
        
        // Expand Tools
        this.$toolsContainer
            .find('.toolbar-tool > .tool').transition({'opacity': 1}, toolbarSpeed);
    }
    return deferred.promise;
};

/**
 * Collapses the Toolbar
 *  - This is for Left Positioned Toolbars Only
 *  - Collapses the Width of the Toolbar to show Tool Icons Only
 *      
 * @public
 * @this Flipbook.Toolbar
 * @return {Object} A Promise
 */
Flipbook.Toolbar.prototype.collapse = function() {
    var deferred = Q.defer();
    var toolbarSize = this.app.config.toolbar.size[this.app.viewport.breakpoint][this.app.config.toolbar.position].closed;
    var toolbarSpeed = this.app.config.toolbar.speed;
    var animOpts = {};
    
    var onComplete = $.proxy(function() {
        // Set State Flag
        this.toolbarOpen = false;
        
        // Mark Container as Collapsed
        this.$container.removeClass('expanded');
        this.$mobileOpener.removeClass('expanded');
        
        // Hide Sharing Tools (prevent clicking on them)
        if (this.$shareContainer !== null && this.app.config.toolbar.position !== Flipbook.TOOLBAR_POS_TOP) {
            this.$shareContainer.find('.sharing-tools').css({'display':'none'});
        }
        
        // Resolve Promise
        deferred.resolve('collapsed');
    }, this);
    
    var onCollapse = $.proxy(function() {
        // Mobile Menu
        if (this.mobileToolbar) {
            // Collapse Toolbar Container 
            toolbarSize = this.app.config.toolbar.size[this.app.viewport.breakpoint][this.app.config.toolbar.position].open;
            animOpts[Flipbook.TRANSITION_MODIFIER] = -toolbarSize;
            this.$container.transition(animOpts, toolbarSpeed, onComplete);
        }
        
        // Non-Mobile Menu
        else {
            // Top Positioned Toolbar doesn't Expand
            if (this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_TOP) {
                onComplete();
            } 
            
            // Left Positioned Toolbar
            else {
                // Collapse Toolbar Container 
                this.$container.transition({'width': toolbarSize}, toolbarSpeed, onComplete);
                
                // Collapse Search Tool
                this.$searchContainer
                    .find('div:first-child').transition({'width': toolbarSize}, toolbarSpeed).end()
                    .find('.input').transition({'width': 0, 'opacity': 0}, toolbarSpeed);
                
                // Collapse Share Tool
                if (this.$shareContainer !== null) {
                    this.$shareContainer
                        .find('.icon').transition({'opacity': 1}, toolbarSpeed).end()
                        .find('.sharing-tools').transition({'opacity': 0}, toolbarSpeed);
                }
                
                // Collapse Logo
                if (this.app.config.titleData.has_logo) {
                    this.$logoContainer
                        .find('.logo').transition({'opacity': 0}, toolbarSpeed);            
                }
                
                // Collapse Tools
                this.$toolsContainer
                    .find('.toolbar-tool > .tool').transition({'opacity': 0}, toolbarSpeed);
            }
        }
    }, this);
    
    // Collapse Toolbar (Content Panel First, if Open)
    if (this.contentPanelOpen !== false) {
        this.hidePanel().then(onCollapse);
    } else {
        onCollapse();
    }
    
    return deferred.promise;
};

/**
 * Gets a reference to the Toolbar Content Panel
 *
 * @public
 * @this Flipbook.Toolbar
 * @return {Object} Toolbar Panel
 */
Flipbook.Toolbar.prototype.getPanel = function() {
    return this.$contentPanel;
};

/**
 * Gets a reference to the Toolbar Content Panel Scrollbar
 *
 * @public
 * @this Flipbook.Toolbar
 * @return {Object} Toolbar Panel
 */
Flipbook.Toolbar.prototype.getPanelScrollbar = function() {
    return this.contentPanelScrollbar;
};

/**
 * Updates the Toolbar Content Panel Scrollbar Control
 *
 * @public
 * @this Flipbook.Toolbar
 * @return undefined
 */
Flipbook.Toolbar.prototype.updatePanelScrollbar = function() {
    if (this.contentPanelScrollbar !== null) {
        Shared.onNextEventLoop(function() {
            this.contentPanelScrollbar.refresh();
        }, this);
    }
};

/**
 * Shows the Toolbar Content Panel
 *      
 * @public
 * @this Flipbook.Toolbar
 * @param {Object} toolRef A reference to the Tool that opened the Panel
 * @param {Object} panelOptions Options for the Panel to be Shown (@link Flipbook.ToolsBase.defaultToolOptions.panel)
 * @return {Object} A Promise
 */
Flipbook.Toolbar.prototype.showPanel = function(toolRef, panelOptions) {
    var deferred = Q.defer();
    var toolbarSize = this.app.config.toolbar.size[this.app.viewport.breakpoint][this.app.config.toolbar.position].open;
    var panelWidth = this.app.config.toolbar.contentPanel.width[this.app.viewport.breakpoint];
    var toolbarSpeed = this.app.config.toolbar.speed;
    var panelLeft = {'start': 0, 'end': 0};
    
    var onShow = $.proxy(function() {
        // Mark Container as Panel-Opened
        this.$container.addClass('panel-open');
        
        // Clear Content Panel
        this.$contentPanelBody.empty();
        
        // Closing via Shifter-Mask
        this.app.shifter.closeViaMask = panelOptions.closeViaMask;
        
        // Display Loading Screen on Panel
        this.$contentPanelLoading.css({'display': 'block', 'opacity': 1});
        
        // Determine Start/End Positions of Content Panel
        if (this.mobileToolbar || this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_TOP) {
            panelLeft.start = -panelWidth;
            panelLeft.end   = 0;
        } else {
            panelLeft.start = -(panelWidth - toolbarSize);
            panelLeft.end   = toolbarSize;
        }
        
        // Store Reference to Active Tool Object
        this.activeTool = toolRef;
        
        // Mark Tool as Active in Menu
        if (this.activeTool && this.activeTool.toolOptions.inMenu) {
            this.activeTool.$tool.addClass(this.app.config.toolbar.activeClass);
        }
        
        // Display Content Panel
        this.$contentPanel
            .css({'display':'block', 'width': panelWidth, 'left': panelLeft.start})
            .transition({'left': panelLeft.end}, toolbarSpeed, $.proxy(function() {
                // Set State
                this.contentPanelOpen = panelOptions.id;
                
                // Call Panel Opened Event
                if (this.activeTool) {
                    this.activeTool.panelOpened();
                }
                
                // Resolve Promise
                deferred.resolve('opened');
            }, this));
    }, this);

    // Ensure Valid Tools Options
    if (panelOptions === undefined) {
        panelOptions = Flipbook.ToolsBase.defaultToolOptions.panel;
    }
    
    // Ensure Valid Panel Width
    if (panelWidth === 0) {
        panelWidth = this.app.viewport.width;
    }
    
    if (this.contentPanelOpen === false) { 
        // Expand Toolbar First, then Show Content Panel
        if (!this.app.state.shifted) {
            this.activeTool = toolRef;
            this.shiftContent().then(onShow);
        } else {
            onShow();
        }
    } else {
        // Check if a different tool was requested, and change panels
        if (panelOptions.id !== this.contentPanelOpen) {
            // Hide Previous Panel, Show New One
            this.hidePanel().then(function() {
                Shared.onNextEventLoop(onShow);
            });
        } else {
            // Resolve Promise
            deferred.resolve('opened');          
        }
    }

    return deferred.promise;
};

/**
 * Hides the Toolbar Content Panel
 * 
 * @public
 * @this Flipbook.Toolbar
 * @return {Object} A Promise
 */
Flipbook.Toolbar.prototype.hidePanel = function() {
    var deferred = Q.defer();
    var toolbarSize = this.app.config.toolbar.size[this.app.viewport.breakpoint][this.app.config.toolbar.position].open;
    var panelWidth = this.app.config.toolbar.contentPanel.width[this.app.viewport.breakpoint];
    var toolbarSpeed = this.app.config.toolbar.speed;
    var panelLeft = 0;
    
    if (this.contentPanelOpen === false) { 
        deferred.resolve('closed');
        return deferred.promise;
    }
    
    // Ensure Valid Panel Width
    if (panelWidth === 0) {
        panelWidth = this.app.viewport.width;
    }
    
    // Determine Start/End Positions of Content Panel
    if (this.mobileToolbar || this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_TOP) {
        panelLeft = -panelWidth;
    } else {
        panelLeft = -(panelWidth - toolbarSize);
    }
    
    // Hide Content Panel 
    this.$contentPanel.transition({'left': panelLeft}, toolbarSpeed, $.proxy(function() {
        // Hide Panel Error Screen
        this.$contentPanelError.hide();
        
        // Hide Panel
        this.$contentPanel.css({'display':'none'});
        
        // Destroy Scrollbar Object
        if (this.contentPanelScrollable && this.contentPanelScrollbar !== null) {
            this.contentPanelScrollbar.destroy();
            this.contentPanelScrollbar = null;
        }
        
        // Disconnect Mutation Observer
        if (this.contentPanelScrollable && this.contentObserver !== null) {
            this.contentObserver.disconnect();
        }
        
        // Set State
        this.contentPanelOpen = false;
        this.contentPanelScrollable = false;
        
        // Mark Container as Panel-Closed
        this.$container.removeClass('panel-open');
        
        // Allow Closing via Shifter-Mask
        this.app.shifter.closeViaMask = true;
        
        if (this.activeTool) {
            // Mark Tool as Inactive in Menu
            if (this.activeTool.toolOptions.inMenu) {
                this.activeTool.$tool.removeClass(this.app.config.toolbar.activeClass);
            }
            
            // Call Panel Closed Event
            this.activeTool.panelClosed();
            
            // Deactivate Tool
            if (this.app.config.toolbar.position !== Flipbook.TOOLBAR_POS_TOP) {
                Shared.onNextEventLoop(function() {
                    this.activeTool = null;
                }, this, null, 500); // Delayed, in case Shifter component needs access to ActiveTool
            }
        }
        
        // Resolve Promise
        deferred.resolve('closed');
    }, this));
    
    return deferred.promise;
};

/**
 * Replaces the HTML of the Toolbar Content Panel
 * 
 * @public
 * @this Flipbook.Toolbar
 * @param {Object|string} html The HTML Content to Populate the Content Panel with (can be a string or an element)
 * @param {boolean} loading Whether or not to display the Loading Icon on the Content Area
 * @param {boolean} scrollable Whether or not the Content should be Scrollable
 * @return {Object} A Promise
 */
Flipbook.Toolbar.prototype.panelReplaceHtml = function(html, loading, scrollable) {
    var deferred = Q.defer();
    var $container = null;
    var $header = null;
    var $content = null;
    var $paging = null;
    var $wrapper = null;
    var contentHeight = 0;
    var wrapperHeight = 0;
    
    // Hide Panel Error Screen
    this.$contentPanelError.hide();

    // Populate Panel Content
    if (typeof html === 'string') {
        this.$contentPanelBody.html(html);
    } else {
        this.$contentPanelBody.empty().append(html);
    }
    
    // Get Elements just Added
    $container = $(this.app.config.toolbar.contentPanel.scroll.container);
    $header = $(this.app.config.toolbar.contentPanel.scroll.header);
    $content = $(this.app.config.toolbar.contentPanel.scroll.content);
    $wrapper = $(this.app.config.toolbar.contentPanel.scroll.content + '-wrapper');
    $paging = this.$contentPanelBody.find('.pagination-wrapper');
    
    // Update Height of Content
    contentHeight = this.$contentPanelBody.height();
    if ($header.length) {
        contentHeight -= $header.outerHeight(true);
    }
    $container.css({'height': contentHeight});
    
    // Get Height of Wrapper Element
    wrapperHeight = $wrapper.height();

    // Attach Scrollbars to Content
    this.contentPanelScrollable = scrollable;
    if (scrollable && $content.length) {
        // Calculate Height of Scrollable Content
        // Account for Padding of Container
        contentHeight -= (parseInt($container.css('padding-top'), 10) || 0);
        contentHeight -= (parseInt($container.css('padding-bottom'), 10) || 0);
        // Account for Margin of Content
        contentHeight -= (parseInt($content.css('margin-top'), 10) || 0);
        contentHeight -= (parseInt($content.css('margin-bottom'), 10) || 0);
        // Account for Paging Elements
        if ($paging.length) {
            contentHeight -= $paging.height();
        }
        
        // Resize Scrollable Content
        $content.css({'height': contentHeight});
        
        // Create Scrollbar Object
        this.contentPanelScrollbar = new IScroll(this.app.config.toolbar.contentPanel.scroll.content, {
            'click'                 : true,
            'scrollbars'            : 'custom',
            'mouseWheel'            : true,
            'interactiveScrollbars' : true,
            'shrinkScrollbars'      : 'clip',
            'fadeScrollbars'        : true
        });
        
        // Enable Mutation Observer
        if (Flipbook.root.MutationObserver) {
            this.contentObserver = new MutationObserver($.proxy(this.updatePanelScrollbar, this));
            this.contentObserver.observe($wrapper[0], {'subtree': true, 'childList': true, 'attributes': true});
        }
        
        // Hook Scroll Event and Pass to Tool to Handle
        this.contentPanelScrollbar.on('scrollEnd', $.proxy(function() {
            var boundsReached = {'top': false, 'bottom': false};
            
            // Determine if there is an Active Tool to send Scroll Events to
            if (this.activeTool === null || !$wrapper.length) { return; }
            
            // Determine Bounds Reached
            boundsReached.top = (this.contentPanelScrollbar.y === 0);
            boundsReached.bottom = (this.contentPanelScrollbar.y === -(wrapperHeight - contentHeight));
            
            // Send Scroll Event to Panel
            this.activeTool.panelScrolled(this.contentPanelScrollbar.x, this.contentPanelScrollbar.y, boundsReached);
            
        }, this));
    }

    // Remove Loading Screen on Panel
    if (loading) {
        this.$contentPanelLoading.transition({'opacity': 0}, 250, $.proxy(function() {
            this.$contentPanelLoading.hide(); //css({'display': 'none'});
            
            // Resolve Promise
            deferred.resolve('replaced');
        }, this));
    } else {
        // Resolve Promise
        deferred.resolve('replaced');
    }
    
    return deferred.promise;
};

/**
 * Appends HTML to the Toolbar Content Panel
 *      
 * @public
 * @this Flipbook.Toolbar
 * @param {Object|string} html The HTML Content to Populate the Content Panel with (can be a string or an element)
 * @return undefined
 */
Flipbook.Toolbar.prototype.panelAppendHtml = function(html, selector) {
    var $target = this.$contentPanelBody;
    if (!$target.length) { return; }
    
    if (selector !== undefined && selector.length) {
        $target = $target.find(selector);
    }
    if (!$target.length) { return; }
        
    // Append Content
    $target.append(html);
    
    if (this.contentPanelScrollable) {
        Shared.onNextEventLoop(function() { this.contentPanelScrollbar.refresh(); }, this);
    }
};

/**
 * Display an Error Message on the Panel
 *  - Used to notify user of a failed load
 *      
 * @public
 * @this Flipbook.Toolbar
 * @param {Object} errorObj The details of the error
 * @return undefined
 */
Flipbook.Toolbar.prototype.panelDisplayError = function(errorObj) {
    var errorMsg = '';
    
    // Hide Loading Screen
    this.$contentPanelLoading.hide();
    
    // Empty Content
    this.$contentPanelBody.empty();
    
    // Build Error Message
    if (errorObj[0].status === 0) {
        errorMsg = Shared.Logger.parse({'msg': 'connection-error', 'type': Shared.LVL_ERROR}).msg;
    } else {
        errorMsg = Shared.Logger.parse({'msg': 'server-error', 'type': Shared.LVL_ERROR, 'args': {'error': errorObj[2]}}).msg;
    }
    
    // Update Error Message
    this.$contentPanelError.find('> span').html(errorMsg);
    
    // Show Panel Error Screen
    this.$contentPanelError.show();
};

/* ******************************************************************************************** */
/* * Public Helper Methods                                                                    * */
/* ******************************************************************************************** */

/**
 * Helper: Moves the Carousel to a Specific Page/Sheet
 *
 * @public
 * @this Flipbook.Toolbar
 * @param {number} sheetIndex The Index of the Sheet to Move To
 * @param {number} speed The Speed of the Animation to Move the Sheet
 * @return {Object} A Promise
 */
Flipbook.Toolbar.prototype.moveToSheet = function(sheetIndex, speed) {
    return this.app.carousel.moveToSheet(sheetIndex, speed);
};

/**
 * Helper: Gets the Current Sheet we are on
 *
 * @public
 * @this Flipbook.Toolbar
 * @return {number} The Current Sheet Index
 */
Flipbook.Toolbar.prototype.getCurrentSheet = function() {
    return this.app.carousel.current.sheet;
};

/**
 * Helper: Gets the Total Sheets count
 *
 * @public
 * @this Flipbook.Toolbar
 * @return {number} The Total Number of Sheets
 */
Flipbook.Toolbar.prototype.getTotalSheets = function() {
    return this.app.carousel.totalSheets;
};

/**
 * Helper: Expands the Toolbar
 *
 * @public
 * @this Flipbook.Toolbar
 * @return {Object} A Promise
 */
Flipbook.Toolbar.prototype.shiftContent = function() {
    return this.app.shifter.shiftContent();
};

/**
 * Helper: Collapses the Toolbar (and Panel) and Restores the Content View
 *
 * @public
 * @this Flipbook.Toolbar
 * @return {Object} A Promise
 */
Flipbook.Toolbar.prototype.restoreContent = function() {
    return this.app.shifter.restoreContent();
};

/**
 * Helper: Track Flipbook Events
 *      
 * @public
 * @this Flipbook.Toolbar
 * @return {Object} A reference to the Stats Object for Method Chaining
 */
Flipbook.Toolbar.prototype.trackSocialShareEvent = function(shareType, flipbookId, flipbookPage) {
    return this.app.stats.socialShare(shareType, flipbookId, flipbookPage);
};

/**
 * Helper: Track Flipbook Events
 *      
 * @public
 * @this Flipbook.Toolbar
 * @return {Object} A reference to the Stats Object for Method Chaining
 */
Flipbook.Toolbar.prototype.trackPdfDownloadEvent = function(flipbookId) {
    return this.app.stats.pdfDownload(flipbookId);
};
