/**
 * Flyp Technologies Inc. - Flipbook v4
 *
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/overlays/bookmarks.js - Flipbook.BookmarksOverlay
 * @author Robert J. Secord, B.Sc.
 */
import 'jquery-migrate';
import $ from 'jquery';
import Flipbook from '../core';
import Q from '/app/libs/promise/q';

/**
 * Flipbook Bookmarks Overlay Controller
 *
 * @class BookmarksOverlay
 * @classdesc Application Bookmarks Overlay
 * @namespace Flipbook
 * @inherits Flipbook.OverlayBase
 * @param {Object} overlayer A reference to the Overlayer Control
 * @param {Object} overlayOptions Configuration Settings for the Overlay
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.BookmarksOverlay = function(overlayer, overlayOptions) {
    // Call Parent Constructor
    Flipbook.OverlayBase.apply(this, [overlayer, overlayOptions]);
    
    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize = null;

    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    
    // Bookmarks Data
    this.bookmarksData = null;
    
    // Bookmarks Container
    this.$bookmarksContainer = null;

    /* **************************************************************************************** */
    /* * Private Methods/Members Definitions                                                  * */
    /* **************************************************************************************** */
    /**
     * Initialize the Bookmarks Overlay
     *
     * @private
     * @this Flipbook.BookmarksOverlay
     * @return {Object} A reference to the BookmarksOverlay Object for Method Chaining
     * @constructs
     */
    initialize = $.proxy(function() {
        // Copy Bookmarks Data Locally
        this.bookmarksData = _.map(this.app.parsedNotesData.bookmark, 'Annotation'); // using the `_.property` callback shorthand
        return this;
    }, this);
    
    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize();
};
Flipbook.BookmarksOverlay.prototype = new Flipbook.OverlayBase();
Flipbook.BookmarksOverlay.prototype.constructor = Flipbook.BookmarksOverlay;
// End of Flipbook.BookmarksOverlay


/* ******************************************************************************************** */
/* * Overridden Base Methods                                                                  * */
/* ******************************************************************************************** */

/**
 * Override Abstract Base Method; Called when ...
 *
 * @public
 * @this Flipbook.BookmarksOverlay
 * @return {Object} A Promise
 */
Flipbook.BookmarksOverlay.prototype.resize = function() {
    return Q.Promise($.proxy(function(resolve, reject, notify) {
        // Debug Message
        Flipbook.log('overlay-resize-bookmarks');
        
        // Build (or Empty if exists) the Bookmarks Container
        if (!this.$bookmarksContainer) {
            this.$bookmarksContainer = $('<div class="bookmarks-container"/>').appendTo(this.getTargetPagebox());
        } else {
            this.$bookmarksContainer.empty();
        }
        
        // Create "Add" Bookmark
        this.addBookmarkElement({'id': 'add', 'page_id': 'add', 'page_fl': 'add', 'title': 'Bookmark this Page', 'className': this.app.config.overlayer.overlays.BookmarksOverlay.addClass});
        
        // Iterate all Bookmarks and Load onto Overlay
        _(this.bookmarksData).forEachRight($.proxy(function(bd) {
            this.addBookmarkElement({'id': bd.id, 'page_id': bd.page_id, 'page_fl': bd.page_fl});
        }, this)).value();
        
        // Resize Complete; Resolve Promise
        resolve(true);
    }, this));
};

/**
 * Override Abstract Base Method; Called when ...
 *
 * @public
 * @this Flipbook.BookmarksOverlay
 * @return {Object} A Promise
 */
Flipbook.BookmarksOverlay.prototype.update = function() {
    return Q.Promise($.proxy(function(resolve, reject, notify) {
        // Debug Message
        Flipbook.log('overlay-update-bookmarks');
        
        // Remove Active State from Bookmarks
        $('.fb-bookmark').removeClass(this.app.config.overlayer.overlays.BookmarksOverlay.activeClass);
        
        // Mark as Active if on Bookmarked Page
        _(this.app.stats.currentPageIds).forEach($.proxy(function(pageId) {
            $('.fb-bookmark[data-pageid="' + pageId + '"]').addClass(this.app.config.overlayer.overlays.BookmarksOverlay.activeClass);
        }, this)).value();
        
        // Update Complete; Resolve Promise
        resolve(true);
    }, this));
};


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Adds a Bookmark Element to the Page
 * 
 * @public
 * @this Flipbook.BookmarksOverlay
 * @param {Object} data The configuration settings for the Bookmark;
 *                        - (Required) id        - The ID of the Bookmark
 *                        - (Required) page_id   - The ID of the Page the Bookmark is placed on
 *                        - (Required) page_fl   - The Logical Number of the Page the Bookmark is placed on
 *                        - (Optional) title     - The tooltip Title for the Bookmark (defaults to "Remove this Bookmark")
 *                        - (Optional) className - Additional CSS classes to apply to the Bookmark
 * @return {Element} The Bookmark Element
 */
Flipbook.BookmarksOverlay.prototype.addBookmarkElement = function(data) {
    var $bookmark = null;
    var pageNum = 0;
    
    // Required Data:
    if (data.id === undefined || data.page_id === undefined || data.page_fl === undefined) { return; }
    
    // Optional Data:
    if (data.title === undefined) { data.title = 'Jump to Page'; }
    
    // Check for Valid Page Number, otherwise blank out the value
    pageNum = _.parseInt(data.page_fl, 10) + this.app.config.titleData.page_num_offset;
    if (_.isNaN(pageNum)) { pageNum = ''; }
    
    // Create Bookmark
    $bookmark = Flipbook.buildFromTemplate({
        'template': this.app.config.overlayer.overlays.BookmarksOverlay.template,
        'appendTo': this.$bookmarksContainer,
        'returnAs': 'element',
        'tags' : {
            'id'       : data.id,
            'page_id'  : data.page_id,
            'page_fl'  : data.page_fl,
            'page_num' : pageNum
        }
    });
    $bookmark.addClass('tooltip').attr('title', data.title)
        .find('em').addClass('tooltip').attr('title', 'Remove this Bookmark');
    
    // Aditional Class Names
    if (data.className !== undefined && data.className.length) {
        $bookmark.addClass(data.className);
    }
    
    // Attach Click Event to Bookmark
    Flipbook.onClickTap(this.app, $bookmark, this.handleBookmarkEvent($bookmark), this, 'OverlayBookmark');
    
    return $bookmark;
};

/**
 * Handles the Event when a Bookmark is Clicked
 *   Event could be Jump to Page, Add Bookmark or Remove Bookmark
 * 
 * @public
 * @this Flipbook.BookmarksOverlay
 * @param {Element} $bookmark The Bookmark Element for the Event
 * @return undefined
 */
Flipbook.BookmarksOverlay.prototype.handleBookmarkEvent = function($bookmark) {
    return $.proxy(function(e) {
        var $realTarget = $(e.target);
        var pageFl = 0, sheet = 0;
        
        e.stopPropagation();
        
        /**
         * Inline Function; Jump to a Specific Sheet depending on the Bookmark Clicked
         * 
         * @private
         * @this local
         * @param {Element} $el - The Bookmark Element to get info from
         * @return undefined
         */
        var jumpToPage = $.proxy(function($el) {
            var pageFl = _.parseInt($el.attr('data-pagefl'), 10);
            var newSheet = Flipbook.getSheetFromPage(this.app, pageFl);
            
            if (newSheet >= 0 && newSheet < this.app.carousel.totalSheets[this.app.viewport.orientation]) {
                this.app.carousel.moveToSheet(newSheet, this.app.config.carousel.defaultSlideSpeed);
            }
        }, this);
        
        /**
         * Inline Function; Add a New Bookmark for the Current Page
         *   - If there are 2 pages displayed, only bookmark the first one
         * 
         * @private
         * @this local
         * @return undefined
         */
        var addBookmark = $.proxy(function() {
            var saveUrl = this.app.config.urls.server + this.app.config.overlayer.overlays.BookmarksOverlay.saveUrl;
            var pageId = this.app.stats.currentPageIds[0];
            var pageFl = this.app.stats.currentPageFLs[0];
            var bookmarkExists = false;
            var i, saveData = {};

            /**
             * Inline Function; After the Bookmark is Saved; 
             *    - Add Data to Internal Array and Resort
             *    - Add Bookmark Element to Page and Position accurately
             * 
             * @private
             * @this local
             * @param {Object} data The Saved Bookmark Data
             * @return undefined
             */
            var saveSuccess = $.proxy(function(data) {
                var i, insertBeforeId = 0;
                var $bookmark = null;
                
                // Add Bookmark to Internal Array
                this.bookmarksData.push(data);
                this.bookmarksData = _.sortBy(this.bookmarksData, function(obj) { return _.parseInt(obj.page_fl, 10); });
                
                // Add Bookmark Overlay Element to Page
                $bookmark = this.addBookmarkElement({'id': data.id, 'page_id': data.page_id, 'page_fl': data.page_fl, 'className': this.app.config.overlayer.overlays.BookmarksOverlay.activeClass});
                
                // Move Bookmark to Correct Position
                for (i = 0; i < this.bookmarksData.length; i++) {
                    if (this.bookmarksData[i].page_fl === data.page_fl) {
                        if (i > 0) {
                            insertBeforeId = this.bookmarksData[i-1].page_id;
                        }
                        break;
                    }
                }
                if (insertBeforeId !== 0) {
                    $bookmark.insertBefore( $('.fb-bookmark[data-pageid="' + insertBeforeId + '"]') );
                }
            }, this);

            /**
             * Inline Function; After a Bookmark Fails to Save;
             *   - Alert user of failed attempt
             * 
             * @private
             * @this local
             * @return undefined
             */
            var saveFailure = $.proxy(function() {
                this.app.modal.alert('Failed to Bookmark Page!  Please try again.', {'header' : 'Bookmark Failed!', 'width': 350, 'height': 40});
            }, this);
            
            // !~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            // Begin: addBookmark()
            
            // Check if Bookmark already exists
            for (i = 0; i < this.bookmarksData.length; i++) {
                if (pageId === this.bookmarksData[i].page_id) {
                    this.app.modal.alert('You have already bookmarked this page!', {'header' : 'Already Bookmarked!', 'width': 350, 'height': 40});
                    bookmarkExists = true;
                    break;
                }
            }
            
            // Add Bookmark
            if (!bookmarkExists) {
                // Save Bookmark via Ajax
                saveData = {'data': JSON.stringify({'type': 'bookmark', 'issue_id': this.app.config.issueData.id, 'page_fl': pageFl})};
                Flipbook.qAjax({'url': saveUrl, 'data': saveData}).then(saveSuccess, saveFailure);
            }
        }, this);
        
        /**
         * Inline Function; Remove a specific Bookmark
         * 
         * @private
         * @this local
         * @param {Element} $el - The Bookmark Element to get info from
         * @return undefined
         */
        var removeBookmark = $.proxy(function($el) {
            var deleteUrl = this.app.config.urls.server + this.app.config.overlayer.overlays.BookmarksOverlay.deleteUrl;
            var bookmarkId = $el.attr('data-id');
            var pageId = $el.attr('data-pageid');

            /**
             * Inline Function; After the Bookmark is Deleted
             *    - Remove Data from Internal Array
             *    - Remove Bookmark Element from Page
             * 
             * @private
             * @this local
             * @return undefined
             */
            var deleteSuccess = $.proxy(function() {
                // Remove Bookmark Overlay Element from Page
                $('.fb-bookmark[data-pageid="' + pageId + '"]').remove();
                
                // Remove from Internal Array
                _.remove(this.bookmarksData, function(obj) { return obj.page_id === pageId; });
            }, this);

            /**
             * Inline Function; After a Bookmark Fails to Delete;
             *   - Alert user of failed attempt
             * 
             * @private
             * @this local
             * @return undefined
             */
            var deleteFailure = $.proxy(function() {
                this.app.modal.alert('Failed to Remove Bookmark!  Please try again.', {'header' : 'Update Failed!', 'width': 350, 'height': 40});
            }, this);
            
            // !~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            // Begin: removeBookmark()
            
            // Update Delete URL
            deleteUrl += bookmarkId + '/' + this.app.config.issueData.id + '/' + pageId;
            
            // Confirm Deletion
            this.app.modal.confirm('Are you sure you want to delete this Bookmark?', {'header' : 'Delete Bookmark?', 'width': 350, 'height': 50}).then(function() {
                // Delete Bookmark
                Flipbook.qAjax({'url': deleteUrl}).then(deleteSuccess, deleteFailure);
            });
        }, this);
        
        // !~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        // Begin: handleBookmarkEvent()
        
        // Check if Active Bookmark was Clicked
        if ($bookmark.hasClass(this.app.config.overlayer.overlays.BookmarksOverlay.activeClass)) {
            // Clicked on Remove Icon
            if ($realTarget.prop('tagName') === 'EM') {
                removeBookmark($bookmark);
            } 
            
            // Clicked on Bookmark
            else {
                jumpToPage($bookmark);
            }
        }
        
        // Check if Add Bookmark was Clicked
        else if ($bookmark.hasClass(this.app.config.overlayer.overlays.BookmarksOverlay.addClass)) {
            addBookmark();
        }
        
        // Normal Bookmark Clicked
        else {
            jumpToPage($bookmark);
        }
        
        return true;
    }, this);
};
