/**
 * Flyp Technologies Inc. - Flipbook v4
 *
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/overlays/widgets.js - Flipbook.WidgetsOverlay
 * @author Robert J. Secord, B.Sc.
 */
import 'jquery-migrate';
import $ from 'jquery';
import Flipbook from '../core';
import Shared from '../shared_util';
import Q from '/app/libs/promise/q';

/**
 * Flipbook Widgets Overlay Controller
 *
 * @class WidgetsOverlay
 * @classdesc Application Widgets Overlay
 * @namespace Flipbook
 * @inherits Flipbook.OverlayBase
 * @param {Object} overlayer A reference to the Overlayer Control
 * @param {Object} overlayOptions Configuration Settings for the Overlay
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.WidgetsOverlay = function(overlayer, overlayOptions) {
    // Call Parent Constructor
    Flipbook.OverlayBase.apply(this, [overlayer, overlayOptions]);
    
    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize = null;


    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    
    // Overlay Widgets
    this.$overlayWidgetEls = [];

    this.widgetsTracked      = [];
    this.overlayDisplayTimer = 0;
    this.overlayWidgetTimer  = 0;

    /* **************************************************************************************** */
    /* * Private Methods/Members Definitions                                                  * */
    /* **************************************************************************************** */
    /**
     * Initialize the Widgets Overlay
     *
     * @private
     * @this Flipbook.WidgetsOverlay
     * @return {Object} A reference to the WidgetsOverlay Object for Method Chaining
     * @constructs
     */
    initialize = $.proxy(function() {

        // Hook Blur Event on Window for Tracking Widget Interactions
        Shared.$('window').on('debouncedblur.Flipbook.OverlayWidget', $.proxy(this.handleOverlayBlur, this));

        return this;
    }, this);
    
    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize();
};
Flipbook.WidgetsOverlay.prototype = new Flipbook.OverlayBase();
Flipbook.WidgetsOverlay.prototype.constructor = Flipbook.WidgetsOverlay;
// End of Flipbook.WidgetsOverlay


/* ******************************************************************************************** */
/* * Overridden Base Methods                                                                  * */
/* ******************************************************************************************** */

/**
 * Override Abstract Base Method; Called when ...
 *
 * @public
 * @this Flipbook.WidgetsOverlay
 * @return {Object} A Promise
 */
Flipbook.WidgetsOverlay.prototype.resize = function() {
    return Q.Promise($.proxy(function(resolve, reject, notify) {
        // Debug Message
        Flipbook.log('overlay-resize-widgets');

        // Update Sizes
        this.calculateSizes();
        
        // Resize Complete; Resolve Promise
        resolve(true);
    }, this));
};

/**
 * Override Abstract Base Method; Called when ...
 *
 * @public
 * @this Flipbook.WidgetsOverlay
 * @return {Object} A Promise
 */
Flipbook.WidgetsOverlay.prototype.add = function() {
    return Q.Promise($.proxy(function(resolve, reject, notify) {
        var i, j, widgetCount = 0;
        var widgetId, pageId, widgetPageId;
        var $widget;
        var dimensions;
        var widgetUrl, frameUrl;
        var strictSize, optionclose, hideIfSmall;
        var pageIndices = this.app.carousel.sheets[this.app.carousel.current.sheet].pageIndices;
        var pageWidth = this.app.config.sheet.pageSize[this.app.viewport.orientation].w;
        var ns = '.Flipbook.OverlayWidget';
        
        var widgetClose = function($el) {
            return function(e) {
                $el.remove();
            };
        };

        // Debug Message
        Flipbook.log('overlay-add-widgets');
        
        // Iterate Current Pages
        for (i = 0; i < pageIndices.length; i++) {
            pageId = _.parseInt(this.app.parsedPageData[pageIndices[i]].realId, 10);
            
            // Look for Widgets for Current Pages
            for (j = 0; j < this.app.parsedWidgetData.length; j++) {
                widgetPageId = _.parseInt(this.app.parsedWidgetData[j].Widget.page_id, 10);
                
                // Widget for Current Page Found
                if (pageId === widgetPageId) {
                    // Get Widget ID
                    widgetId = _.parseInt(this.app.parsedWidgetData[j].Widget.id, 10);
                    
                    // Get Options of Widget
                    strictSize = /1|true/i.test(this.app.parsedWidgetData[j].Widget.strict_size);
                    optionclose = /1|true/i.test(this.app.parsedWidgetData[j].Widget.option_close);
                    hideIfSmall = /1|true/i.test(this.app.parsedWidgetData[j].Widget.hide_if_small);
                    
                    // Get Dimensions of Widgets
                    dimensions = $.extend({}, this.app.parsedWidgetData[j].Widget.scaled);
                    
                    // Determine Correct Left Position
                    if (i > 0 || (this.app.carousel.current.sheet === 0 && Flipbook.isFirstPageOnRight(this.app))) {
                        dimensions.x += pageWidth;
                    }
                    
                    // Don't display Widget if it is rendered Smaller than the Minimum Size set on the Widget
//                        if (hideIfSmall && ((dimensions.minw > 0 && dimensions.minw > dimensions.w) || (dimensions.minh > 0 && dimensions.minh > dimensions.h))) {
//                            continue;
//                        }
                    
                    // Create New Widget Element from Template
                    $widget = this.addTemplate({
                        'widget_id'      : widgetId,
                        'widget_page_id' : widgetPageId
                    });

                    // Set Size/Position/Scale of Widget
                    $widget.css({
                        'left'   : dimensions.x, 
                        'top'    : dimensions.y,
                        'width'  : dimensions.w, 
                        'height' : dimensions.h,
                        'scale'  : dimensions.scale,
                        'touch-action': 'auto'
                    });
                    
                    // Set Min/Max Dimensions of Widget
                    if (dimensions.minw > 0) { $widget.css({'min-width':  dimensions.minw}); }
                    if (dimensions.minh > 0) { $widget.css({'min-height': dimensions.minh}); }
                    if (dimensions.maxw > 0) { $widget.css({'max-width':  dimensions.maxw}); }
                    if (dimensions.maxh > 0) { $widget.css({'max-height': dimensions.maxh}); }
                    
                    // Set Closable Option
                    if (optionclose) {
                        // Mark Widget as Cloasable
                        $widget.addClass('closable');
                        
                        // Handle Click on Close
                        Flipbook.onClickTap(this.app, $widget.find('.overlay-widget-close'), widgetClose($widget), this, 'OverlayWidget');
                    }
                    
                    // Update Frame URL
                    widgetUrl = this.app.config.urls.server + this.app.config.overlayer.overlays.WidgetsOverlay.itemUrl;
                    widgetUrl = widgetUrl.replace('{{id}}', widgetId).replace('{{width}}', dimensions.w).replace('{{height}}', dimensions.h);

                    // detect iOS
                    if (Shared.IOS) {
                        widgetUrl += '?device=ios';
                    }

                    frameUrl = this.app.config.urls.files + 'js_redirect.php?msg=false&url=' + widgetUrl;
                    $widget.find('> iframe').attr('src', frameUrl);
                    
                    // Prevent Resizing the Flipbook when Entering Fullscreen Mode
                    $widget.find('> iframe').on('fullscreenchange'+ns+' mozfullscreenchange'+ns+' webkitfullscreenchange'+ns+' MSFullscreenChange'+ns, Flipbook.preventResize);
                    
                    // Add to Internal Widgets Array for later Reuse
                    this.$overlayWidgetEls.push($widget);
                    
                    // Increment Widget Count
                    widgetCount++;
                }
            }
        }
        
        // Overlay Added; Resolve Promise
        resolve({'type': 'Widget', 'count': widgetCount});
    }, this));
};

/**
 * Override Abstract Base Method; Called when ...
 *
 * @public
 * @this Flipbook.WidgetsOverlay
 * @return {Object} A Promise
 */
Flipbook.WidgetsOverlay.prototype.remove = function() {
    return Q.Promise($.proxy(function(resolve, reject, notify) {
        var widgetCount = this.$overlayWidgetEls.length;
        
        // Debug Message
        Flipbook.log('overlay-remove-widgets');

        // Remove Page Widgets
        for (var i = 0; i < widgetCount; i++) {
            if (this.$overlayWidgetEls[i].length) {
                this.$overlayWidgetEls[i].remove();
            }
        }
        this.$overlayWidgetEls.length = 0;
        
        // Overlay Removed; Resolve Promise
        resolve({'type': 'Widget', 'count': widgetCount});
    }, this));
};


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Calculate the Size/Position of the Widget Elements based on the Size of the Page
 *
 * @public
 * @this Flipbook.WidgetsOverlay
 * @return undefined
 */
Flipbook.WidgetsOverlay.prototype.calculateSizes = function() {
    var i, j, r;
    var scaleRatio;
    var $pageData;
    var pageWidth;
    var pageHeight;
    var pageDisplayWidth = this.app.config.sheet.pageSize[this.app.viewport.orientation].w;
    
    // Debug Message
    Flipbook.log('overlay-calc-size-widgets');
    
    // Calculate Sizes of Page Widgets
    for (i = 0; i < this.app.parsedWidgetData.length; i++) {
        $pageData = this.app.config.$issueSettings.find('pages > page[realid=' + this.app.parsedWidgetData[i].Widget.page_id + ']');
        if (!$pageData.length) { continue; }
        
        // Get the Original Page Size
        pageWidth = (_.parseInt($pageData.attr('width'), 10) || 1);
        pageHeight = (_.parseInt($pageData.attr('height'), 10) || 1);
        
        // Get the Scale Ratio for the Original Page to the Display Page
        scaleRatio = pageDisplayWidth / pageWidth;
        
        // Get Dimensions of Widget
        r = this.app.parsedWidgetData[i].Widget.dimensions.split(',');
        for (j = 0; j < r.length; j++) { r[j] = parseFloat(r[j]); }
        
        // Convert Position from Percent to Pixel
        r[0] = Math.round((r[0] / 100) * pageWidth);
        r[1] = Math.round((r[1] / 100) * pageHeight);
        r[2] = Math.round((r[2] / 100) * pageHeight * scaleRatio);
        r[3] = Math.round((r[3] / 100) * pageWidth * scaleRatio);

        // Override Width and Height if min/max values are equal
        if (r[4] > 0 && r[4] === r[6]) { r[0] = r[4]; }
        if (r[5] > 0 && r[5] === r[7]) { r[1] = r[5]; }
        
        // Ensure Scaled Size doesn't exceed Min/Max Bounds
//            if (r[4] > 0 && (r[0] * scaleRatio < r[4])) { scaleRatio = r[4] / r[0]; } // If Scaled Width is Less Than Min-Width
//            if (r[5] > 0 && (r[1] * scaleRatio < r[5])) { scaleRatio = r[5] / r[1]; } // If Scaled Height is Less Than Min-Height
//            if (r[6] > 0 && (r[0] * scaleRatio > r[6])) { scaleRatio = r[6] / r[0]; } // If Scaled Width is Greater Than Max-Width
//            if (r[7] > 0 && (r[1] * scaleRatio > r[7])) { scaleRatio = r[7] / r[1]; } // If Scaled Height is Greater Than Max-Height
        
        // Apply Scale to Widgets
        this.app.parsedWidgetData[i].Widget.scaled = {
            'w'    : r[0], 'h'    : r[1],
            'y'    : r[2], 'x'    : r[3],
            'minw' : r[4], 'minh' : r[5],
            'maxw' : r[6], 'maxh' : r[7],
            'scale': scaleRatio
        };
    }
};

/**
 * 
 * 
 * @public
 * @this Flipbook.WidgetsOverlay
 * @return undefined
 */
Flipbook.WidgetsOverlay.prototype.handleOverlayBlur = function(e) {
    var i, n, widgetId, pageId;
    var $activeWidget = null;
    if (!/^widgetIframe/i.test(document.activeElement.id)) { return; }
    
    // Prevent Resize
    Flipbook.preventResize();
    
    // Get Active Widget
    $activeWidget = $('#' + document.activeElement.id).parent();
    if (!$activeWidget.length) { return; }
    
    // Get Widget and Page IDs
    widgetId = _.parseInt($activeWidget.attr('data-widget-id') || 0, 10);
    pageId = _.parseInt($activeWidget.attr('data-page-id') || 0, 10);
    
    // Ensure Valid Widget and Page IDs
    if (widgetId === 0 || pageId === 0) { return; }

    // Prevent tracking the same widget more than once within a session
    for (i = 0, n = this.widgetsTracked.length; i < n; i++) {
        if (this.widgetsTracked[i] === widgetId) {
            return;
        }
    }
    this.widgetsTracked.push(widgetId);

    // Debug Message
    Flipbook.log({'msg': 'overlay-widget-interaction', 'args': {'widget': widgetId, 'page': pageId}, 'type': Shared.LVL_DEBUG});

    // Track Widget Interaction
    this.app.stats.widgetInteract(widgetId, pageId);
    
    // Force Focus back to Window
    Q.delay(750).then(function() { top.window.focus(); });
};
