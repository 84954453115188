import 'jquery-migrate';
import $ from 'jquery';
import Flipbook from './core';
import Shared from './shared_util';
import Q from '/app/libs/promise/q';
import MBP from '/app/libs/mbp/js/helper';

/**
 * Bring Shared Logger to Local Object
 *
 * @public
 * @static
 * @borrows Shared.Logger.log as Flipbook.log
 */
Flipbook.log = Shared.Logger.log;

/**
 * Flipbook Application
 *
 * @class App
 * @classdesc Application Controller
 * @namespace Flipbook
 * @inherits Flipbook.Core
 * @mixins Flipbook.Loading
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.App = function(appOptions) {
  // Call Parent Constructor
  Flipbook.Core.apply(this, [appOptions]);

  /* **************************************************************************************** */
  /* * Private Methods/Members Declarations                                                 * */
  /* **************************************************************************************** */
  var initialize;
  var loadSettings;
  var resizeEvent;
  var beforeUnloadEvent;
  var getIssueFormat;
  var parsePageData;
  var buildElements;
  var preventResizeOnInputFocus;


  /* **************************************************************************************** */
  /* * Public Properties                                                                    * */
  /* **************************************************************************************** */

  /**
   * Component: Shifter - The shifter sits above the Carousel and either "shifts" content or "overlaps" content when the Toolbar is opened
   *   All elements are inside Shifter Component so that they get "shifted" when neccessary
   *
   * @member {Object} shifter
   * @see Flipbook.Shifter
   * @protected
   */
  this.shifter = null;

  /**
   * Component: Toolbar - The Toolbar can be displayed at the Top of the Flipbook, or at the Left
   *   Holds all Tools for the Flipbook
   *
   * @member {Object} toolbar
   * @see Flipbook.Toolbar
   * @protected
   */
  this.toolbar = null;

  /**
   * Component: Pagebar - The Pagebar sits at the Bottom of the Flipbook and moves along with Page Changes
   *   Can be dragged to a specific page number to jump to that page
   *
   * @member {Object} pagebar
   * @see Flipbook.Pagebar
   * @protected
   */
  this.pagebar = null;

  /**
   * Component: Modal - Popup Modals for Flipbook
   *   Types of Modals: alert, confirm, content
   *
   * @member {Object} modal
   * @see Flipbook.Modal
   * @protected
   */
  this.modal = null;

  /**
   * Component: Carousel - The Carousel holds all of the Pages, and is used to Flip the Pages
   *
   * @member {Object} carousel
   * @see Flipbook.Carousel
   * @protected
   */
  this.carousel = null;

  /**
   * Component: Navigation - Standard Navigation controls for Flipping Pages without Swiping
   *   For Page Navigation
   *
   * @member {Object} navigation
   * @see Flipbook.Navigation
   * @protected
   */
  this.navigation = null;

  /**
   * Component: Non-Touch - Extra Navigation controls for devices that do not have Touch Support
   *   For Zooming and Panning
   *
   * @member {Object} nontouch
   * @see Flipbook.NonTouchControls
   * @protected
   */
  this.nontouch = null;

  /**
   * Component: Stats - For tracking user interactions within the Flipbook
   *
   * @member {Object} stats
   * @see Flipbook.Stats
   * @protected
   */
  this.stats = null;

  /**
   * Component: Overlayer - Controller for Handling Page Overlays
   *   Overlays: Links, Videos, Audio, Widgets, Annotations, Bookmarks
   *
   * @member {Object} stats
   * @see Flipbook.Stats
   * @protected
   */
  this.overlayer = null;

  /**
   * An offscreen fragment for preloading images into the DOM
   *
   * @member {Object} $offscreenFrag
   * @see Flipbook.Sheet.preloadImage
   * @protected
   */
  this.$offscreenFrag = null;
  //this.imagesLoaded = null;

  /* **************************************************************************************** */
  /* * Private Methods/Members Definitions                                                  * */
  /* **************************************************************************************** */
  /**
   * Initialize the Application
   *   Instantiates all Components
   *   Load Settings from AJAX
   *   Parse/Massage Loaded Settings
   *   Builds Required Elements in DOM
   *   Invoke Resize to Update all Components and Elements
   *     - Note: Resize is used to Update all Components and Elements of the Flipbook.
   *             The only Class to Hook the Resize Event is App, which then cascades the event
   *             down to all components by calling their respective resize() method.
   *             Resize is also used for Reorienting a Device from Landscape to Portrait and vice-versa.
   *
   * @private
   * @this Flipbook.App
   * @return {Object} A reference to the App Object for Method Chaining
   * @constructs
   */
  initialize = $.proxy(function() {
    // Update Logging/Profiling State
    Shared.Logger.enabled = this.config.enableDebugger;
    Shared.Profiler.enabled = this.config.enableProfiler;

    // Profiler
    Shared.Profiler.start('Flipbook:App->Init');

    // Debug Message
    Flipbook.log('app-init');

    // Initialize Stats Tracking
    this.stats = new Flipbook.Stats(this);

    // Initialize the Loading Screen
    this.initLoadingScreen();

    // Get a Handle to the Viewport
    this.getViewport();

    // Determine Display Type (Desktop, Embedded, Iframe, Mobile-Phone, Mobile-Tablet, Etc..)
    Flipbook.identifyDisplayType(this);

    // Hook Required Events
    document.addEventListener('touchmove', function(e) { e.preventDefault(); }, { passive: false }); // Prevent native scrolling in iOS devices
    Shared.$('window').on('debouncedresize.Flipbook.App', resizeEvent);
    Shared.$('window').on('beforeunload.Flipbook.App', beforeUnloadEvent);
    Shared.$('window').on('fullscreenchange mozfullscreenchange webkitfullscreenchange MSFullscreenChange', Flipbook.checkNeedsResizing);
          
    // Prevent Resizing on Input Focus
    preventResizeOnInputFocus();

    // Prevent Right Click Menu
    if (!this.config.enableDebugger) {
      this.viewport.$element.on('contextmenu', function(e) { return false; });
    }

    // Load Settings for Flipbook via Ajax
    loadSettings().then($.proxy(function() {
      // Clean Settings Data
      this.cleanIssueData();
      this.cleanTitleData();

      // Get Measurements of the Viewport
      Flipbook.measureViewport(this);

      // Build the Flipbook
      getIssueFormat();
      parsePageData();
      buildElements();
      this.resizeElements().then($.proxy(function() {
        // Hide Loading Screen after Delay
        //  - Also displays Welcome Message if exists
        //  - Afterwards, Flashes Overlays
        this.hideLoadingScreen(this.config.loadingScreen.hideDelay);

        // Track Flipbook Opened
        this.stats.flipbookOpened();

        // Debug Message
        Flipbook.log('app-init-end');

        // Profiler
        Shared.Profiler.end('Flipbook:App->Init');
        Shared.Profiler.outputAll();
      }, this));
    }, this));

    return this;
  }, this);

  /**
   * Observes and Responds to the Resize Events fired on the Global Window Object or App Container
   *     - Also fires for the OrientationChanged event, as the behaviour is essentially the same.
   *
   * @private
   * @this Flipbook.App
   * @param {Object} eventData The Event Data associated with the Event
   * @return {boolean} The success state of the event; determines whether to allow event-bubbling
   */
  resizeEvent = $.proxy(function(eventData) {
    if (this.state.animating || this.state.mobileInputFocus || (this.carousel && this.carousel.zoom.pinch.initiated)) { return false; }

    // Prevent Resize Flagged (usually Toggled when Entering Fullscreen Mode)
    if (Flipbook.PREVENT_RESIZE) { return false; }

    // Get Measurements of the Viewport
    Flipbook.measureViewport(this);

    if(!Flipbook.NEEDS_RESIZING) {
      var isFullScreen = (document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen) || (document.activeElement.scrollHeight == window.innerHeight);
      if (/^widgetIframe/i.test(document.activeElement.id) && isFullScreen) {
        // Widget is currently full screen
        Flipbook.NEEDS_RESIZING = true;
        return false;
      }
      // Confirm Viewport actually Resized
      if (!Flipbook.confirmViewportResize(this)) {
        return false;
      }
    } else {
      // After this resizing we can unset this variable
      Flipbook.NEEDS_RESIZING = false;
    }

    // Debug Message
    Flipbook.log('app-resize');

    // Resize Elements
    this.resizeElements();

    // Update Stats
    this.stats.resize();

    return false;
  }, this);

  /**
   * Observes and Responds to the Before-Unload Event fired on the Global Window Object
   *
   * @private
   * @this Flipbook.App
   * @param {Object} eventData The Event Data associated with the Event
   * @return {boolean} The success state of the event; determines whether to allow event-bubbling
   */
  beforeUnloadEvent = $.proxy(function(eventData) {
    // Debug Message
    Flipbook.log('app-before-unload');

    // Track Flipbook Closed Event
    this.stats.flipbookClosed();
    return;
  }, this);

  /**
   * Loads the Flipbook Settings via Ajax
   *   Gets Title, Issue, Widget, Notes and Bookmarks Settings\Data associated with Current Flipbook.
   *   Title & Issue Data is returned in XML Format; luckily jQuery DOM Parser works here,
   *   and we can access the Data with jQuery using standard query selectors and DOM functions.
   *   We invoke Ajax via Q so that the Promise returned is compliant with the Promises/A+ spec.
   *
   * @private
   * @this Flipbook.App
   * @return {Object} A Promise
   */
  loadSettings = $.proxy(function() {
    var titlePromise, issuePromise, widgetPromise, notesPromise;
    var pageWidgetUrl, pageNotesAndMarksUrl;

    // Debug Message
    Flipbook.log('app-load-settings');

    // Profiler
    Shared.Profiler.start('Flipbook:App->loadSettings');

    // Title Promise
    titlePromise = Q.invoke($, 'ajax', {'url': this.config.urls.titleSettings, 'type': 'GET', 'cache': false, 'async': true, 'dataType': 'xml'});

    // Issue Promise
    issuePromise = Q.invoke($, 'ajax', {'url': this.config.urls.issueSettings, 'type': 'GET', 'cache': false, 'async': true, 'dataType': 'xml'});

    // We Don't Load Widgets, Annotations or Bookmarks in LibraryApp - Offline Mode
    //  as they all require an active internet connection

    // Widgets Promise
    if (!this.config.device.isOffline && this.config.overlayer.overlays.WidgetsOverlay !== undefined) {
      pageWidgetUrl = this.config.urls.server + this.config.overlayer.overlays.WidgetsOverlay.listUrl + this.config.issueData.id + '/' + this.viewport.breakpoint + '/';
      widgetPromise = Q.invoke($, 'ajax', {'url': pageWidgetUrl, 'type': 'GET', 'cache': false, 'async': true, 'dataType': 'json'});
    } else {
      widgetPromise = Q('no-flipbook-widgets'); // self-resolving promise
    }

    // Annotations\Bookmarks Promise
    if (!this.config.device.isOffline && this.config.features.annotations && this.config.overlayer.overlays.BookmarksOverlay !== undefined) {
      pageNotesAndMarksUrl = this.config.urls.server + this.config.overlayer.overlays.BookmarksOverlay.loadUrl + this.config.issueData.id + '?t=' + Shared.getTime();
      notesPromise = Q.invoke($, 'ajax', {'url': pageNotesAndMarksUrl, 'type': 'GET', 'cache': false, 'async': true, 'dataType': 'json'});
    } else {
      notesPromise = Q('no-flipbook-notes'); // self-resolving promise
    }

    // Return a Promise
    return Q.spread([titlePromise, issuePromise, widgetPromise, notesPromise],
              // Success Callback
              $.proxy(function promiseSuccess(titleXml, issueXml, widgetJson, notesJson) {
                // Store Title Settings
                this.config.$titleSettings = $(titleXml);

                // Store Issue Settings
                this.config.$issueSettings = $(issueXml);

                // Store Widget Data
                this.parsedWidgetData = widgetJson;

                // Store Annotations Data
                this.parsedNotesData = notesJson;

                // Profiler
                Shared.Profiler.end('Flipbook:App->loadSettings');

                // Debug Message
                Flipbook.log('app-load-settings-end');
              }, this),

              // Failure Callback
              function promiseFailure() {
                Flipbook.embedError(this, Shared.Logger.parse({'msg': 'no-flipbook-settings', 'args': {'reason': 'Could not Fetch XML Resource via Ajax'}, 'type': Shared.LVL_ERROR}));
              }
          );
  }, this);

  getIssueFormat = $.proxy(function() {
    // For IE11
    // use Modernizr to check for SVG SMIL compatibility (animations & zoom)
    var browserHasSMIL = Modernizr.smil;

    if (this.config.hasSvg && browserHasSMIL) {
      this.issueFormat = '.svg';
      this.showExplodedImages = false;
    }
    else {
      this.issueFormat = '.jpg';
    }
  }, this);

  /**
   * Parses the Overlays for each Page (Links, Audio, Video, SWFs and Articles)
   *   Also Groups the Results from the Annotations\Bookmarks Data into Separate Objects within the original
   *
   * @private
   * @this Flipbook.App
   * @return undefined
   */
  parsePageData = $.proxy(function() {
    var pageRealId;
    var pages, $page;
    var articles;
    var items, $item;
    var $thumb;
    var pageNum = 0;

    // Reset Parsed Page Data
    this.parsedPageData = [];
    this.parsedPageData.length = 0;

    // Reset Parsed Article Data
    this.parsedArticleData = [];
    this.parsedArticleData.length = 0;

    // Iterate All Pages in Flipbook
    pages = this.config.$issueSettings.find('pages > page');
    $.each(pages, $.proxy(function(pageIndex, page) {
      // Get Handle to Page
      $page = $(page);

      // Get RealID of Page
      pageRealId = $page.attr('realid');

      // Create Data Object for Page
      this.parsedPageData[pageIndex] = {
        'realId' : pageRealId,
        'thumb'  : '',
        'links'  : [],
        'videos' : [],
        'swfs'   : [],
        'audios' : []
      };

      // Find Thumbnail for Page
      $thumb = $page.find('thumb');
      if ($thumb && $thumb.length) {
        this.parsedPageData[pageIndex].thumb = $thumb.text().replace('-0.jpg', '');
      }

      // Find All Link Items of Page
      items = $page.find('> links > item');
      if (items.length) {

        // Iterate All Items of Page and Store Data in Array
        $.each(items, $.proxy(function(itemIndex, item) {
          // Get Handle to Item
          $item = $(item);

          this.parsedPageData[pageIndex].links.push({
            'id'          : $item.attr('id') || '',
            'rect'        : $item.attr('rect') || '',
            'highlight'   : $item.attr('highlight') || '',
            'type'        : $item.find('type').text() || '',
            'destination' : $item.find('destination').text() || '',
            'linkStyle'   : $item.find('linkStyle').text() || '',
            'hovertip'    : $item.find('hovertip').text() || '',
            'target'      : $item.find('target').text() || '',
            'iframeW'     : $item.find('iframe_w').text() || '',
            'iframeH'     : $item.find('iframe_h').text() || '',
            'scaled'      : {},
            'largeLink'   : false,
            'disabled'    : false
          });
        }, this));
      }

      // Find All Video Items of Page
      items = $page.find('> videos > item');
      if (items.length) {

        // Iterate All Items of Page and Store Data in Array
        $.each(items, $.proxy(function(itemIndex, item) {
          // Get Handle to Item
          $item = $(item);

          this.parsedPageData[pageIndex].videos.push({
            'id'       : $item.attr('id') || '',
            'rect'     : $item.attr('rect') || '',
            'src'      : $item.find('src').text() || '',
            'autoplay' : $item.find('autoPlay').text() || 0,
            'autohide' : $item.find('autohide').text() || 0,
            'scaled'   : {}
          });
        }, this));
      }

      // Find All SWF Items of Page
      items = $page.find('> swfs > item');
      if (items.length) {

        // Iterate All Items of Page and Store Data in Array
        $.each(items, $.proxy(function(itemIndex, item) {
          // Get Handle to Item
          $item = $(item);

          this.parsedPageData[pageIndex].swfs.push({
            'id'          : $item.attr('id') || '',
            'rect'        : $item.attr('rect') || '',
            'src'         : $item.find('src').text() || '',
            'interactive' : $item.find('interactive').text() || 0,
            'scaled'      : {}
          });
        }, this));
      }

      // Find All MP3 Items of Page
      items = $page.find('> mp3 > item');
      if (items.length) {

        // Iterate All Items of Page and Store Data in Array
        $.each(items, $.proxy(function(itemIndex, item) {
          // Get Handle to Item
          $item = $(item);

          this.parsedPageData[pageIndex].audios.push({
            'id'       : $item.attr('id') || '',
            'rect'     : $item.attr('rect') || '',
            'src'      : $item.find('src').text() || '',
            'autoplay' : $item.find('autoPlay').text() || 0,
            'scaled'   : {}
          });
        }, this));
      }
    }, this));

    // Iterate All Articles in Flipbook
    articles = this.config.$issueSettings.find('articles > item');
    $.each(articles, $.proxy(function(itemIndex, item) {
      // Get Handle to Item
      $item = $(item);
      pageNum = parseInt($item.find('page').text() || 0, 10);

      // Create Data Object for Article
      this.parsedArticleData[itemIndex] = {
        'title' : $item.find('title').text(),
        'page'  : pageNum,
        'text'  : $item.find('text').text(),
        'thumb' : this.parsedPageData[pageNum].thumb
      };
    }, this));

    // Group Parsed Notes/Bookmarks Data and Sort
    if (_.isArray(this.parsedNotesData)) {
      this.parsedNotesData = _.groupBy(this.parsedNotesData, function(obj) { return obj.Annotation.type; });
      this.parsedNotesData.bookmark = _.sortBy(this.parsedNotesData.bookmark, function(obj) { return _.parseInt(obj.Annotation.page_fl, 10); });
      this.parsedNotesData.note = _.sortBy(this.parsedNotesData.note, function(obj) { return _.parseInt(obj.Annotation.page_fl, 10); });
    }

  }, this);

  /**
   * Build the Primary Flipbook Elements;
   *   - Set Theming
   *   - Instantiate Components which build themselves
   *       - Shifter, Toolbar, Pagebar, Carousel, etc..
   *
   * @private
   * @this Flipbook.App
   * @return undefined
   */
  buildElements = $.proxy(function() {
    var $flipbookBackground = null;

    // Debug Message
    Flipbook.log('app-build-elements');

    // Profiler
    Shared.Profiler.start('Flipbook:App->buildElements');

    // Set Theme of App
    this.viewport.$element.addClass('theme-' + this.config.theme.iconSet);
    this.viewport.$element.addClass('tool-theme-' + this.config.theme.theme);

    // Set Positioning Class of Toolbar
    if (this.config.toolbar.enabled) {
      this.viewport.$element.addClass('toolbar-position-' + this.config.toolbar.position);
    }

    // Set Background Color/Image of App
    $flipbookBackground = this.config.$titleSettings.find('#flipbook_background');
    if ($flipbookBackground.length) {
      this.viewport.$element.css({'background': $flipbookBackground.text()});
    } else {
      this.viewport.$element.css({'background-color': this.config.titleData.flypbook_bg_color});
    }

    // Build the Shifter Control
    this.shifter = new Flipbook.Shifter(this, this.viewport.$element);

    // Build the Toolbar Control
    if (this.config.toolbar.enabled) {
      this.toolbar = new Flipbook.Toolbar(this, this.shifter.$toolbar);
    }

    // Build the Pagebar Control
    if (this.config.pagebar.enabled) {
      this.pagebar = new Flipbook.Pagebar(this, this.shifter.$pagebar);
    }

    // Build the Carousel Control
    this.carousel = new Flipbook.Carousel(this, this.shifter.$carousel);

    // Build the Carousel-Navigation Controls
    this.navigation = new Flipbook.Navigation(this, this.shifter.$content);

    // Build the Modal Control
    this.modal = new Flipbook.Modal(this, this.viewport.$element);

    // Build the Overlayer Control
    this.overlayer = new Flipbook.Overlayer(this, this.viewport.$element, this.carousel.$scrollDiv);

    // Build the Non-Touch Controls
    this.nontouch = new Flipbook.NonTouchControls(this, this.shifter.$content);

    // ImagesLoaded Object
    this.$offscreenFrag = $('.offscreen-fragment');

    // Profiler
    Shared.Profiler.end('Flipbook:App->buildElements');

    // Debug Message
    Flipbook.log('app-build-elements-end');
  }, this);

  /**
   * Prevent the App from Resizing when an Input Element is Focuses on Mobile Devices
   *   - Mobile devices that use a virtual keyboard cause a resize event to occur when the
   *     virtual keyboard is opened.  This would in turn cause the flipbook to resize and
   *     lose focus of the input, preventing the user from entering text.  So we monitor
   *     for the focus event on input elements and prevent the app from resizing.
   *
   * @private
   * @this Flipbook.App
   * @return {Object} A Promise
   */
  preventResizeOnInputFocus = $.proxy(function() {
    if (!Flipbook.isMobile.any(this)) { return; }

    // Monitor for Blurred Input
    var inputBlur = $.proxy(function() {
      Shared.onNextEventLoop(function() {
        var $active = $(document.activeElement);
        if (!$active.length) { return; }

        // Check if Active Element is an Input or Textarea
        if (/input|textarea/i.test($active.prop('tagName'))) {
          return inputBlur();
        }

        // Flag App as No Input Focused
        this.state.mobileInputFocus = false;
      }, this, 500);

      return false;
    }, this);

    // Detect Input Focus
    this.viewport.$element.on('focus.Flipbook.App', ':input', $.proxy(function() {
              // Flag App as Input Focused
      this.state.mobileInputFocus = true;

      // Monitor for Input Blur
      inputBlur();
    }, this));
  }, this);


  /* **************************************************************************************** */
  /* * Entry Point                                                                          * */
  /* **************************************************************************************** */
  return initialize();
};
Flipbook.App.prototype = new Flipbook.Core();
Flipbook.App.prototype.constructor = Flipbook.App;
// End of Flipbook.App


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Resize the Flipbook Components
 *   Renders the Elements on Screen
 *
 * @private
 * @this Flipbook.App
 * @return {Object} A Promise
 */
Flipbook.App.prototype.resizeElements = function() {
  var deferred = Q.defer();
  var isFullScreen = this.viewport.fullscreen;
  var isDesktop = Flipbook.isDesktop(this);

  // Prevent Resize Flagged (usually Toggled when Entering Fullscreen Mode), when not on desktop
  if (Flipbook.PREVENT_RESIZE && !isDesktop) { return; }

  // Debug Message
  Flipbook.log('app-resize-elements');

  // Exit Fullscreen Mode
  if (!isFullScreen) {
    Shared.exitFullscreen();
  }

  // Hide the URL bar
  MBP.hideUrlBar();

  // Update Toolbar State
  this.toggleEmbeddedToolbarState();

  // Resize Components
  this.shifter.resize().then($.proxy(function() {
    if (this.config.toolbar.enabled && !Flipbook.isFullscreen(this)) { this.toolbar.resize(); }
    if (this.config.pagebar.enabled) { this.pagebar.resize(); }
    this.carousel.resize();
    this.overlayer.resize();
    this.navigation.resize();

    // Debug Message
    Flipbook.log('app-resize-elements-end');

    // Resolve Promise
    deferred.resolve('expanded');
  }, this));

  return deferred.promise;
};

/**
 * Toggle the Toolbar State when Embedded
 *   - When a Flipbook is Embedded in an Uberflip Hub, we only want to display
 *     the toolbar when the Flipbook is expanded into Fullscreen mode.
 *
 * @public
 * @this Flipbook.App
 * @return undefined
 */
Flipbook.App.prototype.toggleEmbeddedToolbarState = function() {
  var ww, wh, sw, sh;
  var isFullScreen = Flipbook.determineFullscreen();

  // Check for HUB Source
  //   - When embedded in a Hub, we only display the toolbar in Fullscreen Mode
  if (this.config.source === Flipbook.SOURCE_HUB) {
    ww = Shared.$('window').outerWidth();
    wh = Shared.$('window').outerHeight();
    sw = screen.width;
    sh = screen.height;

    if (ww === sw || ww === sh || wh === sw || wh === sh || isFullScreen) {
      // Fullscreen Mode
      this.config.toolbar.enabled = true;
      if (this.toolbar) {
        this.toolbar.show();
      }
    } else {
      // Non-Fullscreen Mode
      if (this.toolbar) {
        this.toolbar.hide();
      }
      this.config.toolbar.enabled = false;
    }
  }
};

/**
 * Forces the Carousel into Scrubber Mode
 *   Useful for Automated Mode Switching and for Debugging via Desktop Console
 *
 * @public
 * @this Flipbook.App
 * @return {Object} A Promise
 */
Flipbook.App.prototype.forceScrubberMode = function() {
  return Q.Promise($.proxy(function(resolve, reject, notify) {
    this.state.animating = true;
    this.carousel.$container.transition({'scale': this.config.sheet.minScale[this.viewport.orientation]}, 200, $.proxy(function() {
      this.carousel.resetPinch({'mode': Flipbook.CAROUSEL_SCRUBBER, 'scale': this.config.sheet.minScale[this.viewport.orientation], 'reset': true})
                  .then($.proxy(function() {
                    this.state.animating = false;
                    resolve(true);
                  }, this));
    }, this));
  }, this));
};

/**
 * Forces the Carousel into Slider Mode
 *   Useful for Automated Mode Switching and for Debugging via Desktop Console
 *
 * @public
 * @this Flipbook.App
 * @return {Object} A Promise
 */
Flipbook.App.prototype.forceSliderMode = function() {
  return Q.Promise($.proxy(function(resolve, reject, notify) {
    this.state.animating = true;
    this.carousel.$container.transition({'scale': 1}, 200, $.proxy(function() {
      this.carousel.resetPinch({'mode': Flipbook.CAROUSEL_SLIDER, 'scale': 1, 'reset': true})
                  .then($.proxy(function() {
                    this.state.animating = false;
                    this.stats.pageChanged();
                    resolve(true);
                  }, this));
    }, this));
  }, this));
};


  /*
  Flipbook.App.prototype.testModalAlert = function(message, options) {
      return this.modal.alert(message, options);
  };
  Flipbook.App.prototype.testModalConfirm = function(message, options) {
      return this.modal.confirm(message, options);
  };
  Flipbook.App.prototype.testModalContent = function(content, options) {
      return this.modal.content(content, options);
  };
  */


/* ******************************************************************************************** */
/* * Class Mixins                                                                             * */
/* ******************************************************************************************** */

// Augment App to Have Loading Screen
//  This essentially brings all functionality of Loading into the App Class,
//  so Scope is that of the App Class, and function/property names must not conflict across Classes.
Shared.augment(Flipbook.App, [Flipbook.Loading]);

// Augment Carousel to have Slider, Scrubber & Zoomer Event Handlers
//  This essentially brings all functionality of Slider, Scrubber and Zoomer into the Carousel Class,
//  so Scope is that of the Carousel Class, and function/property names must not conflict across Classes.
Shared.augment(Flipbook.Carousel, [Flipbook.Slider, Flipbook.Scrubber, Flipbook.Zoomer]);
