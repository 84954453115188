/**
 * Flyp Technologies Inc. - Flipbook v4
 *
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/tools/archives.js - Flipbook.ArchivesTool
 * @author Robert J. Secord, B.Sc.
 */
import 'jquery-migrate';
import $ from 'jquery';
import Flipbook from '../core';
import Q from '/app/libs/promise/q';

/**
 * Flipbook Archives Tool
 *
 * @class ArchivesTool
 * @classdesc Application Archives Tool
 * @namespace Flipbook
 * @inherits Flipbook.ToolsBase
 * @param {Object} toolbar A reference to the Toolbar Control
 * @param {Object} toolData Configuration Settings for the Tool
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.ArchivesTool = function(toolbar, toolData) {
    // Call Parent Constructor
    Flipbook.ToolsBase.apply(this, [toolbar, toolData]);

    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize = null;


    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    this.archivesUrl = '';
    this.$archivesList = null;
    this.$loadMore = null;
    this.sortDir = 'DESC';
    this.sortField = 'Date';
    this.archivesCount = 0;
    this.currentPage = 1;
    this.lazyLoadScrollPos = 0;
    this.canLoadMore = true;


    /* **************************************************************************************** */
    /* * Private Methods/Members Definitions                                                  * */
    /* **************************************************************************************** */
    /**
     * Initialize the Archives Tool
     *
     * @private
     * @this Flipbook.ArchivesTool
     * @return {Object} A reference to the ArchivesTool Object for Method Chaining
     * @constructs
     */
    initialize = $.proxy(function() {
        // Build Archives URL
        if (!this.app.config.device.isOffline || navigator.onLine) {
            this.archivesUrl = this.app.config.urls.server + 'read/archives/' +
                this.app.config.stats.type + '/' +
                this.app.config.titleData.id + '/' +
                this.app.config.issueData.id + '/' +
                parseInt(Flipbook.version, 10) + '/';
        }

        return this;
    }, this);

    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize();
};
Flipbook.ArchivesTool.prototype = new Flipbook.ToolsBase();
Flipbook.ArchivesTool.prototype.constructor = Flipbook.ArchivesTool;
// End of Flipbook.ArchivesTool


/* ******************************************************************************************** */
/* * Overridden Base Methods                                                                  * */
/* ******************************************************************************************** */

/**
 * Override Abstract Base Method; Called when the Tool Panel is Opened
 *
 * @public
 * @this Flipbook.ArchivesTool
 * @return undefined
 */
Flipbook.ArchivesTool.prototype.panelOpened = function() {
    // Replace Panel Content
    this.replacePanelContent(this.getArchivesUrl(), true).then($.proxy(function() {
        // Get reference to Archives List Element
        this.getListElement();

        // Check if we have reached the Archived Item Limit
        this.checkLimitReached();
    }, this));
};

/**
 * Override Abstract Base Method; Called when the Tool Panel is Opened
 *
 * @public
 * @this Flipbook.ArchivesTool
 * @return undefined
 */
Flipbook.ArchivesTool.prototype.panelClosed = function() {
    // Clear Handle to Archives List Element
    this.$archivesList = null;

    // Reset Panel
    this.resetPanel();
};

/**
 * Override Abstract Base Method to Handle Panel-Scroll Events on Tool Panel
 *
 * @public
 * @this Flipbook.ArchivesTool
 * @return undefined
 */
Flipbook.ArchivesTool.prototype.panelScrolled = function(scrollLeft, scrollTop, boundsReached) {
    // Check if we have reached the Bottom Bounds of the Panel
    if (boundsReached.bottom && this.lazyLoadScrollPos !== scrollTop) {
        // Load More Archives
        this.loadMore();

        // Store Last Position of Scroll
        this.lazyLoadScrollPos = scrollTop;
    }
};


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Resets the Panel Data
 *
 * @public
 * @this Flipbook.ArchivesTool
 * @return {Object} A reference to the ArchivesTool Object for Method Chaining
 */
Flipbook.ArchivesTool.prototype.resetPanel = function() {
    // Reset Lazy-Load Scroll Position
    this.lazyLoadScrollPos = 0;

    // Reset Current Page
    this.currentPage = 1;

    // Reset Load-More Flag
    this.canLoadMore = true;

    return this;
};

/**
 * Get the Archives List URL for AJAX Requests
 *
 * @public
 * @this Flipbook.ArchivesTool
 * @return {String} The Archives URL for AJAX
 */
Flipbook.ArchivesTool.prototype.getArchivesUrl = function() {
    return this.archivesUrl +
        '?page=' + this.currentPage +
        '&sortBy=' + this.sortField +
        '&sortDirection=' + this.sortDir;
};

/**
 * Gets Handles to the Archives List Element and Loading Element
 *   - Also finds and stores the archived issue count
 *
 * @public
 * @this Flipbook.ArchivesTool
 * @return {Object} A reference to the ArchivesTool Object for Method Chaining
 */
Flipbook.ArchivesTool.prototype.getListElement = function() {
    // Get Handle to Archives List Element
    this.$archivesList = this.getPanel().find('.archived-issues');

    // Get Handle to Load-More Element
    this.$loadMore = this.getPanel().find('.load-more-container');

    // Get Archived-Issues Count from Archives List Element
    this.archivesCount = parseInt(this.$archivesList.attr('data-issue-count'), 10);

    return this;
};

/**
 * Check if we have reached the Archived Issue Limit
 *   - if so, we remove the Loading Element
 *
 * @public
 * @this Flipbook.ArchivesTool
 * @return {Object} A reference to the ArchivesTool Object for Method Chaining
 */
Flipbook.ArchivesTool.prototype.checkLimitReached = function() {
    if (!this.$archivesList.length) { return; }

    // Check if we have reached the Archived Item Limit
    var currentCount = this.$archivesList.find('.archive-item-wrapper').length;
    if (currentCount >= this.archivesCount) {
        // Remove Lazy-Loading Block
        this.$loadMore.remove();

        // Disable Lazy-Loading
        this.canLoadMore = false;
    }

    return this;
};

/**
 * Toggles the Loading State of the Panel
 *
 * @public
 * @this Flipbook.ArchivesTool
 * @return {Object} A reference to the ArchivesTool Object for Method Chaining
 */
Flipbook.ArchivesTool.prototype.toggleLoadingState = function(isLoading) {
    this.$loadMore[ isLoading ? 'addClass' : 'removeClass' ]('is-loading');
    return this;
};

/**
 * Replaces the Panel Content with New Content and Hooks Required Links within
 *
 * @public
 * @this Flipbook.ArchivesTool
 * @return {Object} A Promise
 */
Flipbook.ArchivesTool.prototype.replacePanelContent = function(contentUrl, loading) {
    var deferred = Q.defer();

    // Reset Panel
    this.resetPanel();

    // Cancel any pending AJAX Requests
    Flipbook.cancelLastAjax();

    // Request HTML for Panel Content via AJAX
    Flipbook.qAjax({'url': contentUrl, 'dataType': 'html'})

        .then($.proxy(function(responseData) {
            // Base: Replace Panel Contents
            this.panelReplaceHtml(responseData, loading).then($.proxy(function(promiseValue) {
                // Hook Panel Links
                this.hookPanelLinks();

                // Resolve Promise
                deferred.resolve(promiseValue);
            }, this));
        }, this))

        .fail($.proxy(function(error) {
            // Base: Display Error Message
            this.panelDisplayError(error);
        }, this));

    return deferred.promise;
};

/**
 * Loads more content via Ajax and appends to the Archives List Element
 *
 * @public
 * @this Flipbook.ArchivesTool
 * @return {Object} A Promise
 */
Flipbook.ArchivesTool.prototype.loadMore = function() {
    var deferred = Q.defer();

    if (this.canLoadMore) {
        // Toggle Loading State
        this.toggleLoadingState(true);

        // Increment Current Page
        this.currentPage += 1;

        // Cancel any pending AJAX Requests
        Flipbook.cancelLastAjax();

        // Request HTML for Panel Content via AJAX
        Flipbook.qAjax({'url': this.getArchivesUrl(), 'dataType': 'html'}).then($.proxy(function(responseData) {
            if (!this.$archivesList.length) { return; }

            // Toggle Loading State
            this.toggleLoadingState(false);

            // Append New Content to Archives List Element
            this.$archivesList.append(responseData);

            // Check if we have reached the Archived Item Limit
            this.checkLimitReached();

            // Update Scrollbar in Panel
            this.updatePanelScrollbar();

            // Resolve Promise
            deferred.resolve('loaded');
        }, this));
    }

    return deferred.promise;
};

/**
 * Finds and Hooks Links within the Panel
 *   - Namely: Paging Links, Load-More Link
 *
 * @public
 * @this Flipbook.ArchivesTool
 * @return {Object} A reference to the ArchivesTool Object for Method Chaining
 */
Flipbook.ArchivesTool.prototype.hookPanelLinks = function() {
    var $panel = this.getPanel();
    var $pagingLinks = $panel.find('.sort-links a:not(.hooked)');
    var $loadingLink = $panel.find('.load-more:not(.hooked)');

    // Iterate over Paging Links that are not already Hooked
    $pagingLinks.each($.proxy(function(idx, link) {
        var $link = $(link);

        // Mark Link as Hooked
        $link.addClass('hooked');

        // Attach Click/Tap Event to Paging Links
        Flipbook.onClickTap(this.app, $link, this.handlePagingLinkClick, this, this.toolName + 'SortLink' + idx);
    }, this));

    // Hook Load-More Link
    if ($loadingLink.length) {
        // Mark Link as Hooked
        $loadingLink.addClass('hooked');

        // Attach Click/Tap Event to Load-More Link
        Flipbook.onClickTap(this.app, $loadingLink, this.loadMore, this, this.toolName + 'LoadMoreLink');
    }

    return this;
};

/**
 * Handles the Click/Tap Event on Paging Links
 *   - These links cause the entire panel to be reloaded
 *
 * @public
 * @this Flipbook.ArchivesTool
 * @return {Boolean} Event bubble flag
 */
Flipbook.ArchivesTool.prototype.handlePagingLinkClick = function(e) {
    var $link = $(e.target);
    var href = $link.attr('data-original-href');
    var matches = null;

    if (Flipbook.hasTouch(this.app)) {
        e.gesture.preventDefault();
    } else {
        e.preventDefault();
    }
    e.stopPropagation();

    // Get HREF of Link
    if (href === undefined) {
        href = $link.attr('href');
        $link.attr({'data-original-href': href, 'href': 'javascript:void(0)'});
    }

    // Get Sort Field
    matches = href.match(/sortBy=(Date|Title)/i);
    if (matches && matches.length > 1) {
        this.sortField = matches[1];
    }

    // Get Sort Direction
    matches = href.match(/sortDirection=(ASC|DESC)/i);
    if (matches && matches.length > 1) {
        this.sortDir = matches[1];
    }

    // Replace Panel Content
    this.replacePanelContent(href, false).then($.proxy(function() {
        // Get reference to Archives List Element
        this.getListElement();

        // Check if we have reached the Archived Item Limit
        this.checkLimitReached();
    }, this));

    // Prevent link from following HREF attribute (Desktop only)
    return false;
};
