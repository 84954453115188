/**
 * Flyp Technologies Inc. - Flipbook v4
 *
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/carousel.js - Flipbook.Carousel
 * @author Robert J. Secord, B.Sc.
 */
import 'jquery-migrate';
import $ from 'jquery';
import Flipbook from './core';
import Shared from './shared_util';
import Q from '/app/libs/promise/q';

/**
 * Flipbook Carousel
 *
 * @class Carousel
 * @classdesc Application Carousel Controller
 * @namespace Flipbook
 * @mixins Flipbook.Slider, Flipbook.Scrubber, Flipbook.Zoomer
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.Carousel = function(app, $container) {

    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize;
    var buildElements;
    var attachEvents;


    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    /**
     * A reference to the Application Object
     *
     * @member {Object} app
     * @see Flipbook.App
     * @protected
     */
    this.app = app;

    /**
     * A jQuery Element Handle to the Container Element which holds the Carousel
     *   - Provided by Shifter Component
     *
     * @member {Object} $container
     * @see Flipbook.App
     * @protected
     */
    this.$container = $container;

    /**
     * A jQuery Element Handle to the Scrollable DIV within the Carousel
     *   - Created and added to DOM internally
     *
     * @member {Object} $scrollDiv
     * @protected
     */
    this.$scrollDiv = null;

    /**
     * Current View Mode of the Carousel
     *   - View Modes can be Slider, Scrubber or Zoomer (Slider is Default View)
     *
     * @member {string} viewMode
     * @protected
     */
    this.viewMode = Flipbook.CAROUSEL_SLIDER;

    /**
     * Current Size of the Carousel Container
     *
     * @member {Object} containerSize
     * @member {number} containerSize.width The Width of the Container in Pixels
     * @member {number} containerSize.height The Height of the Container in Pixels
     * @protected
     */
    this.containerSize = {'width': 0, 'height': 0};

    /**
     * Current Size of Slider Buffer
     *   - The buffer keeps only a limited number of pages in view,
     *     so that a Carousel of 100 pages or more does not overload
     *     the memory limits of the device viewing the Flipbook.
     *
     * @member {Object} sliderBufferSize
     * @member {number} sliderBufferSize.landscape The Buffer Size for the Slider Component in Landscape Mode
     * @member {number} sliderBufferSize.portrait The Buffer Size for the Slider Component in Portrait Mode
     * @protected
     */
    this.sliderBufferSize = {'landscape': 0, 'portrait': 0};

    /**
     * Current Size of Scrubber Buffer
     *   - The buffer keeps only a limited number of pages in view,
     *     so that a Carousel of 100 pages or more does not overload
     *     the memory limits of the device viewing the Flipbook.
     *   - The Scrubber loads smaller images and therefore is generally
     *     a larger buffer
     *
     * @member {Object} scrubberBufferSize
     * @member {number} scrubberBufferSize.landscape The Buffer Size for the Scrubber Component in Landscape Mode
     * @member {number} scrubberBufferSize.portrait The Buffer Size for the Scrubber Component in Portrait Mode
     * @protected
     */
    this.scrubberBufferSize = {'landscape': 0, 'portrait': 0};

    /**
     * Number of Sheets for the Entire Flipbook
     *   - When the Flipbook is in Landscape Mode it is capable of displaying
     *     2 pages on 1 sheet, however Portrait Mode always displays only 1 page per sheet.
     *
     * @member {Object} totalSheets
     * @member {number} totalSheets.landscape The Number of Required Sheets in Landscape Mode
     * @member {number} totalSheets.portrait The Number of Required Sheets in Portrait Mode
     * @protected
     */
    this.totalSheets = {'landscape': 0, 'portrait': 0};

    /**
     * Array of All Sheets Objects in the Carousel
     *
     * @member {Array} sheets
     * @see Flipbook.Sheet
     * @protected
     */
    this.sheets = [];

    /**
     * Array of Page Indexes for Sheets that have 2 Pages
     *   - Example for an 8 Page Flipbook in Landscap Mode with the
     *     First Page on Right Side and the Last Page on Left Side:
     *     [[0], [1,2], [3,4], [5,6], [7]]
     *
     * @member {Array} doublePageSheets
     * @protected
     */
    this.doublePageSheets = [];

    /**
     * Array of Sheet Positions within the Carousel
     *   - Sheet Positions are calculated during initialization
     *   - Used for animating sheets into Absolute Position (or Translation)
     *
     * @member {Array} sheetPositions
     * @protected
     */
    this.sheetPositions = [];

    /**
     * Current Page State Data
     *   - The Page does not always take up the full space on the device display
     *     so we keep track of the adjustments made to the page size and bounds
     *
     * @member {Object} page
     * @member {Object} page.size The Varied Sizes of the Page Image in Pixels
     * @member {Object} page.size.original The Original Size of the Page Image in Pixels
     * @member {number} page.size.original.x The Original Size (Width) of the Page Image in Pixels
     * @member {number} page.size.original.y The Original Size (Height) of the Page Image in Pixels
     * @member {Object} page.size.current The Current Size of the Page Image in Pixels
     * @member {number} page.size.current.x The Current Size (Width) of the Page Image in Pixels
     * @member {number} page.size.current.y The Current Size (Height) of the Page Image in Pixels
     * @member {Object} page.offset The Position Offset of the Page Image in Pixels
     * @member {number} page.offset.x The X-Coordinate Position Offset of the Page Image in Pixels
     * @member {number} page.offset.y The Y-Coordinate Position Offset of the Page Image in Pixels
     * @member {Object} page.bounds The Bounding Box of the Page Image in Pixels
     * @member {number} page.bounds.x1 The X-Coordinate for the Left Edge of the Bounding Box of the Page Image in Pixels
     * @member {number} page.bounds.y1 The Y-Coordinate for the Top Edge of the Bounding Box of the Page Image in Pixels
     * @member {number} page.bounds.x2 The X-Coordinate for the Right Edge of the Bounding Box of the Page Image in Pixels
     * @member {number} page.bounds.y2 The Y-Coordinate for the Bottom Edge of the Bounding Box of the Page Image in Pixels
     * @protected
     */
    this.page = {
        'size'  : {
            'original' : {'x': 0, 'y': 0},
            'current'  : {'x': 0, 'y': 0}   // Zoom Size
        },
        'offset': {'x': 0, 'y': 0},
        'bounds': {'x1': 0, 'y1': 0, 'x2': 0, 'y2': 0},  // Zoomed Bounds
        'area'  : 0
    };

    /**
     * Data for the Current State of the Carousel
     *
     * @member {Object} current
     * @member {boolean} current.twopage Whether the Current Sheet is displaying Two Pages or not
     * @member {number} current.sheet The Index of the Current Sheet
     * @member {number} current.maxscroll The Maximum Scrolling Distance for the Scroller DIV based on the width of all Sheets
     * @protected
     */
    this.current = {
        'twopage'   : false,
        'sheet'     : 0,
        'maxscroll' : 0     // Slider Max-Scroll
    };


    /* **************************************************************************************** */
    /* * Private Methods/Members                                                              * */
    /* **************************************************************************************** */
    /**
     * Initialize the Carousel
     *
     * @private
     * @this Flipbook.Carousel
     * @return {Object} A reference to the Carousel Object for Method Chaining
     * @constructs
     */
    initialize = $.proxy(function() {
        var startSheet = {};

        // Debug Message
        Flipbook.log('carousel-init');

        // Get the Total Number of Sheets for the Flipbook
        this.totalSheets = Flipbook.getTotalSheets(this.app);

        // Update Buffer Sizes for both Slider and Scrubber
        this.sliderBufferSize[Flipbook.ORIENT_PORTRAIT] = (this.totalSheets[Flipbook.ORIENT_PORTRAIT] > this.app.config.sheet.maxBufferSize.slider) ? this.app.config.sheet.maxBufferSize.slider : this.totalSheets[Flipbook.ORIENT_PORTRAIT];
        this.sliderBufferSize[Flipbook.ORIENT_LANDSCAPE] = (this.totalSheets[Flipbook.ORIENT_LANDSCAPE] > this.app.config.sheet.maxBufferSize.slider) ? this.app.config.sheet.maxBufferSize.slider : this.totalSheets[Flipbook.ORIENT_LANDSCAPE];
        this.scrubberBufferSize[Flipbook.ORIENT_PORTRAIT] = (this.totalSheets[Flipbook.ORIENT_PORTRAIT] > this.app.config.sheet.maxBufferSize.scrubber) ? this.app.config.sheet.maxBufferSize.scrubber : this.totalSheets[Flipbook.ORIENT_PORTRAIT];
        this.scrubberBufferSize[Flipbook.ORIENT_LANDSCAPE] = (this.totalSheets[Flipbook.ORIENT_LANDSCAPE] > this.app.config.sheet.maxBufferSize.scrubber) ? this.app.config.sheet.maxBufferSize.scrubber : this.totalSheets[Flipbook.ORIENT_LANDSCAPE];

        // Page Indices for Sheets with Double Pages
        this.doublePageSheets = this.totalSheets.doublePages;

        // Get the Starting Sheet to Display
        startSheet = Flipbook.getStartSheet(this.app, this.totalSheets);
        this.current.sheet = startSheet.sheet;
        this.current.twopage = startSheet.twopage;

        // Build the Carousel Elements
        buildElements();

        // Attach Events to Carousel
        attachEvents();

        return this;
    }, this);

    /**
     * Build the Carousel Elements
     *
     * @private
     * @this Flipbook.Carousel
     * @return undefined
     */
    buildElements = $.proxy(function() {
        var i, n = (this.totalSheets[Flipbook.ORIENT_PORTRAIT] > this.totalSheets[Flipbook.ORIENT_LANDSCAPE]) ? this.totalSheets[Flipbook.ORIENT_PORTRAIT] : this.totalSheets[Flipbook.ORIENT_LANDSCAPE];

        // Debug Message
        Flipbook.log('carousel-build-elements');

        // Profiler
        Shared.Profiler.start('Flipbook:Carousel->buildElements');

        // Build Carousel-Inner Element
        this.$scrollDiv = $('<div class="carousel-scrolldiv"/>').appendTo(this.$container);

        // Build Carousel Sheets
        //  we build all sheets, and only use the ones required for current Orientation;
        //  Landscape in Double-Page mode uses less sheets (2 pages per sheet) than Portrait mode.
        for (i = 0; i < n; i++) {
            this.sheets.push(new Flipbook.Sheet(this, this.$scrollDiv, {'arrayIndex': i}));
        }

        // Profiler
        Shared.Profiler.end('Flipbook:Carousel->buildElements');
    }, this);

    /**
     * Attach Events to the Carousel Elements
     *   Events are Handled according to View Mode and passed to associated Class Methods;
     *     (Slider Mode = sliderHandleDrag)
     *     (Scrubber Mode = scrubberHandleDrag)
     *     (Zoomer Mode = zoomerHandleDrag)
     *   Pinch Events are always handled by the Zoomer Class
     *
     * @private
     * @this Flipbook.Carousel
     * @return undefined
     */
    attachEvents = $.proxy(function() {
        var ns = '.Flipbook.Carousel';
        var hammerOptions = {'drag_block_vertical': true, 'drag_min_distance': 3};

        // Debug Message
        Flipbook.log('carousel-attach-events');

        // Hook Drag/Pinch Events
        if (Flipbook.hasTouch(this.app)) {
            this.$container.hammer(hammerOptions).on('pinch'+ns+' release'+ns,
                $.proxy(function(e) { this.zoomerHandlePinch(e); }, this) // Zoom In/Out
            );
        }

        // Hook Drag Events
        Flipbook.onDrag(this.app, this.$container, function(e) { this[this.viewMode + 'HandleDrag'](e); }, this, 'Carousel', hammerOptions);

        // Hook Click/Tap Events
        Flipbook.onClickTap(this.app, this.$container, function(e) { this[this.viewMode + 'HandleTap'](e); }, this, 'Carousel', true);

        // Hook Key based Events
        $(document).on('keydown'+ns, $.proxy(function(e) { this[this.viewMode + 'KeyPress'](e); }, this)); // Slide/Pan via Keyboard

        // Prevent Gesture Events interfering with Touch Events in iOS7
        if (Shared.IOS7) {
            this.$container.on('gesturestart gesturechange', function(e) { e.preventDefault(); return false; });
        }

    }, this);


    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize();
};
// End of Flipbook.Carousel


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Resize/Reorient the Carousel Elements
 *   Renders the Elements on Screen
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Carousel.prototype.resize = function() {
    // Debug Message
    Flipbook.log('carousel-resize');

    // Update State Flags
    this.app.state.animating = true;    // Prevent Flipping Pages while Resizing
    this.slide.moved = false;          // Prevent Profiler-Output when Resizing

    // Reset Zoom
    this.resetZoom();

    // Update Size of the Carousel Container
    this.updateContainerSize();

    // Determine Ideal Image Sizes
    this.determineImageSize();

    // Determine Current Sheet
    this.determineCurrentSheet();

    // Determine Sheet Positions
    this.determineSheetPositions();

    // Set Size/Position of Sheets based on Current Sheet
    this.resizeSheets().repositionSheets(true);

    // Set Size/Position of ScrollDiv
    this.resizeScrollDiv().repositionScrollDiv();

    // Load Initial Sheets
    this.sliderInitBuffer();

    // Get Current Page Size/Offset/Bounds
    this.updatePageMetrics(1, true);

    // Restore Page State
    this.app.state.animating = false;
    return this;
};

/**
 * Updates the Size of the Carousel Container Element
 *   Accounts for the Width of the Toolbar if Present
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Carousel.prototype.updateContainerSize = function() {
    var toolbarWidth = 0;
    var toolbarHeight = 0;
    // fullscreen flipbooks on tablet/mobile don't have the tool bar 
    var toolbarBaseSize = this.app.config.toolbar.size[this.app.viewport.breakpoint][
        this.app.config.toolbar.position
    ].closed;
    var isDesktop = this.app.viewport.breakpoint === 'desktop';
    var hasToolbarIcon = this.app.config.titleData.has_logo || this.app.config.titleData.favicon;
    var showToolbar = !Flipbook.isFullscreen(this.app) || isDesktop;
    
    if (this.app.config.toolbar.enabled && showToolbar) {
        if (this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_TOP) {
            if (!isDesktop && hasToolbarIcon) {
                // on smaller screens we stack the toolbar into 2 rows
                // Add 8px to add allow for some margin between the rows. 
                toolbarHeight = toolbarBaseSize * 2 + 8;
            } else {
                toolbarHeight = toolbarBaseSize;
            }
        } else {
            toolbarWidth = toolbarBaseSize;
        }
    }

    // Debug Message
    Flipbook.log('carousel-container-size');

    // Update Size of the Carousel Container
    // If were in full screen mode, we dont want to calculate the toolbar into the container sizes
    this.containerSize.width = showToolbar ? this.app.viewport.width - toolbarWidth : this.app.viewport.width;
    this.containerSize.height = showToolbar ? this.app.viewport.height - toolbarHeight : this.app.viewport.height;
    this.$container.css(this.containerSize).css({ top: toolbarHeight, left: toolbarWidth });
    return this;
};

/**
 * Determines the Sheet to Start Buffering from, based on Current Sheet and Buffer Size
 *
 * @public
 * @this Flipbook.Carousel
 * @param {string} [viewMode] The View Mode to get the Starting Sheet for (affects Buffer Size).  Optional; Defaults to the Current View Mode.
 * @return {number} The Starting Index for Buffered Sheets from Current Sheet
 */
Flipbook.Carousel.prototype.getSheetStartIndex = function(viewMode) {
    var totalSheets = this.totalSheets[this.app.viewport.orientation];
    var bufferSize = 0;
    var midIndex = 0;
    var startIndex = this.current.sheet;

    if (viewMode === undefined) {
        viewMode = this.viewMode;
    }
    bufferSize = this[viewMode + 'BufferSize'][this.app.viewport.orientation];
    midIndex = (bufferSize % 2) ? ((bufferSize - 1) / 2) : (bufferSize / 2);

    // Adjust Starting Index by Mid Index
    startIndex -= midIndex;

    // Check Bounds
    if (startIndex + bufferSize > totalSheets) {
        startIndex -= ((startIndex + bufferSize) - totalSheets);
    }
    if (startIndex < 0) { startIndex = 0; }

    return startIndex;
};

/**
 * Load a Specific Carousel Sheet with Content
 *   Sheet may contain 1 Page or 2
 *
 * @public
 * @this Flipbook.Carousel
 * @param {number} sheetIndex The Index of the Sheet to Load
 * @param {boolean} sheetFocused True if the Sheet is currently in view
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Carousel.prototype.loadSheet = function(sheetIndex, sheetFocused) {
    var pagesToLoad = [sheetIndex];

    // Debug Message
    //Flipbook.log('carousel-load-sheet');

    // Landscape and Two-Page Spread Mode
    if (this.current.twopage) {
        pagesToLoad = this.doublePageSheets[sheetIndex].slice();
    }

    // Load Pages into Sheet
    this.sheets[sheetIndex].load(pagesToLoad, sheetFocused);
    return this;
};

/**
 * Unload a Specific Carousel Sheet of Content
 *   Sheet may contain 1 Page or 2
 *
 * @public
 * @this Flipbook.Carousel
 * @param {number} sheetIndex The Index of the Sheet to Unload
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Carousel.prototype.unloadSheet = function(sheetIndex) {
    // Debug Message
    //Flipbook.log('carousel-unload-sheet');
    this.sheets[sheetIndex].clearSheet();
    return this;
};

/**
 * Resizes the Carousel ScrollDiv based on the Total Sheets of the Flipbook
 *   Also determines the Max Scroll of the Slider/Scrubber
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Carousel.prototype.resizeScrollDiv = function() {
    var totalSheets = this.totalSheets[this.app.viewport.orientation];

    // Debug Message
    Flipbook.log('carousel-resize-scrolldiv');

    // Resize ScrollDiv Element (MAX WIDTH: 134,217,726px == 104857 Sheets at 1280x1024 Resolution)
    this.$scrollDiv.width(totalSheets * this.containerSize.width).height(this.containerSize.height);

    // Determine Max Bounds of ScrollDiv
    this.current.maxscroll = -(totalSheets * this.containerSize.width) + this.containerSize.width;

    // Determine Snap Threshold
    this.slide.snap = Math.round(this.containerSize.width * this.app.config.carousel.snapThresholdPct);
    return this;
};

/**
 * Resizes all Sheets in the Carousel
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Carousel.prototype.resizeSheets = function() {
    var totalSheets = this.sheets.length;

    // Debug Message
    Flipbook.log('carousel-resize-sheets');

    // Resize Carousel Sheets
    for (var i = 0; i < totalSheets; i++) {
        this.sheets[i].resize();
    }
    return this;
};

/**
 * Repositions the Carousel ScrollDiv on the Active Sheet
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A Promise
 */
Flipbook.Carousel.prototype.repositionScrollDiv = function() {
    // Debug Message
    Flipbook.log('carousel-reposition-scrolldiv');

    // Update Position of ScrollDiv
    return this.moveToSheet(); // move to current sheet
};

/**
 * Repositions all Sheets in the correct order, according to current buffering
 *
 * @public
 * @this Flipbook.Carousel
 * @param {boolean} [afterResize] Whether or not the Repositioning is happening after Resize or not; If so, positions first need to be recalculated.  Optional; Defaults to false.
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Carousel.prototype.repositionSheets = function(afterResize) {
    var totalSheets = this.sheets.length;

    // Debug Message
    Flipbook.log('carousel-reposition-sheets');

    // Reposition Carousel Sheets
    for (var i = 0; i < totalSheets; i++) {
        this.sheets[i].reposition(afterResize);
    }
    return this;
};

/**
 * Directly Sets the Absolute Position of the Carousel ScrollDiv
 *   No Animation used here.
 *   Used primarily for direct dragging of Slider/Scrubber.
 *
 * @public
 * @this Flipbook.Carousel
 * @param {number} position The x-position of the carousel
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Carousel.prototype.setPosition = function(position) {
    var animOpts = {};
    animOpts[Flipbook.TRANSITION_MODIFIER] = position;
    this.$scrollDiv.transition(animOpts, 0);
    return this;
};

/**
 * Updates the URL bar with the Current Page (or first page of a two-page spread)
 *   Requires History API to be supported by browser.
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Carousel.prototype.updateUrlBar = function() {
    var type = Flipbook.getUrlType(this.app);
    var path = this.app.config.urls.server + type + '/' + this.app.config.issueData.id + this.app.config.issueData.issueSlug + '/';
    var title = this.app.config.titleData.title + ' - ' + this.app.config.issueData.name;
    var page = this.current.sheet;

    // No URL Bar in Native WebView
    if (this.app.config.device.isNative || this.app.config.device.isOffline) { return; }

    // Debug Message
    Flipbook.log('carousel-update-url');

    // Get first page of a two-page spread
    if (this.current.twopage) {
        page = this.doublePageSheets[this.current.sheet][0];
    }

    // Update History API
    if(typeof(window.history.pushState) === 'function') {
        window.history.replaceState(null, title, path + page + this.app.config.urlQueryString);
    }
    return this;
};

/**
 * Calculates the Absolute Position of Each Sheet in both Slider and Scrubber Modes
 *   used for Positioning Sheets in Carousel in correct positions, and for calculating
 *   which sheet is currently active.
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Carousel.prototype.determineSheetPositions = function() {
    var totalSheets = this.sheets.length;
    var sheetWidth = this.app.config.sheet.pageSize[this.app.viewport.orientation].w;

    // Debug Message
    Flipbook.log('carousel-sheet-positions');

    // Double Sheet Width for Two-Page Spreads
    if (this.current.twopage) { sheetWidth *= 2; }

    // Reset Sheet Positions
    this.sheetPositions.length = 0;

    // Populate Page Positions
    for (var i = 0; i < totalSheets; i++) {
        this.sheetPositions.push({'slider': i * this.containerSize.width, 'scrubber': (i * (sheetWidth + 10))});
    }
    return this;
};

/**
 * Determines & Stores the Current Sheet Index and Two-Page Spread Mode
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Carousel.prototype.determineCurrentSheet = function() {
    var pageFocus = this.current.sheet;

    // Debug Message
    Flipbook.log('carousel-current-sheet');

    // Determine Page being Viewed on Current Sheet
    if (this.current.twopage) {
        pageFocus = this.doublePageSheets[this.current.sheet][0];
    }

    // Landscape and Two-Page Spread Mode
    if (this.app.viewport.orientation === Flipbook.ORIENT_LANDSCAPE && !this.app.config.titleData.single_page_view) {
        for (var i = 0, n = this.doublePageSheets.length; i < n; i++) {
            if (this.doublePageSheets[i][0] === pageFocus || (this.doublePageSheets[i].length === 2 && this.doublePageSheets[i][1] === pageFocus)) {
                this.current.sheet = i;
                break;
            }
        }
        this.current.twopage = true;
    }

    // Portrait or Single Page Mode
    else {
        this.current.sheet = pageFocus;
        this.current.twopage = false;
    }

    // Store: Current Page(s)
    this.app.stats.getCurrentPages().getCurrentPageFLs();

    return this;
};

/**
 * Move to a Specific Sheet in Carousel
 *   May or may not be animated.
 *   When Complete, handles updating Flipbook States;
 *      URL Bar, Pagebar, Buffering
 *
 * @public
 * @this Flipbook.Carousel
 * @param {number} [sheetIndex] The Sheet Index to Move to.  Optional; Defaults to Current Sheet.
 * @param {number} [speed] The speed of the animation, if any.  0 = no animation.  Optional; Defaults to 0
 * @return {Object} A Promise
 */
Flipbook.Carousel.prototype.moveToSheet = function(sheetIndex, speed) {
    var deferred = Q.defer();
    var prevSheet = this.current.sheet;
    var animOpts = {};

    var moveComplete = $.proxy(function() {
        // Update URL Bar
        this.updateUrlBar();

        // Update Pagebar
        if (this.app.config.pagebar.enabled) {
            this.app.pagebar.moveToSheet(sheetIndex, (speed > 0));
        }

        // Show Navigation for a few Seconds
        this.app.navigation.toggleViewMode(true, true);

        // Check if Buffering is needed
        if (prevSheet !== this.current.sheet) {
            this[this.viewMode + (Math.abs(prevSheet - this.current.sheet) > 1 ? 'InitBuffer' : 'UpdateBuffer')]();

            // Update Page Overlays
            this.app.overlayer.sheetChanged(prevSheet);

            // Track Page Change
            if (this.viewMode !== Flipbook.CAROUSEL_SCRUBBER) {
                this.app.stats.pageChanged();
            }
        }

        this.app.state.animating = false;

        // Resolve Promise
        deferred.resolve('moveToSheet');
    }, this);

    // Debug Message
    Flipbook.log('carousel-move-to-sheet');

    // Ensure Valid Params
    if (sheetIndex === undefined) { sheetIndex = this.current.sheet; }
    if (speed === undefined) { speed = 0; }

    // Store Current Sheet
    this.current.sheet = sheetIndex;

    // Store Current Touch Point
    this.slide.position.x = this.scrub.position.x = -this.sheetPositions[sheetIndex][this.viewMode];

    // Toggle Animation State
    this.app.state.animating = true;

    // Display Pagebar
    if (this.app.config.pagebar.enabled) {
        this.app.pagebar.showTip(250, this.app.config.pagebar.hideDelay);
    }

    // Move into Position
    animOpts[Flipbook.TRANSITION_MODIFIER] = this.slide.position.x;
    this.$scrollDiv.transition(animOpts, speed, moveComplete);

    return deferred.promise;
};

/**
 * Determines Sizing for Images in All Modes of Display
 *   Based on Issue Settings for Current Flipbook.
 *   Calculates True Min/Max Scaling for Images.
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Carousel.prototype.determineImageSize = function() {
    // Declare scoped variables for future use
    var row, col;
    var gridSize;
    var imageWidth;
    var pageWidth, pageHeight, pageMargin;
    var partWidth, partHeight;
    var imgPixelLossW, imgPixelLossH;
    var portraitAspectRatio, landscapeAspectRatio;

    // Set scoped variables for better readability
    var app = this.app;
    var container = this.containerSize;
    var issueFormat = app.issueFormat;
    var config = app.config;
    var portraitMode = Flipbook.ORIENT_PORTRAIT;
    var landscapeMode = Flipbook.ORIENT_LANDSCAPE;
    var pageSize = config.sheet.pageSize;
    var pageSizeMin = pageSize.scaled.min;
    var pageSizeMax = pageSize.scaled.max;
    var toolbarHeight = config.toolbar.size[app.viewport.breakpoint][config.toolbar.position].closed;

    // Debug Message
    Flipbook.log('carousel-image-size');

    // Get the Aspect Ratio for the Page to the Viewport
    portraitAspectRatio = container.width * config.sheet.sizePercentage / config.issueData.w1;
    if ((config.issueData.h1 * portraitAspectRatio) > (container.height * config.sheet.sizePercentage)) {
        portraitAspectRatio = container.height * config.sheet.sizePercentage / config.issueData.h1;
    }

    // Get the Aspect Ratio for the Page to Half of the Viewport
    landscapeAspectRatio = (container.width / 2) * config.sheet.sizePercentage / config.issueData.w1;
    if ((config.issueData.h1 * landscapeAspectRatio) > (container.height * config.sheet.sizePercentage)) {
        landscapeAspectRatio = container.height * config.sheet.sizePercentage / config.issueData.h1;
    }

    // Calculate Ideal Size for Portrait Mode Pages
    pageWidth = Math.floor(config.issueData.w1 * portraitAspectRatio);
    pageHeight = Math.floor(config.issueData.h1 * portraitAspectRatio);
    pageMargin = Math.floor((container.height - pageHeight) / 2);

    imageWidth = Math.floor(pageWidth * Flipbook.root.devicePixelRatio);
    if (imageWidth <= 0 || imageWidth > config.issueData.w2) {
        imageWidth = '-2.jpg';
    } else {
        imageWidth = '-w-' + imageWidth + issueFormat;
    }

    // Portrait Mode:
    pageSize[portraitMode].w = pageWidth;
    pageSize[portraitMode].h = pageHeight;
    pageSize[portraitMode].m = pageMargin;
    pageSize[portraitMode].i = imageWidth;

    // Landscape Mode:
    // Single Page Mode
    if (config.titleData.single_page_view) {
        // If we are in full screen mode and Single Page Mode, lets add some spacing between the Flipbook and the toolbar
        if (Flipbook.isFullscreen(app) && app.viewport.breakpoint === 'desktop') {
            pageMargin = pageMargin + (toolbarHeight / 2);
        }

        pageSize[landscapeMode].w = pageWidth;
        pageSize[landscapeMode].h = pageHeight;
        pageSize[landscapeMode].m = pageMargin;
        pageSize[landscapeMode].i = imageWidth;
    }

    // Double Page Mode
    else {
        // Calculate Ideal Width for Landscape Mode Pages
        pageWidth = Math.floor(config.issueData.w1 * landscapeAspectRatio);
        pageHeight = Math.floor(config.issueData.h1 * landscapeAspectRatio);
        pageMargin = Math.floor((container.height - pageHeight) / 2);
        imageWidth = Math.floor(pageWidth * Flipbook.root.devicePixelRatio);
        if (imageWidth <= 0 || imageWidth > config.issueData.w2) {
            imageWidth = '-2.jpg';
        } else {
            imageWidth = '-w-' + imageWidth + issueFormat;
        }

        // If we are in full screen mode and Double Page Mode,
        // lets add some spacing between the Flipbook and the toolbar on desktop only
        if (Flipbook.isFullscreen(app) && app.viewport.breakpoint === 'desktop') {
            pageMargin = pageMargin + (toolbarHeight / 2);
        }

        pageSize[landscapeMode].w = pageWidth;
        pageSize[landscapeMode].h = pageHeight;
        pageSize[landscapeMode].m = pageMargin;
        pageSize[landscapeMode].i = imageWidth;
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Calculate Min Scaled Sizes

    // Portrait Mode:
    pageWidth = Math.floor(pageSize[portraitMode].w * config.sheet.minScale[portraitMode]);
    pageHeight = Math.floor(pageSize[portraitMode].h * config.sheet.minScale[portraitMode]);
    imageWidth = Math.floor(pageWidth * Flipbook.root.devicePixelRatio);
    if (imageWidth <= 0 || imageWidth > config.issueData.w2) {
        imageWidth = '-2.jpg';
    } else {
        imageWidth = '-w-' + imageWidth + issueFormat;
    }
    pageSizeMin[portraitMode].w = pageWidth;
    pageSizeMin[portraitMode].h = pageHeight;
    pageSizeMin[portraitMode].i = imageWidth;

    // Landscape Mode:
    // Single Page Mode
    if (config.titleData.single_page_view) {
        pageSizeMin[landscapeMode].w = pageWidth;
        pageSizeMin[landscapeMode].h = pageHeight;
        pageSizeMin[landscapeMode].i = imageWidth;
    }

    // Double Page Mode
    else {
        // Calculate Ideal Width for Landscape Mode Pages
        pageWidth = Math.floor(pageSize[landscapeMode].w * config.sheet.minScale[landscapeMode]);
        pageHeight = Math.floor(pageSize[landscapeMode].h * config.sheet.minScale[landscapeMode]);
        imageWidth = Math.floor(pageWidth * Flipbook.root.devicePixelRatio);
        if (imageWidth <= 0 || imageWidth > config.issueData.w2) {
            imageWidth = '-2.jpg';
        } else {
            imageWidth = '-w-' + imageWidth + issueFormat;
        }

        pageSizeMin[landscapeMode].w = pageWidth;
        pageSizeMin[landscapeMode].h = pageHeight;
        pageSizeMin[landscapeMode].i = imageWidth;
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Calculate Max Scaled Sizes
    pageWidth = Math.floor(config.issueData.w1 * portraitAspectRatio);
    config.sheet.maxScale[portraitMode] = config.issueData.w2 / pageWidth;

    // Portrait Mode:
    pageWidth = Math.floor(pageSize[portraitMode].w * config.sheet.maxScale[portraitMode]);
    pageHeight = Math.floor(pageSize[portraitMode].h * config.sheet.maxScale[portraitMode]);
    imageWidth = Math.floor(pageWidth * Flipbook.root.devicePixelRatio);
    if (imageWidth <= 0 || imageWidth > config.issueData.w2) {
        imageWidth = '-2.jpg';
    } else {
        imageWidth = '-w-' + imageWidth + issueFormat;
    }
    pageSizeMax[portraitMode].w = pageWidth;
    pageSizeMax[portraitMode].h = pageHeight;
    pageSizeMax[portraitMode].i = imageWidth;

    // Landscape Mode:
    // Single Page Mode
    if (config.titleData.single_page_view) {
        pageSizeMax[landscapeMode].w = pageWidth;
        pageSizeMax[landscapeMode].h = pageHeight;
        pageSizeMax[landscapeMode].i = imageWidth;
    }

    // Double Page Mode
    else {
        // Calculate Max Scaled Sizes
        pageWidth = Math.floor(config.issueData.w1 * landscapeAspectRatio);
        config.sheet.maxScale[landscapeMode] = config.issueData.w2 / pageWidth;

        // Calculate Ideal Width for Landscape Mode Pages
        pageWidth = Math.floor(pageSize[landscapeMode].w * config.sheet.maxScale[landscapeMode]);
        pageHeight = Math.floor(pageSize[landscapeMode].h * config.sheet.maxScale[landscapeMode]);
        imageWidth = Math.floor(pageWidth * Flipbook.root.devicePixelRatio);
        if (imageWidth <= 0 || imageWidth > config.issueData.w2) {
            imageWidth = '-2.jpg';
        } else {
            imageWidth = '-w-' + imageWidth + issueFormat;
        }

        pageSizeMax[landscapeMode].w = pageWidth;
        pageSizeMax[landscapeMode].h = pageHeight;
        pageSizeMax[landscapeMode].i = imageWidth;
    }


    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Calculate Max Scaled Image-Part Sizes
    gridSize = config.sheet.gridSize;

    // Floor the Width/Height so that we do not have Sub-Pixels
    //   ex:  w2=1801, gridSize=4, partWidth=450.25, floor'd=450, sub-pixel-loss=0.25
    partWidth = Math.floor(config.issueData.w2 / gridSize);
    partHeight = Math.floor(config.issueData.h2 / gridSize);

    // Determine the Total Amount of Sub-Pixels lost across all Image Parts
    //   ex: sub-pixel-loss=0.25, gridSize=4, imgPixelLossW= 0.25 x 4 = 1px
    imgPixelLossW = ((config.issueData.w2 / gridSize) - partWidth) * gridSize;
    imgPixelLossH = ((config.issueData.h2 / gridSize) - partHeight) * gridSize;

    // If Image Part is in Last Column or Row, add Pixel-Loss to Width or Height
    //   ex: part1=450, part2=450, part3=450, part4=450+1=451, total=1801
    pageSizeMax.parts.length = 0;
    for (row = 0; row < gridSize; row++) {
        for (col = 0; col < gridSize; col++) {
            pageSizeMax.parts.push({
                'x': col * partWidth,
                'y': row * partHeight,
                'w': partWidth + (col === (gridSize-1) ? imgPixelLossW : 0),
                'h': partHeight + (row === (gridSize-1) ? imgPixelLossH : 0),
                'm': '-m-' + (gridSize * gridSize) + '-' + ((row * gridSize) + col + 1) + issueFormat,
                'loaded': false
            });
        }
    }

    return this;
};

/**
 * Calculates the Current Page Metrics based on View Mode and Scaling
 *   Determines Page-Box Bounds for Zoomed Sheets
 *
 * @public
 * @this Flipbook.Carousel
 * @param {number} [scale] The scale used to calculate page metrics.  Optional; Defaults to Current Scale.
 * @param {boolean} [afterResize] Whether or not the Repositioning is happening after Resize; If so, positions first need to be recalculated.  Optional; Defaults to false.
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Carousel.prototype.updatePageMetrics = function(scale, afterResize) {
    var margin = 0;
    var axis = this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_LEFT ? 'x' : 'y';

    //if (this.app.state.animating || this.app.state.shifted) { return this; }
    if (scale === undefined || scale < 1) { scale = 1; }

    // Debug Message
    Flipbook.log('carousel-page-metrics');

    // Original Size of Page Box
    this.page.size.original = {
        'x': this.app.config.sheet.pageSize[this.app.viewport.orientation].w,
        'y': this.app.config.sheet.pageSize[this.app.viewport.orientation].h
    };
    this.page.area = this.page.size.original.x * this.page.size.original.y;

    // Offset of Page Box
    if (afterResize) {
        this.page.offset = {
            'x': this.sheets[this.current.sheet].$pageBox.offset().left,
            'y': this.sheets[this.current.sheet].$pageBox.offset().top
        };
        if (this.app.config.toolbar.enabled && !Flipbook.isFullscreen(this.app)) {
            this.page.offset[axis] -= this.app.config.toolbar.size[this.app.viewport.breakpoint][this.app.config.toolbar.position].closed / (axis === 'x' ? 2 : 1);
        }
    }

    // Current Page Box Size
    this.page.size.current = $.extend({}, this.page.size.original);
    if (this.current.twopage) {
        this.page.size.current.x *= this.doublePageSheets[this.current.sheet].length;
    }
    this.page.size.current.x *= scale;
    this.page.size.current.y *= scale;

    // Get Boundaries of Page Box
    this.page.bounds.x1 = -(this.page.offset.x / scale);
    this.page.bounds.y1 = -(this.page.offset.y / scale);
    this.page.bounds.x2 = -((this.page.size.current.x + this.page.offset.x - this.app.viewport.width) / scale);
    this.page.bounds.y2 = -((this.page.size.current.y + this.page.offset.y - this.app.viewport.height) / scale) +1; // <--  Why does +1 work ???  is bottom offset diff from top offset??

    // Adjustments for First Page on Right
    if (this.current.sheet === 0 && Flipbook.isFirstPageOnRight(this.app)) {
        this.page.bounds.x1 -= (this.page.size.current.x / scale);
        this.page.bounds.x2 -= (this.page.size.current.x / scale);
    }

    // Adjustments for X-Axis when Zoomed Page Box has Smaller Width than Viewport
    margin = 0;
    if (this.page.size.current.x < this.containerSize.width) {
        margin = (this.containerSize.width - this.page.size.current.x) / scale;
        this.page.bounds.x1 += margin;
        this.page.bounds.x2 -= margin;
    }

    // Adjustments for Y-Axis when Zoomed Page Box has Smaller Height than Viewport
    margin = 0;
    if (this.page.size.current.y < this.containerSize.height) {
        margin = (this.containerSize.height - this.page.size.current.y) / scale;
        this.page.bounds.y1 += margin;
        this.page.bounds.y2 -= margin;
    }

    return this;
};

/**
 * Shows the Toolbar and makes room for it in the Container by updating the Carousel Size
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Carousel.prototype.showToolbar = function() {
    if (!this.app.config.toolbar.enabled) { return this; }

    // Update Size of Current Sheet
    this.sheets[this.current.sheet].toggleFullscreen(false, this.containerSize.width, this.containerSize.height);

    // Dont show Toolbar if we are in Fullscreen Mode
    if (this.app.viewport.fullscreen) { return this; }

    // Show Toolbar Control
    this.app.toolbar.show(this.app.config.toolbar.speed);

    // Update Size of the Carousel Container
    if (this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_TOP) {
        this.$container.css({'top': this.app.config.toolbar.size[this.app.viewport.breakpoint][this.app.config.toolbar.position].closed, 'height': this.containerSize.height});
    } else {
        this.$container.css({'left': this.app.config.toolbar.size[this.app.viewport.breakpoint][this.app.config.toolbar.position].closed, 'width': this.containerSize.width});
    }
    return this;
};

/**
 * Hides the Toolbar and allows more room for the Carousel in the Container by updating the Carousel Size
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Carousel.prototype.hideToolbar = function() {
    if (!this.app.config.toolbar.enabled) { return; }

    // Hide Toolbar Control
    this.app.toolbar.hide();

    // Update Size of the Carousel Container
    this.$container.css({'top': 0, 'left': 0, 'width': this.app.viewport.width, 'height': this.app.viewport.height});

    // Update Size of Current Sheet
    this.sheets[this.current.sheet].toggleFullscreen(true);
    return this;
};

/**
 * Updates the Quality of the Zoomed Page Images on the Sheet
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Carousel.prototype.updateZoomedImage = function() {
    if (this.zoom.scale <= 1) { return this; }
    if (this.app.showExplodedImages) {
        this.sheets[this.current.sheet].updateLargeImage();
    }
    return this;
};

/**
 * Calculates the Sheet Translation Amount based on the Scale of the Sheet
 *   Accounts for Previous Sheet Scale/Translation and Current Panning.
 *   CSS Transform Origin is always Top/Left (0, 0), as it does not account for Sub-Pixel Rendering.
 *
 * @public
 * @this Flipbook.Carousel
 * @param {Object} startEvent The X/Y Coordinates of the Starting Event (dragstart).
 * @param {Object} [currentEvent] The X/Y Coordinates of the Current Event for Panning (drag).  Optional.
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Carousel.prototype.calculateSheetTranslation = function(startEvent, currentEvent) {
    var newSize = {'x': 0, 'y': 0};
    var pageBox = {'x': this.page.size.original.x, 'y': this.page.size.original.y};

    // Debug Message
    Flipbook.log('carousel-sheet-translation');

    // Landscape and Two-Page Spread Mode
    if (this.current.twopage) { pageBox.x *= this.doublePageSheets[this.current.sheet].length; }

    // Calculate new size of Page
    newSize.x = pageBox.x * this.zoom.scale;
    newSize.y = pageBox.y * this.zoom.scale;

    // Calculate Translation of Page
    this.zoom.translate.x = this.zoom.translate.y = 0;
    if (this.zoom.scale > 1) {
        this.zoom.translate.x = -((startEvent.x * (newSize.x - this.page.size.current.x)) / newSize.x);
        this.zoom.translate.y = -((startEvent.y * (newSize.y - this.page.size.current.y)) / newSize.y);
    }

    // Account for Previous Translation
    if (this.zoom.last.scale > 1) {
        this.zoom.translate.x += this.zoom.last.translate.x;
        this.zoom.translate.y += this.zoom.last.translate.y;
    }

    // Allow Panning while Zooming:
    if (currentEvent !== undefined) {
        // Account for Page Offset
        currentEvent.pageX -= this.page.offset.x;
        currentEvent.pageY -= this.page.offset.y;

        // Account for Previous Scaling
        currentEvent.pageX /= this.zoom.last.scale;
        currentEvent.pageY /= this.zoom.last.scale;

        // Calculate Translation of Page
        if (this.zoom.scale > 1) {
            this.zoom.translate.x += (currentEvent.pageX - startEvent.x) / (this.zoom.scale / this.zoom.last.scale);
            this.zoom.translate.y += (currentEvent.pageY - startEvent.y) / (this.zoom.scale / this.zoom.last.scale);
        }
    }

    return this;
};

/**
 * Automatically Zooms to a Point
 *   Useful to allow Zooming via Tap Events
 *
 * @public
 * @this Flipbook.Carousel
 * @param {Object} centerPoint The X/Y Coordinates of the Event Center-Point.
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Carousel.prototype.autoZoom = function(centerPoint) {
    var speed = 300;
    var mode = Flipbook.CAROUSEL_ZOOMER;

    // Debug Message
    Flipbook.log('carousel-auto-zoom');

    // Stats Point
    if (centerPoint.stats === undefined) {
        centerPoint.stats = {'x': centerPoint.pageX, 'y': centerPoint.pageY};
    }

    // Get Center Coordinate of Pinch
    this.zoom.pinch.start.x = this.zoom.pan.point.x = centerPoint.pageX;
    this.zoom.pinch.start.y = this.zoom.pan.point.y = centerPoint.pageY;

    // Account for Page Offset
    this.zoom.pinch.start.x -= (this.page.offset.x / this.zoom.scale);
    this.zoom.pinch.start.y -= (this.page.offset.y / this.zoom.scale);

    // Get the Translation Amount
    this.calculateSheetTranslation(this.zoom.pinch.start);

    // Reset Mode
    if (this.zoom.scale === 1) { mode = Flipbook.CAROUSEL_SLIDER; }
    this.resetPinch({'reset': true});

    // Ensure page is within Boundaries
    if (this.zoom.scale > 1) {
        if (this.zoom.translate.x > this.page.bounds.x1) { this.zoom.translate.x = this.page.bounds.x1; }
        if (this.zoom.translate.x < this.page.bounds.x2) { this.zoom.translate.x = this.page.bounds.x2; }
        if (this.zoom.translate.y > this.page.bounds.y1) { this.zoom.translate.y = this.page.bounds.y1; }
        if (this.zoom.translate.y < this.page.bounds.y2) { this.zoom.translate.y = this.page.bounds.y2; }

        // Update Scale of Large Image
        if (this.zoom.scale !== this.zoom.last.scale) {
            this.sheets[this.current.sheet].clearLargeImage().displayLargeImage();
        }

        // Track Flipbook Zoom Event
        this.app.stats.pageZoomed(centerPoint.stats);
    } else {
        this.zoom.translate.x = 0;
        this.zoom.translate.y = 0;
    }
    this.resetPinch({'mode': mode, 'scale': this.zoom.scale}).then($.proxy(function() {
        // Animate into Place
        this.app.state.animating = true;
        this.app.overlayer.$carousel.pagebox.transition({'scale': this.zoom.scale, 'translate': [this.zoom.translate.x, this.zoom.translate.y]});
        this.sheets[this.current.sheet].$pageBox.transition({'scale': this.zoom.scale, 'translate': [this.zoom.translate.x, this.zoom.translate.y]}, speed, $.proxy(function() {
            this.app.state.animating = false;

            // Update Zoomed Image
            this.updateZoomedImage();
        }, this));

    }, this));

};

/**
 * Toggles the View Mode between Slider and Scrubber Modes.
 *   Sometimes the Gap between sheets is rather large,
 *   and we want to squeeze the sheets closer together in Scrubber Mode.
 *   We also always show the Pagebar in Scrubber Mode.
 *
 * @public
 * @this Flipbook.Carousel
 * @param {string} oldMode The Old View Mode that we Toggled From
 * @return {Object} A Promise
 */
Flipbook.Carousel.prototype.toggleViewMode = function(oldMode) {
    var i, gap = 0;
    var speed = 250;
    var reposition = false;
    var startIdx = 0;
    var bufferSize = 0;
    var deferred = Q.defer();
    var totalSheets = this.totalSheets[this.app.viewport.orientation];
    var sheetWidth = this.app.config.sheet.pageSize[this.app.viewport.orientation].w;

    // Debug Message
    Flipbook.log('carousel-toggle-view');

    if (this.current.twopage) { sheetWidth *= 2; }
    gap = this.containerSize.width - sheetWidth - 10;

    // Update View-Mode for Overlays
    this.app.overlayer.toggleViewMode();

    // Scrubber Mode
    if (this.viewMode === Flipbook.CAROUSEL_SCRUBBER) {
        // Determine Max Bounds of ScrollDiv
        this.current.maxscroll = -(totalSheets * (sheetWidth + 10)) + sheetWidth;

        // Get Start Index and Buffer Size
        startIdx = this.getSheetStartIndex(Flipbook.CAROUSEL_SLIDER);
        bufferSize = this.sliderBufferSize[this.app.viewport.orientation];

        // Nudge Visible Sheets
        for (i = startIdx; i < startIdx + bufferSize; i++) {
            this.sheets[i].nudge(gap * (this.current.sheet - i), speed);
        }

        // Update Style of Pagebar
        if (this.app.config.pagebar.enabled) {
            this.app.pagebar.displayPartial(false).showTip(250, 0);
        }

        // Hide Navigation Controls
        this.app.navigation.toggleViewMode();

        reposition = true;
    }

    // Slider Mode
    if (this.viewMode === Flipbook.CAROUSEL_SLIDER) {
        // Determine Max Bounds of ScrollDiv
        this.current.maxscroll = -(totalSheets * this.containerSize.width) + this.containerSize.width;

        // Show Toolbar
        this.showToolbar();

        // From Scrubber Mode
        if (oldMode === Flipbook.CAROUSEL_SCRUBBER) {
            // Get Start Index and Buffer Size
            startIdx = this.getSheetStartIndex(Flipbook.CAROUSEL_SCRUBBER);
            bufferSize = this.scrubberBufferSize[this.app.viewport.orientation];

            // Nudge Visible Sheets
            for (i = startIdx; i < startIdx + bufferSize; i++) {
                this.sheets[i].nudge(-gap * (this.current.sheet - i), speed);
            }

            reposition = true;
        }

        // From Zoomer Mode
        if (oldMode === Flipbook.CAROUSEL_ZOOMER) {
            // Clear Large Zoomed-In Image
            this.sheets[this.current.sheet].clearLargeImage();

            // Hide Non-Touch Zoom-Controls
            this.app.nontouch.hide();
        }

        // Update Style of Pagebar
        if (this.app.config.pagebar.enabled) {
            this.app.pagebar.displayPartial(true).hideTip(250);
        }

        // Display Navigation Controls for a few Seconds
        this.app.navigation.toggleViewMode(true, true);
    }

    // Zoomer Mode
    if (this.viewMode === Flipbook.CAROUSEL_ZOOMER) {
        // Display Large Zoomed-In Image
        this.sheets[this.current.sheet].displayLargeImage();

        // Hide Toolbar
        this.hideToolbar();

        // Update Style of Pagebar
        if (this.app.config.pagebar.enabled) {
            this.app.pagebar.hideTip(0);
        }

        // Display Non-Touch Zoom-Controls
        this.app.nontouch.show();

        // Hide Navigation Controls
        this.app.navigation.toggleViewMode();
    }

    // Reposition Sheets after Animation Completes
    if (reposition) {
        Q.delay(speed).then($.proxy(function() {
            // Reposition Sheets and ScrollDiv
            var promise = this.repositionSheets(true).repositionScrollDiv();

            // Buffer Images
            this[this.viewMode + 'InitBuffer']();

            // Update Page Overlays
            this.app.overlayer.update();

            // Resolve Promise
            deferred.resolve(promise);
        }, this));
    } else {
        // Resolve Promise
        deferred.resolve('toggleViewMode');
    }

    return deferred.promise;
};

/**
 * Sets the Direction of the slides
 * -1 = Forward, 1 = Backwards
 * @public
 * @this Flipbook.Carousel
 * @param {number} nextSheet The sheet index to move to
 * @param {number} currentSheet The current sheet index
 * @return void
*/
Flipbook.Carousel.prototype.setSlideDirection = function(nextSheet, currentSheet) {
    this.slide.direction.x = (nextSheet > currentSheet) ? -1 : 1;
};
