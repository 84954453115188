/**
 * Flyp Technologies Inc. - Flipbook v4
 *
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/tools/search.js - Flipbook.SearchTool
 * @author Robert J. Secord, B.Sc.
 */
import 'jquery-migrate';
import $ from 'jquery';
import Flipbook from '../core';
import Shared from '../shared_util';
import Q from '/app/libs/promise/q';

/**
 * Flipbook Search Tool
 *
 * @class SearchTool
 * @classdesc Application Search Tool
 * @namespace Flipbook
 * @inherits Flipbook.ToolsBase
 * @param {Object} toolbar A reference to the Toolbar Control
 * @param {Object} toolData Configuration Settings for the Tool
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.SearchTool = function(toolbar, toolData) {
    // Call Parent Constructor
    Flipbook.ToolsBase.apply(this, [toolbar, toolData]);
    
    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize = null;
    var attachEvents = null;


    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    this.searchUrl = '';
    this.$searchInput = null;
    this.$searchInputWrapper = null;
    this.$searchButton = null;
    

    /* **************************************************************************************** */
    /* * Private Methods/Members Definitions                                                  * */
    /* **************************************************************************************** */
    /**
     * Initialize the Application
     *
     * @private
     * @this Flipbook.SearchTool
     * @return {Object} A reference to the SearchTool Object for Method Chaining
     * @constructs
     */
    initialize = $.proxy(function() {
        // Build Search URL
        this.searchUrl = this.app.config.urls.server + 'read/flipbook4_search/' + 
            this.app.config.issueData.id + '/' +
            this.app.config.titleData.page_num_offset + '/' +
            this.app.viewport.breakpoint + '/';
        
        // Get Handle to Search Input Elements
        this.$searchInputWrapper = this.toolbar.$searchContainer.find('.input');
        this.$searchInput = this.$searchInputWrapper.find('> input');
        this.$searchButton = this.toolbar.$searchContainer.find('.icon');
        
        // Set Background Color of Search if Toolbar is at Top
        if (this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_TOP) {
            Shared.onNextEventLoop(function() {
                this.$searchInputWrapper.css({'background-color': this.app.config.titleData.flypbook_banner_color});
            }, this);
        }
        
        // Attach Events to Search Elements
        attachEvents();
        
        return this;
    }, this);
    
    
    /**
     * Attach Events to the Search Elements
     *
     * @private
     * @this Flipbook.SearchTool
     * @return undefined
     */
    attachEvents = $.proxy(function() {
        // Attach Click/Tap Event to Search Button
        Flipbook.onClickTap(this.app, this.$searchButton, this.handleButtonClick, this, 'SearchTool');

        // Attach Keypress Event to Search Input
        this.$searchInput.on('keyup.Flipbook.SearchTool', $.proxy(this.handleKeyPress, this));
        
    }, this);
    
    
    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize();
};
Flipbook.SearchTool.prototype = new Flipbook.ToolsBase();
Flipbook.SearchTool.prototype.constructor = Flipbook.SearchTool;
// End of Flipbook.SearchTool


/* ******************************************************************************************** */
/* * Overridden Base Methods                                                                  * */
/* ******************************************************************************************** */

/**
 * Override Abstract Base Method; Called when the Content is Shifted
 *
 * @public
 * @this Flipbook.SearchTool
 * @return undefined
 */
Flipbook.SearchTool.prototype.contentShifted = function() {
    if (this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_TOP) {
        // Show Search Input
        this.$searchInputWrapper.addClass(this.app.config.toolbar.activeClass);
        
        // Hide Toolbar Tools
        this.toolbar.$toolsContainer.css({'visibility': 'hidden'});
        
        // Focus on Input
        this.$searchInput[0].focus();
    }
};

/**
 * Override Abstract Base Method; Called when the Content is Restored
 *
 * @public
 * @this Flipbook.SearchTool
 * @return undefined
 */
Flipbook.SearchTool.prototype.contentRestored = function() {
    if (this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_TOP) {
        // Hide Search Input
        this.$searchInputWrapper.removeClass(this.app.config.toolbar.activeClass);
        
        // Show Toolbar Tools
        this.toolbar.$toolsContainer.css({'visibility': 'visible'});
    }
    
    // Clear Last Search
    this.$searchInput.val('');
};


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * 
 *
 * @public
 * @this Flipbook.SearchTool
 * @return undefined
 */
Flipbook.SearchTool.prototype.handleButtonClick = function(e) {
    var searchQuery = this.$searchInput.val();
    
    var onShow = $.proxy(function() {
        var inputWidth = 0;
        
        // Set Active Tool
        this.toolbar.activeTool = this;
        
        // Determine Max-Width of Search Input
        inputWidth = this.app.config.toolbar.contentPanel.width[this.app.viewport.breakpoint];
        if (inputWidth < 1) {
            inputWidth = this.app.viewport.width - this.toolbar.$searchContainer.offset().left - this.toolbar.$searchContainer.width();
            inputWidth -= this.app.viewport.width < 400 ? 20 : 30; // padding
        }
        this.$searchInputWrapper.css({'max-width': inputWidth});
        
        // Shift Content
        this.toolbar.app.shifter.shiftContent();
        
        // Perform Search
        if (searchQuery && searchQuery.length) {
            this.performSearch();
        }
    }, this);
    
    // Show the Search Input
    if (this.toolbar.activeTool !== this) {
        this.toolbar.app.shifter.restoreContent().then(onShow);
    } else {
        onShow();
    }
};

/**
 * 
 *
 * @public
 * @this Flipbook.SearchTool
 * @return undefined
 */
Flipbook.SearchTool.prototype.handleKeyPress = function(e) {
    // Check if the ENTER key is pressed
    if (e.which === 13) {
        e.preventDefault();
        this.performSearch();
    }
};

/**
 * 
 *
 * @public
 * @this Flipbook.SearchTool
 * @return {Object} A Promise
 */
Flipbook.SearchTool.prototype.performSearch = function() {
    var deferred = Q.defer();
    var searchQuery = this.$searchInput.val();
    
    // For offline;
    var i, pageText, pageFl, resultCount = 0;
    var searchRegex = new RegExp(searchQuery, 'gim');
    var resultPos, resultExcerpt;
    var $resultContainer = null;
    
    // Update Panel Content after Search
    var updatePanelContent = $.proxy(function(panelContent) {
        // Wait for Virtual Keyboard to Close
        Shared.onNextEventLoop(function() {
            // Update Panel Contents
            this.panelReplaceHtml(panelContent, true).then($.proxy(function() {
                // Hook Panel Links
                this.hookPanelLinks();
                
                // Resolve Promise
                deferred.resolve(searchQuery); 
            }, this));
        }, this, 250);
    }, this);
    
    // Ensure Valid Search Query
    if (!searchQuery || !searchQuery.length) { return; }
    
    // Blur away from Keyboard (close Keyboard on Mobile)
    this.$searchInput[0].blur();
    
    // Offline Search
    if (this.app.config.device.isOffline) {
        // Build Results Container from Template
        $resultContainer = Flipbook.buildFromTemplate({'template': this.toolOptions.offlineResults, 'returnAs': 'element'});
        
        // Iterate all Pages to Search Page Text
        // - "pages" is a Global variable that comes from a script 
        //   included only for Offline Mode in file "data/pages.js"
        for (i = 0; i < pages.length; i++) {
            // Get Text for Page
            pageText = pages[i].Page.text;
            if (Flipbook.EMPTY.test(pageText)) { continue; }
            
            // Get PageFL
            pageFl = pages[i].Page.page_fl;
            
            // Search for Text
            resultPos = pageText.search(searchRegex);
            if (resultPos != -1) {
                // Get Excerpt of Page Text
                resultExcerpt = pageText.substring(resultPos - 85, resultPos + 85);

                // Build Result Item from Template
                Flipbook.buildFromTemplate({
                    'template' : this.toolOptions.offlineResultItem, 
                    'appendTo' : $resultContainer,
                    'tags' : {
                        'result_text' : resultExcerpt.replace(searchRegex, '<span>' + searchQuery + '</span>'),
                        'page_fl'     : pageFl,
                        'page_num'    : parseInt(pageFl, 10) + parseInt(this.app.config.titleData.page_num_offset, 10),
                        'thumb_image' : this.app.parsedPageData[pageFl].thumb + '-0.jpg'
                    }
                });
                
                resultCount++;
            }
        }
        
        // Populate Results Fields
        if (resultCount) {
            $resultContainer.find('#no-results').hide();
            $resultContainer.find('#search-query-id').text(searchQuery);
            $resultContainer.find('#results-count-id').text(resultCount);
        } 
        
        // No Results
        else {
            $resultContainer.find('#has-results').hide();
        }
        
        // Show the Panel
        this.showPanel().then($.proxy(function(promiseValue) {
            updatePanelContent($resultContainer);
        }, this));
    } 
    
    // Online Search
    else {
        // Cancel any pending AJAX Requests
        Flipbook.cancelLastAjax();

        // Show the Panel
        this.showPanel().then($.proxy(function(promiseValue) {
            // Request HTML for Panel Content via AJAX
            Flipbook.qAjax({'url': this.searchUrl, 'dataType': 'html', 'data': {'q': searchQuery}})
            
                .then($.proxy(function(responseData) {
                    updatePanelContent(responseData);
                }, this))

                .fail($.proxy(function(error) {
                    // Base: Display Error Message
                    this.panelDisplayError(error);
                    
                    // Resolve Promise
                    deferred.reject(error); 
                }, this));
            
        }, this));
    }
    
    return deferred.promise;
};

/**
 * Finds and Hooks Links within the Panel
 *
 * @public
 * @this Flipbook.SearchTool
 * @return {Object} A reference to the SearchTool Object for Method Chaining
 */
Flipbook.SearchTool.prototype.hookPanelLinks = function() {
    var $panel = this.getPanel();
    var $links = $panel.find('.result-item[data-load-page]:not(.hooked)');
    
    var linkClicked = function(e) {
        var $el = $(e.currentTarget);
        var targetPage = parseInt($el.attr('data-load-page'), 10);
        
        // Move to Sheet
        this.moveToSheet(Flipbook.getSheetFromPage(this.app, targetPage), 100);
        
        // Close Toolbar and Restore Content
        this.restoreContent();
    };
    
    // Iterate over Paging Links that are not already Hooked
    $links.each($.proxy(function(idx, link) {
        var $link = $(link);
        
        // Mark Link as Hooked
        $link.addClass('hooked');

        // Attach Click/Tap Event to Paging Links
        Flipbook.onClickTap(this.app, $link, linkClicked, this, this.toolName + 'PageLink' + idx);
        
    }, this));
    
    return this;
};
