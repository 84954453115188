require('./core.js');
require('./shared_constants.js');
require('./constants.js');
require('./config.js');
require('./shared_util.js');
require('./util.js');
require('./logger.js');
require('./profiler.js');
require('./stats.js');
require('./toolbar.js');
require('./tools/base.js');
require('./tools/search.js');
require('./tools/sharing.js');
require('./tools/archives.js');
require('./tools/contents.js');
require('./tools/text.js');
require('./tools/pdf.js');
require('./tools/fullscreen.js');
require('./tools/help.js');
require('./shifter.js');
require('./pagebar.js');
require('./modal.js');
require('./nontouch.js');
require('./sheet.js');
require('./overlayer.js');
require('./overlays/base.js');
require('./overlays/links.js');
require('./overlays/audio.js');
require('./overlays/video.js');
require('./overlays/widgets.js');
require('./overlays/bookmarks.js');
require('./overlays/notes.js');
require('./slider.js');
require('./scrubber.js');
require('./zoomer.js');
require('./carousel.js');
require('./navigation.js');
require('./loading.js');
require('./app.js');

/*
    sourceRoot: basepath + 'src/js/flipbook',
    outputRoot: basepath + 'fe/webroot/js/flipbook',
    outputFile: 'flipbook_app'
   */
