/**
 * Flyp Technologies Inc. - Flipbook v4
 * 
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/shifter.js - Flipbook.Shifter
 * @author Robert J. Secord, B.Sc.
 */

import 'jquery-migrate';
import $ from 'jquery';
import Flipbook from './core';
import Q from '/app/libs/promise/q';
import Shared from './shared_util';

/**
 * Flipbook Shifter
 *
 * @class Shifter
 * @classdesc Application Shifter Controller
 * @namespace Flipbook
 * @param {Object} app       - A reference to the App Object
 * @param {Object} $container - A jQuery Element Object; the Container that the Shifter will be Appended to
 * @return {Object}           - The Class Instance
 * @constructor
 */
Flipbook.Shifter = function(app, $container) {

    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize    = null;
    var buildElements = null;
    var attachEvents  = null;


    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    this.app        = app;
    this.$container = $container;
    this.$content   = null;
    this.$carousel  = null;
    this.$toolbar   = null;
    this.$pagebar   = null;
    this.$mask      = null;
    
    this.closeViaMask = true;
    

    /* **************************************************************************************** */
    /* * Private Methods/Members Definitions                                                  * */
    /* **************************************************************************************** */
    /**
     * Initialize the Shifter Control
     *
     * @private
     * @this Flipbook.Shifter
     * @return {Object} A reference to the Shifter Object for Method Chaining
     * @constructs
     */
    initialize = $.proxy(function() {
        // Debug Message
        Flipbook.log('shifter-init');
        
        // Build the Shifter Elements
        buildElements();
        attachEvents();
        
        return this;
    }, this);

    /**
     * Build the Shifter Elements
     *
     * @private
     * @this Flipbook.Shifter
     * @return undefined
     */
    buildElements = $.proxy(function() {
        var html = '';
        
        // Debug Message
        Flipbook.log('shifter-build-elements');
        
        // Profiler
        Shared.Profiler.start('Flipbook:Shifter->buildElements');

        // Build Main Content Container Element
        this.$content = $('<div class="shifter-content"/>').appendTo(this.$container);

        // Build Tool Bar Container Element
        if (this.app.config.toolbar.enabled) {
            this.$toolbar = $('<div class="flipbook-toolbar"/>').appendTo(this.$content);
        }

        // Build Carousel Container Element
        this.$carousel = $('<div class="flipbook-carousel"/>').appendTo(this.$content);
        
        // Build Page Bar Container Element
        if (this.app.config.pagebar.enabled) {
            this.$pagebar = $('<div class="flipbook-pagebar"/>').appendTo(this.$content);
        }

        // Build Mask for Overlaying Content when Toolbar is Opened
        this.$mask = $('<div class="shifter-content-mask"/>').appendTo(this.$content);
        
        // Profiler
        Shared.Profiler.end('Flipbook:Shifter->buildElements');
    }, this);

    /**
     * Attach Events to the Shifter Elements
     *
     * @private
     * @this Flipbook.Shifter
     * @return undefined
     */
    attachEvents = $.proxy(function() {
        // Debug Message
        Flipbook.log('shifter-attach-events');
        
        // Tap based Events
        Flipbook.onClickTap(this.app, this.$mask, function(e) {
            if (this.closeViaMask) {
                this.restoreContent();
            }
        }, this, 'Shifter');
        
    }, this);


    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize();
};
// End of Flipbook.Shifter


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Resize the Shifter Elements
 *   Shifter Main Content Block should always be the full size of the Viewport
 *      
 * @public
 * @this Flipbook.Shifter
 * @return {Object} - A Promise
 */
Flipbook.Shifter.prototype.resize = function() {
    var deferred = Q.defer();
    
    // Debug Message
    Flipbook.log('shifter-resize');
    
    // Restore Shifted Content
    this.restoreContent().then($.proxy(function() {
        // Update Size of Content
        //this.$content.width(this.app.viewport.width).height(this.app.viewport.height);
        //this.$mask.width(this.app.viewport.width).height(this.app.viewport.height); 
        
        // Resolve Promise
        deferred.resolve('resized'); 
    }, this));

    return deferred.promise;
};

/**
 * Shifts the Content so that the Toolbar Menu is in View
 *      
 * @public
 * @this Flipbook.Shifter
 * @return {Object} - A Promise
 */
Flipbook.Shifter.prototype.shiftContent = function() {
    var deferred = Q.defer();
    var toolbarWidth = 0;
    var toolbarSpeed = this.app.config.toolbar.speed;
    var maskOpacity = this.app.config.shifter.mask.opacity;
    var animOpts = {};
    
    var onComplete = $.proxy(function() {
        // Update State Flags
        this.app.state.animating = false; 
        
        // Call Content-Shifted Event
        if (this.app.toolbar.activeTool) {
            this.app.toolbar.activeTool.contentShifted();
        }
        
        // Resolve Promise
        deferred.resolve('shifted'); 
    }, this);

    // Reject if Toolbar is not Enabled or Already Shifted
    if (!this.app.config.toolbar.enabled || this.app.state.shifted) { 
        deferred.resolve('shifted');
        return deferred.promise; 
    }
    
    // Get Open-Width of Toolbar
    if (this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_LEFT) {
        toolbarWidth = this.app.config.toolbar.size[this.app.viewport.breakpoint][this.app.config.toolbar.position].open;
    }
    
    // Debug Message
    Flipbook.log('shifter-shift-content');
    
    // Update State Flags
    this.app.state.shifted = true;
    this.app.state.animating = true;
    
    // Animate Mask over Content
    this.$mask.css({'display': 'block', 'opacity': 0}).transition({'opacity': maskOpacity}, toolbarSpeed);
    
    // Open Toolbar (Overlap Content)
    if (this.app.config.shifter.style === Flipbook.SHIFTER_OVERLAP) {
        // Expand Toolbar
        this.app.toolbar.expand().then(onComplete);
    }
    
    // Open Toolbar (Shift Content)
    else {
        // Shift Carousel Container
        if (this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_LEFT) {
            animOpts[Flipbook.TRANSITION_MODIFIER] = toolbarWidth;
            this.$carousel.transition(animOpts, toolbarSpeed);
        }
        
        // Expand Toolbar
        this.app.toolbar.expand().then(onComplete);
    }
    return deferred.promise;
};

/**
 * Shifts the Content so that the Toolbar Menu is not in View
 *      
 * @public
 * @this Flipbook.Shifter
 * @return {Object} - A Promise
 */
Flipbook.Shifter.prototype.restoreContent = function() {
    var deferred = Q.defer();
    var toolbarSize = this.app.config.toolbar.size[this.app.viewport.breakpoint][this.app.config.toolbar.position].closed;
    var toolbarSpeed = this.app.config.toolbar.speed;
    var animOpts = {};
    
    var onComplete = $.proxy(function() {
        // Update State Flags
        this.app.state.shifted = false;
        this.app.state.animating = false;
        
        // Hide Content Mask
        this.$mask.css({'display': 'none'});
        
        // Call Content-Restored Event
        if (this.app.toolbar.activeTool) {
            this.app.toolbar.activeTool.contentRestored();

            // Deactivate Tool (Top Toolbar Mode Only)
            if (this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_TOP) {
                //Shared.onNextEventLoop(function() {
                    this.app.toolbar.activeTool = null;
                //}, this);
            }
        }
        
        // Resolve Promise
        deferred.resolve('restored');
    }, this);

    // Reject if Toolbar is not Enabled or Not Shifted
    if (!this.app.config.toolbar.enabled || !this.app.state.shifted) { 
        deferred.resolve('restored');
        return deferred.promise; 
    }
    
    // Debug Message
    Flipbook.log('shifter-restore-content');
    
    // Update State Flags
    this.app.state.animating = true;
    
    // Animate Mask out of Content
    this.$mask.transition({'opacity': 0}, toolbarSpeed);
    
    // Close Toolbar (Overlap Content)
    if (this.app.config.shifter.style === Flipbook.SHIFTER_OVERLAP) {
        // Collapse Toolbar
        this.app.toolbar.collapse().then(onComplete);
    }
    
    // Close Toolbar (Shift Content)
    else {
        // Shift Carousel Container
        if (this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_LEFT) {
            animOpts[Flipbook.TRANSITION_MODIFIER] = toolbarSize;
            this.$carousel.transition(animOpts, toolbarSpeed);
        }
        
        // Collapse Toolbar
        this.app.toolbar.collapse().then(onComplete);
    }
    return deferred.promise;
};
