/**
 * Flyp Technologies Inc. - Flipbook v4
 * 
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/core.js - Flipbook.Core
 * @author Robert J. Secord, B.Sc.
 */
import 'jquery-migrate';
import $ from 'jquery';
import Modernizr from 'modernizr';
import Shared from './shared_util';
import MBP from '/app/libs/mbp/js/helper';

const Flipbook = {
  root: window ? window : {},
  version: '4.0.1',
};

window.Flipbook = Flipbook;

/**
 * Flipbook Core
 *
 * @class Core
 * @classdesc Application Controller
 * @namespace Flipbook
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.Core = function(appOptions) {

  /* **************************************************************************************** */
  /* * Private Methods/Members Declarations                                                 * */
  /* **************************************************************************************** */
  var initialize;
  var fixMobileIE10;
  var optimizeMobile;
  var identifyDeviceCapabilities;

  /* **************************************************************************************** */
  /* * Public Properties                                                                    * */
  /* **************************************************************************************** */

  /**
   * Configuration settings for the current Flipbook instance
   *
   * @member {Object} config
   * @see Flipbook.Config
   * @protected
   */
  this.config = null;

  /**
   * The current state of the Flipbook instance
   *
   * @member {Object} state
   * @member {boolean} state.shifted True when the Toolbar is in an open (shifted) state
   * @member {boolean} state.animating True when the Flipbook is currently in an animation state
   * @member {boolean} state.firstPageOnRight True when the First Page of a Flipbook is on the Right Side of a Two-Page Spread in Landscape Mode
   * @member {boolean} state.lastPageOnLeft True when the Last Page of a Flipbook is on the Left Side of a Two-Page Spread in Landscape Mode
   * @member {boolean} state.mobileInputFocus True when an Input Box is currently in Focus on a Mobile Device (used to prevent resizing when the virtual keyboard opens)
   * @protected
   */
  this.state = {
    'shifted'          : false,
    'animating'        : false,
    'firstPageOnRight' : false,
    'lastPageOnLeft'   : false,
    'mobileInputFocus' : false
  };
      
  /**
   * The current state/info of the Flipbook Viewport
   *
   * @member {Object} viewport
   * @member {Object} viewport.$element A jQuery Element handle to the Viewport Element (Body Tag, or a Container Tag when Embedded)
   * @member {number} viewport.width The current Width of the Viewport in pixels
   * @member {number} viewport.height The current Height of the Viewport in pixels
   * @member {string} viewport.orientation The current Orientation of the Viewport ({@link Flipbook.ORIENT_PORTRAIT} or {@link Flipbook.ORIENT_LANDSCAPE})
   * @member {string} viewport.breakpoint The current Responsive Breakpoint of the Viewport {@link Flipbook.Config.breakpoints}
   * @member {boolean} viewport.fullscreen True when the Flipbook (NOT the Browser) is in Fullscreen Mode (meaning the Toolbar is hidden, and the pages take as much space in the Viewport as possible)
   * @member {number} viewport.oldWidth The last known Width of the Viewport in pixels
   * @member {number} viewport.oldHeight The last known Height of the Viewport in pixels
   * @protected
   */
  this.viewport = {
    '$element'    : null,
    'width'       : 0,
    'height'      : 0,
    'orientation' : Flipbook.ORIENT_LANDSCAPE,
    'breakpoint'  : 'desktop',
    'fullscreen'  : false,
          
    // These are for determining if the Viewport actually changed sizes, Don't Change.
    'oldWidth'    : 0,
    'oldHeight'   : 0
  };

  /**
   * The file format for the flipbook
   *
   * @member {string} issueType
   * @see Flipbook.App~issueFormat
   * @protected
   */
  this.issueFormat = null;

  /**
   * Flag used to manage zoomed images on flipbook
   *
   * @member {boolean} showExplodedImages
   * @see Flipbook.App~showExplodedImages
   * @protected
   */
  this.showExplodedImages = true;

  /**
   * The Parsed Page Data for the Current Flipbook;
   *  Page Data contains an Array of Page Objects with keys;
   *   - [realId] the Real (Database) ID of the Page
   *   - [thumb] the Thumbnail image of the Page
   *   - [links] any Link Overlays for the Page
   *   - [videos] any Video Overlays for the Page
   *   - [audios] any Audio Overlays for the Page
   *   - [swfs] any SWF Overlays for the Page
   *
   * @member {Array} parsedPageData
   * @see Flipbook.App~parsePageData
   * @protected
   */
  this.parsedPageData    = null;
      
  /**
   * The Parsed Article Data for all of the Pages of the Current Flipbook;
   *  Article Data contains an Array of Article Objects with keys:
   *   - [page] the Page Number the Article belongs to
   *   - [title] the Title of the Article
   *   - [text] the Text of the Article
   *   - [thumb] the Thumbnail image of the Article
   *
   * @member {Array} parsedArticleData
   * @see Flipbook.App~parsePageData
   * @protected
   */
  this.parsedArticleData = null;
      
  /**
   * The Parsed Widget Data for all of the Pages of the Current Flipbook;
   *  Widget Data contains an Array of Widget Objects with keys:
   *   - [id] the Real (Database) ID of the Widget
   *   - [page_fl] the Page Number the Widget belongs to
   *   - [page_id] the Real ID of the Page the Widget belongs to
   *   - [dimensions] the Size and Position of the Widget
   *   - [scaled] the Scaled Size and Position of the Widget relative to the Scaled Size of the Page when displayed
   *   - [strict_size] Truthy when the Widget should maintain a Strict Size and not Scale accordingly with the Page Size
   *   - [hide_if_small] Truthy when the Widget should be hidden if scaled too small
   *   - [desktop] Truthy when the Widget is allowed to be displayed on Desktop Devices
   *   - [tablet] Truthy when the Widget is allowed to be displayed on Tablet Devices
   *   - [phone] Truthy when the Widget is allowed to be displayed on Phone Devices
   *   - [service] the Name of the Service of the Widget (youtube, vimeo, etc..)
   *   - [option_close] Truthy when the Widget is allowed to be closed by the user
   *
   * @member {Array} parsedWidgetData
   * @see Flipbook.App~loadSettings
   * @protected
   */
  this.parsedWidgetData  = null;

  /**
   * The Parsed Annotations & Bookmarks Data for all of the Pages of the Current Flipbook;
   *  Annotations Data contains an Array of Note Objects with keys:
   *   - [id] the Real (Database) ID of the Note
   *   - [page_fl] the Page Number the Note belongs to
   *   - [page_id] the Real ID of the Page the Note belongs to
   *   - [coordinate_x] the X position for the Note as a percentage of the Page Width
   *   - [coordinate_y] the Y position for the Note as a percentage of the Page Height
   *   - [scaled] the Scaled position of the Note in pixels
   *   - [note] the Textual Content of the Note
   *   - [created] the Date & Time the Note was Created
   *   - [modified] the Date & Time the Note was Modified
   *  Bookmark Data contains an Array of Bookmark Objects with keys:
   *   - [id] the Real (Database) ID of the Bookmark
   *   - [page_fl] the Page Number the Bookmark belongs to
   *   - [page_id] the Real ID of the Page the Bookmark belongs to
   *   - [created] the Date & Time the Bookmark was Created
   *   - [modified] the Date & Time the Bookmark was Modified
   *
   * @member {Array} parsedNotesData
   * @see Flipbook.App~loadSettings
   * @protected
   */
  this.parsedNotesData   = null;  // Bookmarks and Annotations
      

  /* **************************************************************************************** */
  /* * Private Methods/Members Definitions                                                  * */
  /* **************************************************************************************** */
  /**
   * Initialize the Core of the Application
   *     Merges Config Data with Options set at Instantiation
   *     Initializes L10N Support
   *     Determines Device Capabilities
   *     Registers Special Events used by the Flipbook
   *
   * @private
   * @this Flipbook.Core
   * @return {Object} A reference to the Core Object for Method Chaining
   * @constructs
   */
  initialize = $.proxy(function(options) {
    if (options === undefined) { return; }
          
    // Get Configuration Settings for Application
    this.config = $.extend(true, {}, Flipbook.Config, options);
          
    // Add Localization to Logger
    Shared.Logger.setL10N(Flipbook.DEBUG_MSG);
          
    // Ensure Special Events are initialized; we need the debouncedresize event
    Shared.registerSpecialEvents([
              {'event': 'resize', 'prefix': 'debounced', 'threshold': 250},
              {'event': 'blur',   'prefix': 'debounced', 'threshold': 250}
    ]);
          
    // Identify Device Capabilities
    identifyDeviceCapabilities();
          
    // Fix Mobile IE 10
    fixMobileIE10();
          
    // Optimizations for Mobile Devices
    optimizeMobile();
          
    return this;
  }, this);
      
  /**
   * Identify Device Capabilities for later reference
   *
   * @private
   * @this Flipbook.Core
   * @return undefined
   */
  identifyDeviceCapabilities = $.proxy(function() {
    var dummyStyle = $('<div/>')[0].style;
          
    // Fallback to frame-based animation when CSS Transitions are not available
    if (!Modernizr.csstransitions) {
      $.fn.transition = $.fn.animate;
      Flipbook.TRANSITION_MODIFIER = 'left';
    }
          
    // Prevent dragging images in Firefox
    $(document).on('dragstart', function(e) {
      if (/img/i.test(e.target.nodeName)) {
        return false;
      }
    });
          
    // Disable Tracking for Offline
    this.config.enableTracking = this.config.enableTracking && !this.config.device.isOffline;
          
    // Detect IFrame
    if (Flipbook.root.location !== Flipbook.root.parent.location) {
      this.config.device.iframe = true;
    }
          
    // Detect Touch Support
    if (('ontouchstart' in window) || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
      this.config.device.hasTouch = true;
    }
          
    // Detect Multi-Touch Support
    if (this.config.device.hasTouch) {
      this.config.device.hasMultiTouch = true;
      $.each(Flipbook.NON_MULTI_TOUCH, $.proxy(function(idx, nonMultiTouchDevice) {
        if (nonMultiTouchDevice) {
          this.config.device.hasMultiTouch = false;
        }
      }, this));
    }
          
    // Detect 3D Support
    if (dummyStyle[Modernizr.prefixed('perspective')] !== undefined) {
      this.config.device.has3D = true;
    }
          
    // Device Pixel Ratio
    if (Flipbook.root.devicePixelRatio === undefined) {
      Flipbook.root.devicePixelRatio = 1;
    }
          
    // Embedded Flipbooks need to use Script Tag Comm Method for Stats
    if (Flipbook.isEmbedded(this)) {
      this.config.statsCommMethod = Flipbook.COMM_METHOD_SCRIPT;
    }
  }, this);

  /**
   * Overrides the device-width declaration in CSS to force IE 10 Mobile to play nicely
   *
   * @private
   * @this Flipbook.Core
   * @return undefined
   */
  fixMobileIE10 = function() {
    var msViewportStyle = null;
    if (Shared.MS_IE_10) {
      msViewportStyle = document.createElement('style');
      msViewportStyle.appendChild(document.createTextNode('@-ms-viewport{width:auto!important}'));
      document.getElementsByTagName('head')[0].appendChild(msViewportStyle);
    }
  };
      
  /**
   * Optimizations for Mobile Devices
   *
   * @private
   * @this Flipbook.Core
   * @return undefined
   */
  optimizeMobile = $.proxy(function() {
    if (!Flipbook.isMobile.any(this)) { return; }
          
    // Hide URL Bar
    MBP.hideUrlBarOnLoad();
          
    // Fix for iPhone viewport scale bug
    if (Shared.IOS && !Shared.IOS8) {
      MBP.scaleFix();
    }
          
    // Enable CSS active pseudo styles in Mobile Safari
    MBP.enableActive();
  }, this);


  /* **************************************************************************************** */
  /* * Entry Point                                                                          * */
  /* **************************************************************************************** */
  return initialize(appOptions);
};
// End of Flipbook.Core


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Cleans the Issue Data loaded via AJAX
 *   All data is in String Format, and we need some data to be Integers or Boolean
 *
 * @public
 * @this Flipbook.App
 * @return undefined
 */
Flipbook.Core.prototype.cleanIssueData = function() {
    // Convert to Integers
  this.config.issueData.w1 = _.parseInt(this.config.issueData.w1, 10);
  this.config.issueData.w2 = _.parseInt(this.config.issueData.w2, 10);
  this.config.issueData.h1 = _.parseInt(this.config.issueData.h1, 10);
  this.config.issueData.h2 = _.parseInt(this.config.issueData.h2, 10);
  this.config.issueData.num_of_pages = _.parseInt(this.config.issueData.num_of_pages, 10);
};
  
/**
 * Cleans the Title Data loaded via AJAX
 *   All data is in String Format, and we need some data to be Integers or Boolean
 *
 * @public
 * @this Flipbook.App
 * @return undefined
 */
Flipbook.Core.prototype.cleanTitleData = function() {
  // Covert to Integers
  this.config.titleData.page_num_offset = _.parseInt(this.config.titleData.page_num_offset, 10);
      
  // Convert to Boolean
  this.config.titleData.single_page_view = Flipbook.TRUTHY.test(this.config.titleData.single_page_view);
  this.config.titleData.always_open = Flipbook.TRUTHY.test(this.config.titleData.always_open);
  this.config.titleData.favicon = Flipbook.TRUTHY.test(this.config.titleData.favicon);
  this.config.titleData.has_logo = Flipbook.TRUTHY.test(this.config.titleData.has_logo);
  this.config.titleData.flipbook_background = Flipbook.TRUTHY.test(this.config.titleData.flipbook_background);
  this.config.titleData.allow_downloading = Flipbook.TRUTHY.test(this.config.titleData.allow_downloading);
  this.config.titleData.allow_fullscreen = Flipbook.TRUTHY.test(this.config.titleData.allow_fullscreen);
      
  // Tool Settings
  this.config.titleData.allow_emailing = Flipbook.TRUTHY.test(this.config.titleData.allow_emailing);
  this.config.titleData.allow_sharing = Flipbook.TRUTHY.test(this.config.titleData.allow_sharing);
  this.config.titleData.allow_text_tool = Flipbook.TRUTHY.test(this.config.titleData.allow_text_tool);
  this.config.titleData.show_archive_listing = Flipbook.TRUTHY.test(this.config.titleData.show_archive_listing);
  this.config.titleData.show_archive_sorting = Flipbook.TRUTHY.test(this.config.titleData.show_archive_sorting);
  this.config.titleData.show_links = Flipbook.TRUTHY.test(this.config.titleData.show_links);
  this.config.titleData.show_toc = Flipbook.TRUTHY.test(this.config.titleData.show_toc);
      
  // Expanded Mode
  this.config.expanded = Flipbook.TRUTHY.test(this.config.expanded);
};
  
/**
 * Gets a Handle to the Viewport Element
 *   Could be an HTML Element or the Document Body.
 *   When it is an Element, Flipbook is Embedded.
 *   We also append the Root CSS Classname to the Element for Namespacing the CSS
 *
 * @public
 * @this local
 * @return undefined
 */
Flipbook.Core.prototype.getViewport = function() {
  if (this.config.container.length) {
    this.viewport.$element = $(this.config.container);
  }
  if (!this.viewport.$element || !this.viewport.$element.length) {
    this.viewport.$element = Shared.$('body');
  }
  // Add Class to Container to Namespace the CSS
  this.viewport.$element.addClass(this.config.cssNamespace);
};
  

/* ******************************************************************************************** */
/* * Backwards Compatibility (with Mobile 3)                                                  * */
/* ******************************************************************************************** */

/**
 * Holds Widget Data and Handles Resizing Widgets (from Old Flipbook)
 *
 * @type {Object} g_overlay
 * @global
 */
Flipbook.root.g_overlay = Flipbook.root.g_overlay || {};

/**
 * Updates the Size of a Widget Frame Element
 *
 * @public
 * @this local
 * @return undefined
 */
Flipbook.root.g_overlay.auto_resize_iframe = function(widgetId, width, height) {
  Flipbook.resizeWidgetFrame(widgetId, {'width': width + 2, 'height': height + 2});
};

export default Flipbook;
