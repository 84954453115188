/**
 * Flyp Technologies Inc. - Flipbook v4
 *
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/overlays/audio.js - Flipbook.AudioOverlay
 * @author Robert J. Secord, B.Sc.
 */
import 'jquery-migrate';
import $ from 'jquery';
import Flipbook from '../core';
import Q from '/app/libs/promise/q';

/**
 * Flipbook Audio Overlay Controller
 *
 * @class AudioOverlay
 * @classdesc Application Audio Overlay
 * @namespace Flipbook
 * @inherits Flipbook.OverlayBase
 * @param {Object} overlayer A reference to the Overlayer Control
 * @param {Object} overlayOptions Configuration Settings for the Overlay
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.AudioOverlay = function(overlayer, overlayOptions) {
    // Call Parent Constructor
    Flipbook.OverlayBase.apply(this, [overlayer, overlayOptions]);
    
    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize = null;


    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    
    // Overlay Audios
    this.$overlayAudioEls = [];

    /* **************************************************************************************** */
    /* * Private Methods/Members Definitions                                                  * */
    /* **************************************************************************************** */
    /**
     * Initialize the Audio Overlay
     *
     * @private
     * @this Flipbook.AudioOverlay
     * @return {Object} A reference to the AudioOverlay Object for Method Chaining
     * @constructs
     */
    initialize = $.proxy(function() {

        // not much to do here..
        
        return this;
    }, this);
    
    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize();
};
Flipbook.AudioOverlay.prototype = new Flipbook.OverlayBase();
Flipbook.AudioOverlay.prototype.constructor = Flipbook.AudioOverlay;
// End of Flipbook.AudioOverlay


/* ******************************************************************************************** */
/* * Overridden Base Methods                                                                  * */
/* ******************************************************************************************** */

/**
 * Override Abstract Base Method; Called when ...
 *
 * @public
 * @this Flipbook.AudioOverlay
 * @return {Object} A Promise
 */
Flipbook.AudioOverlay.prototype.resize = function() {
    return Q.Promise($.proxy(function(resolve, reject, notify) { 
        // Debug Message
        Flipbook.log('overlay-resize-audios');

        // Update Sizes
        this.calculateSizes();
        
        // Resize Complete; Resolve Promise
        resolve(true);
    }, this));
};

/**
 * Override Abstract Base Method; Called when ...
 *
 * @public
 * @this Flipbook.AudioOverlay
 * @return {Object} A Promise
 */
Flipbook.AudioOverlay.prototype.add = function() {
    return Q.Promise($.proxy(function(resolve, reject, notify) {
        var i, j, audioCount = 0;
        var $audioContainer, $audio;
        var audios, rect;
        var leftPosition, pageId;
        var pageIndices = this.app.carousel.sheets[this.app.carousel.current.sheet].pageIndices;
        var pageWidth = this.app.config.sheet.pageSize[this.app.viewport.orientation].w;

        // Debug Message
        Flipbook.log('overlay-add-audios');
        
        // Iterate Pages of Current Sheet
        for (i = 0; i < pageIndices.length; i++) {
            pageId = _.parseInt(this.app.parsedPageData[pageIndices[i]].realId, 10);
            
            // Find Audios for Each Page of Current Sheet
            audios = this.app.parsedPageData[ pageIndices[i] ].audios;
            if (audios && audios.length) {
                    
                // Iterate All Audios for Page
                for (j = 0; j < audios.length; j++) {
                    // Get Area of Audio
                    rect = audios[j].scaled;
                    
                    // Create New Audio Element
                    if (audioCount >= this.$overlayAudioEls.length) {
                        // Create Audio
                        $audioContainer = this.addTemplate({
                            'audio_id'      : audios[j].id,
                            'audio_page_id' : pageId
                        });
                        
                        // Add to Internal Audio Array for later Reuse
                        this.$overlayAudioEls.push($audioContainer);
                        
                        // Attach Click/Tap Event to Audio Link
                        Flipbook.onClickTap(this.app, $audioContainer, this.handleAudioClick, this, 'OverlayAudio');
                    }
                    
                    // Determine Correct Left Position
                    leftPosition = rect.x;
                    if (i > 0 || (this.app.carousel.current.sheet === 0 && Flipbook.isFirstPageOnRight(this.app))) {
                        leftPosition += pageWidth;
                    }
                    
                    // Apply Style & Attributes to Audio
                    this.$overlayAudioEls[audioCount]
                        .attr('data-page-idx', pageIndices[i])
                        .attr('data-audio-id', audios[j].id)
                        .css({
                            'display' : 'block',
                            'left'    : leftPosition,
                            'top'     : rect.y,
                            'scale'   : rect.s
                        });
                    
                    // Set Audio Attributes
                    $audio = this.$overlayAudioEls[audioCount].find('audio');
                    $audio.attr('preload', 'metadata');
                    $audio.attr('src', audios[j].src);
                    $audio.append('<p>Your browser does not support this audio.</p>');
                    
                    // AutoPlay / AutoHide Attributes
                    if (Flipbook.TRUTHY.test(audios[j].autoplay) && !Flipbook.isMobile.any(this.app)) {
                        $audio.attr('autoplay', true);
                        this.$overlayAudioEls[audioCount].addClass(this.app.config.overlayer.overlays.AudioOverlay.playClass);
                    }
                    
                    // Track Number of Visible Audios
                    audioCount++;
                }
            }
        }
        
        // Overlay Added; Resolve Promise
        resolve({'type': 'Audio', 'count': audioCount});
    }, this));
};

/**
 * Override Abstract Base Method; Called when ...
 *
 * @public
 * @this Flipbook.AudioOverlay
 * @return {Object} A Promise
 */
Flipbook.AudioOverlay.prototype.remove = function() {
    return Q.Promise($.proxy(function(resolve, reject, notify) {
        var audioCount = this.$overlayAudioEls.length;
        
        // Debug Message
        Flipbook.log('overlay-remove-audios');

        // Remove Page Audios
        for (var i = 0; i < this.$overlayAudioEls.length; i++) {
            if (this.$overlayAudioEls[i].length) {
                this.$overlayAudioEls[i].remove();
            }
        }
        this.$overlayAudioEls.length = 0;
        
        // Overlay Removed; Resolve Promise
        resolve({'type': 'Audio', 'count': audioCount});
    }, this));
};


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Calculate the Size/Position of the Audio Elements based on the Size of the Page
 *
 * @public
 * @this Flipbook.AudioOverlay
 * @return undefined
 */
Flipbook.AudioOverlay.prototype.calculateSizes = function() {
    var i, j, r;
    var scaleRatio;
    var $pageData;
    var pageWidth;
    var pageDisplayWidth = this.app.config.sheet.pageSize[this.app.viewport.orientation].w;
    
    // Debug Message
    Flipbook.log('overlay-calc-size-audios');
    
    // Calculate Sizes of Page Audios
    for (i = 0; i < this.app.parsedPageData.length; i++) {
        $pageData = this.app.config.$issueSettings.find('pages > page[realid=' + this.app.parsedPageData[i].realId + ']');
        if (!$pageData.length) { continue; }
        
        // Get the Original Page Width
        pageWidth = (_.parseInt($pageData.attr('width'), 10) || 1);
        
        // Get the Scale Ratio for the Original Page to the Display Page
        scaleRatio = pageDisplayWidth / pageWidth;

        // Apply Scale
        for (j = 0; j < this.app.parsedPageData[i].audios.length; j++) {
            r = this.app.parsedPageData[i].audios[j].rect.split(',');
            
            // Set Size/Position
            this.app.parsedPageData[i].audios[j].scaled = {
                'x': Math.round(parseFloat(r[0]) * scaleRatio), 
                'y': Math.round(parseFloat(r[1]) * scaleRatio), 
                'w': Math.round(parseFloat(r[2]) * scaleRatio), 
                'h': Math.round(parseFloat(r[3]) * scaleRatio),
                's': scaleRatio
            };
        }
    }
};

/**
 * 
 * 
 * @public
 * @this Flipbook.AudioOverlay
 * @return undefined
 */
Flipbook.AudioOverlay.prototype.handleAudioClick = function(e) {
    var $el = $(e.currentTarget);
    if (!$el.length || !$el.hasClass('overlay-audio')) { return; }
    if (this.app.config.overlayer.overlays.AudioOverlay === undefined) { return; }
    
    var $audio = $el.find('audio');
    if (!$audio.length) { return; }
    
    e.preventDefault();
    
    // Toggle State of Audio
    if ($el.hasClass(this.app.config.overlayer.overlays.AudioOverlay.playClass)) {
        $audio[0].pause();
        $el.removeClass(this.app.config.overlayer.overlays.AudioOverlay.playClass);
    } else {
        $audio[0].play();
        $el.addClass(this.app.config.overlayer.overlays.AudioOverlay.playClass);
    }
    
    return true;
};
