/**
 * Flyp Technologies Inc. - Flipbook v4
 *
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/overlays/links.js - Flipbook.LinksOverlay
 * @author Robert J. Secord, B.Sc.
 */
import 'jquery-migrate';
import $ from 'jquery';
import Flipbook from '../core';
import Q from '/app/libs/promise/q';

/**
 * Flipbook Links Overlay Controller
 *
 * @class LinksOverlay
 * @classdesc Application Links Overlay
 * @namespace Flipbook
 * @inherits Flipbook.OverlayBase
 * @param {Object} overlayer A reference to the Overlayer Control
 * @param {Object} overlayOptions Configuration Settings for the Overlay
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.LinksOverlay = function(overlayer, overlayOptions) {
    // Call Parent Constructor
    Flipbook.OverlayBase.apply(this, [overlayer, overlayOptions]);
    
    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize = null;


    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */

    // Overlay Links
    this.$overlayLinkEls = [];

    /* **************************************************************************************** */
    /* * Private Methods/Members Definitions                                                  * */
    /* **************************************************************************************** */
    /**
     * Initialize the Links Overlay
     *
     * @private
     * @this Flipbook.LinksOverlay
     * @return {Object} A reference to the LinksOverlay Object for Method Chaining
     * @constructs
     */
    initialize = $.proxy(function() {
        
        // not much to do here..
        
        return this;
    }, this);
    
    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize();
};
Flipbook.LinksOverlay.prototype = new Flipbook.OverlayBase();
Flipbook.LinksOverlay.prototype.constructor = Flipbook.LinksOverlay;
// End of Flipbook.LinksOverlay


/* ******************************************************************************************** */
/* * Overridden Base Methods                                                                  * */
/* ******************************************************************************************** */

/**
 * Override Abstract Base Method; Called when ...
 *
 * @public
 * @this Flipbook.LinksOverlay
 * @return {Object} A Promise
 */
Flipbook.LinksOverlay.prototype.resize = function() {
    return Q.Promise($.proxy(function(resolve, reject, notify) {
        // Debug Message
        Flipbook.log('overlay-resize-links');
        
        // Update Sizes
        this.calculateSizes();
        
        // Resize Complete; Resolve Promise
        resolve(true);
    }, this));
};

/**
 * Override Abstract Base Method; Called when ...
 *
 * @public
 * @this Flipbook.LinksOverlay
 * @return {Object} A Promise
 */
Flipbook.LinksOverlay.prototype.add = function() {
    return Q.Promise($.proxy(function(resolve, reject, notify) {
        var i, j, linkCount = 0;
        var $linkElement;
        var links, rect, rgba;
        var linkArea, leftPosition;
        var pageIndices = this.app.carousel.sheets[this.app.carousel.current.sheet].pageIndices;
        var pageWidth = this.app.config.sheet.pageSize[this.app.viewport.orientation].w;

        // Debug Message
        Flipbook.log('overlay-add-links');
        
        // Iterate Pages of Current Sheet
        for (i = 0; i < pageIndices.length; i++) {
            
            // Find Links for Each Page of Current Sheet
            links = this.app.parsedPageData[ pageIndices[i] ].links;
            if (links && links.length) {
                    
                // Iterate All Links for Page
                for (j = 0; j < links.length; j++) {
                    // Ignore Disabled Links
                    if (links[j].disabled) { continue; }
                    
                    // Get Area of Link
                    rect = links[j].scaled;
                    linkArea = rect.w * rect.h;
                    
                    // Create New Link Element
                    if (linkCount >= this.$overlayLinkEls.length) {
                        // Create Link Element from Template
                        $linkElement = this.addTemplate();
                        
                        // Add to Internal Links Array for later Reuse
                        this.$overlayLinkEls.push($linkElement);
                        
                        // Attach Click/Tap Event to Link
                        Flipbook.onClickTap(this.app, $linkElement, this.handleLinkClick, this, 'OverlayLink');
                    }
                    
                    // Determine Correct Left Position
                    leftPosition = rect.x;
                    if (i > 0 || (this.app.carousel.current.sheet === 0 && Flipbook.isFirstPageOnRight(this.app))) {
                        leftPosition += pageWidth;
                    }
                    
                    // Apply Style & Attributes to Link
                    this.$overlayLinkEls[linkCount]
                        .attr('data-page-idx', pageIndices[i])
                        .attr('data-link-id', links[j].id)
                        .addClass('link-style-' + links[j].linkStyle)
                        .css({
                            'display' : 'block',
                            'left'    : leftPosition,
                            'top'     : rect.y,
                            'width'   : rect.w,
                            'height'  : rect.h
                        });
                    
                    
                    // Highlight Color (if any)
                    if (links[j].highlight !== undefined && links[j].highlight.length) {
                        rgba = Flipbook.hexToRgb(links[j].highlight);
                        rgba.a = (links[j].linkStyle === 'H') ? 0.85 : 0.55;
                    } else {
                        rgba = {r: 255, g: 255, b: 200, a: 0.55};  // Default; Light Yellow
                    }
                    this.$overlayLinkEls[linkCount].find('> span').css({'background-color': 'rgba('+rgba.r+','+rgba.g+','+rgba.b+','+rgba.a+')'});
                    
                    // Hover Tip (if any)
                    if (links[j].hovertip !== undefined && links[j].hovertip.length) {
                        this.$overlayLinkEls[linkCount]
                            .attr('data-hovertip', 'tooltip')
                            .attr('title', links[j].hovertip);
                    }
                    
                    // Track Number of Visible Links
                    linkCount++;
                }
            }
        }
        
        // Overlay Added; Resolve Promise
        resolve({'type': 'Links', 'count': linkCount});
    }, this));
};

/**
 * Override Abstract Base Method; Called when ...
 *
 * @public
 * @this Flipbook.LinksOverlay
 * @return {Object} A Promise
 */
Flipbook.LinksOverlay.prototype.remove = function() {
    return Q.Promise($.proxy(function(resolve, reject, notify) {
        var linkCount = this.getTargetPagebox().find('.overlay-link:visible').length;
        
        // Debug Message
        Flipbook.log('overlay-remove-links');
        
        // Hide Link Elements
        for (var i = 0; i < this.$overlayLinkEls.length; i++) {
            this.removeLinkElement(this.$overlayLinkEls[i]);
        }
        
        // Overlay Removed; Resolve Promise
        resolve({'type': 'Links', 'count': linkCount});
    }, this));
};

/**
 * Override Abstract Base Method; Called when ...
 *
 * @public
 * @this Flipbook.LinksOverlay
 * @return {Object} A Promise
 */
//    Flipbook.LinksOverlay.prototype.update = function() {
//        return Q.Promise($.proxy(function(resolve, reject, notify) {
//            // Resolve with Count of Visible Links
//            resolve(this.getTargetPagebox().find('.overlay-link:visible').length);
//        }, this));
//    };

/**
 * Override Abstract Base Method; Called when ...
 *
 * @public
 * @this Flipbook.LinksOverlay
 * @return {Object} A Promise
 */
Flipbook.LinksOverlay.prototype.flash = function() {
    return Q.Promise($.proxy(function(resolve, reject, notify) {
        // Find Links to Flash
        var $links = this.getTargetPagebox().find('.overlay-link:visible');
        if (!$links.length) { return; }
        
        // Debug Message
        Flipbook.log({'msg': 'overlay-flash-links', 'args': {'linkCount': $links.length}});
        
        // Flash Links in-and-out of view
        Q.delay(500)
            .then(function() { if ($links.length) { $links.addClass('active'); } })
            .then(function() { return Q.delay(Flipbook.PAGE_LINK_FLASH_DELAY); })
            .then(function() { if ($links.length) { $links.removeClass('active'); } });
        
        // Overlay Flashed; Resolve Promise
        resolve(true);
    }, this));
};


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Calculate the Size/Position of the Links based on the Size of the Page
 *
 * @public
 * @this Flipbook.LinksOverlay
 * @return undefined
 */
Flipbook.LinksOverlay.prototype.calculateSizes = function() {
    var i, j, r;
    var scaleRatio;
    var $pageData;
    var pageWidth;
    var pageDisplayWidth = this.app.config.sheet.pageSize[this.app.viewport.orientation].w;
    
    // Debug Message
    Flipbook.log('overlay-calc-size-links');
    
    // Calculate Sizes of Page Links
    for (i = 0; i < this.app.parsedPageData.length; i++) {
        $pageData = this.app.config.$issueSettings.find('pages > page[realid=' + this.app.parsedPageData[i].realId + ']');
        if (!$pageData.length) { continue; }
        
        // Get the Original Page Width
        pageWidth = (_.parseInt($pageData.attr('width'), 10) || 1);
        
        // Get the Scale Ratio for the Original Page to the Display Page
        scaleRatio = pageDisplayWidth / pageWidth;

        // Apply Scale
        for (j = 0; j < this.app.parsedPageData[i].links.length; j++) {
            r = this.app.parsedPageData[i].links[j].rect.split(',');
            
            // Set Size/Position
            this.app.parsedPageData[i].links[j].scaled = {
                'x': Math.round(parseFloat(r[0]) * scaleRatio), 
                'y': Math.round(parseFloat(r[1]) * scaleRatio), 
                'w': Math.round(parseFloat(r[2]) * scaleRatio), 
                'h': Math.round(parseFloat(r[3]) * scaleRatio),
                's': scaleRatio
            };
        }
    }
};

/**
 * 
 * 
 * @public
 * @this Flipbook.LinksOverlay
 * @return undefined
 */
Flipbook.LinksOverlay.prototype.removeLinkElement = function($linkEl) {
    if (!$linkEl.length) { return; }
    $linkEl
        .css({'display': 'none'})
        .removeAttr('style title data-hovertip data-page-idx data-link-id')
        .removeClass('active link-style-N link-style-H link-style-UR link-style-UG link-style-UB')
        .find('> span').removeAttr('style');
};

/**
 * Handles Click Event on Links
 *   
 * Link Targets:
 *   M -- iframe (desktop support only; tablets & phones open new window)
 *   J -- iframe (desktop & tablet support; phones open new window)
 *   H -- hidden (utility)
 *   W -- widget iframe (requires embed widget)
 *   B -- new window
 *   S -- same window
 *
 * @public
 * @this Flipbook.LinksOverlay
 * @return undefined
 */
Flipbook.LinksOverlay.prototype.handleLinkClick = function(e) {
    var $linkEl = $(e.currentTarget);
    var linkId = 0;
    var pageIndex = 0;
    var moveToSheet = 0;
    var links = [];
    var linkData = {};
    var params = '';
    var windowMargin = 50;
    var okButton = 'yes';
    var externalUrl = '';
    var isTablet = Flipbook.isTablet(this.app) || Flipbook.isPhone(this.app);
    var isPhone = Flipbook.isPhone(this.app);
    var isNotOpenedNewTab = true;
    
    var openLink = $.proxy(function() {
        // Check if Target Type is allowed for Mobile Devices
        var inIframe = /M|J/i.test(linkData.target);
        if (isPhone || (isTablet && /M/i.test(linkData.target))) {
            window.open(externalUrl);
            isNotOpenedNewTab = false;
            inIframe = false;
        }

        // Open in Modal Popup
        if (inIframe) {
            params = 'width:' + linkData.iframeW + 'px;height:' + linkData.iframeH + 'px;';

            // Bug #8102 was cause by this. Commenting out until further investigation
            /////////////////////////////////////////////////////////////////////////////////
            // Experimental:
            //if (Flipbook.isMobile.iOS()) {
            //    params = 'width:100%;height:100%;';
            //}
            /////////////////////////////////////////////////////////////////////////////////

            this.app.modal.content('<iframe src="' + externalUrl + '" frameborder="0" style="' + params + '">Your device does not support iframes.</iframe>', {
                'width'  : _.parseInt(linkData.iframeW, 10),
                'height' : _.parseInt(linkData.iframeH, 10)
            });
        }

        // Open in Current Window
        else {
            if (isNotOpenedNewTab) {
                document.location.href = externalUrl;
            }
        }
    }, this);

    // Prevent Clicking when Flipbook State is Animating or Shifted
    if (this.app.state.animating || this.app.state.shifted) { return true; }

    // Ensure Valid Link Element
    if (!$linkEl.length) { return; }

    // Get Link ID
    linkId = $linkEl.attr('data-link-id');
    if (linkId === undefined) { return; }

    // Get Page-Data Index
    pageIndex = $linkEl.attr('data-page-idx');
    if (pageIndex === undefined) { return; }
    pageIndex = _.parseInt(pageIndex, 10);

    // Get Link Data (if any)
    links = this.app.parsedPageData[pageIndex].links;
    if (!links.length) { return; }
    linkData = Flipbook.findById(linkId, links);
    if (linkData === undefined) { return; }

    // track click in MAP
    this.trackLinkClick(linkData);

    // Debug Message
    Flipbook.log({'msg': 'overlay-link-clicked', 'args': {'linkId': linkId}});

    // Get URL
    externalUrl = this.app.config.urls.server + 'read/clicks/F/' + linkData.id;

    // Internal Links
    if (linkData.type === 'internal') {
        // Zoom Out
        if (this.app.carousel.viewMode === Flipbook.CAROUSEL_ZOOMER) {
            this.app.carousel.resetZoom();
        }

        // Move to Sheet
        moveToSheet = Flipbook.getStartSheet(this.app, this.app.carousel.totalSheets, _.parseInt(linkData.destination, 10) );
        // Set Slide Direction x before moving to a new sheet
        this.app.carousel.setSlideDirection(moveToSheet.sheet, this.app.carousel.current.sheet);
        this.app.carousel.moveToSheet(moveToSheet.sheet, this.app.config.carousel.defaultSlideSpeed);
    }

    // External Links
    else {
        // Open the External Link
        if (/B/i.test(linkData.target)) {
            window.open(externalUrl);
        } else {
            openLink();
        }
    }
};

Flipbook.LinksOverlay.prototype.trackLinkClick = function (linkData) {
    // get link url
    var page = linkData.destination;
    if (linkData.type != "external") {
        var url = Flipbook.root.document.URL.split("/");
        url.pop();
        url.push(page);
        page = url.join("/");
    }
    // track click in Marketo
    if (Flipbook.root.mktoMunchkinFunction !== undefined) {
        Flipbook.root.mktoMunchkinFunction('clickLink', {href: page});
    }
};
