/**
 * Flyp Technologies Inc. - Flipbook v4
 *
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/tools/fullscreen.js - Flipbook.FullscreenTool
 * @author Robert J. Secord, B.Sc.
 */
import 'jquery-migrate';
import $ from 'jquery';
import Flipbook from '../core';
import Shared from '../shared_util';

/**
 * Flipbook Fullscreen Tool
 *
 * @class FullscreenTool
 * @classdesc Application Fullscreen Tool
 * @namespace Flipbook
 * @inherits Flipbook.ToolsBase
 * @param {Object} toolbar A reference to the Toolbar Control
 * @param {Object} toolData Configuration Settings for the Tool
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.FullscreenTool = function(toolbar, toolData) {
    // Call Parent Constructor
    Flipbook.ToolsBase.apply(this, [toolbar, toolData]);

    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize = null;


    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    this.$container = null;
    this.$exitFullscreenBtn = null;
    this.sheetSizePercentage = 0;
    this.fullScreenToolClicked = false;


    /* **************************************************************************************** */
    /* * Private Methods/Members Definitions                                                  * */
    /* **************************************************************************************** */
    /**
     * Initialize the Fullscreen Tool
     *
     * @private
     * @this Flipbook.FullscreenTool
     * @return {Object} A reference to the FullscreenTool Object for Method Chaining
     * @constructs
     */
    initialize = $.proxy(function() {
        // Store reference to Container Element
        this.$container = this.app.viewport.$element;

        // Store Sheet Size Percentage for Restoring Size after Fullscreen
        this.sheetSizePercentage = this.app.config.sheet.sizePercentage;

        // Build Exit Button
        this.$exitFullscreenBtn = $('<div class="toolbar-tool-exit-fullscreen"><span class="icon"></span></div>').appendTo(this.$container);
        Flipbook.onClickTap(this.app, this.$exitFullscreenBtn, this.exitFullscreen, this, 'FullscreenTool');

        if (Flipbook.isDesktop(this.app)) {
            Shared.$('window').on('fullscreenchange mozfullscreenchange webkitfullscreenchange MSFullscreenChange', $.proxy(this.changeIcons, this));
        }
        return this;
    }, this);

    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize();
};
Flipbook.FullscreenTool.prototype = new Flipbook.ToolsBase();
Flipbook.FullscreenTool.prototype.constructor = Flipbook.FullscreenTool;
// End of Flipbook.FullscreenTool


/* ******************************************************************************************** */
/* * Overridden Base Methods                                                                  * */
/* ******************************************************************************************** */

/**
 * Override Abstract Base Method; Called when the Tool is Clicked
 *
 * @public
 * @this Flipbook.FullscreenTool
 * @return undefined
 */
Flipbook.FullscreenTool.prototype.toolClicked = function(e) {
    var app = this.app;

    this.fullScreenToolClicked = true;
    // to toggle the fullscreen
    if (Flipbook.isDesktop(app) && app.viewport.fullscreen === true) {
        this.exitFullscreen();
        return;
    }

    // Set Fullscreen Flag and Sizing
    app.viewport.fullscreen = true;
    app.config.sheet.sizePercentage = 1;

    // Mark Flipbook as Fullscreen
    this.$container.addClass(this.toolOptions.stateClass);

    if (Flipbook.isDesktop(app)) {

        // Toggle Browser Fullscreen Mode (Works, but doesn't look so good)
        Shared.enterFullscreen();

        $(".fullscreen-tool").children("div.icon").children("span:first").css("background-position", "-192px -24px");

        Shared.onNextEventLoop(function() {

            // Resize Elements
            app.resizeElements();

            // Update Stats
            app.stats.resize();

        }, this, 1000);
    }
    else {
        // Hide Toolbar
        app.carousel.hideToolbar();

        // Resize Elements
        app.resizeElements();

        // Update Stats
        app.stats.resize();
    }
};


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 *
 * @public
 * @this Flipbook.FullscreenTool
 * @return undefined
 */
Flipbook.FullscreenTool.prototype.exitFullscreen = function(e) {
    var app = this.app;

    // Set Fullscreen Flag and Sizing
    app.viewport.fullscreen = false;
    app.config.sheet.sizePercentage = this.sheetSizePercentage;

    // Mark Flipbook as Not Fullscreen
    this.$container.removeClass(this.toolOptions.stateClass);

    // Toggle Browser Fullscreen Mode
    if (Flipbook.isDesktop(app)) {

        Shared.exitFullscreen();

        $(".fullscreen-tool").children("div.icon").children("span:first").css("background-position", "-168px -24px");

        Shared.onNextEventLoop(function() {
            // Resize Elements
            app.resizeElements();

            // Update Stats
            app.stats.resize();

        }, this, 1000);
    }
    else {
        // Show Toolbar
        app.carousel.showToolbar();

        // Resize Elements
        app.resizeElements();

        // Update Stats
        app.stats.resize();
    }
};

Flipbook.FullscreenTool.prototype.changeIcons = function() {
    //only if fullscreen exit is triggered by keyboard "Esc" key or browser "Exit Full Screen" link
    if (this.fullScreenToolClicked === false  && !(document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen)) {
        $(".fullscreen-tool").children("div.icon").children("span:first").css("background-position", "-168px -24px");
        this.app.config.sheet.sizePercentage = this.sheetSizePercentage;
        this.app.viewport.fullscreen = false;
        this.$container.removeClass(this.toolOptions.stateClass);
    }

    this.fullScreenToolClicked = false;
};
