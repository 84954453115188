/**
 * Flyp Technologies Inc. - Flipbook v4
 * 
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/overlayer.js - Flipbook.Overlayer
 * @author Robert J. Secord, B.Sc.
 */
import 'jquery-migrate';
import $ from 'jquery';
import Flipbook from './core';
import Shared from './shared_util';
import Q from '/app/libs/promise/q';

/**
 * Flipbook Overlayer
 *
 * @class Overlayer
 * @classdesc Application Overlayer Controller
 * @namespace Flipbook
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.Overlayer = function(app, $viewport, $carousel) {

    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize       = null;
    var buildElements    = null;
    var attachEvents     = null;
    var loadOverlays     = null;

    
    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    this.app       = null;
    this.$viewport = {'container': null, 'overlay': null, 'pagebox': null};
    this.$carousel = {'container': null, 'overlay': null, 'pagebox': null};
    
    // Overlays Array
    this.overlays = [];
    

    /* **************************************************************************************** */
    /* * Private Methods/Members                                                              * */
    /* **************************************************************************************** */
    /**
     * Initialize the Overlayer
     *
     * @private
     * @this Flipbook.Overlayer
     * @return {Object} A reference to the Overlayer Object for Method Chaining
     * @constructs
     */
    initialize = $.proxy(function() {
        // Debug Message
        Flipbook.log('overlayer-init');
        
        // Store Reference to App Object
        this.app = app;
        
        // Store Handles to DOM Elements
        this.$viewport.container = $viewport;
        this.$carousel.container = $carousel;
        
        // Build the Overlayer Elements
        buildElements();
        attachEvents();
        
        // Initialize Overlay Objects
        loadOverlays();
    }, this);
    
    /**
     * Build the Overlayer Elements
     *
     * @private
     * @this Flipbook.Overlayer
     * @return undefined
     */
    buildElements = $.proxy(function() {
        // Debug Message
        Flipbook.log('overlayer-build-elements');
        
        // Build Overlay Containers
        this.$viewport.overlay = $('<div class="viewport-overlay-container"/>').appendTo(this.$viewport.container);
        this.$carousel.overlay = $('<div class="carousel-overlay-container"/>').appendTo(this.$carousel.container);
        
        // Build Overlay Pagebox Elements
        this.$viewport.pagebox = $('<div class="overlay-page-box"/>').appendTo(this.$viewport.overlay);
        this.$carousel.pagebox = $('<div class="overlay-page-box"/>').appendTo(this.$carousel.overlay);

    }, this);

    /**
     * Attach Events to the Overlayer Elements
     *   
     * @private
     * @this Flipbook.Overlayer
     * @return undefined
     */
    attachEvents = $.proxy(function() {
        var ns = '.Flipbook.Overlayer';
        
        // Debug Message
        Flipbook.log('overlayer-attach-events');

        // Hook Document Fullscreen Event for Preventing Flipbook from Resizing when a Video or Widget is Toggled into Fullscreen or back
        Shared.$('document').on('fullscreenchange'+ns+' mozfullscreenchange'+ns+' webkitfullscreenchange'+ns+' MSFullscreenChange'+ns, Flipbook.preventResize);
    }, this);

    /**
     * Initializes the Individual Overlay Objects
     *   
     * @private
     * @this Flipbook.Overlayer
     * @return undefined
     */
    loadOverlays = $.proxy(function() {
        // Debug Message
        Flipbook.log('overlayer-load-all');
        
        // Profiler
        Shared.Profiler.start('Flipbook:Overlayer->loadOverlays');
        
        // Find All Tools
        $.each(this.app.config.overlayer.overlays, $.proxy(function(overlayName, overlayOptions) {
            var overlay = null;
            
            // Check if Overlay is Enabled
            if (!Flipbook.isComponentEnabled(this.app, overlayOptions.enabled)) { return; }
            
            // Check if Overlay Exists
            if (Flipbook[overlayName] === undefined) {
                Flipbook.log({'msg': 'overlayer-load-failed', 'args': {'overlay': overlayName}, 'type': Shared.LVL_ERROR});
                return;
            }
            
            // Set Overlay Name
            overlayOptions.name = overlayName;
            
            // Create New Overlay
            overlay = new Flipbook[overlayName](this, overlayOptions);
            
            // Add Overlay to Array
            this.overlays.push(overlay);
        }, this));
        
        // Profiler
        Shared.Profiler.end('Flipbook:Overlayer->loadOverlays');
    }, this);


    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize();
};
// End of Flipbook.Overlayer


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * 
 * 
 * @public
 * @this Flipbook.Overlayer
 * @return {Object} A Promise
 */
Flipbook.Overlayer.prototype.updateAllOverlays = function(prevSheet) {
    var promise = Q(true);
    
    // Debug Message
    Flipbook.log('overlayer-update-all');
    
    // Remove Previous Overlay Elements
    if (prevSheet !== undefined) { 
        promise = this.remove();
    }
    promise
        // Add Overlay Elements to Current Sheet
        .then($.proxy(function(results) {
            this.resultsCountDebugMsg('Removed', results);
            return this.add();
        }, this))
        
        // Update Overlay Elements
        .then($.proxy(function(results) {
            this.resultsCountDebugMsg('Added', results);
            return this.update();
        }, this))
        
        // Flash Overlay Elements
        .then($.proxy(function(results) {
            this.resultsCountDebugMsg('Updated', results);
            if (prevSheet !== undefined) {
                return this.flash();
            }
        }, this));
    
    return promise;
};

/**
 * 
 * 
 * @public
 * @this Flipbook.Overlayer
 * @return undefined
 */
Flipbook.Overlayer.prototype.sheetChanged = function(prevSheet) {
    if (this.app.carousel.viewMode !== Flipbook.CAROUSEL_SLIDER) { return; }
    
    // Debug Message
    Flipbook.log('overlayer-sheet-changed');
    
    // Reposition Overlay Container
    this.$carousel.overlay.css({'left': this.app.carousel.sheetPositions[this.app.carousel.current.sheet][this.app.carousel.viewMode]});
    
    // Update All Overlays
    return this.updateAllOverlays(prevSheet);
};

/**
 * Resize/Reorient the Overlayer Elements and Overlay Objects
 *      
 * @public
 * @this Flipbook.Overlayer
 * @return {Object} A Promise
 */
Flipbook.Overlayer.prototype.resize = function() {
    var promises = [];
    var toolbarClosedSize = 0;
    
    // Debug Message
    Flipbook.log('overlayer-resize');
    
    // Get Size of Toolbar
    if (this.app.config.toolbar.enabled && !Flipbook.isFullscreen(this.app)) {
        toolbarClosedSize = this.app.config.toolbar.size[this.app.viewport.breakpoint][this.app.config.toolbar.position].closed;
    }
    
    // Update Size of Overlay Container
    this.$viewport.overlay.css(this.app.carousel.containerSize);
    this.$carousel.overlay.css(this.app.carousel.containerSize);
    
    // Reposition Overlay Container
    this.$carousel.overlay.css({'left': this.app.carousel.sheetPositions[this.app.carousel.current.sheet][this.app.carousel.viewMode]});
    
    // Reposition Viewport Container
    if (this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_TOP) {
        this.$viewport.overlay.css({'top': toolbarClosedSize});
    } else {
        this.$viewport.overlay.css({'left': toolbarClosedSize});
    }
    
    // Update Size of Page Box
    Flipbook.resizePageBox(this.app, this.$viewport.pagebox);
    Flipbook.resizePageBox(this.app, this.$carousel.pagebox);
    
    // Update View Mode of Overlays
    this.toggleViewMode();
    
    // Resize All Overlays
    $.each(this.overlays, function(idx, overlay) {
        promises.push(overlay.resize());
    });
    
    // Update All Overlays
    promises.push(this.updateAllOverlays(true));
    
    // After all Promises have Resolved
    return Q.allSettled(promises);
};

/**
 * 
 *      
 * @public
 * @this Flipbook.Overlayer
 * @return {Object} A Promise
 */
Flipbook.Overlayer.prototype.add = function() {
    var promises = [];
    
    // Debug Message
    Flipbook.log('overlayer-add');
    
    // Resize All Overlays
    $.each(this.overlays, function(idx, overlay) {
        promises.push(overlay.add());
    });
    
    // After all Promises have Resolved
    return Q.allSettled(promises);
};

/**
 * 
 *      
 * @public
 * @this Flipbook.Overlayer
 * @return {Object} A Promise
 */
Flipbook.Overlayer.prototype.remove = function() {
    var promises = [];
    
    // Debug Message
    Flipbook.log('overlayer-remove');
    
    // Resize All Overlays
    $.each(this.overlays, function(idx, overlay) {
        promises.push(overlay.remove());
    });
    
    // After all Promises have Resolved
    return Q.allSettled(promises);
};

/**
 * 
 *      
 * @public
 * @this Flipbook.Overlayer
 * @return {Object} A Promise
 */
Flipbook.Overlayer.prototype.update = function() {
    var promises = [];
    
    // Debug Message
    Flipbook.log('overlayer-update');
    
    // Resize All Overlays
    $.each(this.overlays, function(idx, overlay) {
        promises.push(overlay.update());
    });
    
    // After all Promises have Resolved
    return Q.allSettled(promises);
};

/**
 * 
 *      
 * @public
 * @this Flipbook.Overlayer
 * @return {Object} A Promise
 */
Flipbook.Overlayer.prototype.flash = function() {
    var promises = [];
    
    // Debug Message
    Flipbook.log('overlayer-flash');
    
    // Resize All Overlays
    $.each(this.overlays, function(idx, overlay) {
        promises.push(overlay.flash());
    });
    
    // After all Promises have Resolved
    return Q.allSettled(promises);
};

/**
 * 
 *      
 * @public
 * @this Flipbook.Overlayer
 * @return {Object} A reference to the Overlayer Object for Method Chaining
 */
Flipbook.Overlayer.prototype.toggleViewMode = function() {
    // Debug Message
    Flipbook.log('overlayer-toggle-view');
    
    // Hide Overlays
    if (this.app.carousel.viewMode === Flipbook.CAROUSEL_SCRUBBER) {
        this.$viewport.overlay.hide();
        this.$carousel.overlay.hide();
    }
    
    // Show Carousel Overlays Only
    else if (this.app.carousel.viewMode === Flipbook.CAROUSEL_ZOOMER) {
        this.$viewport.overlay.hide();
        this.$carousel.overlay.show();
    }
    
    // Show Both Overlays
    else {
        this.$viewport.overlay.show();
        this.$carousel.overlay.show();
    }
    
    // Toggle View Mode of All Overlays
    $.each(this.overlays, function(idx, overlay) {
        overlay.toggleViewMode();
    });
};

/**
 * 
 *      
 * @private
 * @this Flipbook.Overlayer
 * @return {Object} A reference to the Overlayer Object for Method Chaining
 */
Flipbook.Overlayer.prototype.resultsCountDebugMsg = function(type, results) {
    var msg = [];
    if (!_.isArray(results)) { return; }
    
    _.forEach(_.map(results, 'value'), function(obj) { 
        if (obj.count > 0) {
            msg.push(obj.type + ': ' + obj.count);
        }
    });
    
    msg = msg.join(', ');
    if (!msg.length) { return; }
    
    // Debug Message
    Flipbook.log({'msg': 'overlayer-count-msg', 'args': {'type': type, 'msg': msg}});
};
