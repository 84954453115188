/**
 * Flyp Technologies Inc. - Flipbook v4
 * 
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/sheet.js - Flipbook.Sheet
 * @author Robert J. Secord, B.Sc.
 */

import Flipbook from './core';

/**
 * Flipbook Carousel-Sheet
 *
 * @class Sheet
 * @classdesc Application Carousel-Sheet Controller
 * @namespace Flipbook
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.Sheet = function(carousel, $container, sheetOptions) {

    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize    = null;
    var buildElements = null;


    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    this.app            = carousel.app;
    this.carousel       = carousel;
    this.$container     = $container;
    this.$sheet         = null;
    this.$pageBox       = null;
    this.$page          = [];
    this.$image         = [];
    this.$imagePartials = [];
    this.imageUrl       = [];
    this.pageIndices    = [];
    this.loadedParts    = [];
    this.position       = null;
    this.quality        = Flipbook.IMG_QUALITY_NONE;
    

    /* **************************************************************************************** */
    /* * Private Methods/Members Definitions                                                  * */
    /* **************************************************************************************** */
    /**
     * Initialize the Carousel-Sheet
     *
     * @private
     * @this Flipbook.Sheet
     * @return undefined
     * @constructs
     */
    initialize = $.proxy(function() {
        // Debug Message
        //Flipbook.log('sheet-init');
        
        // Build the Carousel-Sheet Elements
        buildElements();
        return this;
    }, this);

    /**
     * Build the Carousel-Sheet Elements
     *
     * @private
     * @this Flipbook.Sheet
     * @return undefined
     */
    buildElements = $.proxy(function() {
        var left = this.carousel.containerSize.width * sheetOptions.arrayIndex;
        
        // Debug Message
        //Flipbook.log('sheet-build-elements');

        // Build Carousel-Sheet Element
        this.$sheet = $('<div class="carousel-sheet"/>').appendTo(this.$container);
        this.$sheet.css({'display': 'none', 'left': left});
        this.$sheet.attr('id', 'sheet' + sheetOptions.arrayIndex);
        this.$sheet.data('sheetIndex', sheetOptions.arrayIndex);
        
        // Build Sheet Page Elements
        this.$pageBox = $('<div class="page-box"/>').appendTo(this.$sheet);
        this.$page.push($('<div class="page"/>').attr('id', 'sheet' + sheetOptions.arrayIndex + '-page1').appendTo(this.$pageBox));
        this.$page.push($('<div class="page"/>').attr('id', 'sheet' + sheetOptions.arrayIndex + '-page2').appendTo(this.$pageBox));
        
        // Build Page Image Elements
        this.$image.push($('<div class="page-image"/>').appendTo(this.$page[0]));
        this.$image.push($('<div class="page-image"/>').appendTo(this.$page[1]));
        this.$imagePartials.push($('<div class="page-image-partials"/>').appendTo(this.$image[0]));
        this.$imagePartials.push($('<div class="page-image-partials"/>').appendTo(this.$image[1]));
    }, this);


    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize();
};
// End of Flipbook.Sheet


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Resize the Sheet Elements
 *   Sheet Blocks should always be the full size of the Viewport
 *   Pagebox Blocks should fit the Page(s) exactly
 *      
 * @public
 * @this Flipbook.Sheet
 * @return {Object} A reference to the Sheet Object for Method Chaining
 */
Flipbook.Sheet.prototype.resize = function() {
    // Debug Message
    //Flipbook.log('sheet-resize');
    
    var pageMargin = this.app.config.sheet.pageSize[this.app.viewport.orientation].m;
    // Resize Carousel-Sheet Element
    this.$sheet.width(this.carousel.containerSize.width).height(this.carousel.containerSize.height + pageMargin);

    // Update Size of Page Box
    Flipbook.resizePageBox(this.app, this.$pageBox, '.page-image');
    return this;
};

/**
 * Load the Sheet Contents
 *    1 Page or 2
 *    
 * @public
 * @this Flipbook.Sheet
 * @param {Array} pages An array of Page Numbers to Load within the Sheet
 * @param {Boolean} focused True if the Sheet is Currently in View
 * @return {Object} A reference to the Sheet Object for Method Chaining
 */
Flipbook.Sheet.prototype.load = function(pages, focused) {
    var i, imgWidth, lastQualityLoaded = this.quality;
    
    // Debug Message
    Flipbook.log({'msg': 'sheet-load', 'args': {'sheet': this.$sheet.data('sheetIndex')}});
    
    // Store Pages in Sheet
    this.pageIndices = pages.slice();
    
    // Get Image Width
    if (this.carousel.viewMode === Flipbook.CAROUSEL_SCRUBBER) {
        // Load Low-Quality Images for Scrubber
        if (this.app.config.device.isOffline) {
            imgWidth = '-0.jpg';
        } else {
            imgWidth = this.app.config.sheet.pageSize.scaled.min[Flipbook.getPageOrientation(this.app)].i;
        }
        this.quality = Flipbook.IMG_QUALITY_LOW;
    } else {
        // Load Standard-Quality Images for Slider
        if (this.app.config.device.isOffline) {
            if(     (window.innerWidth > window.innerHeight && window.innerWidth > 750) ||
                    (window.innerHeight > window.innerWidth && window.innerHeight > 750)) {
                        imgWidth = '-large.jpg';
            } else {
                imgWidth = '-small.jpg';
            }
            this.quality = Flipbook.IMG_QUALITY_HIGH;
        } else {
            imgWidth = this.app.config.sheet.pageSize[Flipbook.getPageOrientation(this.app)].i;
            this.quality = Flipbook.IMG_QUALITY_DEFAULT;
        }
    }
    
    // Reset Pages
    this.$sheet.css({'display': 'block'});
    this.$page[0].css({'float': 'left'});
    this.$page[1].css({'display': 'none'});
    
    // Landscape Mode & Two-Page-Spread Mode
    if (this.app.viewport.orientation === Flipbook.ORIENT_LANDSCAPE && !this.app.config.titleData.single_page_view) {
        // Not Always Open (1 Page on First Sheet)
        if (!this.app.config.titleData.always_open && pages[0] === 0) {
            this.$page[0].css({'float': 'right'});
        }
        
        // Build HTML for Second Page in Two-Page-Spread Mode 
        if (pages.length === 2) {
            this.$page[1].css({'display': 'block'});
        }
    }
    
    // Get Page Image URLs
    this.imageUrl.length = 0;
    for (i = 0; i < pages.length; i++) {
        this.imageUrl.push(this.app.parsedPageData[ pages[i] ].thumb);
    }
    
    // Check if Sheet is Focused and if we Load Default Quality Image over-top of a Low Quality Image
    if (focused && lastQualityLoaded === Flipbook.IMG_QUALITY_LOW && this.quality === Flipbook.IMG_QUALITY_DEFAULT) {
        // Preload Images within Pages
        this.preloadImage(imgWidth);
    } else {
        // Update Images within Pages
        for (i = 0; i < pages.length; i++) {
            this.$image[i].css({'background-image': 'url("' + this.imageUrl[i] + imgWidth + '")'});
        }
    }
    
    return this;
};

/**
 * Preloads the Default Quality Image behind the Low Quality Image for a 
 *  Smooth visual transition from the Low Quality Image to the Default Quality Image.
 *  This is only performed when focused on a Low Quality Image in Scrubber Mode and then
 *  Zoomed-In to a Default Quality Image in Slider Mode.
 *      
 * @public
 * @this Flipbook.Sheet
 * @return undefined
 */
Flipbook.Sheet.prototype.preloadImage = function(imgWidth) {
    var i;
    var imgFragment; 
    var docFragment = document.createDocumentFragment();
    var hideOverlay = function() {
        this.css({'display': 'none', 'background-image': 'none', 'width': 0, 'height': 0});
    };
    
    // Iterate over all Page Images in Sheet
    for (i = 0; i < this.pageIndices.length; i++) {
        
        // Add IMG Tags to Off-Screen Document Fragment for Preloading 
        imgFragment = document.createElement('img');
        imgFragment.src = this.imageUrl[i] + imgWidth;
        docFragment.appendChild(imgFragment);
        
        // Update Partials-Container with LQ Image
        this.$imagePartials[i].css({
            'display': 'block',
            'opacity': 1,
            'width': this.$image[i].width(), 
            'height': this.$image[i].height(),
            'background-image': this.$image[i].css('background-image')
        });
        
        // Update Main Image with Default Quality Image (Behind Overlay)
        this.$image[i].css({'background-image': 'url("' + this.imageUrl[i] + imgWidth + '")'});
    }
    
    // Add Fragments to DOM
    this.app.$offscreenFrag[0].appendChild(docFragment);
    
    // Wait for Images to Load
    imagesLoaded(this.app.$offscreenFrag).on('done', $.proxy(function(instance) {
        
        // Fade Out Overlay to Reveal Default Quality Image behind Partials-Container
        for (i = 0; i < this.pageIndices.length; i++) {
            this.$imagePartials[i].transition({'opacity': 0}, 400, hideOverlay);
        }
        
        // Empty Off-Screen Document Fragment (Preloading Complete)
        this.app.$offscreenFrag.empty();
    }, this));
};

/**
 * Clears the Background Images from a Sheet to Clear Up Memory
 *      
 * @public
 * @this Flipbook.Sheet
 * @return {Object} A reference to the Sheet Object for Method Chaining
 */
Flipbook.Sheet.prototype.clearSheet = function() {
    if (this.quality === Flipbook.IMG_QUALITY_NONE) { return this; }
    
    // Debug Message
    Flipbook.log({'msg': 'sheet-unload', 'args': {'sheet': this.$sheet.data('sheetIndex')}});
    
    // Remove Background Images
    this.$image[0].css({'background-image': 'none'});
    this.$image[1].css({'background-image': 'none'});
    
    // Hide Sheet
    this.$sheet.css({'display': 'none'});
    
    // Reset Quality and Indices
    this.quality = Flipbook.IMG_QUALITY_NONE;
    this.pageIndices.length = 0;
    
    return this;
};

/**
 * 
 *      
 * @public
 * @this Flipbook.Sheet
 * @return {Object} A reference to the Sheet Object for Method Chaining
 */
Flipbook.Sheet.prototype.toggleFullscreen = function(fullscreen, width, height) {
    var i = 0, sheet = 0;
    var total = this.carousel.totalSheets[this.app.viewport.orientation];
    var bufferSize = 0;
    if ((this.carousel.sliderBufferSize[this.app.viewport.orientation] % 2) === 0) {
        bufferSize = this.carousel.sliderBufferSize[this.app.viewport.orientation] / 2;
    } else {
        bufferSize = (this.carousel.sliderBufferSize[this.app.viewport.orientation] - 1) / 2;
    }
    
    if (fullscreen === undefined) { fullscreen = true; }
    if (width === undefined) { width = this.app.viewport.width; }
    if (height === undefined) { height = this.app.viewport.height; }
    
    if (fullscreen) {
        // Make Sheet the Full size of the Screen
        this.$sheet.css({'width': width, 'height': height, 'zIndex': 50});
        
        // Hide Surrounding Sheets
        for (i = -bufferSize; i <= bufferSize; i++) {
            sheet = this.carousel.current.sheet + i;
            if (sheet >= 0 && sheet < total && i !== 0) {
                this.carousel.sheets[sheet].$sheet.css({'display': 'none'});
            }
        }
    } else {
        // Make Sheet the Full size of the Screen
        this.$sheet.css({'width': width, 'height': height, 'zIndex': 1});
        
        // Show Surrounding Sheets
        for (i = -bufferSize; i <= bufferSize; i++) {
            sheet = this.carousel.current.sheet + i;
            if (sheet >= 0 && sheet < total && i !== 0) {
                this.carousel.sheets[sheet].$sheet.css({'display': 'block'});
            }
        }
    }
    
    return this;
};

/**
 * Prepares the HQ Image Display by Loading the Image Overlay Div.  
 *  Lazy-Loaded Image Parts are appended to the Image Overlay Div.
 *      
 * @public
 * @this Flipbook.Sheet
 * @return {Object} A reference to the Sheet Object for Method Chaining
 */ 
Flipbook.Sheet.prototype.displayLargeImage = function() {
    var i, j, w, h, r, g, b,
        gridSize = this.app.config.sheet.gridSize,
        totalParts = gridSize * gridSize;
    
    // Store Current Quality
    if (this.quality === Flipbook.IMG_QUALITY_HIGH) { return this; }
    this.quality = Flipbook.IMG_QUALITY_HIGH;
    
    // Debug Message
    Flipbook.log('sheet-display-large');
    
    // Load HQ Image Overlay
    // Calculate Size
    w = this.$image[0].width() * this.carousel.zoom.scale;
    h = this.$image[0].height() * this.carousel.zoom.scale;
    
    // Reset Loaded Image Parts
    this.resetLoadedImageParts();
    
    // Display Partials-Container for Holding Image Parts
    for (i = 0; i < this.pageIndices.length; i++) {
        this.$imagePartials[i].css({
            'display': 'block',
            'opacity': 1,
            'scale' : (1 / this.carousel.zoom.scale),
            'width': w, 'height': h
        });
    }
    return this;
};

/**
 * Updates the Large HQ Image by Lazy-Loading sections of the HQ image that are in View.
 *      
 * @public
 * @this Flipbook.Sheet
 * @return {Object} A reference to the Sheet Object for Method Chaining
 */ 
Flipbook.Sheet.prototype.updateLargeImage = function() {
    var i, j;
    var sheetRect    = {'x': 0, 'y': 0, 'w': 0, 'h': 0};
    var viewportRect = {'x': 0, 'y': 0, 'w': this.app.viewport.width, 'h': this.app.viewport.height};
    var pageOrientation = Flipbook.getPageOrientation(this.app);
    var pageWidth = this.app.config.sheet.pageSize[pageOrientation].w * this.carousel.zoom.scale;
    var translateX = (this.carousel.zoom.translate.x + (this.carousel.page.offset.x / this.carousel.zoom.scale)) * this.carousel.zoom.scale;
    var translateY = (this.carousel.zoom.translate.y + (this.carousel.page.offset.y / this.carousel.zoom.scale)) * this.carousel.zoom.scale;
    var gridSize = this.app.config.sheet.gridSize;
    var totalParts = gridSize * gridSize;
    var firstPageRightSide = false;
    var inBounds = false;
    
    // Debug Message
    Flipbook.log('sheet-update-large');
    
    // First Page on Right Side of Two-Page-Spread
    firstPageRightSide = (this.pageIndices[0] === 0 && Flipbook.isFirstPageOnRight(this.app));
    
    // Iterate each Page in Sheet
    for (i = 0; i < this.pageIndices.length; i++) {
        
        // Lazy Load HQ Image Parts
        for (j = 0; j < totalParts; j++) {
            // Skip parts that are already loaded
            if (this.loadedParts[i][j].loaded) { continue; }
            inBounds = false;
            
            // Determine Accurate Location of Image Part
            sheetRect.x = Math.round((this.app.config.sheet.pageSize.scaled.max.parts[j].x / this.app.config.sheet.maxScale[pageOrientation]) * this.carousel.zoom.scale) + translateX;
            sheetRect.y = Math.round((this.app.config.sheet.pageSize.scaled.max.parts[j].y / this.app.config.sheet.maxScale[pageOrientation]) * this.carousel.zoom.scale) + translateY;
            sheetRect.w = Math.round((this.app.config.sheet.pageSize.scaled.max.parts[j].w / this.app.config.sheet.maxScale[pageOrientation]) * this.carousel.zoom.scale);
            sheetRect.h = Math.round((this.app.config.sheet.pageSize.scaled.max.parts[j].h / this.app.config.sheet.maxScale[pageOrientation]) * this.carousel.zoom.scale);

            // Account for Page on Right Side
            //  When a page is on the right side of a two-page spread, 
            //  we must account for the width of the left side page to
            //  accurately determine the image part's position in relation
            //  to the sheet.  However, when placing the image part in the 
            //  imageOverlay element, the absolute position is relative to
            //  its parent Page, not the Sheet.
            if (firstPageRightSide || i > 0) {
                sheetRect.x += pageWidth;
            }

            // Check Bounds of Image Part
            inBounds = Flipbook.rectIntersect(sheetRect, viewportRect);
            if (inBounds) {
                // Account for Page on Right Side
                //  Absolute position of Image Part is in relation to
                //  the Page, not the Sheet.  The first image part is 
                //  at absolute position 0,0.  Even for the right side page.
                if (firstPageRightSide || i > 0) {
                    sheetRect.x -= pageWidth;
                }

                // Display HQ Image Parts
                $('<div class="page-image-part" />').css({
                    'top'    : sheetRect.y - translateY, 
                    'left'   : sheetRect.x - translateX, 
                    'width'  : sheetRect.w,  
                    'height' : sheetRect.h, 
                    'background-image' : 'url("' + this.imageUrl[i] + this.app.config.sheet.pageSize.scaled.max.parts[j].m + '")'
                }).appendTo(this.$imagePartials[i]);
                
                // Mark Image Part as Loaded
                this.loadedParts[i][j].loaded = true;
            }
        }
    }
    return this;
};

/**
 * Clears the HQ Image Display by Emptying the Image Overlay Div of all Image Parts.  
 *      
 * @public
 * @this Flipbook.Sheet
 * @return {Object} A reference to the Sheet Object for Method Chaining
 */ 
Flipbook.Sheet.prototype.clearLargeImage = function() {
    var i;
    
    // Debug Message
    Flipbook.log('sheet-clear-large');
    
    // Empty Image Partials-Container of Image Parts and Hide Partials-Container
    for (i = 0; i < this.pageIndices.length; i++) {
        this.$imagePartials[i].empty().css({'display': 'none', 'background-image': 'none', 'width': 0, 'height': 0, 'scale': 1});
    }
    
    // Reset Current Quality to Default
    this.quality = Flipbook.IMG_QUALITY_DEFAULT;
    return this;
};

/**
 * Resets the Array that tracks the Loaded Image Parts for a Zoomed-In Sheet.
 *      
 * @public
 * @this Flipbook.Sheet
 * @return {Object} A reference to the Sheet Object for Method Chaining
 */ 
Flipbook.Sheet.prototype.resetLoadedImageParts = function() {
    var i, j,
        gridSize = this.app.config.sheet.gridSize,
        totalParts = gridSize * gridSize;

    // Debug Message
    Flipbook.log('sheet-reset-loaded-parts');
    
    // Empty Arrays
    for (i = 0; i < this.loadedParts.length; i++) {
        this.loadedParts[i].length = 0;
    }
    this.loadedParts.length = 0;
    
    // Reset Arrays to False
    for (i = 0; i < this.pageIndices.length; i++) {
        this.loadedParts.push([]);
        for (j = 0; j < totalParts; j++) {
            this.loadedParts[i].push({'loaded': false});
        }
    }
    
    return this;
};

/**
 * Nudges the Position of a Sheet by a specified amount
 *   Allows squeezing sheets closer together in Scrubber Mode
 *      
 * @public
 * @this Flipbook.Sheet
 * @param {Integer} amount The Amount of Pixels to Nudge the Element (may be positive or negative)
 * @param {Integer} (speed) Optional.  The speed of the animation for the Nudge.  Defaults to 0, no animation.
 * @return {Object} A reference to the Sheet Object for Method Chaining
 */
Flipbook.Sheet.prototype.nudge = function(amount, speed) {
    var pos = parseInt(this.$sheet.css('left') || 0, 10);
    if (speed === undefined) { speed = 0; }
    this.$sheet.transition({'left': pos + amount}, speed);
    return this;
};

/**
 * Updates the Position of a Sheet
 *   If afterResize, position needs to be recalculated.
 *      
 * @public
 * @this Flipbook.Sheet
 * @param {Boolean} (afterResize) Optional.  Whether or not to recalculate position before applying position.
 * @return {Object} A reference to the Sheet Object for Method Chaining
 */
Flipbook.Sheet.prototype.reposition = function(afterResize) {
    if (afterResize === undefined) { afterResize = false; }
    
    // Reposition the Carousel-Sheet Element
    if (afterResize || this.position === null) {
        this.position = this.carousel.sheetPositions[ this.$sheet.data('sheetIndex') ][this.carousel.viewMode];
    }
    this.$sheet.css({'left': this.position});
    return this;
};

/**
 * Gets the Current Image Quality loaded in the Sheet (if any)
 *      
 * @public
 * @this Flipbook.Sheet
 * @return {String} The Quality of the Loaded Images (if any)
 */
Flipbook.Sheet.prototype.imgQuality = function() {
    return this.quality;
};
