/**
 * Flyp Technologies Inc. - Flipbook v4
 *
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/util.js - Flipbook.Utilities
 * @author Robert J. Secord, B.Sc.
 */
import './index_libs';
import 'jquery-migrate';
import $ from 'jquery';
import Flipbook from './core';
import Q from '/app/libs/promise/q';
import Shared from './shared_util';

/**
 * Flipbook Template Cache
 *   - Keep templates in a cache to prevent excessive DOM reads
 *    
 * @public
 * @static
 */
Flipbook.TemplateCache = {};

/**
 * Flipbook Last XHR
 *   - Request Identifier for the last AJAX request.  This is used to cancel if neccessary.
 * 
 * @public
 * @static
 */
Flipbook.LastXHR = null;

/**
 * Flipbook Resize Flag to Prevent Resizing
 *   - used when Videos/Widgets go fullscreen
 * 
 * @public
 * @static
 */
Flipbook.PREVENT_RESIZE = false;

/**
 * Flipbook Flag to make sure it resizes after exiting full screen
 *   - It gets set to true when Videos/Widgets go fullscreen and the orientation changes.
 *     In those cases we don't want to resize right away, otherwise the widget closes, but we want to resize
 *     as soon as we exit full screen.
 *
 * @public
 * @static
 */
Flipbook.NEEDS_RESIZING = false;

/* ******************************************************************************************** */
/* * Viewport Related Methods                                                                 * */
/* ******************************************************************************************** */


/**
 * Gets a Handle to the Viewport Element
 *   Could be an HTML Element or the Document Body.
 *   When it is an Element, Flipbook is Embedded.
 *   We also append the Root CSS Classname to the Element for Namespacing the CSS
 *
 * @public
 * @this local
 * @return undefined
 */
//    Flipbook.getViewport = function(app) {
//        if (app.config.container.length) {
//            app.viewport.$element = $(app.config.container);
//        }
//        if (!app.viewport.$element || !app.viewport.$element.length) {
//            app.viewport.$element = Shared.$('body');
//        }
//        // Add Class to Container to Namespace the CSS
//        app.viewport.$element.addClass(app.config.cssNamespace);
//    };

/**
 * Gets the Measurements of the Viewport
 *   Also Determines Current Orientation and Breakpoint.
 *   Accounts for Height Issues in iOS 7 Safari. 
 *
 * @public
 * @this local
 * @return undefined
 */
Flipbook.measureViewport = function(app) {
    var i, n, bps = app.config.breakpoints;
    
    // Debug Message
    Flipbook.log('util-measure-viewport');
    
    // Get Size of Viewport
    //if (!Flipbook.isEmbedded(app)) {
    //    app.viewport.$element.css({'width': '100%', 'height': '100%'});
    //}
    app.viewport.width = app.viewport.$element.width();
    app.viewport.height = app.viewport.$element.height();
    
    if (app.viewport.oldWidth === 0) { app.viewport.oldWidth = app.viewport.width; }
    if (app.viewport.oldHeight === 0) { app.viewport.oldHeight = app.viewport.height; }
    
    // Get Orientation of Viewport
    app.viewport.orientation = Flipbook.ORIENT_LANDSCAPE;
    if (app.viewport.height > app.viewport.width) {
        app.viewport.orientation = Flipbook.ORIENT_PORTRAIT;
    }
    
    // Fix height for iOS 7 Safari
    if (Flipbook.isMobile.iOS7() && !Flipbook.isMobile.CriOS() && !Flipbook.isStandalone() && app.viewport.orientation === Flipbook.ORIENT_LANDSCAPE) {
        app.viewport.height -= 19;
    }
    
    // Update Size of Viewport to a Fixed Size
    //app.viewport.$element.css({'width': app.viewport.width, 'height': app.viewport.height});
    
    // Determine Current Breakpoint
    for (i = 0, n = bps.length; i < n; i++) {
        if (app.viewport.width >= bps[i].enter && app.viewport.width <= bps[i].exit) {
            app.viewport.breakpoint = bps[i].label;
            break;
        }
    }
};

/**
 * Confirms if the Viewport was actually resized, or if a bogus event was fired.
 * 
 * @public
 * @this local
 * @return {boolean} Whether the Viewport did resize or not
 */
Flipbook.confirmViewportResize = function(app) {
    if (app.viewport.width !== app.viewport.oldWidth || app.viewport.height !== app.viewport.oldHeight) {
        app.viewport.oldWidth = app.viewport.width;
        app.viewport.oldHeight = app.viewport.height;
        return true;
    }
    return false;
};

/**
 * Checks if the Flipbook needs resizing upon exiting full screen
 *   - Fullscreen Elements include Widgets, Videos, Flipbook itself..
 *
 * @public
 * @this local
 * @return undefined
 */
Flipbook.checkNeedsResizing = function() {
    var isFullScreen = (document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen) || (document.activeElement.scrollHeight == window.innerHeight);
    if (Flipbook.NEEDS_RESIZING && !isFullScreen) {
        // Unset Flipbook.PREVENT_RESIZE - if set
        Flipbook.PREVENT_RESIZE = false;
        // We need to unset the variable document.activeElement.id from the value "widgetIframe..."
        document.activeElement.blur();
        // Trigger the resize event
        Shared.$('window').trigger('debouncedresize.Flipbook.App');
    }
};

/**
 * Prevent the App from Resizing when an Element is toggled into Fullscren Mode
 *   - Fullscreen Elements include Widgets, Videos, Flipbook itself..
 * 
 * @public
 * @this local
 * @return undefined
 */
Flipbook.preventResize = function() {
    Flipbook.PREVENT_RESIZE = true;
    if (Flipbook.preventResizeTimer > 0) {
        Shared.root.clearTimeout(Flipbook.preventResizeTimer);
    }
    Flipbook.preventResizeTimer = Shared.root.setTimeout(function() { Flipbook.PREVENT_RESIZE = false; }, 2000);
};

/**
 * Identify the Display Type to CSS (add identifying class to viewport)
 *  - Desktop, Embedded, Iframe, Mobile-Phone, Mobile-Tablet, Etc..
 *
 * @public
 * @this local
 * @return undefined
 */
Flipbook.identifyDisplayType = function(app) {
    // Identify Embedded Flipbooks
    if (Flipbook.isEmbedded(app)) {
        app.viewport.$element.addClass('embedded');
    } else {
        app.viewport.$element.addClass('non-embedded');
    }
    
    // Identify Iframe Flipbooks
    if (Flipbook.isIframe(app)) {
        app.viewport.$element.addClass('iframed');
    } else {
        app.viewport.$element.addClass('non-iframed');
    }
    
    // Identify Mobile Flipbooks
    if (Flipbook.isMobile.any(app)) {
        app.viewport.$element.addClass('mobile');
    } else {
        app.viewport.$element.addClass('non-mobile');
    }
    
    // Identify Retina Devices
    if (Flipbook.root.devicePixelRatio >= 2) {
        app.viewport.$element.addClass('retina');
    } else {
        app.viewport.$element.addClass('non-retina');
    }
    
    // Identify iOS
    if (Shared.IOS6 || Shared.IOS7) {
        app.viewport.$element.addClass('iOS');

        if (Shared.IOS6) { app.viewport.$element.addClass('iOS6'); }
        if (Shared.IOS7) { app.viewport.$element.addClass('iOS7'); }
        if (Shared.IOS8) { app.viewport.$element.addClass('iOS8'); }
    }
    
    // Identify Mobile IE10
    if (Shared.MS_IE_10) {
        app.viewport.$element.addClass('ie10');
    }
};


/* ******************************************************************************************** */
/* * Browser Related Methods                                                                  * */
/* ******************************************************************************************** */

/**
 * Open a Popup Window or New Tab to a URL
 *   - works accross all devices
 *      
 * @public
 * @this local
 * @param {Object} app A reference to the Flipbook App
 * @param {string} url The URL to Open a Popup Window to
 * @param {Object} [options] Options to customize the Popup Window
 * @param {string} options.type The type of Popup being opened, either 'web' or 'email'.  Defaults to 'web'. 
 * @param {number} options.top The Top Position of the Popup being opened in pixes. Defaults to 100.  
 * @param {number} options.left The Left Position of the Popup being opened in pixes. Defaults to 100. 
 * @param {number} options.width The width of the Popup being opened in pixes. Defaults to 550. 
 * @param {number} options.height The height of the Popup being opened in pixes. Defaults to 450. 
 * @return undefined 
 */
Flipbook.openUrl = function(app, url, options) {
    var popup = null;
    var target = '';
    var params = '';
    
    options = options || {};
    if (!/email|web/i.test(options.type)) { options.type = 'web'; }
    if (options.top === undefined) { options.top = 100; }
    if (options.left === undefined) { options.left = 100; }
    if (options.width === undefined) { options.width = 800; }
    if (options.height === undefined) { options.height = 600; }

    // Determine Popup Target
    if (Flipbook.isMobile.any(app) && options.type === 'email') { target = '_top'; }
        
    // Open a Popup for Sharing
    params = 'left='+options.left+',top='+options.top+',width='+options.width+',height='+options.height+',personalbar=0,toolbar=0,scrollbars=1,resizable=1';
    popup = Flipbook.root.open(url, target, params);
    
    // Force Close the Popup on Desktop
    if (!Flipbook.isMobile.any(app) && options.type === 'email') {
        Flipbook.root.setTimeout(function() {
            if (!popup.location || popup.location.href === 'about:blank') {
                popup.close();
            }
        }, 5000);
    }
};

/**
 * Determine whether current state is fullscreen
 *
 * @returns {boolean}
 */
Flipbook.determineFullscreen = function() {
    return (document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen);
};


/* ******************************************************************************************** */
/* * Sheet Related Methods                                                                    * */
/* ******************************************************************************************** */


/**
 * Gets the Current Page Orientation
 *  - Returns the Viewport orientation unless the page is forced into Portrait Mode, then returns Portrait Orientation
 *      
 * @public
 * @this local
 * @return {string} 
 */
Flipbook.isTwoPageMode = function(app) {
    return (app.viewport.orientation === Flipbook.ORIENT_LANDSCAPE && Flipbook.getPageOrientation(app) === Flipbook.ORIENT_LANDSCAPE);
};

/**
 * Gets the Current Page Orientation
 *  - Returns the Viewport orientation unless the page is forced into Portrait Mode, then returns Portrait Orientation
 *      
 * @public
 * @this local
 * @return {string} 
 */
Flipbook.getPageOrientation = function(app) {
    var orientation = app.viewport.orientation;
    if (app.config.titleData.single_page_view) {
        orientation = Flipbook.ORIENT_PORTRAIT;
    }
    return orientation;
};

/**
 * Determines the Total Number of Sheets to Cycle through 
 *   Accounts for both Landscape and Portrait Modes, Double-Page Mode and Always Open Mode
 *
 * @public
 * @this local
 * @return {Object} - An Object containing Total Sheet Counts for Landscape & Portrait Modes, 
 *                     as well as an Array of Page Indices for Sheets with Double Pages (Landscape Mode Only)
 */
Flipbook.getTotalSheets = function(app) {
    var i = 0, a = [], totalSheets = {'landscape': 0, 'portrait': 0, 'doublePages': []};
    
    // Debug Message
    Flipbook.log('util-get-total-sheets');
    
    // Portrait Mode:
    totalSheets.portrait = app.config.issueData.num_of_pages;
    
    // Landscape Mode:
    // Single Page Mode
    if (app.config.titleData.single_page_view) {
        totalSheets.landscape = app.config.issueData.num_of_pages;
    }
    
    // Double Page Mode
    else {
        // Always Open (2 Pages on First Sheet)
        if (app.config.titleData.always_open) {
            totalSheets.landscape = Math.round(app.config.issueData.num_of_pages / 2);
            for (i = 0; i < app.config.issueData.num_of_pages; i++) {
                a = [i];
                if (++i < app.config.issueData.num_of_pages) { a.push(i); }
                totalSheets.doublePages.push(a);
            }
        }
        
        // Not Always Open (1 Page on First Sheet)
        else {
            totalSheets.landscape = Math.round((app.config.issueData.num_of_pages - 1) / 2) + 1;
            totalSheets.doublePages.push([0]);
            for (i = 1; i < app.config.issueData.num_of_pages; i++) {
                a = [i];
                if (++i < app.config.issueData.num_of_pages) { a.push(i); }
                totalSheets.doublePages.push(a);
            }
        }
    }
    
    if (totalSheets.doublePages.length) {
        app.state.firstPageOnRight = totalSheets.doublePages[0].length < 2;
        app.state.lastPageOnLeft = totalSheets.doublePages.length > 1 && totalSheets.doublePages[totalSheets.doublePages.length-1].length < 2;
    }
    
    return totalSheets;
};

/**
 * Determines if the Very First Page of a Flipbook is displayed on the Right side when in Landscape Mode
 *      
 * @public
 * @this local
 * @return {boolean} - Whether or not the First Page is displayed on the Right
 */
Flipbook.isFirstPageOnRight = function(app) {
    return Flipbook.isTwoPageMode(app) && app.state.firstPageOnRight;
};

/**
 * Determines if the Very Last Page of a Flipbook is displayed on the Left side when in Landscape Mode
 *      
 * @public
 * @this local
 * @return {boolean} - Whether or not the Last Page is displayed on the Left
 */
Flipbook.isLastPageOnLeft = function(app) {
    return Flipbook.isTwoPageMode(app) && app.state.lastPageOnLeft;
};

/**
 * Determines if the Very Last Page of a Flipbook is displayed on the Left side when in Landscape Mode
 *      
 * @public
 * @this local
 * @return {boolean} - Whether or not the Last Page is displayed on the Left
 */
Flipbook.sheetHasTwoPages = function(app, totalSheets, currentSheet) {
    if (currentSheet === 0 && Flipbook.isFirstPageOnRight(app)) { return false; }
    if (currentSheet === totalSheets.landscape-1 && Flipbook.isLastPageOnLeft(app)) { return false; }
    return Flipbook.isTwoPageMode(app);
};

/**
 * Determines the Starting Sheet to Display based on Starting Page and Orientation
 *
 * @public
 * @this local
 * @param {Number} pageNum - (Optional) The Page Number to get the Starting Sheet for
 * @return {Object} - An Object containing the Starting Sheet Index
 *                     as well as a flag indicating Double-Page Mode
 */
Flipbook.getStartSheet = function(app, totalSheets, pageNum) {
    var sheetData = {'sheet': 0, 'twopage': false},
        startPage = parseInt(app.config.startPage, 10);
    
    if (pageNum !== undefined) { startPage = pageNum; }
    
    // In offline mode, startPage comes in as 'offline', so lets take them to the first page
    if (isNaN(startPage)) { startPage = 0; }
    
    // Debug Message
    Flipbook.log('util-get-start-sheet');
    
    // Portrait or Single Page Mode (Sheet# == Page#)
    sheetData.sheet = Flipbook.getSheetFromPage(app, startPage);
    sheetData.twopage = Flipbook.isTwoPageMode(app);
    
    if (sheetData.sheet < 0) { sheetData.sheet = 0; }
    if (sheetData.sheet > totalSheets[app.viewport.orientation] - 1) { sheetData.sheet = totalSheets[app.viewport.orientation] - 1; }
    
    return sheetData;
};

/**
 * Determines the Sheet Number based on the Page Number
 *
 * @public
 * @this local
 * @param {Number} pageNum - The Page Number to get the Sheet From
 * @return {Number} - The Sheet Number of the Page Number requested
 */
Flipbook.getSheetFromPage = function(app, pageNum) {
    // Portrait or Single Page Mode (Sheet# == Page#)
    var sheetNum = pageNum;
    
    // Debug Message
    Flipbook.log('util-get-sheet-from-page');
    
    // Landscape and Two-Page Spread Mode
    if (app.viewport.orientation === Flipbook.ORIENT_LANDSCAPE && !app.config.titleData.single_page_view) {
        // Always Open (2 Pages on First Sheet)
        if (app.config.titleData.always_open) {
            sheetNum = Math.floor(pageNum / 2);
        }
        
        // Not Always Open (1 Page on First Sheet)
        else {
            sheetNum = Math.ceil(pageNum / 2);
        }
    }
    
    return sheetNum;
};

/**
 * Determines the Page Number based on the Sheet Number
 *
 * @public
 * @this local
 * @param {Number} sheetNum - The Sheet Number to get the Page From
 * @return {Number} - The Page Number of the Sheet Number requested
 */
Flipbook.getPageFromSheet = function(app, sheetNum) {
    // Portrait or Single Page Mode (Sheet# == Page#)
    var pageNum = sheetNum;
    
    // Debug Message
    Flipbook.log('util-get-page-from-sheet');
    
    // Landscape and Two-Page Spread Mode
    if (app.viewport.orientation === Flipbook.ORIENT_LANDSCAPE && !app.config.titleData.single_page_view) {
        pageNum = sheetNum * 2;
        
        if (sheetNum > 0 && !app.config.titleData.always_open) {
            pageNum -= 1;
        }
    }
    
    return pageNum;
};

/**
 * Determines the Page Number based on the Sheet Number
 *
 * @public
 * @this local
 * @param {Number} pageNum - The Page Number to get the RealID From
 * @return {Number} - The RealID Page Number requested
 */
Flipbook.getRealPageId = function(app, pageNum) {
    return app.parsedPageData[ pageNum ].realId;
};


/* ******************************************************************************************** */
/* * Measurement Related Methods                                                              * */
/* ******************************************************************************************** */


/**
 * Measures the Size of a jQuery Element
 *
 * @public
 * @this local
 * @param {Mixed} $el An HTML Element or Selector to measure the size of
 * @return {Object} The size of the Element as an object with width/height properties
 */
Flipbook.measureSize = function($el) {
    if (!($el instanceof jQuery)) { $el = $($el); }
    return {'width': $el.width(), 'height': $el.height()};
};

/**
 * Checks if 2 Rectangles Intersect
 *   - Taken from .NET implementation for Rectangle Intersect()
 *   - Thx to Dmitry for finding a sample: http://stackoverflow.com/questions/2752349/fast-rectangle-to-rectangle-intersection
 *
 * @public
 * @this local
 * @param {Object} rectA An object representing the coordinates of a Rectangle
 * @param {Object} rectB An object representing the coordinates of a Rectangle
 * @return {boolean} True if the 2 Rectangles Intersect
 */
Flipbook.rectIntersect = function(rectA, rectB) {
    var x    = Math.max(rectA.x, rectB.x);
    var y    = Math.max(rectA.y, rectB.y);
    var num1 = Math.min(rectA.x + rectA.w, rectB.x + rectB.w);
    var num2 = Math.min(rectA.y + rectA.h, rectB.y + rectB.h);
    return (num1 >= x && num2 >= y);
};

/**
 * Updates the Size of a PageBox Element and its Children
 *      
 * @public
 * @this local
 * @param {Object} $pageBoxEl - A jQuery Element Object of the PageBox
 * @param {string} childSelector - A selector for finding children of the PageBox Element
 * @return undefined
 */
Flipbook.resizePageBox = function(app, $pageBoxEl, childSelector) {
    var pw = app.config.sheet.pageSize[app.viewport.orientation].w;
    var ph = app.config.sheet.pageSize[app.viewport.orientation].h;
    var pm = app.config.sheet.pageSize[app.viewport.orientation].m;
    var $children = null;
    
    // Debug Message
    //Flipbook.log('util-resize-pagebox');

    // Update Size of Page Box
    if (app.viewport.orientation === Flipbook.ORIENT_LANDSCAPE && !app.config.titleData.single_page_view) {
        $pageBoxEl.css({'width': (pw * 2), 'height': ph, 'margin-top': pm});
    } else {
        $pageBoxEl.css({'width': pw, 'height': ph, 'margin-top': pm});
    }
    
    // Update Size of Child Elements
    if (childSelector !== undefined && childSelector.length) {
        $children = $pageBoxEl.find(childSelector);
        if ($children.length) {
            $children.css({'width': pw, 'min-height': ph-1});
        }
    }
};

/**
 * Updates the Size of a Widget Frame Element (called from Widget Frame; see page_widget.thtml)
 *      
 * @public
 * @this local
 * @return undefined
 */
Flipbook.resizeWidgetFrame = function(widgetId, sizingCss) {
    var $el = $('#widget' + widgetId);
    if (!$el.length) { return; }
    $el.css(sizingCss);
};




/* ******************************************************************************************** */
/* * Event Related Methods                                                                    * */
/* ******************************************************************************************** */


/**
 * Attach a Click or Tap Event Handler on an Element
 *  - Touch devices get a Tap Event, other devices get a Click Event
 *
 * @public
 * @this local
 * @param {Mixed}    element   - A DOM Element, a jQuery Element Object, or a String Selector to an Element
 * @param {Function} fnHandler - The handler function for the Event
 * @param {Object}   context   - The context from which the handler function is to run (defaults to Flipbook)
 * @param {string}   namespace - An event namespace for the Event
 * @param {boolean}  asDouble  - Whether or not to Register Double Click/Tap events as well as Single events
 * @return undefined
 */
Flipbook.onClickTap = function(app, element, fnHandler, context, namespace, asDouble) {
    var ns = '.Flipbook';
    if (!$(element).length) { return; }
    
    // Extend Namespace
    if (namespace !== undefined && namespace.length) {
        ns += '.' + namespace;
    }
    
    // Double or Single Click?
    asDouble = asDouble || false;
    
    // Apply Context
    context = context || Flipbook;


    // Attach Event
    if(Flipbook.hasTouch(app)) {
        $(element).hammer().on('tap'+ns, $.proxy(fnHandler, context));
        if(asDouble) {
            $(element).hammer().on('doubletap'+ns, $.proxy(fnHandler, context));
        }
    }
    else {
        $(element).on('click'+ns, $.proxy(fnHandler, context));
        if(asDouble) {
            $(element).on('dblclick'+ns, $.proxy(fnHandler, context));
        }
    }
};


/**
 * Removes a Click or Tap Event Handler on an Element
 *
 * @public
 * @this local
 * @param {Mixed}    element   - A DOM Element, a jQuery Element Object, or a String Selector to an Element
 * @param {string}   namespace - An event namespace for the Event
 * @return undefined
 */
Flipbook.offClickTap = function(app, element, namespace) {
    var ns = '.Flipbook';
    var $el = $(element);
    if (!$el.length) { return; }
    
    // Extend Namespace
    if (namespace !== undefined && namespace.length) {
        ns += '.' + namespace;
    }
    
    // Attach Event
    if(!Flipbook.hasTouch(app)) {
        $el.off('click'+ns);
        $el.off('dblclick'+ns);
    }
    else {
        $el.hammer().off('tap'+ns);
        $el.hammer().off('doubletap'+ns);
    }

};

/**
 * Attach a Drag Event Handler on an Element
 *  - Touch devices get a Drag Events, other devices get a Mouse Events
 *
 * @public
 * @this local
 * @param {Mixed}    element       - A DOM Element, a jQuery Element Object, or a String Selector to an Element
 * @param {Function} fnHandler     - The handler function for the Event
 * @param {Object}   context       - The context from which the handler function is to run (defaults to Flipbook)
 * @param {string}   namespace     - An event namespace for the Event  
 * @param {Object}   hammerOptions - A JSON Object of Options to pass into HammerJS (Touch Only)
 * @return undefined
 */
Flipbook.onDrag = function(app, element, fnHandler, context, namespace, hammerOptions) {
    var ns = '.Flipbook';
    var $el = $(element);
    if (!$el.length) { return; }
    
    // Extend Namespace
    if (namespace !== undefined && namespace.length) {
        ns += '.' + namespace;
    }
    
    // Apply Context
    context = context || Flipbook;
    
    // Attach Event
    if (Flipbook.hasTouch(app)) {
        $el.hammer(hammerOptions).on('dragstart'+ns+' drag'+ns+' dragend'+ns, $.proxy(fnHandler, context));
    } else {
        $el.on('mousedown'+ns+' mousemove'+ns+' mouseup'+ns, $.proxy(fnHandler, context));
    }
};


/* ******************************************************************************************** */
/* * Template Related Methods                                                                 * */
/* ******************************************************************************************** */


/**
 * Build a portion of HTML DOM from a Template String
 *   - The template gets cached for future use
 *      
 * @public
 * @this local
 * @param {Object} options A list of Options for Building the Template. Options include:
 *                      - {string} template  - The Element ID of the <script> tag that holds the Template HTML
 *                      - {Mixed}  appendTo  - A jQuery Element Object, a DOM Element or a String Selector to an Element. The template will be appended to this element.
 *                      - {Object} tags      - A JSON List of Template Tags and associated data to Replace within the Template.  Ex: {'name': 'Rob'} will replace {{name}} with Rob in the Template.
 *                      - {string} returnAs  - Can be one of 'element' or 'string'
 *                                               - 'element' to return the jQuery Element Object of the Template.
 *                                               - 'string' to return the Template HTML String.
 * @return {Mixed} - jQuery Element Object of the Template, or the Template HTML String.
 */
Flipbook.buildFromTemplate = function(options) {
    var $el = null;
    var $tpl = null;
    var html = '';
    
    // Template ID is Required
    if (options === undefined || options.template === undefined) {
        return Shared.Logger.parse({'msg': 'template-build-error', 'type': Shared.LVL_DEBUG}).msg;
    }
    
    // Check if Template exists in Cache, if not Get from DOM Element
    if (Flipbook.TemplateCache[options.template] === undefined || Flipbook.TemplateCache[options.template].length < 1) {
        $tpl = $(options.template);
        if (!$tpl.length) {
            return Shared.Logger.parse({'msg': 'template-build-not-found', 'type': Shared.LVL_DEBUG, 'args': {'template': options.template}}).msg;
        }
        Flipbook.TemplateCache[options.template] = $tpl.html();
    }
    html = Flipbook.TemplateCache[options.template];
    
    // Check for Return Type
    if (options.returnAs === undefined || !/^e$|^element$|^s$|^string$/i.test(options.returnAs)) {
        options.returnAs = 'string';
    }
    
    // Check for Tags to Replace
    if (options.tags !== undefined && _.isPlainObject(options.tags)) {
        _.forOwn(options.tags, function(val, key, obj) {
            html = html.replace(new RegExp('{{' + key + '}}', 'gi'), val);
        });
    }
    
    // Convert HTML Template to DOM Element
    $el = $(html);
    
    // Append Element to Container
    if (options.appendTo && options.appendTo.length) {
        $el.appendTo(options.appendTo);
    }
    
    // Return Element or HTML String
    return /^e$|^element$/i.test(options.returnAs) ? $el : html;
};


/* ******************************************************************************************** */
/* * AJAX Related Methods                                                                     * */
/* ******************************************************************************************** */


/**
 * Initiates an AJAX request via jQuery and Returns a Q-compatible Promise
 *      
 * @public
 * @this local
 * @param {Object} options - (Optional) The options for the AJAX request (url, type, data, etc..)
 * @return {Object} - A Promise
 */
Flipbook.qAjax = function(options) {
    var deferred = Q.defer();
    
    // Ensure we have a Valid URL
    if (options === undefined || options.url === undefined || !options.url.length) {
        deferred.reject('invalid url');
    }
    
    // Check for Other Options for AJAX
    if (options.type === undefined) { options.type = 'POST'; }
    if (options.data === undefined) { options.data = {}; }
    if (options.async === undefined) { options.async = true; }
    if (options.dataType === undefined) { options.dataType = 'json'; }

    // Success Handler; Resolve Promise
    options.success = function(data) {
        deferred.resolve(data);
    };

    // Error Handler; Reject Promise
    options.error = function(jqXhr, textStatus, errorThrown) {
        deferred.reject([jqXhr, textStatus, errorThrown]);
    };
    
    // Track Last AJAX Request
    Flipbook.LastXHR = $.ajax(options);
    
    // Return Promise
    return deferred.promise;
};

/**
 * Cancel the Last AJAX Request
 *      
 * @public
 * @this local
 * @return {boolean} - Whether or not the Last AJAX Request was Cancelled
 */
Flipbook.cancelLastAjax = function() {
    if (Flipbook.LastXHR && Flipbook.LastXHR.readyState !== 4) {
        Flipbook.LastXHR.abort();
        return true;
    }
    return false;
};


/* ******************************************************************************************** */
/* * Color Related Methods                                                                    * */
/* ******************************************************************************************** */


/**
 * Convert a Hex Color Code String to an RGB Array
 * 
 * @public
 * @this local
 * @param {string} hex - The Hex Color Code to convert to RGB
 * @return {Array} - The RGB Color Code
 */
Flipbook.hexToRgb = function(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};

/**
 * 
 * 
 * @public
 * @this local
 * @return {string} - 
 */
Flipbook.blendColors = function(c0, c1, p) {
    var f  = parseInt(c0.slice(1), 16);
    var t  = parseInt(c1.slice(1), 16);
    var R1 = f >> 16;
    var G1 = f >> 8 & 0x00FF;
    var B1 = f & 0x0000FF;
    var R2 = t >> 16;
    var G2 = t >> 8 & 0x00FF;
    var B2 = t & 0x0000FF;
    return '#' + (0x1000000 + (Math.round((R2-R1)*p)+R1) * 0x10000 + (Math.round((G2-G1)*p)+G1) * 0x100 + (Math.round((B2-B1)*p)+B1)).toString(16).slice(1);
};


/* ******************************************************************************************** */
/* * Convenience Methods                                                                      * */
/* ******************************************************************************************** */


/**
 * Find an Element within a List by ID
 *      
 * @public
 * @this local
 * @param {Mixed} id - The ID to search for in the List
 * @param {Mixed} list - The list to search within
 * @return {Mixed} - The element
 */
Flipbook.findById = function(id, list) {
    var retval;
    for (var i = 0, n = list.length; i < n; i++) {
        if (list[i].id === id) {
            retval = list[i];
        }
    }
    return retval;
};

/**
 * Get the URL portion of the Flipbook that is based on the Type of Flipbook being Viewed
 *  - returns 'i' for 'issue', 't' for 'title', 'g' for 'geo'
 *      
 * @public
 * @this local
 * @return {string} The URL Portion for the Type of Flipbook
 */
Flipbook.getUrlType = function(app) {
    return app.config.stats.type.charAt(0);
};

/**
 * Embeds an Error Message in place of the Flipbook, in the case where the Flipbook
 *   fails to load for some reason.
 *
 * @public
 * @this local
 * @param {Object} errorObj - The Error Object containing the Error Message
 * @return undefined
 */
Flipbook.embedError = function(app, errorObj) {
    var div = $('<div class="warning"/>'), para = $('<p/>');
    para.html(errorObj.msg);
    app.viewport.$element.addClass('load-error gradient');
    app.viewport.$element.append(div.append(para));
};


/* ******************************************************************************************** */
/* * Capability Related Methods                                                               * */
/* ******************************************************************************************** */

/**
 * Checks if a Specific Component is Enabled
 *
 * @public
 * @this local
 * @return {boolean} True if the Component is Enabled
 */
Flipbook.isComponentEnabled = function(app, data) {
    var enabled = false;
    if (typeof data === 'boolean') {
        enabled = data;
    } else if (typeof data === 'string') {
        if (data.length) {
            enabled = app.config.titleData[data] || false;
        }
    } else if (typeof data === 'function') {
        enabled = data.call(app);
    } else {
        // Enabled if at least 1 val is True
        $.each(data, function(idx, val) {
            enabled = enabled || Flipbook.isComponentEnabled(app, val);
        });
    }
    return enabled;
};


/**
 * Checks if the device is considered a Mobile device
 *
 * @public
 * @this local
 * @return {boolean} True if the device is a Mobile device
 */
Flipbook.isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    Android4: function() {
        return navigator.userAgent.match(/Android\s4.(3|4)/i);
    },
    Android5: function() {
        return navigator.userAgent.match(/Android\s5/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    iOS7: function() {
        return navigator.userAgent.match(/OS 7/i);
    },
    iPhone: function() {
        return navigator.userAgent.match(/iPhone|iPod/i);
    },
    iPad: function() {
        return navigator.userAgent.match(/iPad/i);
    },
    CriOS: function() {
        return navigator.userAgent.match(/CriOS/i); // Chrome on iOS
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function(app) {
        return (
            Flipbook.TRUTHY.test(app.config.device.isMobile) ||
            Flipbook.isMobile.Android() || 
            Flipbook.isMobile.BlackBerry() || 
            Flipbook.isMobile.iOS() || 
            Flipbook.isMobile.CriOS() || 
            Flipbook.isMobile.Opera() || 
            Flipbook.isMobile.Windows()
        );
    }
};

/**
 * Checks if the Device Type is a Desktop Browser
 *
 * @public
 * @this local
 * @return {boolean} True if the Device Type is a Desktop Browser
 */
Flipbook.isDesktop = function(app) {
    return /^desktop/i.test(app.viewport.breakpoint);
};

/**
 * Checks if the Device Type is a Tablet Browser
 *
 * @public
 * @this local
 * @return {boolean} True if the Device Type is a Tablet Browser
 */
Flipbook.isTablet = function(app) {
    return /^tablet/i.test(app.viewport.breakpoint);
};

/**
 * Checks if the Device Type is a Phone Browser
 *
 * @public
 * @this local
 * @return {boolean} True if the Device Type is a Phone Browser
 */
Flipbook.isPhone = function(app) {
    return /^phone/i.test(app.viewport.breakpoint);
};

/**
 * Checks if the Flipbook is Embedded
 *
 * @public
 * @this local
 * @return {boolean} True if the Flipbook is Embedded
 */
Flipbook.isEmbedded = function(app) {
    return app.config.device.embedded;
};

/**
 * Checks if the Flipbook is within an Iframe
 *
 * @public
 * @this local
 * @return {boolean} True if the Flipbook is within an Iframe
 */
Flipbook.isIframe = function(app) {
    return app.config.device.iframe;
};

/**
 * Checks if the Flipbook is in Standalone mode (launched from homescreen)
 *
 * @public
 * @this local
 * @return {boolean} True if the Flipbook is in Standalone Mode
 */
Flipbook.isStandalone = function() {
    return window.navigator.standalone || false;
};

/**
 * Checks if the Device is in Fullscreen Mode
 *
 * @public
 * @this local
 * @return {boolean} True if the Device is in Fullscreen Mode
 */
Flipbook.isFullscreen = function(app) {
    return app.viewport.fullscreen;
};

/**
 * Checks if the Device has Touch Capabilities
 *  FIXME:
 *      (Issues: https://uberflip.atlassian.net/browse/DEV-11055, https://uberflip.atlassian.net/browse/DEV-10489)
 *      Windows devices with touch-screens are annoying to deal with and since we don't have specific APIs to target them across browsers,
 *      so until that improves, we consider  all windows devices with touch-screens as non-touch devices.
 * @public
 * @this local
 * @return {boolean} True if the Device has Touch Capabilities
 */
Flipbook.hasTouch = function(app) {
    return app.config.device.hasTouch && !Flipbook.isWindowsOS();
};

/**
 * Checks if the Device runs Windows
 * @returns {boolean} True if the device runs Windows
 */
Flipbook.isWindowsOS = function () {
    return (window.navigator.userAgent.indexOf("Windows") != -1);
};

/**
 * Checks if the Device has Multi-Touch Capabilities
 *  FIXME:
 *      (Issues: https://uberflip.atlassian.net/browse/DEV-11055, https://uberflip.atlassian.net/browse/DEV-10489)
 *      Windows devices with touch-screens are annoying to deal with and since we don't have specific APIs to target them across browsers,
 *      so until that improves, we consider all windows devices with touch-screens as non-touch devices.
 * @public
 * @this local
 * @return {boolean} True if the Device has Multi-Touch Capabilities
 */
Flipbook.hasMultiTouch = function(app) {
    return app.config.device.hasMultiTouch && !Flipbook.isWindowsOS();
};

/**
 * Checks if the Device has 3D Capabilities
 *
 * @public
 * @this local
 * @return {boolean} True if the Device has 3D Capabilities
 */
Flipbook.has3D = function(app) {
    return app.config.device.has3D;
};


/* **************************************************************************************** */
/* * Private Static Properties                                                            * */
/* **************************************************************************************** */

/**
 * Display Error/Warning/Log Messages on Mobile Devices without using alert()
 *
 * @public
 * @this local
 * @return {boolean} True if the Device has 3D Capabilities
 */
Flipbook.MobileConsole = function(app, msg) {
    var $modal = app.modal.getModal();
    
    if (!$modal.is(':visible')) {
        app.modal.content(msg);
    } else {
        app.modal.appendContent(msg);
    }
};
