/**
 * Flyp Technologies Inc. - Flipbook v4
 * 
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/scrubber.js - Flipbook.Scrubber
 * @author Robert J. Secord, B.Sc.
 */
import Flipbook from './core';
import 'jquery-migrate';
import $ from 'jquery';
import Modernizr from 'modernizr';

/**
 * Flipbook Carousel-Scrubber
 *
 * @class Scrubber
 * @classdesc Application Carousel-Scrubber Controller
 * @namespace Flipbook
 * @return {Object} The Class Instance
 * @constructor
 * @mixin
 */
Flipbook.Scrubber = function() {};


/* **************************************************************************************** */
/* * Public Properties                                                                    * */
/* **************************************************************************************** */

// Data for Touch Events on Carousel Scrubber
//  (This data is available accross entire Carousel Code [Carousel, Slider, Zoomer, Scrubber]) 
Flipbook.Scrubber.prototype.scrub = {
    'initiated' : false, 
    'moved'     : false, 
    'start'     : {'x': 0, 'y': 0}, 
    'point'     : {'x': 0, 'y': 0}, 
    'position'  : {'x': 0, 'y': 0}, 
    'direction' : {'x': 0, 'y': 0}, 
    'snap'      : 0
};


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Initializes the Buffer for Scrubber Mode
 *    Clears all Unbuffered Sheets.
 *    Loads Visible Sheet First (if not already loaded).
 *    Loads all other Buffered Sheets (if not already loaded).
 *
 * @public
 * @abstracted
 * @this Flipbook.Carousel
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Scrubber.prototype.scrubberInitBuffer = function() {
    var i;
    var bufferSize = this[this.viewMode + 'BufferSize'][this.app.viewport.orientation];
    var totalSheets = this.sheets.length, startIdx = 0;
    
    // Debug Message
    Flipbook.log('scrubber-init-buffer');

    // Get Starting Index for Loading Sheets
    startIdx = this.getSheetStartIndex();

    // Clear & Hide all unbuffered Sheets by default
    for (i = 0; i < totalSheets; i++) {
        if (i < startIdx || i >= startIdx + bufferSize) {
            this.sheets[i].clearSheet();
        }
    }

    // Populate Buffered Sheets (Load Focused Sheet First)
    if (this.sheets[this.current.sheet].imgQuality() === Flipbook.IMG_QUALITY_NONE) {
        this.loadSheet(this.current.sheet, true);
    }
    for (i = 0; i < bufferSize; i++) {
        if (startIdx + i !== this.current.sheet && this.sheets[startIdx + i].imgQuality() === Flipbook.IMG_QUALITY_NONE) {
            this.loadSheet(startIdx + i, false);
        }
    }
    return this;
};

/**
 * Updates the Buffer for Scrubber Mode
 *    Loads all Sheets within Buffer Range; 
 *    May move more than 1 sheet at a time, therefore must load/unload more than 1 sheet at a time.
 *
 * @public
 * @abstracted
 * @this Flipbook.Carousel
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Scrubber.prototype.scrubberUpdateBuffer = function() {
    var i;
    var bufferSize = this[this.viewMode + 'BufferSize'][this.app.viewport.orientation];
    var totalSheets = this.sheets.length, startIdx = 0;
    
    // Debug Message
    Flipbook.log('scrubber-update-buffer');

    // Get Starting Index for Loading Sheets
    startIdx = this.getSheetStartIndex();

    // Clear & Hide all unbuffered Sheets by default
    for (i = 0; i < totalSheets; i++) {
        if (i < startIdx || i >= startIdx + bufferSize) {
            this.sheets[i].clearSheet();
        }
    }

    // Populate Buffered Sheets (Load Focused Sheet First)
    if (this.sheets[this.current.sheet].imgQuality() === Flipbook.IMG_QUALITY_NONE) {
        this.loadSheet(this.current.sheet, true);
    }
    for (i = 0; i < bufferSize; i++) {
        if (startIdx + i !== this.current.sheet && this.sheets[startIdx + i].imgQuality() === Flipbook.IMG_QUALITY_NONE) {
            this.loadSheet(startIdx + i, false);
        }
    }
    return this;
};

/**
 * Event: Drag
 *   Handles Scrubbing to the Next/Previous Sheets (Multiple Sheets at a Time in Scrubber Mode)
 *
 * @public
 * @abstracted
 * @this Flipbook.Carousel
 * @param {Object} e The Event Data from HammerJS
 * @return undefined
 */
Flipbook.Scrubber.prototype.scrubberHandleDrag = function(e) {
    var deltaX, thetaX, newX;
    var distance;
    var touch = Flipbook.hasTouch(this.app);
    
    // Disable browser scrolling
    if (touch) {
        e.gesture.preventDefault();
    } else {
        e.preventDefault();
    }
    e.stopPropagation();
    
    // Prevent Scrubbing While Sliding, Pinching or Panning
    if (this.slide.initiated || this.zoom.pinch.initiated || this.zoom.pan.initiated) { return true; }
    
    // Prevent Sliding when Flipbook State is Animating or Shifted
    if (this.app.state.animating || this.app.state.shifted) { return true; }

    // Must be a Single-Touch Event, with a Scale less than 1
    if ((touch && e.gesture.touches.length > 1) || this.zoom.scale >= 1) { return true; }
    
    switch(e.type) {
        case 'dragstart':
        case 'mousedown':
            if (this.scrub.initiated) { return; }

            // Setup Vars
            this.scrub.initiated = true;
            this.scrub.start.x = touch ? e.gesture.center.pageX : e.pageX;
            this.scrub.point.x = touch ? e.gesture.center.pageX : e.pageX;
            this.scrub.direction.x = 0;
            
            // Set Transition Duration to Zero so Animation is not delayed while sliding/moving
            this.$scrollDiv[0].style[Modernizr.prefixed('transitionDuration')] = '0s';
            break;
            
        case 'drag':
        case 'mousemove':
            if (!this.scrub.initiated) { return; }
            
            // Calculate New Position/Distance Moved
            thetaX = (touch ? e.gesture.center.pageX : e.pageX) - this.scrub.start.x;
            deltaX = (touch ? e.gesture.center.pageX : e.pageX) - this.scrub.point.x;
            newX = this.scrub.position.x + (deltaX / this.zoom.scale * 0.75);
            
            // Track current position/distance
            this.scrub.point.x = (touch ? e.gesture.center.pageX : e.pageX);
            this.scrub.direction.x = thetaX > 0 ? 1 : thetaX < 0 ? -1 : 0;
            
            // Fix New X based on Position
            // - prevents pushing first/last page off-screen
            if (newX > 0 || newX < this.current.maxscroll) {
                newX = this.scrub.position.x + (deltaX / 2);
            }
            
            // Set New Position of Slider
            this.scrub.position.x = newX;
            this.setPosition(newX);
            break;
            
        case 'dragend':
        case 'mouseup':
            if (!this.scrub.initiated) { return; }
            if (touch) { e.gesture.stopDetect(); } // prevent release event
            
            // Calculate Distance Moved
            distance = Math.abs((touch ? e.gesture.center.pageX : e.pageX) - this.scrub.start.x);
            
            // Touch Event has Ended
            if (distance) {
                this.scrubberDragFinished(e);
            }
            break;
    }
    
};

/**
 * Finalizes Drag Events; Called when Drag is Finished
 *   Determines which Sheet to Focus on depending on which sheet is closest to Center.
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A Promise
 */
Flipbook.Scrubber.prototype.scrubberDragFinished = function(eventData) {
    var speed = 100;
    var newSheet = 0;
    var distToLeftSheet = 0, distToRightSheet = 0;
    var totalSheets = this.totalSheets[this.app.viewport.orientation];
    var sheetWidth = this.app.config.sheet.pageSize[this.app.viewport.orientation].w;
    
    // Touch Event has Ended
    this.scrub.initiated = false;
    
    // Determine Sheet Width
    if (this.current.twopage) { sheetWidth *= 2; }
    sheetWidth += 10;
    
    // Determine Direction to Move
    if (this.scrub.direction.x > 0) {
        newSheet = -Math.ceil(this.scrub.position.x / sheetWidth);
    } else {
        newSheet = -Math.floor(this.scrub.position.x / sheetWidth);
    }

    // Apply Momentum
    if (Flipbook.hasTouch(this.app) && eventData.gesture.deltaTime < 500) {
        // Calculate relative distance
        this.scrub.position.x += (eventData.gesture.deltaX * (1 + eventData.gesture.velocityX) * ((500 - eventData.gesture.deltaTime) / 100));
        
        // Prevent Scroll beyond Boundaries
        if (this.scrub.position.x > 0 || this.scrub.position.x < this.current.maxscroll) {
            if (this.scrub.position.x > 0) { newSheet = 0; } 
            else { newSheet = totalSheets - 1; }
        }
        
        // Scroll to Centre of Sheet
        else {

            // Determine Direction to Move
            if (this.scrub.direction.x > 0) {
                newSheet = -Math.ceil(this.scrub.position.x / sheetWidth);
            } else {
                newSheet = -Math.floor(this.scrub.position.x / sheetWidth);
            }
            
            // Calculate Distance to Closest Sheets
            distToLeftSheet = Math.abs(this.scrub.position.x + this.sheetPositions[newSheet][this.viewMode]);
            distToRightSheet = Math.abs(-this.sheetPositions[newSheet+1][this.viewMode] - this.scrub.position.x);
            
            // Centre on Closest Sheet
            if (distToLeftSheet > distToRightSheet) {
                newSheet += 1;
            }
            speed = 800 - eventData.gesture.deltaTime;
        }
    }
    
    if (newSheet < 0) { newSheet = 0; }
    if (newSheet > totalSheets - 1) { newSheet = totalSheets - 1; }

    // Update ScrollDiv to New Position
    return this.moveToSheet(newSheet, speed);
};

/**
 * Event: Tap/DoubleTap
 *   Automatically Focuses on the Selected Sheet, and switches to Slider Mode
 *
 * @public
 * @abstracted
 * @this Flipbook.Carousel
 * @param {Object} e The Event Data from HammerJS
 * @return undefined
 */
Flipbook.Scrubber.prototype.scrubberHandleTap = function(e) {
    var sheetId = 0;
    var realTargetId = 0;
    
    if (this.app.state.animating || this.app.state.shifted) { return; }
    if (!/tap|click/i.test(e.type)) { return; }

    e.stopPropagation();
    
    // Determine Real Target of Tap Event
    //  - IE likes to play games..
    if (e.target.className === 'page-image') {
        realTargetId = e.target.parentElement.id.split('-')[0].replace('sheet', '');
    } else {
        realTargetId = e.target.id.replace('sheet', '');
    }
    
    // Get Sheet ID from Target
    sheetId = parseInt(realTargetId || -1, 10);
    
    // Update ScrollDiv to New Position
    if (sheetId >= 0) {
        this.slide.initiated = false;
        this.moveToSheet(sheetId, 100).then($.proxy(function() {
            this.app.forceSliderMode().then($.proxy(function() {
                this.app.overlayer.sheetChanged(-1);
            }, this));
        }, this));
    }
    
    // Prevent Click on Parent Element
    //if (!Flipbook.hasTouch(this.app)) {
        this.slide.initiated = false;
        this.scrub.initiated = false;
    //}
};

/**
 * Event: KeyDown
 *   Moves the Scrubber 3 Sheets at a time, in the direction of the Arrow Key pressed.
 *
 * @public
 * @abstracted
 * @this Flipbook.Carousel
 * @param {Object} e The Event Data from jQuery
 * @return undefined
 */
Flipbook.Scrubber.prototype.scrubberKeyPress = function(e) {
    if (this.app.state.animating || this.app.state.shifted) { return; }
    e.preventDefault();

    switch (e.which) {
        case Flipbook.ARROW_KEY_UP:
        case Flipbook.ARROW_KEY_LEFT:
            this.scrubberMovePrev(400);
            break;
        case Flipbook.ARROW_KEY_RIGHT:
        case Flipbook.ARROW_KEY_DOWN:
            this.scrubberMoveNext(400);
            break;
        case Flipbook.ENTER_KEY:
            this.app.forceSliderMode().then($.proxy(function() {
                this.app.overlayer.sheetChanged(-1);
            }, this));
            break;
    }
};

/**
 * Moves the Carousel to the Next Set of Sheets
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object}  A Promise
 */
Flipbook.Scrubber.prototype.scrubberMoveNext = function(speed) {
    var newSheet = 0;
    
    // Determine new sheet to display
    newSheet = this.current.sheet + 1;
    if (newSheet >= this.totalSheets[this.app.viewport.orientation]) {
        newSheet = this.totalSheets[this.app.viewport.orientation] - 1;
        return this.scrubberBounceBack(speed, false);
    }
    this.scrub.direction.x = -1;
    
    // Update ScrollDiv to New Position
    return this.moveToSheet(newSheet, speed);
};

/**
 * Moves the Carousel to the Previous Set of Sheets
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A Promise
 */
Flipbook.Scrubber.prototype.scrubberMovePrev = function(speed) {
    var newSheet = 0;
    
    // Determine new sheet to display
    newSheet = this.current.sheet - 1;
    if (newSheet < 0) {
        newSheet = 0;
        return this.scrubberBounceBack(speed, true);
    }
    this.scrub.direction.x = 1;
    
    // Update ScrollDiv to New Position
    return this.moveToSheet(newSheet, speed);
};

/**
 * Bounces the Scrubber back within Bounds when Moved beyond First or Last Sheet in Carousel.
 *   Allows for Haptic feedback when trying to Move beyond Carousel Boundaries.
 *
 * @public
 * @this Flipbook.Carousel
 * @return {Object} A reference to the Carousel Object for Method Chaining
 */
Flipbook.Scrubber.prototype.scrubberBounceBack = function(speed, forward) {
    var x = [300, 0];
    var startOptions = {}, endOptions = {};
    if (speed > 0) { speed = speed / 2; }
    if (!forward) { x = [this.current.maxscroll - 300, this.current.maxscroll]; }
    
    startOptions[Flipbook.TRANSITION_MODIFIER] = x[0];
    endOptions[Flipbook.TRANSITION_MODIFIER] = x[1];
    
    // Animate Scroll Div to Bounce Back
    this.$scrollDiv.transition(startOptions, speed).transition(endOptions, speed, $.proxy(function() {
        this.app.state.animating = false;
    }, this));
    return this;
};
