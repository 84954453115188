/**
 * Flyp Technologies Inc. - Flipbook v4
 * 
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/pagebar.js - Flipbook.Pagebar
 * @author Robert J. Secord, B.Sc.
 */
import Flipbook from './core';
import Shared from './shared_util';
import Modernizr from 'modernizr';
import 'jquery-migrate';
import $ from 'jquery';

/**
 * Flipbook Pagebar
 *
 * @class Pagebar
 * @classdesc Application Pagebar Controller
 * @namespace Flipbook
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.Pagebar = function(app, $container) {

    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize    = null;
    var buildElements = null;
    var attachEvents  = null;


    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    this.app        = app;
    this.$container = $container;
    this.$pagingBar = null;
    this.$pagingBtn = null;
    this.$pagingTip = null;
    this.partial    = true;
    this.tipTimer   = 0;
    
    // Sheet Positions
    this.sheetPositions = [];
    
    // Working Data
    this.bar     = {'width': 0, 'height': 0, 'offset': 0};
    this.button  = {'width': 0, 'step': 0};
    this.touch   = {'initiated': false, 'point': 0, 'offset': 0};
    this.current = {'sheet': 0};
    

    /* **************************************************************************************** */
    /* * Private Methods/Members Definitions                                                  * */
    /* **************************************************************************************** */
    /**
     * Initialize the Pagebar Control
     *
     * @private
     * @this Flipbook.Pagebar
     * @return undefined
     * @constructs
     */
    initialize = $.proxy(function() {
        // Debug Message
        Flipbook.log('pagebar-init');
        
        // Build the Pagebar Elements
        buildElements();
        attachEvents();

        return this;
    }, this);

    /**
     * Build the Pagebar Elements
     *
     * @private
     * @this Flipbook.Pagebar
     * @return undefined
     */
    buildElements = $.proxy(function() {
        // Debug Message
        Flipbook.log('pagebar-build-elements');
        
        // Profiler
        Shared.Profiler.start('Flipbook:Pagebar->buildElements');
        
        // Build Pagebar from Template
        Flipbook.buildFromTemplate({'template': this.app.config.pagebar.template, 'appendTo': this.$container});
        
        // Get Handles to Pagebar Elements
        this.$pagingBar = this.$container.find('.pagebar-bar');
        this.$pagingBtn = this.$container.find('.pagebar-btn');
        this.$pagingTip = this.$container.find('.pagebar-tip');
        
        // Update Style
        this.displayPartial();
        
        // Profiler
        Shared.Profiler.end('Flipbook:Pagebar->buildElements');
    }, this);

    /**
     * Attach Events to the Pagebar Elements
     *
     * @private
     * @this Flipbook.Pagebar
     * @return undefined
     */
    attachEvents = $.proxy(function() {
        var ns = '.Flipbook.Pagebar';
        var hammerOptions = {'drag_block_vertical': true, 'drag_min_distance': 3};
        
        // Debug Message
        Flipbook.log('pagebar-attach-events');
        
        // Hook Drag Events
        Flipbook.onDrag(this.app, this.$container, this.handleDrag, this, 'Pagebar', hammerOptions);
        
    }, this);
    

    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize();
};
// End of Flipbook.Pagebar


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Resize the Pagebar Elements
 *      
 * @public
 * @this Flipbook.Pagebar
 * @return {Object} A reference to the Pagebar Object for Method Chaining
 */
Flipbook.Pagebar.prototype.resize = function() {
    var toolbarWidth = 0;
    if (this.app.config.toolbar.enabled && this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_LEFT) {
        toolbarWidth = this.app.config.toolbar.size[this.app.viewport.breakpoint][this.app.config.toolbar.position].closed; 
    }
    
    // Debug Message
    Flipbook.log('pagebar-resize');
    
    // Update Size of Pagebar Container
    this.$container.css({
        'width'  : this.app.viewport.width - toolbarWidth,
        'height' : this.app.config.pagebar.height[this.app.viewport.breakpoint]
    });
    
    // Get Size of Pagebar Elements
    this.button.width = this.$pagingBtn.outerWidth();
    this.bar.width = this.$pagingBar.outerWidth();
    this.bar.offset = this.$pagingBar[0].offsetLeft;  // this.$pagingBar.offset().left;
    
    // Update Sheet Positions
    return this.updateSheetPositions();
};

/**
 * Updates the Positions on the Pagebar, so that Pagebar Positions are Respective to Sheet Positions  
 *      
 * @public
 * @this Flipbook.Pagebar
 * @return {Object} A reference to the Pagebar Object for Method Chaining
 */
Flipbook.Pagebar.prototype.updateSheetPositions = function() {
    var i;
    var totalSheets = this.app.carousel.totalSheets[this.app.viewport.orientation];
    
    // Calculate Size of Step between Page Positions
    this.button.step = (this.bar.width - this.button.width) / (totalSheets - 1);
    
    // Populate Page Positions
    this.sheetPositions.length = 0;
    for (i = 0; i < totalSheets; i++) {
        this.sheetPositions.push(i * this.button.step);
    }
    return this;
};

/**
 * Move the Pagebar to a Specific Position respective of the Sheet Position
 *      
 * @public
 * @this Flipbook.Pagebar
 * @param {Integer} (sheetIndex) Optional. The Sheet Index to Move to.  Defaults to Current Sheet.
 * @param {Boolean} (animate) Optional.  Whether or not to Animate the movement.
 * @return {Object} A reference to the Pagebar Object for Method Chaining
 */
Flipbook.Pagebar.prototype.moveToSheet = function(sheetIndex, animate) {
    // Debug Message
    Flipbook.log({'msg': 'pagebar-move-to-sheet', 'args': {'sheet': sheetIndex}});
    
    // Get Current Sheet if no Sheet Provided
    if (sheetIndex === undefined) { sheetIndex = this.current.sheet; }
    
    // Default to No Animation
    if (animate === undefined) { animate = false; }
    
    // Store Current Sheet
    this.current.sheet = sheetIndex;
    
    // Move into Position
    if (animate) {
        this.$pagingBtn.transition({'left': this.sheetPositions[sheetIndex]}, 100);
    } else {
        this.$pagingBtn.css({'left': this.sheetPositions[sheetIndex]});
    }
    
    // Update Tooltip
    return this.updateTip();
};

/**
 * Shows the Tooltip of the Pagebar
 *      
 * @public
 * @this Flipbook.Pagebar
 * @param {Integer} (speed) Optional.  The speed of the Animation when showing the Tooltip.  Defaults to 0, no animation.
 * @param {Integer} (delay) Optional.  The delay in milliseconds before hiding the Tooltip.  Defaults to 0, not hidden after.
 * @return {Object} A reference to the Pagebar Object for Method Chaining
 */
Flipbook.Pagebar.prototype.showTip = function(speed, delay) {
    if (speed === undefined) { speed = 0; }
    if (delay === undefined) { delay = 0; }

    // Display Paging Tooltip 
    this.$pagingTip.css({'display':'block'});
    
    this.$pagingTip.transition({'opacity': 1.0}, speed);
    
    if (this.tipTimer) { Flipbook.root.clearTimeout(this.tipTimer); }
    
    if (delay > 0) {
        this.tipTimer = Flipbook.root.setTimeout($.proxy(function() {
            this.hideTip(250);
        }, this), delay);
    }
    return this;
};

/**
 * Hides the Tooltip of the Pagebar
 *      
 * @public
 * @this Flipbook.Pagebar
 * @param {Integer} (speed) Optional.  The speed of the Animation when hiding the Tooltip.  Defaults to 0, no animation.
 * @return {Object} A reference to the Pagebar Object for Method Chaining
 */
Flipbook.Pagebar.prototype.hideTip = function(speed) {
    if (speed === undefined) { speed = 0; }
    if (this.app.carousel.viewMode === Flipbook.CAROUSEL_SCRUBBER) { return this; }
    
    // Hide Paging Tooltip 
    this.$pagingTip.transition({'opacity': 0.1}, speed, $.proxy(function() {
        this.$pagingTip.css({'display':'none'});
    }, this));

    return this;
};

/**
 * Updates the Pagebar Display Type
 *   Slider Mode = Partial Pagebar (Tooltip Only)
 *   Scrubber Mode = Full Pagebar (Bar, Handle, Tooltip)
 *      
 * @public
 * @this Flipbook.Pagebar
 * @param {Boolean} (partial) Optional.  Wether or not to display the Partial Pagebar.
 * @return {Object} A reference to the Pagebar Object for Method Chaining
 */
Flipbook.Pagebar.prototype.displayPartial = function(partial) {
    if (partial === undefined) { partial = this.partial; }
    this.partial = partial;
    
    if (partial) {
        this.$container.addClass('partial');
    } else {
        this.$container.removeClass('partial');
    }
    return this;
};

/**
 * Updates the Display of the Pagebar Tooltip
 *   Current Page Numbers are Displayed in the Tooltip using L10N Support.
 *      
 * @public
 * @this Flipbook.Pagebar
 * @param {Integer} (sheetIndex) Optional. The Sheet Index to Display Page Info for.  Defaults to Current Sheet.
 * @return {Object} A reference to the Pagebar Object for Method Chaining
 */
Flipbook.Pagebar.prototype.updateTip = function(sheetIndex) {
    var p1, p2;
    var pagesText;
    var pagesTpl;
    var totalSheets = this.app.carousel.sheets.length - 1;
    var pageNumOffset = this.app.config.titleData.page_num_offset;
    var $info = this.$pagingTip.find('.pagebar-info');
    if (!$info.length) { return; }
    
    // Debug Message
    Flipbook.log('pagebar-update-tip');
    
    // Get Current Sheet if no Sheet Provided
    if (sheetIndex === undefined) {
        sheetIndex = this.current.sheet;
    }
    
    // Single Page or Portrait Mode
    p1 = (sheetIndex + pageNumOffset);

    // Double-Page Mode
    if (this.app.carousel.current.twopage) {
        p1 = (this.app.carousel.doublePageSheets[sheetIndex][0] + pageNumOffset);
        
        if (this.app.carousel.doublePageSheets[sheetIndex].length > 1) {
            p2 = (this.app.carousel.doublePageSheets[sheetIndex][1] + pageNumOffset);
        }
    }
    
    // Generate Localized Text from Template
    if (this.app.carousel.current.twopage && this.app.carousel.doublePageSheets[sheetIndex].length > 1) {
        pagesTpl = this.app.config.l10n.pagebar.tooltip.page[1];
        pagesText = pagesTpl.replace('%p1', p1).replace('%p2', p2); // replace %p1 with p1 and %p2 with p2
    } else {
        pagesTpl = this.app.config.l10n.pagebar.tooltip.page[0];
        pagesText = pagesTpl.replace('%p1', p1).replace('%p', p1);  // replace %p or %p1 with p1
    }
    pagesText = pagesText.replace('%t', (totalSheets + pageNumOffset));
    
    // Update Info in Tooltip
    $info.text(pagesText);
    
    return this;
};

/**
 * Event: Drag
 *   Handles Dragging the Pagebar Tooltip to Select a Sheet in the Carousel
 *
 * @public
 * @this Flipbook.Pagebar
 * @param {Object} e The Event Data from HammerJS
 * @return undefined
 */
Flipbook.Pagebar.prototype.handleDrag = function(e) {
    var touch = Flipbook.hasTouch(this.app);
    var pageX;
    
    // Disable browser scrolling
    if (touch) {
        e.gesture.preventDefault();
    } else {
        e.preventDefault();
    }
    e.stopPropagation();
    
    // Prevent Paging While Sliding, Scrubbing, Pinching or Panning
    if (this.app.carousel.slide.initiated || this.app.carousel.scrub.initiated || this.app.carousel.zoom.pinch.initiated || this.app.carousel.zoom.pan.initiated) { return true; }
    
    // Prevent Sliding when Flipbook State is Animating or Shifted
    if (this.app.state.animating || this.app.state.shifted) { return true; }
    
    switch(e.type) {
        case 'dragstart':
        case 'mousedown':
            if (this.touch.initiated) { return; }

            // Touch Started
            this.touch.initiated = true;
            
            // Show Tip
            this.showTip(0, 0);
            
            // Get offset of Touch Point to Left Edge of Button
            pageX = touch ? e.gesture.center.pageX : e.pageX;
            this.touch.offset = pageX - this.bar.offset - parseInt(this.$pagingBtn.css('left') || 0, 10);
            
            // Set Transition Duration to Zero so Animation is not delayed while sliding/moving
            this.$pagingBtn[0].style[Modernizr.prefixed('transitionDuration')] = '0s';
            break;
            
        case 'drag':
        case 'mousemove':
            if (!this.touch.initiated) { return; }

            // Track Touch Point
            pageX = touch ? e.gesture.center.pageX : e.pageX;
            this.touch.point = pageX;
            
            // Update Elements after Drag Event
            this.updateAfterDrag(false);
            break;
            
        case 'dragend':
        case 'mouseup':
            if (!this.touch.initiated) { return; }

            // Touch Ended
            this.touch.initiated = false;

            // Update Elements after Drag Event
            this.updateAfterDrag(true);
            
            // Start Timer to Hide Tip
            this.tipTimer = Flipbook.root.setTimeout($.proxy(function() {
                this.hideTip(250);
            }, this), this.app.config.pagebar.hideDelay);
            break;
    }
};

/**
 * Determines which Sheet is Focused after Dragging the Pagebar Tooltip
 *   Display current page in Tooltip.
 *   When Drag is Finished, Moves the Carousel to the Selected Sheet
 *
 * @public
 * @this Flipbook.Pagebar
 * @param {Boolean} (finishedDrag) Optional.  Whether or not the Drag Event is finished; if finished Move Carousel to Selected Sheet
 * @return {Object} A reference to the Pagebar Object for Method Chaining
 */
Flipbook.Pagebar.prototype.updateAfterDrag = function(finishedDrag) {
    var sheetIndex;
    var currentX = this.touch.point - this.touch.offset - this.bar.offset;
    
    // Debug Message
    Flipbook.log('pagebar-update-after-drag');
    
    // Stay within Boundaries
    if (currentX < 0) { currentX = 0; }
    if (currentX > (this.bar.width - this.button.width)) { currentX = (this.bar.width - this.button.width); }
    
    // Move Button to Dragged Position
    this.$pagingBtn.css({'left': currentX});
    
    // Check which Sheet we have Dragged to
    sheetIndex = this.getSheetFromDrag(currentX);
    
    // Update Contents of Tooltip
    this.updateTip(sheetIndex);
    
    if (finishedDrag) {
        // Snap Button to Closest Sheet Step
        this.$pagingBtn.transition({'left': this.sheetPositions[sheetIndex]}, 100);
        
        // Update Displayed Flipbook Page
        this.app.carousel.moveToSheet(sheetIndex, 300);
    }
    
    return this;
};

/**
 * Updates the Tooltip when the Scrubber is Moved
 *   Called by Scrubber Class to Update Tooltip while dragging.
 *
 * @public
 * @this Flipbook.Pagebar
 * @param {Integer} scrubberX The X Coordinate of the Scrubber
 * @param {Integer} scrubberMaxX The Maximum X Coordinate that the Scrubber can move to (for calculating distance)
 * @return {Object} A reference to the Pagebar Object for Method Chaining
 */
Flipbook.Pagebar.prototype.updateAfterScrubberDrag = function(scrubberX, scrubberMaxX) {
    var currentX = 0;
    var pagebarMaxX = this.sheetPositions[this.sheetPositions.length-1];
    var percentMoved = Math.abs(scrubberX) / Math.abs(scrubberMaxX);
    
    // Debug Message
    Flipbook.log('pagebar-update-after-scrubber-drag');
    
    // Convert scrubberX to currentX
    currentX = pagebarMaxX * percentMoved;
    
    // Move Button to Dragged Position
    this.$pagingBtn.css({'left': currentX});
    
    // Update Contents of Tooltip
    return this.updateTip(this.getSheetFromDrag(currentX));
};

/**
 * Gets the Current Selected Sheet based on the Position of the Scrubber
 *      
 * @public
 * @this Flipbook.Pagebar
 * @param {Integer} currentX The Current X Coordinate of the Scrubber
 * @return {Integer} The Index of the Current Sheet
 */
Flipbook.Pagebar.prototype.getSheetFromDrag = function(currentX) {
    var i;
    var sheetIndex = 0;
    var remainder = 0;
    var totalSheets = this.app.carousel.totalSheets[this.app.viewport.orientation];
    
    // Debug Message
    Flipbook.log('pagebar-sheet-from-drag');
    
    // Get Distance Between Steps
    remainder = currentX % this.button.step;
    
    // Get Sheet Index
    sheetIndex = Math.round((currentX - remainder) / this.button.step);
    
    // Check if we are closer to the right-side page
    if (sheetIndex < totalSheets-1 && remainder > (this.button.step / 2)) { sheetIndex += 1; }
    
    // Return Sheet Index
    return sheetIndex;
};
