/**
 * Flyp Technologies Inc. - Flipbook v4
 *
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/overlays/video.js - Flipbook.VideoOverlay
 * @author Robert J. Secord, B.Sc.
 */
import Flipbook from '../core';
import 'jquery-migrate';
import $ from 'jquery';
import Q from '/app/libs/promise/q';

/**
 * Flipbook Video Overlay Controller
 *
 * @class VideoOverlay
 * @classdesc Application Video Overlay
 * @namespace Flipbook
 * @inherits Flipbook.OverlayBase
 * @param {Object} overlayer A reference to the Overlayer Control
 * @param {Object} overlayOptions Configuration Settings for the Overlay
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.VideoOverlay = function(overlayer, overlayOptions) {
    // Call Parent Constructor
    Flipbook.OverlayBase.apply(this, [overlayer, overlayOptions]);
    
    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize = null;


    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    
    // Overlay Videos
    this.$overlayVideoEls = [];

    /* **************************************************************************************** */
    /* * Private Methods/Members Definitions                                                  * */
    /* **************************************************************************************** */
    /**
     * Initialize the Video Overlay
     *
     * @private
     * @this Flipbook.VideoOverlay
     * @return {Object} A reference to the VideoOverlay Object for Method Chaining
     * @constructs
     */
    initialize = $.proxy(function() {

        // not much to do here..
        
        return this;
    }, this);
    
    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize();
};
Flipbook.VideoOverlay.prototype = new Flipbook.OverlayBase();
Flipbook.VideoOverlay.prototype.constructor = Flipbook.VideoOverlay;
// End of Flipbook.VideoOverlay


/* ******************************************************************************************** */
/* * Overridden Base Methods                                                                  * */
/* ******************************************************************************************** */

/**
 * Override Abstract Base Method; Called when ...
 *
 * @public
 * @this Flipbook.VideoOverlay
 * @return {Object} A Promise
 */
Flipbook.VideoOverlay.prototype.resize = function() {
    return Q.Promise($.proxy(function(resolve, reject, notify) { 
        // Debug Message
        Flipbook.log('overlay-resize-videos');

        // Update Sizes
        this.calculateSizes();
        
        // Resize Complete; Resolve Promise
        resolve(true);
    }, this));
};

/**
 * Override Abstract Base Method; Called when ...
 *
 * @public
 * @this Flipbook.VideoOverlay
 * @return {Object} A Promise
 */
Flipbook.VideoOverlay.prototype.add = function() {
    return Q.Promise($.proxy(function(resolve, reject, notify) {
        var i, j, videoCount = 0;
        var $videoContainer, $video;
        var videos, rect, rgba;
        var leftPosition, pageId;
        var sources = {'flv': '', 'mp4': '', 'webm': ''};
        var pageIndices = this.app.carousel.sheets[this.app.carousel.current.sheet].pageIndices;
        var pageWidth = this.app.config.sheet.pageSize[this.app.viewport.orientation].w;
        var ns = '.Flipbook.OverlayVideo';

        // Toggle the Display of the Video Controls
        var toggleVideoControls = function($videoContainer, $videoEl) {
            return $.proxy(function(e) {
                if ($videoEl.attr('controls') === undefined) {
                    $videoEl.attr('controls', true);
                } else {
                    $videoEl.removeAttr('controls');
                }
            }, this);
        };
        
        
        // Debug Message
        Flipbook.log('overlay-add-videos');
        
        // Iterate Pages of Current Sheet
        for (i = 0; i < pageIndices.length; i++) {
            pageId = _.parseInt(this.app.parsedPageData[pageIndices[i]].realId, 10);
            
            // Find Videos for Each Page of Current Sheet
            videos = this.app.parsedPageData[ pageIndices[i] ].videos;
            if (videos && videos.length) {
                    
                // Iterate All Videos for Page
                for (j = 0; j < videos.length; j++) {
                    // Get Area of Video
                    rect = videos[j].scaled;
                    
                    // Create New Video Element
                    if (videoCount >= this.$overlayVideoEls.length) {
                        // Create Video
                        $videoContainer = this.addTemplate({
                            'video_id'      : videos[j].id,
                            'video_page_id' : pageId,
                            'width'         : rect.w,
                            'height'        : rect.h
                        });
                        
                        // Add to Internal Video Array for later Reuse
                        this.$overlayVideoEls.push($videoContainer);
                        
                        // Get Handle to Video Element
                        $video = this.$overlayVideoEls[videoCount].find('video');
                        
                        // Attach Events to Video
                        $video.on('play'+ns, this.handleVideoEvent('play', $videoContainer));
                        $video.on('pause'+ns, this.handleVideoEvent('pause', $videoContainer));
                        
                        // Prevent Resizing the Flipbook when Entering Fullscreen Mode
                        $video.on('fullscreenchange'+ns+' mozfullscreenchange'+ns+' webkitfullscreenchange'+ns+' MSFullscreenChange'+ns, Flipbook.preventResize);
                        $video.on('webkitbeginfullscreen'+ns+' webkitendfullscreen'+ns, Flipbook.preventResize); // for iOS/Safari
                        
                        // Toggle Video Controls
                        Flipbook.onClickTap(this.app, $videoContainer, toggleVideoControls($videoContainer, $video), this, 'OverlayVideo');
                    }
                    
                    // Build Sources to Video Player
                    sources.flv = videos[j].src;
                    sources.mp4 = videos[j].src.replace('.flv', '.mp4');
                    sources.webm = videos[j].src.replace('.flv', '.webm');
                    
                    if (Flipbook.isMobile.any(this.app)) {
                        sources.webm = videos[j].src.replace('.mp4', '.webm');
                        
                        if (/iphone/i.test(navigator.userAgent) && Flipbook.root.devicePixelRatio < 2) {
                            sources.mp4 = videos[j].src.replace('.mp4', '-legacy.mp4');
                        }
                    }
                    
                    // Determine Correct Left Position
                    leftPosition = rect.x;
                    if (i > 0 || (this.app.carousel.current.sheet === 0 && Flipbook.isFirstPageOnRight(this.app))) {
                        leftPosition += pageWidth;
                    }
                    
                    // Apply Style & Attributes to Video
                    this.$overlayVideoEls[videoCount]
                        .attr('data-page-idx', pageIndices[i])
                        .attr('data-video-id', videos[j].id)
                        .css({
                            'display' : 'block',
                            'left'    : leftPosition,
                            'top'     : rect.y,
                            'width'   : rect.w + 1,
                            'height'  : rect.h + 1
                        });
                    
                    // Set Video Attributes
                    $video.attr('preload', 'metadata');
                    
                    // AutoPlay / AutoHide Attributes
                    if (Flipbook.TRUTHY.test(videos[j].autoplay)) {
                        $video.prop('autoplay', !Flipbook.isMobile.any(this.app));
                    }
                    if (!Flipbook.TRUTHY.test(videos[j].autohide) || Flipbook.isMobile.any(this.app)) {
                        $video.prop('controls', true);
                    }
                    
                    // Add Sources to Video Player
                    $video.append('<source src="' + sources.mp4 + '" type="video/mp4">');
                    $video.append('<source src="' + sources.webm + '" type="video/webm">');
                    $video.append('<p>Your browser does not support this video.</p>');
                    
                    // Track Number of Visible Videos
                    videoCount++;
                }
            }
        }
        
        // Overlay Added; Resolve Promise
        resolve({'type': 'Video', 'count': videoCount});
    }, this));
};

/**
 * Override Abstract Base Method; Called when ...
 *
 * @public
 * @this Flipbook.VideoOverlay
 * @return {Object} A Promise
 */
Flipbook.VideoOverlay.prototype.remove = function() {
    return Q.Promise($.proxy(function(resolve, reject, notify) {
        var videoCount = this.$overlayVideoEls.length;
        
        // Debug Message
        Flipbook.log('overlay-remove-videos');

        // Remove Page Videos
        for (var i = 0; i < videoCount; i++) {
            if (this.$overlayVideoEls[i].length) {
                this.$overlayVideoEls[i].remove();
            }
        }
        this.$overlayVideoEls.length = 0;
        
        // Overlay Removed; Resolve Promise
        resolve({'type': 'Video', 'count': videoCount});
    }, this));
};


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Calculate the Size/Position of the Video Elements based on the Size of the Page
 *
 * @public
 * @this Flipbook.VideoOverlay
 * @return undefined
 */
Flipbook.VideoOverlay.prototype.calculateSizes = function() {
    var i, j, r;
    var scaleRatio;
    var $pageData;
    var pageWidth;
    var pageDisplayWidth = this.app.config.sheet.pageSize[this.app.viewport.orientation].w;
    
    // Debug Message
    Flipbook.log('overlay-calc-size-videos');
    
    // Calculate Sizes of Page Videos
    for (i = 0; i < this.app.parsedPageData.length; i++) {
        $pageData = this.app.config.$issueSettings.find('pages > page[realid=' + this.app.parsedPageData[i].realId + ']');
        if (!$pageData.length) { continue; }
        
        // Get the Original Page Width
        pageWidth = (_.parseInt($pageData.attr('width'), 10) || 1);
        
        // Get the Scale Ratio for the Original Page to the Display Page
        scaleRatio = pageDisplayWidth / pageWidth;

        // Apply Scale
        for (j = 0; j < this.app.parsedPageData[i].videos.length; j++) {
            r = this.app.parsedPageData[i].videos[j].rect.split(',');
            
            // Set Size/Position
            this.app.parsedPageData[i].videos[j].scaled = {
                'x': Math.round(parseFloat(r[0]) * scaleRatio), 
                'y': Math.round(parseFloat(r[1]) * scaleRatio), 
                'w': Math.round(parseFloat(r[2]) * scaleRatio), 
                'h': Math.round(parseFloat(r[3]) * scaleRatio),
                's': scaleRatio
            };
        }
    }
};

/**
 * 
 * 
 * @public
 * @this Flipbook.VideoOverlay
 * @return undefined
 */
Flipbook.VideoOverlay.prototype.handleVideoEvent = function(eventType, $videoContainer) {
    return $.proxy(function(e) {
        
        // console.log(eventType, $videoContainer);
        
        return true;
    }, this);
};
