/**
 * Flyp Technologies Inc. - Flipbook v4
 *
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/config.js - Flipbook.Config
 * @author Robert J. Secord, B.Sc.
 */

import Flipbook from './core';

/**
 * Flipbook Default Config Data
 * 
 * @public
 * @static
 */
Flipbook.Config = {
    
    /**
     * Flipbook Container Element
     *   If empty will use body element.
     *   Useful for Embedding Flipbook.
     */
    'container' : '',
    
    /**
     * Flipbook Loading Screen
     */
    'loadingScreen' : {
        'selector'  : '.fb4-loading-wrapper',
        'hideDelay' : 1500
    },
    
    /**
     * Flipbook Starting Page
     */
    'startPage' : 0,
    
    /**
     * Carousel Configs
     */
    'carousel' : {
        'snapThresholdPct'  : 0.1,
        'defaultSlideSpeed' : 400
    },
    
    /**
     * Sheet Configs
     */
    'sheet' : {
        'maxBufferSize'  : {'slider': 5, 'scrubber': 9},    // must always be odd numbers
        'sizePercentage' : 0.98,
        
        /**
         * True Min/Max Scale of Sheets based on Maximum Size of Flipbook Page
         */
        'minScale' : {'portrait': Flipbook.MIN_SCALE_SIZE, 'landscape': Flipbook.MIN_SCALE_SIZE},
        'maxScale' : {'portrait': Flipbook.MAX_SCALE_SIZE, 'landscape': Flipbook.MAX_SCALE_SIZE},
        
        /**
         * Size of Grid for Large HQ Images
         *   * must be between 2 and 10;  2 == 4 image parts, 3 == 9 parts, 4 == 16 parts, etc..
         */
        'gridSize' : 4,
        
        /**
         * Measured Page Sizes for Various Modes of Display
         *   w = width, h = height, m = margin-top, i = image
         */
        'pageSize' : {
            'landscape' : {'w': 0, 'h': 0, 'm': 0, 'i': '-0.jpg'}, 
            'portrait'  : {'w': 0, 'h': 0, 'm': 0, 'i': '-0.jpg'},
            'scaled'    : {
                'min' : {
                    'landscape' : {'w': 0, 'h': 0, 'i': '-0.jpg'}, 
                    'portrait'  : {'w': 0, 'h': 0, 'i': '-0.jpg'}
                },
                'max' : {
                    'landscape' : {'w': 0, 'h': 0, 'i': '-0.jpg'}, 
                    'portrait'  : {'w': 0, 'h': 0, 'i': '-0.jpg'},
                    'parts'     : [/*{'x': 0, 'y': 0, 'w': 0, 'h': 0, 'm': '-m-16-1.jpg'}*/]
                }
            }
        }  
    },

    /**
     * Tool Bar Configs
     */
    'toolbar' : {
        'enabled'     : true,
        'template'    : '#tpl-toolbar',
        'speed'       : 250,
        'scrollSpeed' : 30,
        'activeClass' : 'active',
        'position'    : Flipbook.TOOLBAR_POS_LEFT,
        'size' : {
            // When Positioned "Left": Open/Closed are Widths
            // When Positioned "Top": Open/Closed are Heights
            'phone_xs'  : {'left' : {'open': 185, 'closed':  0}, 'top' : {'open': 36, 'closed':  36}},
            'phone_sm'  : {'left' : {'open': 270, 'closed':  0}, 'top' : {'open': 36, 'closed':  36}},
            'phone_md'  : {'left' : {'open': 300, 'closed':  0}, 'top' : {'open': 42, 'closed':  42}},
            'phone_lg'  : {'left' : {'open': 320, 'closed':  0}, 'top' : {'open': 46, 'closed':  46}},
            'tablet_sm' : {'left' : {'open': 360, 'closed': 52}, 'top' : {'open': 52, 'closed':  52}},
            'tablet_lg' : {'left' : {'open': 380, 'closed': 56}, 'top' : {'open': 56, 'closed':  56}},
            'desktop'   : {'left' : {'open': 400, 'closed': 60}, 'top' : {'open': 60, 'closed':  60}}
        },
        'mobileMenu' : 'phone_xs phone_sm phone_md phone_lg',  // These breakpoints will be treated as Mobile when rendering the Toolbar
        
        'tools' : {
            'SearchTool' : {
                'enabled' : true,
                'inMenu'  : false,
                'classname' : 'toolbar-search',
                'panel'   : {
                    'id'           : 'SearchPanel',
                    'autoOpen'     : false,
                    'closeViaMask' : true,
                    'scrollable'   : true
                },
                'offlineResults'    : '#tpl-toolbar-search-results',
                'offlineResultItem' : '#tpl-toolbar-search-item'
            },
            'SharingTool' : {
                'enabled'   : function isToolEnabled_SharingTool() { return (!this.config.device.isOffline && (this.config.titleData.allow_sharing || this.config.titleData.allow_emailing)); },
                'inMenu'    : false,
                'classname' : 'toolbar-share',
                'panel'     : {
                    'id'           : 'SharingPanel',
                    'enabled'      : function isPanelEnabled_SharingPanel() { return (this.config.toolbar.position === Flipbook.TOOLBAR_POS_TOP); },
                    'autoOpen'     : true,
                    'closeViaMask' : true,
                    'scrollable'   : true
                },
                'toolsContainer'  : 'sharing-tools',
                'sharePanelTpl'   : '#tpl-toolbar-share-top',
                'twoPagePanelTpl' : '#tpl-toolbar-share-two-page'
            },
            'ArchivesTool' : {
                'enabled'   : function isToolEnabled_ArchivesTool() { return (!this.config.device.isOffline && !this.config.device.isNative && this.config.titleData.show_archive_listing); },
                'template'  : '#tpl-toolbar-tool',
                'classname' : 'archives-tool',
                'label'     : 'View Archives',
                'inMenu'    : true,
                'panel'     : {
                    'id'           : 'ArchivesPanel',
                    'autoOpen'     : true,
                    'closeViaMask' : true,
                    'scrollable'   : true,
                    'appendTo'     : '#archivedIssues'
                }
            },
            'ContentsTool' : {
                'enabled'   : ['show_toc', 'show_links'],
                'template'  : '#tpl-toolbar-tool',
                'classname' : 'contents-tool',
                'label'     : 'Table of Contents',
                'inMenu'    : true,
                'panel'     : {
                    'id'           : 'ContentsPanel',
                    'autoOpen'     : true,
                    'closeViaMask' : true,
                    'scrollable'   : true
                },
                'offlineTpl' : {
                    'container' : '#tpl-toolbar-toc',
                    'tocItem'   : '#tpl-toolbar-toc-item'
                }
            },
            'TextTool' : {
                'enabled'   : 'allow_text_tool',        // check flag in this.config.titleData
                'template'  : '#tpl-toolbar-tool',
                'classname' : 'text-tool',
                'label'     : 'Text',
                'inMenu'    : true,
                'panel'     : {
                    'id'           : 'TextPanel',
                    'autoOpen'     : true,
                    'closeViaMask' : true,
                    'scrollable'   : true
                },
                'fontSize' : {'initial': 14, 'min': 8, 'max': 20},
                'pageTextTpl' : '#tpl-toolbar-page-text'
            },
            'PdfTool' : {
                'enabled'   : function isToolEnabled_PdfTool() { return (!this.config.device.isOffline && !this.config.device.isNative && this.config.titleData.allow_downloading); },
                'template'  : '#tpl-toolbar-tool',
                'classname' : 'pdf-tool',
                'label'     : 'Download PDF',
                'inMenu'    : true,
                'panel'     : false,
                
                // The Max PDF File Size Limit to allow downloading from a Mobile Device
                //  - If the PDF is larger than this size, the user will be prompted with a warning and a confirmation to download
                //  - The Max Limit is set in "constants.php" and passed in via settings from "read_mobile_4.thtml"
                //  - A value of 0 means no Max Limit, no warning
                'mobileLimit'   : 0,
                'mobileMessage' : 'This is a large PDF file. It is recommended that you return from a desktop browser to download this PDF. <br><br>Are you sure you would like to download this PDF over your mobile device?'
            },
            'FullscreenTool' : {
                'enabled'    : function isToolEnabled_FullscreenTool() { return (this.config.source !== Flipbook.SOURCE_HUB && this.config.titleData.allow_fullscreen); },
                'template'   : '#tpl-toolbar-tool',
                'classname'  : 'fullscreen-tool',
                'label'      : 'Toggle Fullscreen',
                'inMenu'     : true,
                'panel'      : false,
                'stateClass' : 'fullscreen-mode'
            },
            'HelpTool' : {
                'enabled'   : true,
                'template'  : '#tpl-toolbar-tool',
                'classname' : 'help-tool',
                'label'     : 'Help',
                'inMenu'    : true,
                'panel'     : {
                    'id'           : 'HelpPanel',
                    'autoOpen'     : true,
                    'closeViaMask' : true,
                    'scrollable'   : true
                }
            }
        },
        
        'contentPanel' : {
            'template' : '#tpl-toolbar-content-panel',
            'scroll' : {
                'container' : '.panel-content-body',
                'header'    : '.panel-content-heading',
                'content'   : '.scrollable-content'
            },
            'width' : {
                'phone_xs'  :   0,
                'phone_sm'  :   0,
                'phone_md'  :   0,
                'phone_lg'  :   0,
                'tablet_sm' : 408,
                'tablet_lg' : 560,
                'desktop'   : 640
            }
        }
    },

    /**
     * Page Bar Configs
     */
    'pagebar' : {
        'enabled'   : true,
        'template'  : '#tpl-pagebar',
        'hideDelay' : 3000,
        'height' : {
            'phone_xs'  : 16,
            'phone_sm'  : 16,
            'phone_md'  : 16,
            'phone_lg'  : 16,
            'tablet_sm' : 20,
            'tablet_lg' : 20,
            'desktop'   : 26
        }
    },
    
    /**
     * Modal Popup
     */
    'modal' : {
        'template'  : '#tpl-modal-window',
        'container' : {
            'opacity':  1.0
        },
        'overlay'   : {
            'opacity':  0.75
        }
    },
    
    /**
     * Page Navigation
     */
    'navigation' : {
        'template'  : '#tpl-carousel-nav',
        'hideDelay' : 3000
    },
    
    /**
     * Page Overlays
     */
    'overlayer' : {
        'revealDelay' : 500,
        
        'overlays' : {
            'LinksOverlay' : {
                'enabled'  : true,
                'template' : '#tpl-page-link',
                'target'   : Flipbook.OVERLAY_TARGET_CAROUSEL
            },
            'VideoOverlay' : {
                'enabled'  : true,
                'template' : '#tpl-page-video',
                'target'   : Flipbook.OVERLAY_TARGET_CAROUSEL
            },
            'AudioOverlay' : {
                'enabled'  : true,
                'template' : '#tpl-page-audio',
                'target'   : Flipbook.OVERLAY_TARGET_CAROUSEL,
                
                'playClass' : 'playing'
            },
            'WidgetsOverlay' : {
                'enabled'  : function() { return !this.config.device.isOffline; },
                'template' : '#tpl-page-widget',
                'target'   : Flipbook.OVERLAY_TARGET_CAROUSEL,
                
                'listUrl'  : 'read/ajax_issueWidgets/',
                'itemUrl'  : 'read/page_widget/{{id}}/{{width}}/{{height}}'
            },
            'BookmarksOverlay' : {
                'enabled'  : function() { return !this.config.device.isOffline && this.config.features.annotations; },
                'template' : '#tpl-page-bookmark',
                'target'   : Flipbook.OVERLAY_TARGET_VIEWPORT,
                
                'loadUrl'   : 'read/ajax_issueAnnotations/',  // Returns JSON of both Bookmarks and Annotations
                'saveUrl'   : 'read/ajax_issueAnnotationsSave/',
                'deleteUrl' : 'read/ajax_issueAnnotationsDelete/',
                
                'addClass'    : 'add',
                'activeClass' : 'active'
            },
            'NotesOverlay' : {
                'enabled'  : function() { return !this.config.device.isOffline && this.config.features.annotations; },
                'template' : '#tpl-page-note',
                'target'   : Flipbook.OVERLAY_TARGET_CAROUSEL,
                
                'loadUrl'   : 'read/ajax_issueAnnotations/',  // Returns JSON of both Bookmarks and Annotations
                'saveUrl'   : 'read/ajax_issueAnnotationsSave/',
                'deleteUrl' : 'read/ajax_issueAnnotationsDelete/',
                
                'openClass' : 'opened',
                'editClass' : 'editing',
                'loadClass' : 'loading',
                'charsLeft' : 'chars left:',
                'maxLength' : 1000
            }
        }
    },

    /**
     * Non-Touch Configs
     */
    'nontouch' : {
        'template'  : '#tpl-nontouch-{{control}}',
        'zoomScale' : 0.5
    },

    /**
     * Shifter Configs
     */
    'shifter' : {
        'style' : Flipbook.SHIFTER_OVERLAP,  //  'shift' or 'overlap'
        'mask'  : {
            'opacity' : 0.65 
        }
    },
    
    /**
     * Responsive Breakpoints (must match breakpoints in CSS)
     * 
     * Chrome Emulators:
     *      phone_xs:   LG Optimus One
     *      phone_sm:   BB Z30, Nexus 5/S, iPhone 3GS/4/5, LG Optimus (Most), HTC (All)
     *      phone_md:   BB Z10, Nexus 4, LG Optimus G
     *      phone_lg:   Motorola Droid 3/4/Razr, Galaxy Note 3
     *      tablet_sm:  Nexus 7, Kindle Fire HD 7"
     *      tablet_lg:  BB Playbook, Kindle Fire, iPad (All)
     *      desktop:    Nexus 10, Kindle Fire HD 8.9", Motorola Xoom/Xyboard
     */
    'breakpoints' : [
        {'label': 'phone_xs',  'enter':    0, 'exit':  319},    // red
        {'label': 'phone_sm',  'enter':  320, 'exit':  359},    // dark orange
        {'label': 'phone_md',  'enter':  360, 'exit':  479},    // orange
        {'label': 'phone_lg',  'enter':  480, 'exit':  767},    // yellow
        {'label': 'tablet_sm', 'enter':  768, 'exit': 1023},    // green
        {'label': 'tablet_lg', 'enter': 1024, 'exit': 1279},    // blue
        {'label': 'desktop',   'enter': 1280, 'exit': 9999}     // purple
    ],

    /**
     * Device Type
     */
    'device' : {
        'name'          : '',
        'isOffline'     : false,    // True when in Library App - Read Offline (files downloaded to device)
        'isNative'      : false,    // True when in Library App - Read Online
        'isMobile'      : false,
        'iframe'        : false,
        'embedded'      : false,
        'hasTouch'      : false,
        'hasMultiTouch' : false,
        'has3D'         : false
    },
    
    /**
     * Namespace for All CSS
     */
    'cssNamespace' : 'fb4-css-ns',
    
    /**
     * Localization Support
     */
    'l10n' : {
        'pagebar' : {
            'tooltip' : {
                // %p[#] will be replaced with the page number(s)
                // %t will be replaced with the Total number of Pages
                'page' : ['page: %p', 'pages: %p1-%p2']
            }
        }
    },
    
    /**
     * Dev Mode URL QueryString
     */
    'urlQueryString' : '',
    
    /**
     * Page Tracking
     */
    'enableTracking'  : true,
    'minPageTime'     : 1500, // milliseconds

    /**
     * Profiling and Debugging
     */
    'enableProfiler' : false,
    'enableDebugger' : false
};
