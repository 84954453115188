/**
 * Flyp Technologies Inc. - Flipbook v4
 *
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/constants.js - Flipbook.Constants
 * @author Robert J. Secord, B.Sc.
 */
import Flipbook from './core';
import Shared from './shared_util';

/** 
 * Carousel Event Controllers
 * 
 *   When the Carousel is in a specific Mode, the respective Carousel Class
 *   handles the events for that Mode; function names in the class should
 *   begin with the mode name (ex: sliderInitBuffer(), scrubberInitBuffer(), etc..)
 *                                 ^^^^^^              ^^^^^^^^
 */
Flipbook.CAROUSEL_SLIDER    = 'slider';
Flipbook.CAROUSEL_SCRUBBER  = 'scrubber';
Flipbook.CAROUSEL_ZOOMER    = 'zoomer';

/**
 * Image Quality
 * 
 *   Low Quality Images are for the Scrubber
 *   Default Images are for the Slider
 *   High Quality Images are for the Zoomer
 */
Flipbook.IMG_QUALITY_NONE    = 'none';
Flipbook.IMG_QUALITY_LOW     = 'lq';
Flipbook.IMG_QUALITY_DEFAULT = 'def';
Flipbook.IMG_QUALITY_HIGH    = 'hq';

/**
 * Orientation Modes
 */
Flipbook.ORIENT_PORTRAIT   = 'portrait';
Flipbook.ORIENT_LANDSCAPE  = 'landscape';

/**
 * Shifter Styles
 */
Flipbook.SHIFTER_SHIFT   = 'shift';
Flipbook.SHIFTER_OVERLAP = 'overlap';

/**
 * Toolbar Position
 */
Flipbook.TOOLBAR_POS_TOP  = 'top';
Flipbook.TOOLBAR_POS_LEFT = 'left';

/**
 * Flipbook Source Types
 */
Flipbook.SOURCE_STANDALONE  = 'standalone';
Flipbook.SOURCE_HUB         = 'hub';
Flipbook.SOURCE_EMBED       = 'embed';

/**
 * Page Links
 */
// The Amount of Time a Link is Flashed on the Screen for
//   (allows the user to know it is there)
Flipbook.PAGE_LINK_FLASH_DELAY = 1000;

// Large Link Area (percentage)
//   Links that take up this amount of the page area are considered large
Flipbook.PAGE_LINK_LARGE_AREA = 0.6;

/** 
 * Absolute Min/Max Scale for All Flipbooks
 * 
 *   Do Not use these values directly, instead use: 
 *     Flipbook.Config.sheet.minScale[this.app.viewport.orientation]
 *     Flipbook.Config.sheet.maxScale[this.app.viewport.orientation]
 *   for True Min/Max Scaling Values, as they are calculated based on
 *   the Maximum size of a Specific Flipbook Page (this.app.config.issueData.w2)
 *   
 *   Default Scale is 1, 
 *   Min Scale is for Scrubber,
 *   Max Scale is for Zoomer
 */
Flipbook.MIN_SCALE_SIZE = 0.3;
Flipbook.MAX_SCALE_SIZE = 6.0;

/**
 * Keyboard Key Codes
 */
Flipbook.ENTER_KEY       =  13;
Flipbook.PGUP_KEY        =  33;
Flipbook.PGDN_KEY        =  34;
Flipbook.ARROW_KEY_LEFT  =  37;
Flipbook.ARROW_KEY_UP    =  38;
Flipbook.ARROW_KEY_RIGHT =  39;
Flipbook.ARROW_KEY_DOWN  =  40;
Flipbook.Z_KEY           =  90;
//Flipbook.NUMPAD_KEY_0    =  96; // Insert
//Flipbook.NUMPAD_KEY_1    =  97; // End
Flipbook.NUMPAD_KEY_2    =  98; // Down Arrow
Flipbook.NUMPAD_KEY_3    =  99; // PgDn
Flipbook.NUMPAD_KEY_4    = 100; // Left Arrow
//Flipbook.NUMPAD_KEY_5    = 101; // Nothing
Flipbook.NUMPAD_KEY_6    = 102; // Right Arrow
//Flipbook.NUMPAD_KEY_7    = 103; // Home
Flipbook.NUMPAD_KEY_8    = 104; // Up Arrow
Flipbook.NUMPAD_KEY_9    = 105; // PgUp
Flipbook.PLUS_KEY        = 107;
Flipbook.MINUS_KEY       = 109;
Flipbook.EQUALS_KEY      = 187; // Plus
Flipbook.DASH_KEY        = 189; // Minus

// For the Nintendo Wii System:
//   (PlayStation and Xbox follow standard key mappings)
Flipbook.WII_MINUS = 170;
Flipbook.WII_PLUS  = 174;
Flipbook.WII_UP    = 175;
Flipbook.WII_DOWN  = 176;
Flipbook.WII_RIGHT = 177;
Flipbook.WII_LEFT  = 178;

/**
 * Modifier Property for Transitions
 *   if CSS Transitions/Transforms are not supported, the Modifier becomes 'left'
 *   
 *   'x'    - moves elements using CSS Translate
 *   'left' - moves elements using Top/Left Positioning
 */
Flipbook.TRANSITION_MODIFIER = 'x';

/**
 * Known Touch Devices without Multi-Touch Support
 */
Flipbook.NON_MULTI_TOUCH = [Shared.AMAZON_SILK, Shared.OPERA_MINI, Shared.NOKIA_XPRESS];

/**
 * Overlay Placement Targets
 */
Flipbook.OVERLAY_TARGET_CAROUSEL = 'carousel';
Flipbook.OVERLAY_TARGET_VIEWPORT = 'viewport';

/** 
 * For Testing Empty Values in Strings
 */
Flipbook.EMPTY = /^\s*$/;

/** 
 * For Testing Truthy Values in Strings
 */
Flipbook.TRUTHY = /true|1/i;

/** 
 * For Testing Google Analytics Codes
 */
Flipbook.VALID_GA_CODE = /\bUA-\d{4,10}-\d{1,4}\b/i;

/**
 * Debug Statements
 */
Flipbook.DEBUG_MSG = {
    'app-init'                              : 'Flipbook:App Initializing',
    'app-init-end'                          : 'Flipbook:App Complete & Ready',
    'app-reorient'                          : 'Flipbook:App Event - OrientationChange',
    'app-reorient-end'                      : 'Flipbook:App Event Complete - OrientationChange',
    'app-load-settings'                     : 'Flipbook:App Loading Settings',
    'app-load-settings-end'                 : 'Flipbook:App Settings Loaded Successfully',
    'app-build-elements'                    : 'Flipbook:App Build Elements',
    'app-build-elements-end'                : 'Flipbook:App Build Elements Complete',
    'app-resize'                            : 'Flipbook:App Resizing',
    'app-resize-elements'                   : 'Flipbook:App Event - Resize',
    'app-resize-elements-end'               : 'Flipbook:App Event Complete - Resize',
    'app-add-loading'                       : 'Flipbook:App Displaying Loading Screen',
    'app-remove-loading'                    : 'Flipbook:App Removing Loading Screen',
    'app-after-loading-screen'              : 'Flipbook:App After Loading Screen',
    'app-before-unload'                     : 'Flipbook:App Before Unload..',
    
    'shifter-init'                          : 'Flipbook:Shifter Initializing',
    'shifter-build-elements'                : 'Flipbook:Shifter Build Elements',
    'shifter-attach-events'                 : 'Flipbook:Shifter Attach Events',
    'shifter-resize'                        : 'Flipbook:Shifter Event - Resize',
    'shifter-shift-content'                 : 'Flipbook:Shifter Shifting Content',
    'shifter-restore-content'               : 'Flipbook:Shifter Restoring Content',
    
    'carousel-init'                         : 'Flipbook:Carousel Initializing',
    'carousel-build-elements'               : 'Flipbook:Carousel Build Elements',
    'carousel-attach-events'                : 'Flipbook:Carousel Attach Events',
    'carousel-resize'                       : 'Flipbook:Carousel Event - Resize',
    'carousel-page-metrics'                 : 'Flipbook:Carousel Calculate Page Metrics',
    'carousel-container-size'               : 'Flipbook:Carousel Update Container Size',
    'carousel-image-size'                   : 'Flipbook:Carousel Calculate Image Size',
    'carousel-current-sheet'                : 'Flipbook:Carousel Determine Current Sheet',
    'carousel-sheet-positions'              : 'Flipbook:Carousel Determine Sheet Positions',
    'carousel-resize-sheets'                : 'Flipbook:Carousel Resize Sheets',
    'carousel-reposition-sheets'            : 'Flipbook:Carousel Reposition Sheets',
    'carousel-resize-scrolldiv'             : 'Flipbook:Carousel Resize Scroll-Div',
    'carousel-reposition-scrolldiv'         : 'Flipbook:Carousel Reposition Scroll-Div',
    'carousel-move-to-sheet'                : 'Flipbook:Carousel Move to Sheet',
    'carousel-move-first'                   : 'Flipbook:Carousel Move to First Sheet',
    'carousel-move-prev'                    : 'Flipbook:Carousel Move to Previous Sheet',
    'carousel-move-next'                    : 'Flipbook:Carousel Move to Next Sheet',
    'carousel-move-last'                    : 'Flipbook:Carousel Move to Last Sheet',
    'carousel-toggle-view'                  : 'Flipbook:Carousel Toggle View-Mode',
    'carousel-click-tap'                    : 'Flipbook:Carousel Event - Click/Tap',
    'carousel-auto-zoom'                    : 'Flipbook:Carousel Auto-Zoom',
    'carousel-sheet-translation'            : 'Flipbook:Carousel Translate Sheet',
    'carousel-update-url'                   : 'Flipbook:Carousel Update Address Bar',
    'carousel-drag-finished'                : 'Flipbook:Carousel Event - Drag Finished',
    'carousel-key-press'                    : 'Flipbook:Carousel Event - Key Press',
    'carousel-update-overlays'              : 'Flipbook:Carousel Update Overlays',
    'carousel-flash-overlays'               : 'Flipbook:Carousel Flash Overlays',
    
    'sheet-init'                            : 'Flipbook:Sheet Initializing',
    'sheet-resize'                          : 'Flipbook:Sheet Event - Resize',
    'sheet-build-elements'                  : 'Flipbook:Sheet Build Elements',
    'sheet-load'                            : 'Flipbook:Sheet Loading {{sheet}}',
    'sheet-unload'                          : 'Flipbook:Sheet Unloading {{sheet}}',
    'sheet-display-large'                   : 'Flipbook:Sheet Displaying HQ Image',
    'sheet-update-large'                    : 'Flipbook:Sheet Updating HQ Image',
    'sheet-clear-large'                     : 'Flipbook:Sheet Clear HQ Image',
    'sheet-reset-loaded-parts'              : 'Flipbook:Sheet Reset Loaded Image Parts',
    'sheet-flash-overlays'                  : 'Flipbook:Sheet Flash Overlays',

    'overlayer-init'                        : 'Flipbook:Overlayer Initializing',
    'overlayer-build-elements'              : 'Flipbook:Overlayer Build Elements',
    'overlayer-attach-events'               : 'Flipbook:Overlayer Attach Events',
    'overlayer-resize'                      : 'Flipbook:Overlayer Event - Resize',
    'overlayer-add'                         : 'Flipbook:Overlayer Add All Overlays',
    'overlayer-remove'                      : 'Flipbook:Overlayer Remove All Overlays',
    'overlayer-update'                      : 'Flipbook:Overlayer Update All Overlays',
    'overlayer-flash'                       : 'Flipbook:Overlayer Flash All Overlays',
    'overlayer-toggle-view'                 : 'Flipbook:Overlayer Toggle Overlay View',
    'overlayer-load-all'                    : 'Flipbook:Overlayer Load All Overlays',
    'overlayer-load-failed'                 : 'Flipbook:Overlayer Failed to Load Overlay: {{overlay}}',
    'overlayer-update-all'                  : 'Flipbook:Overlayer Update All Overlays',
    'overlayer-sheet-changed'               : 'Flipbook:Overlayer Update after Sheet Change',
    'overlayer-count-msg'                   : 'Flipbook:Overlayer {{type}}: {{msg}}',
        
    'overlay-initialize'                    : 'Flipbook:Overlay Initializing Overlay: {{overlay}}',
    'overlay-resize-links'                  : 'Flipbook:Overlay Resize Links',
    'overlay-resize-widgets'                : 'Flipbook:Overlay Resize Widgets',
    'overlay-resize-videos'                 : 'Flipbook:Overlay Resize Video',
    'overlay-resize-audios'                 : 'Flipbook:Overlay Resize Audio',
    'overlay-resize-bookmarks'              : 'Flipbook:Overlay Resize Bookmarks (Load)',
    'overlay-resize-notes'                  : 'Flipbook:Overlay Resize Notes',
    'overlay-add-links'                     : 'Flipbook:Overlay Add Links',
    'overlay-add-widgets'                   : 'Flipbook:Overlay Add Widgets',
    'overlay-add-videos'                    : 'Flipbook:Overlay Add Video',
    'overlay-add-audios'                    : 'Flipbook:Overlay Add Audio',
    'overlay-add-bookmarks'                 : 'Flipbook:Overlay Add Bookmarks',
    'overlay-add-notes'                     : 'Flipbook:Overlay Add Notes',
    'overlay-remove-links'                  : 'Flipbook:Overlay Remove Links',
    'overlay-remove-widgets'                : 'Flipbook:Overlay Remove Widgets',
    'overlay-remove-videos'                 : 'Flipbook:Overlay Remove Video',
    'overlay-remove-audios'                 : 'Flipbook:Overlay Remove Audio',
    'overlay-remove-bookmarks'              : 'Flipbook:Overlay Remove Bookmarks',
    'overlay-remove-notes'                  : 'Flipbook:Overlay Remove Notes',
    'overlay-update-bookmarks'              : 'Flipbook:Overlay Update Bookmarks',
    'overlay-flash-links'                   : 'Flipbook:Overlay Flash Links: {{linkCount}}',
    'overlay-calc-size-links'               : 'Flipbook:Overlay Calculate Size of Links',
    'overlay-calc-size-widgets'             : 'Flipbook:Overlay Calculate Size of Widgets',
    'overlay-calc-size-videos'              : 'Flipbook:Overlay Calculate Size of Videos',
    'overlay-calc-size-audios'              : 'Flipbook:Overlay Calculate Size of Audios',
    'overlay-calc-size-notes'               : 'Flipbook:Overlay Calculate Size of Notes',
    'overlay-link-clicked'                  : 'Flipbook:Overlay Event - Link Clicked {{linkId}}',
    'overlay-widget-interaction'            : 'Flipbook:Overlay Widget Interaction Tracked! WidgetID: {{widget}}  PageID: {{page}}',
    'overlay-note-clicked'                  : 'Flipbook:Overlay Event - Note Clicked; NoteID: {{id}}, Action: {{action}}',
    
    'slider-init-buffer'                    : 'Flipbook:Carousel-Slider Initialize Buffer',
    'slider-update-buffer'                  : 'Flipbook:Carousel-Slider Update Buffer',
    'slider-check-position'                 : 'Flipbook:Carousel-Slider Checking Position',
    
    'scrubber-init-buffer'                  : 'Flipbook:Carousel-Scrubber Initialize Buffer',
    'scrubber-update-buffer'                : 'Flipbook:Carousel-Scrubber Update Buffer',
    
    'zoomer-reset-zoom'                     : 'Flipbook:Carousel-Zoomer Reset Zoom',
    'zoomer-reset-pinch'                    : 'Flipbook:Carousel-Zoomer Reset Pinch',
    'zoomer-bounce-back'                    : 'Flipbook:Carousel-Zoomer Bounce-Back to Boundary',
    'zoomer-apply-momentum'                 : 'Flipbook:Carousel-Zoomer Applying Momentum',
    'zoomer-key-press'                      : 'Flipbook:Carousel-Zoomer Key Pressed: {{key}}',
    
    'toolbar-init'                          : 'Flipbook:Toolbar Initializing',
    'toolbar-build-elements'                : 'Flipbook:Toolbar Build Elements',
    'toolbar-attach-events'                 : 'Flipbook:Toolbar Attach Events',
    'toolbar-resize'                        : 'Flipbook:Toolbar Event - Resize',
    'toolbar-load-all-tools'                : 'Flipbook:Toolbar Load All Tools',
    'toolbar-tool-initialize'               : 'Flipbook:Toolbar Initializing Tool: {{tool}}',
    'toolbar-tool-build-elements'           : 'Flipbook:Toolbar Build Elements for Tool: {{tool}}',
    'toolbar-tool-attach-events'            : 'Flipbook:Toolbar Attach Events for Tool: {{tool}}',
    'toolbar-load-tool-failed'              : 'Flipbook:Toolbar Failed to Load Tool: {{tool}} (resource not found)',
    
    'pagebar-init'                          : 'Flipbook:Pagebar Initializing',
    'pagebar-build-elements'                : 'Flipbook:Pagebar Build Elements',
    'pagebar-attach-events'                 : 'Flipbook:Pagebar Attach Events',
    'pagebar-resize'                        : 'Flipbook:Pagebar Event - Resize',
    'pagebar-move-to-sheet'                 : 'Flipbook:Pagebar Move to Sheet {{sheet}}',
    'pagebar-update-tip'                    : 'Flipbook:Pagebar Update Tooltip',
    'pagebar-sheet-from-drag'               : 'Flipbook:Pagebar Get Sheet from Drag Event',
    'pagebar-update-after-drag'             : 'Flipbook:Pagebar Update After Drag',
    'pagebar-update-after-scrubber-drag'    : 'Flipbook:Pagebar Update After Scrubber Drag',
    
    'modal-init'                            : 'Flipbook:Modal Initializing',
    'modal-build-elements'                  : 'Flipbook:Modal Build Elements',
    'modal-attach-events'                   : 'Flipbook:Modal Attach Events',
    
    'navigation-init'                       : 'Flipbook:Navigation Initializing',
    'navigation-build-elements'             : 'Flipbook:Navigation Build Elements',
    'navigation-attach-events'              : 'Flipbook:Navigation Attach Events',
    'navigation-resize'                     : 'Flipbook:Navigation Event - Resize',
    'navigation-toggle'                     : 'Flipbook:Navigation Toggle View Mode',
    'navigation-tapped'                     : 'Flipbook:Navigation Carousel Tapped',
    'navigation-click'                      : 'Flipbook:Navigation Event - Click',
    
    'nontouch-init'                         : 'Flipbook:Non-Touch-Controls Initializing',
    'nontouch-build-elements'               : 'Flipbook:Non-Touch-Controls Build Elements',
    'nontouch-attach-events'                : 'Flipbook:Non-Touch-Controls Attach Events',
    'nontouch-show'                         : 'Flipbook:Non-Touch-Controls Display Controls',
    'nontouch-hide'                         : 'Flipbook:Non-Touch-Controls Hide Controls',
    'nontouch-pan-up'                       : 'Flipbook:Non-Touch-Controls Pan Up',
    'nontouch-pan-down'                     : 'Flipbook:Non-Touch-Controls Pan Down',
    'nontouch-pan-left'                     : 'Flipbook:Non-Touch-Controls Pan Left',
    'nontouch-pan-right'                    : 'Flipbook:Non-Touch-Controls Pan Right',
    'nontouch-pan-center'                   : 'Flipbook:Non-Touch-Controls Pan Center',
    'nontouch-zoom-in'                      : 'Flipbook:Non-Touch-Controls Zoom In',
    'nontouch-zoom-out'                     : 'Flipbook:Non-Touch-Controls Zoom Out',
    'nontouch-zoom-close'                   : 'Flipbook:Non-Touch-Controls Close Zoom Controls',
    
    'util-measure-viewport'                 : 'Flipbook:Utils Measure Viewport',
    'util-get-total-sheets'                 : 'Flipbook:Utils Get Total Sheets',
    'util-get-start-sheet'                  : 'Flipbook:Utils Get Starting Sheet',
    'util-get-sheet-from-page'              : 'Flipbook:Utils Get Sheet from Page Number',
    'util-get-page-from-sheet'              : 'Flipbook:Utils Get Page from Sheet Number',
    
    'ajax-failed'                           : 'Failed to Fetch Ajax. Reason: {{reason}}',
    'ajax-failed-parse'                     : 'Shared.fetchAjax Unable to Parse JSON. Reason: {{reason}}',
    'ajax-failed-receive'                   : 'Shared.fetchAjax Failed to receive ajax result from server. Reason: {{reason}}',
    
    'no-flipbook-settings'                  : 'Failed to load settings for Flipbook: {{reason}}',
    'no-flipbook-notes'                     : 'Annotations Disabled or No Notes/Bookmarks found!',
    
    'connection-error'                      : 'Unable to connect to the Internet',
    'server-error'                          : 'Failed to receive the requested resource: {{error}}',
    'template-build-error'                  : 'Failed to Build from Template.  Template not specified.',
    'template-build-not-found'              : 'Failed to Build from Template.  Template not found: {{template}}.',
    
    'stats-init'                            : 'Flipbook:Stats Initializing',
    'stats-flipbook-opened'                 : 'Flipbook:Stats Event - Flipbook Opened',
    'stats-flipbook-closed'                 : 'Flipbook:Stats Event - Flipbook Closed',
    'stats-page-changed'                    : 'Flipbook:Stats Event - Page Changed',
    'stats-page-zoomed'                     : 'Flipbook:Stats Event - Page Zoomed',
    'stats-page-resized'                    : 'Flipbook:Stats Event - Page Resized',
    'stats-page-social-share'               : 'Flipbook:Stats Event - Social Share',
    'stats-page-widget-interact'            : 'Flipbook:Stats Event - Widget Interaction',
    'stats-page-pdf-download'               : 'Flipbook:Stats Event - PDF Download',
    'stats-tracking-failed'                 : 'Flipbook:Stats Error - Tracking Failed: {{reason}}',
    'stats-send-to-flyptech'                : 'Flipbook:Stats Sending to Flyptech',
    'stats-send-to-google'                  : 'Flipbook:Stats Sending to Google',
    'stats-send-to-google-tag-manager'      : 'Flipbook:Stats Sending to Google Tag Manager',

    // Profiler
    'profiler-display'                      : 'Shared:Profiler (profile: {{name}}) - Elapsed Time: {{elapsed}} seconds'
};
