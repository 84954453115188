/**
 * Flyp Technologies Inc. - Flipbook v4
 * 
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/modal.js - Flipbook.Modal
 * @author Robert J. Secord, B.Sc.
 */
import 'jquery-migrate';
import $ from 'jquery';
import Flipbook from './core';
import Shared from './shared_util';
import IScroll from '/app/libs/iscroll5/iscroll';
import Q from '/app/libs/promise/q';

/**
 * Flipbook Modal
 *
 * @class Modal
 * @classdesc Application Modal Controller
 * @namespace Flipbook
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.Modal = function(app, $container) {

    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize      = null;
    var buildElements   = null;
    var attachEvents    = null;


    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    this.app             = app;
    this.$container      = $container;
    this.$modal          = null;
    this.$overlay        = null;
    this.$modalHeader    = null;
    this.$modalContent   = null;
    this.$modalFooter    = null;
    this.$modalButtons   = null;
    this.$modalScrollbar = null;
    this.$okButton       = null;
    this.$cancelButton   = null;
    this.$closeButton    = null;
    this.deferred        = null;
    this.closable        = false;
    this.showDelay       = 0;
    this.customTheme     = '';

    /* **************************************************************************************** */
    /* * Private Methods/Members Definitions                                                  * */
    /* **************************************************************************************** */
    /**
     * Initialize the Modal Control
     *
     * @private
     * @this Flipbook.Modal
     * @return {Object} A reference to the Modal Object for Method Chaining
     * @constructs
     */
    initialize = $.proxy(function() {
        // Debug Message
        Flipbook.log('modal-init');
        
        // Build the Modal Elements
        buildElements();
        attachEvents();

        return this;
    }, this);

    /**
     * Build the Toolbar Elements
     *
     * @private
     * @this Flipbook.Modal
     * @return undefined
     */
    buildElements = $.proxy(function() {
        var $contentParent = null;
        
        // Debug Message
        Flipbook.log('modal-build-elements');
        
        // Build Modal from Template
        Flipbook.buildFromTemplate({'template': this.app.config.modal.template, 'appendTo': this.$container});
        
        // Get Handles to Modal Elements
        this.$modal         = this.$container.find('.modal-container');
        this.$overlay       = this.$container.find('.modal-overlay');
        this.$modalHeader   = this.$modal.find('.modal-header');
        this.$modalContent  = this.$modal.find('.modal-content-inner');
        this.$modalFooter   = this.$modal.find('.modal-footer');
        this.$modalButtons  = this.$modal.find('.modal-button');
        this.$okButton      = this.$modal.find('.modal-button.ok');
        this.$cancelButton  = this.$modal.find('.modal-button.cancel');
        this.$closeButton   = this.$overlay.find('.modal-close');
    }, this);

    /**
     * Attach Events to the Toolbar Elements
     *
     * @private
     * @this Flipbook.Modal
     * @return undefined
     */
    attachEvents = $.proxy(function() {
        // Debug Message
        Flipbook.log('modal-attach-events');
        
        // Attach Events
        Flipbook.onClickTap(this.app, this.$okButton, this.handleOk, this, 'Modal');
        Flipbook.onClickTap(this.app, this.$cancelButton, this.handleCancel, this, 'Modal');
        Flipbook.onClickTap(this.app, this.$closeButton, this.handleClose, this, 'Modal');
        Flipbook.onClickTap(this.app, this.$overlay, this.handleClose, this, 'Modal');
    }, this);
    
    
    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize();
};
// End of Flipbook.Modal


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * 
 *      
 * @public
 * @this Flipbook.Modal
 * @return {Object} A reference to the Modal Object for Method Chaining
 */
Flipbook.Modal.prototype.clearModal = function() {
    // Clear Existing Modal
    this.$modal.removeAttr('style');
    if (this.$modalHeader.length) { this.$modalHeader.empty().css({'display': 'none'}); }
    if (this.$modalFooter.length) { this.$modalFooter.css({'display': 'none'}); }
    if (this.$modalContent.length) { this.$modalContent.empty().removeAttr('style'); this.$modalContent.parent().removeAttr('style'); }
    if (this.$modalButtons.length) { this.$modalButtons.css({'display': 'none'}); }
    if (this.$closeButton.length) { this.$closeButton.css({'display': 'none'}); }
    
    this.closable = false;
    
    return this;
};

/**
 * 
 *      
 * @public
 * @this Flipbook.Modal
 * @return {Object} A reference to the Modal Object for Method Chaining
 */
Flipbook.Modal.prototype.prepareModal = function(options) {
    var pct, contentPadding = 2; // Border Width
    var newHeight = 0;
    
    // Prepare Modal Container
    // Custom Theme
    if (options.theme !== undefined) {
        this.customTheme = options.theme;
        this.app.viewport.$element.addClass(this.customTheme);
    }
    
    // Padding
    if (options.padding !== undefined) {
        this.$modalContent.parent().css({'margin': options.padding});
        contentPadding += (options.padding * 2);
    }
    
    // Prepare Header
    if (options.header !== undefined && options.header) {
        if (typeof options.header === 'string' && options.header.length && this.$modalHeader.length) {
            this.$modalHeader.css({'display': 'block'}).append($('<p>').html(options.header));
        }
    }
    
    // Prepare Footer
    if ((options.footer !== undefined && options.footer) || (options.ok !== undefined && options.ok) || (options.cancel !== undefined && options.cancel)) {
        this.$modalFooter.css({'display': 'block'});
        
        if (options.ok !== undefined && options.ok && this.$okButton.length) {
            this.$okButton.html(options.ok).css({'display': 'inline-block'});
        }
        if (options.cancel !== undefined && options.cancel && this.$cancelButton.length) {
            this.$cancelButton.html(options.cancel).css({'display': 'inline-block'});
        }
    }
    
    // Width
    if (options.width !== undefined && options.width > 0) {
        // Only set width if Modal Fits
        if (this.app.viewport.width > options.width) {
            this.$modal.css({
                'width': options.width,
                'margin-left': -(options.width / 2)
            });
        } 
    }
    // Height
    if (options.height !== undefined && options.height > 0) {
        newHeight = options.height + contentPadding;
        if (this.$modalHeader.css('display') === 'block') {
            newHeight += this.$modalHeader.outerHeight();
        }
        if (this.$modalFooter.css('display') === 'block') {
            newHeight += this.$modalFooter.outerHeight();
        }
            
        // Only set height if Modal Fits
        if (this.app.viewport.height > newHeight + (newHeight * 0.15)) {
            this.$modal.css({'margin-top': -(newHeight / 2)});
            this.$modalContent.parent().css({'min-height': 0, 'height': options.height});
        }
    }
    
    // Prepare Content
    if (options.content !== undefined && options.content.length && this.$modalContent.length) {
        if (typeof options.content === 'string') {
            this.$modalContent.html(options.content);
        } else {
            this.$modalContent.append(options.content);
        }
    }
    if (options.align !== undefined && options.align.length && this.$modalContent.length) {
        this.$modalContent.css({'text-align': options.align});
    }
    
    // Prepare Overlay
    if (options.closable !== undefined && options.closable && this.$closeButton.length) {
        this.$closeButton.css({'display': 'block'});
        this.closable = true;
    }
    if (options.pointerEvents !== undefined && options.pointerEvents.length && this.$modalContent.length) {
        this.$modal.css({'pointer-events': options.pointerEvents});
    }
    if (options.delay !== undefined && options.delay > 0 && this.$modalContent.length) {
        this.showDelay = options.delay;
    }
    
    return this;
};

/**
 * Shows the Modal via Content
 *      
 * @public
 * @this Flipbook.Modal
 * @return {Object} A Promise
 */
Flipbook.Modal.prototype.content = function(content, options) {
    var modalOptions = {
        'type'     : 'content',
        'header'   : false,
        'footer'   : false,
        'content'  : content,
        'align'    : 'left',
        'closable' : true,
        'padding'  : 2
    };
    
    // Merge Custom Options
    modalOptions = $.extend(true, {}, modalOptions, options);
    
    // Show the Modal
    return this.show(modalOptions);
};

/**
 * Shows the Modal via Alert
 *      
 * @public
 * @this Flipbook.Modal
 * @return {Object} A Promise
 */
Flipbook.Modal.prototype.alert = function(message, options) {
    var modalOptions = {
        'type'     : 'alert',
        'ok'       : 'ok',
        'cancel'   : false,
        'header'   : 'Alert!',
        'content'  : $('<p>').html(message),
        'align'    : 'center',
        'closable' : false
    };
    
    // Merge Custom Options
    modalOptions = $.extend(true, {}, modalOptions, options);
    
    // Show the Modal
    return this.show(modalOptions);
};

/**
 * Shows the Modal via Confirm
 *      
 * @public
 * @this Flipbook.Modal
 * @return {Object} A Promise
 */
Flipbook.Modal.prototype.confirm = function(message, options) {
    var modalOptions = {
        'type'     : 'confirm',
        'ok'       : 'yes',
        'cancel'   : 'no',
        'header'   : 'Are you sure?',
        'content'  : $('<p>').html(message),
        'align'    : 'left',
        'closable' : false
    };
    
    // Merge Custom Options
    modalOptions = $.extend(true, {}, modalOptions, options);
    
    // Show the Modal
    return this.show(modalOptions);
};

/**
 * Shows the Modal and Returns a Promise that gets Resolved when the Modal is Closed
 *      
 * @public
 * @this Flipbook.Modal
 * @return {Object} A Promise
 */
Flipbook.Modal.prototype.show = function(options) {
    var opacity = this.app.config.modal.container.opacity;
    
    // Create Promise Object
    this.deferred = Q.defer();
    
    // Prepare New Modal
    this.clearModal().prepareModal(options);

    // Show after Delay (if any)
    Shared.onNextEventLoop(function() {
        // Set Body Style
        this.app.viewport.$element.addClass('modal-active');
        
        // Add Type Class
        this.$modal.addClass('type-' + options.type);
        
        // Check for Custom Modal Opacity
        if (options.opacity !== undefined && options.opacity > 0) {
            opacity = options.opacity;
        }
        
        // Show Modal with Transitions
        this.$modal.transition({'opacity': opacity}, 350);
        this.$overlay.transition({'opacity': this.app.config.modal.overlay.opacity}, 350);
        
        // Create Scrollbar Object
        //   - Must Initialize IScroll *after* the Modal is displayed, so that IScroll can measure the height of the Content.
        Shared.onNextEventLoop(function() {
            this.$modalScrollbar = new IScroll(this.$modal.find('.modal-content')[0], {  // '.modal-container .modal-content', {
                'scrollbars'            : 'custom',
                'mouseWheel'            : true,
                'interactiveScrollbars' : true,
                'shrinkScrollbars'      : 'clip',
                'fadeScrollbars'        : true
            });
        }, this);
        
        // Reset Delay
        this.showDelay = 0;
    }, this, this.showDelay);
    
    return this.deferred.promise;
};

/**
 * Handles the Click Event on the OK Button
 *   - Hides the Modal and Resolves the Promise
 *      
 * @public
 * @this Flipbook.Modal
 * @return undefined 
 */
Flipbook.Modal.prototype.handleOk = function() {
    // Hide Modal
    this.hide(true);
};

/**
 * Handles the Click Event on the Cancel Button
 *   - Hides the Modal and Rejects the Promise
 *      
 * @public
 * @this Flipbook.Modal
 * @return undefined 
 */
Flipbook.Modal.prototype.handleCancel = function() {
    // Hide Modal
    this.hide(false);
};

/**
 * Handles the Click Event on the Close Button
 *   - Hides the Modal and Rejects the Promise
 *      
 * @public
 * @this Flipbook.Modal
 * @return undefined 
 */
Flipbook.Modal.prototype.handleClose = function() {
    // Hide Modal
    if (this.closable) {
        this.hide(false).clearModal();
    }
};

/**
 * Hides the Modal and Resolves or Rejects the Promise
 *      
 * @public
 * @this Flipbook.Modal
 * @param {Boolean} resolveValue - True if OK was clicked, False if Cancel was clicked
 * @return {Object} A reference to the Modal Object for Method Chaining
 */
Flipbook.Modal.prototype.hide = function(resolveValue) {
    // Hide Modal with Transitions
    this.$modal.transition({'opacity': 0}, 350);
    this.$overlay.transition({'opacity': 0}, 350, $.proxy(function() {
        // Destroy Scrollers
        //this.$modalScrollbar.destroy();
        
        // Set Body Style
        this.app.viewport.$element.removeClass('modal-active');
        
        // Remove Type Class
        this.$modal.removeClass('type-content type-confirm type-alert');
        
        // Remove Custom Theme
        if (this.customTheme.length) {
            this.app.viewport.$element.removeClass(this.customTheme);
        }
        this.customTheme = '';
        
        // Resolve Promise
        if (resolveValue) {
            this.deferred.resolve('ok');
        } else {
            this.deferred.reject('cancel');
        }
    }, this));
    
    return this;
};

/**
 * Returns a Handle to the Modal Element
 *      
 * @public
 * @this Flipbook.Modal
 * @return undefined 
 */
Flipbook.Modal.prototype.getModal = function() {
    return this.$modal;
};

/**
 * Returns a Handle to the Overlay Element
 *      
 * @public
 * @this Flipbook.Modal
 * @return undefined 
 */
Flipbook.Modal.prototype.getOverlay = function() {
    return this.$overlay;
};

/**
 * Updates the Content of the Modal without Closing
 *      
 * @public
 * @this Flipbook.Modal
 * @return undefined 
 */
Flipbook.Modal.prototype.appendContent = function(newContent) {
    if (newContent === undefined || !newContent.length) { return; }
    
    // Convert String to Element
    if (typeof newContent === 'string') {
        newContent = $('<p>' + newContent + '</p>');
    }
    
    // Append Content to Modal
    this.$modalContent.append(newContent);
    
    // Refresh Scrollbar
    this.$modalScrollbar.refresh();
};
