/**
 * Flyp Technologies Inc. - Flipbook v4
 *
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/stats.js - Flipbook.Stats
 * @author Robert J. Secord, B.Sc.
 */
import Flipbook from './core';
import Shared from './shared_util';
import getParentOrigin from './../flipbook_parent_origin';

/**
 * Flipbook Stats
 *
 * @class Stats
 * @classdesc Application Stats-Tracking Controller
 * @namespace Flipbook
 * @param {Object} app The Application instance
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.Stats = function(app) {

    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize = null,
        insertTrackers = null;


    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    this.app             = app;
    this.flipbookId      = 0;
    this.startTime       = Shared.getTime();
    this.lastOrientation = null;
    this.currentPageIds  = [];
    this.currentPageFLs  = [];


    /* **************************************************************************************** */
    /* * Private Methods/Members                                                              * */
    /* **************************************************************************************** */
    /**
     * Initialize the Stats Class
     *
     * @private
     * @this Flipbook.Stats
     * @return {Object} A reference to the Stats Object
     * @constructs
     */
    initialize = $.proxy(function() {

        // Debug Message
        Flipbook.log('stats-init');

        // Get Flipbook ID (titleId or issueId or geoId)
        this.flipbookId = parseInt(this.app.config.stats.id, 10);

        // Insert Tracking Scripts
        insertTrackers();
        return this;
    }, this);

    /**
     * Insert the Tracking Scripts into the Page
     *
     * @private
     * @this Flipbook.Stats
     * @return undefined
     */
    insertTrackers = $.proxy(function() {
        if (!this.app.config.enableTracking || !this.app.config.analyticsCodes || !this.app.config.analyticsCodes.length) { return; }

        // Google Analytics
        Flipbook.root.ga = [];

        window.dataLayer = window.dataLayer || [];
        window.gtag = function() {
            window.dataLayer.push(arguments);
        };
        gtag('js', new Date());

        var analyticsCodes = this.app.config.analyticsCodes;
        if (!Array.isArray(analyticsCodes)) {
            analyticsCodes = [analyticsCodes];
        }
        var gtagScript = document.createElement('script');
        gtagScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=' + analyticsCodes[0]);
        gtagScript.async = true;
        document.head.appendChild(gtagScript);
        for (var index = 0; index < analyticsCodes.length; ++index) {
            var analyticsCode = analyticsCodes[index];
            gtag('config', analyticsCode, {cookie_expires: 31536000, cookie_flags: 'secure;samesite=none'});
        }
    }, this);


    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize();
};
// End of Flipbook.Stats


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Track Flipbook-Open Events
 *
 * @public
 * @this Flipbook.Stats
 * @return {Object} A reference to the Stats Object for Method Chaining
 */
Flipbook.Stats.prototype.flipbookOpened = function() {
    // Store: Current Page(s)
    this.getCurrentPages().getCurrentPageFLs();

    // Track Page Views in UFA
    this.trackPageViews();

    var data = null;

    if (!this.app.config.enableTracking) { return; }

    // Debug Message
    Flipbook.log('stats-flipbook-opened');

    // Store: Current Orientation
    this.lastOrientation = this.app.viewport.orientation;

    // Store: Start Time
    this.startTime = Shared.getTime();

    // Track: Flipbook Opened
    data = {'flipbookOpened': true, 'openedPages': this.currentPageIds, 'openedPageFLs': this.currentPageFLs};
    return this.sendToFlyptech(data).sendToIntegrations(data).sendToGoogleTagManager(data);
};

/**
 * Track Flipbook-Close Events
 *
 * @public
 * @this Flipbook.Stats
 * @return {Object} A reference to the Stats Object for Method Chaining
 */
Flipbook.Stats.prototype.flipbookClosed = function() {
    return this;
};

/**
 * Track Flipbook-Page-Change Events
 *
 * @public
 * @this Flipbook.Stats
 * @return {Object} A reference to the Stats Object for Method Chaining
 */
Flipbook.Stats.prototype.pageChanged = function() {
        // Store: Current Page(s)
        this.getCurrentPages().getCurrentPageFLs();

        // Track Page Views in UFA
        this.trackPageViews();

    var data = null,
        closedPages = null,
        pageTimeSpent = 0,
        endTime = Shared.getTime();

    if (!this.app.config.enableTracking) { return; }

    // Debug Message
    Flipbook.log('stats-page-changed');

    // Calc: Time-Spent on Page
    pageTimeSpent = endTime - this.startTime;

    // Track: Previous Page(s) + Time Spent
    closedPages = this.currentPageIds.slice();

    // Store: Start Time for Current Page
    this.startTime = endTime;

    // Ignore Tracking if Time on Page is less than Min-Page-time
    if (pageTimeSpent < this.app.config.minPageTime) { return this; }

    // Track: Page Flipped
    data = {'pageChanged': true, 'openedPageFLs': this.currentPageFLs, 'openedPages': this.currentPageIds, 'closedPages': closedPages, 'pageTimeSpent': pageTimeSpent};
    return this.sendToFlyptech(data).sendToGoogle(data).sendToGoogleTagManager(data).sendToIntegrations(data);
};

/**
 * Track Flipbook-Page-Zoom Events
 *
 * @public
 * @this Flipbook.Stats
 * @return {Object} A reference to the Stats Object for Method Chaining
 */
Flipbook.Stats.prototype.pageZoomed = function(zoomPoint) {
    var data = null,
        pageId = 0,
        pageSize = this.app.carousel.page.size.original,
        pctx = 0, pcty = 0;

    if (!this.app.config.enableTracking) { return; }

    // Debug Message
    Flipbook.log('stats-page-zoomed');

    // Left-Side Page
    pageId = this.currentPageIds[0];

    // Check if Zoom was Performed on Right-Side Page
    if (zoomPoint.x > pageSize.x) {
        if (this.currentPageIds.length > 1) {
            pageId = this.currentPageIds[1];
        }
        zoomPoint.x -= pageSize.x;
    }

    // Calculate Zoom Coordinates as a Percentage of the Page Size
    pctx = ((zoomPoint.x / pageSize.x) * 100) | 0;
    pcty = ((zoomPoint.y / pageSize.y) * 100) | 0;

    // Track: Page Zoomed
    data = {'openedPages': [pageId], 'zoomPoint': {'x': pctx, 'y': pcty}};
    return this.sendToFlyptech(data);
};

/**
 * Track Flipbook Social-Share Events
 *
 * @public
 * @this Flipbook.Stats
 * @return {Object} A reference to the Stats Object for Method Chaining
 */
Flipbook.Stats.prototype.socialShare = function(shareType, flipbookId, flipbookPage) {
    if (!this.app.config.enableTracking) { return; }

    // Debug Message
    Flipbook.log('stats-page-social-share');

    // Track: Page Zoomed
    var data = {'shareType': shareType, 'flipbookPage': flipbookPage};
    return this.sendToFlyptech(data);
};

/**
 * Track Flipbook Social-Share Events
 *
 * @public
 * @this Flipbook.Stats
 * @return {Object} A reference to the Stats Object for Method Chaining
 */
Flipbook.Stats.prototype.widgetInteract = function(widgetId, pageId) {
    if (!this.app.config.enableTracking) { return; }

    // Debug Message
    Flipbook.log('stats-page-widget-interact');

    // Track: Page Zoomed
    var data = {'widgetId': widgetId, 'pageId': pageId};
    return this.sendToFlyptech(data);
};

/**
 * Track Flipbook PDF Downloads
 *   - Does not follow the same pattern as other tracking mechanisms; we maintain the existing
 *     server-side functionality that is used in older versions of Flipbook.
 *
 * @public
 * @this Flipbook.Stats
 * @return {Object} A reference to the Stats Object for Method Chaining
 */
Flipbook.Stats.prototype.pdfDownload = function(flipbookId) {
    var statsUrl = this.app.config.urls.server + 'issues/download/issue/' + flipbookId + '/';

    if (!this.app.config.enableTracking) { return; }

    // Debug Message
    Flipbook.log('stats-page-pdf-download');

    // Cancel any pending AJAX Requests
    Flipbook.cancelLastAjax();

    // Track PDF Download
    Flipbook.qAjax({'url': statsUrl, 'data': {'v': 'm4'}, 'dataType': 'html'}).then($.proxy(function(responseData) {
        Flipbook.qAjax({'url': statsUrl, 'data': {'v': 'm4', 'data[Download][session]': responseData}, 'dataType': 'html'});
    }, this));
    return this;
};

/**
 *
 *
 * @public
 * @this Flipbook.Stats
 * @return {Object} A reference to the Stats Object for Method Chaining
 */
Flipbook.Stats.prototype.resize = function() {
    var data = null;
    var pageTimeSpent = 0;
    var endTime = Shared.getTime();
    var newOrientation = this.app.viewport.orientation;
    var previousOpenedPages = this.currentPageIds.slice();
    var previousOpenedPageFLs = this.currentPageFLs.slice();
    var openedPageId = 0, openedPageFL = 0;

    if (!this.app.config.enableTracking) { return; }

    // Ignore on Single-Page-View Flipbooks
    if (this.app.config.titleData.single_page_view) { return this; }

    // Debug Message
    Flipbook.log('stats-page-resized');

    // Landscape to Portrait:
    if (newOrientation === Flipbook.ORIENT_PORTRAIT && this.lastOrientation === Flipbook.ORIENT_LANDSCAPE) {
        // Calc: Time-Spent on Page
        pageTimeSpent = endTime - this.startTime;

        // Track: Previous Page(s) + Time Spent (only for Second Page of 2-Page Spread)
        data = {'pageChanged': true, 'closedPages': [this.currentPageIds[this.currentPageIds.length-1]], 'pageTimeSpent': pageTimeSpent};
        this.sendToFlyptech(data);
    }

    // Store: Current Page(s)
    this.getCurrentPages().getCurrentPageFLs();

    // Portrait to Landscape:
    if (newOrientation === Flipbook.ORIENT_LANDSCAPE && this.lastOrientation === Flipbook.ORIENT_PORTRAIT) {
        // Track: Opened Page
        openedPageId = (previousOpenedPages[0] === this.currentPageIds[0]) ? previousOpenedPages[1] : previousOpenedPages[0];
        openedPageFL = (previousOpenedPages[0] === this.currentPageIds[0]) ? previousOpenedPageFLs[1] : previousOpenedPageFLs[0];
        if (openedPageId && openedPageId > 0) {
            data = {'pageOpened': true, 'openedPageFLs': [previousOpenedPageFLs], 'openedPages': [openedPageId]};
            this.sendToFlyptech(data).sendToGoogle(data).sendToIntegrations(data);
        }
    }

    // Store New Orientation
    this.lastOrientation = newOrientation;
    return this;
};


/* ******************************************************************************************** */
/* * Pseudo-Private Methods                                                                   * */
/* ******************************************************************************************** */

/**
 *
 *
 * @private
 * @this Flipbook.Stats
 * @return {Object} A reference to the Stats Object for Method Chaining
 */
Flipbook.Stats.prototype.getCurrentPages = function() {
    var i, sheetId = this.app.carousel.current.sheet;

    // Store: Current Page(s)
    this.currentPageIds.length = 0;
    if (this.app.carousel.current.twopage) {
        for (i = 0; i < this.app.carousel.doublePageSheets[sheetId].length; i++) {
            this.currentPageIds.push( this.app.parsedPageData[ this.app.carousel.doublePageSheets[sheetId][i] ].realId );
        }
    } else {
        this.currentPageIds.push( this.app.parsedPageData[ sheetId ].realId );
    }

    return this;
};


/**
 *
 *
 * @private
 * @this Flipbook.Stats
 * @return {Object} A reference to the Stats Object for Method Chaining
 */
Flipbook.Stats.prototype.getCurrentPageFLs = function() {
    var i, sheetId = this.app.carousel.current.sheet;

    // Store: Current Page(s)
    this.currentPageFLs.length = 0;
    if (this.app.carousel.current.twopage) {
        for (i = 0; i < this.app.carousel.doublePageSheets[sheetId].length; i++) {
            this.currentPageFLs.push( this.app.carousel.doublePageSheets[sheetId][i] );
        }
    } else {
        this.currentPageFLs.push(sheetId);
    }

    return this;
};


/**
 * Sends Stats Data to the Flyptech Servers
 *
 * @private
 * @this Flipbook.Stats
 * @return {Object} A reference to the Stats Object for Method Chaining
 */
Flipbook.Stats.prototype.sendToIntegrations = function(data) {
    var baseUrl = Flipbook.root.location.origin + Flipbook.root.location.pathname;
    var url = '';
    var params = '';
    var hubspot = '';

    // The page number viewed
    var pageNum;

    // The Issue name of the flipbook
    var issueName = this.app.config.issueData.name;

    // The Issue ID of the flipbook
    var issueID = this.app.config.stats.id;

    // if flipbook is being seen in a hub item, need hub Item ID
    var itemID = this.app.config.hubItemId;

    // Lets check and see if the Marketo Tracking library has been initiated (munchkin.js)
    var mktoTrackingIsInit = typeof Flipbook.root.mktoMunchkinFunction === 'function';

    if (!this.app.config.enableTracking) { return; }

    for (var i = 0, n = this.currentPageFLs.length; i < n; i++) {
        pageNum = this.currentPageFLs[i];

        // For each page, send off the data to integrations setup for tracking
        if (Flipbook.root.loadHubspotTracking){
            hubspot = itemID !== false ? 'Flipbook Page ' + pageNum + ' Viewed -  "' + issueName + '" (Flipbook ID: ' + issueID + ', Hub Item ID: ' + itemID  + ')' :
                'Flipbook Page ' + pageNum + ' Viewed -  "' + issueName + '" (Flipbook ID: ' + issueID + ')' ;
            Flipbook.root._hsq.push(["trackEvent", hubspot]);
        }
        if (Flipbook.root.loadEloquaTracking){
            url = itemID !== false ? baseUrl + '?flipbook_page=' + pageNum + '&flipbook_id=' + issueID + '&flipbook_title=' + encodeURIComponent(issueName) + '&hub_item_id=' + itemID :
                baseUrl + '?flipbook_page=' + pageNum + '&flipbook_id=' + issueID + '&flipbook_title=' + encodeURIComponent(issueName);
            Flipbook.root.loadEloquaTracking(url);
        }
        if (Flipbook.root.loadPardotTracking){
            url = itemID !== false ? baseUrl + '/' + pageNum + '?hub_item_id=' + itemID : baseUrl + '/' + pageNum;
            Flipbook.root.loadPardotTracking(url);
        }
        if (Flipbook.root.loadMarketoTracking && mktoTrackingIsInit){
            params = itemID !== false ? 'flipbook_page=' + pageNum + '&flipbook_id=' + issueID + '&flipbook_title=' + encodeURIComponent(issueName) + '&hub_item_id=' + itemID :
                'flipbook_page=' + pageNum + '&flipbook_id=' + issueID + '&flipbook_title=' + encodeURIComponent(issueName);
            Flipbook.root.mktoMunchkinFunction('visitWebPage', {url: baseUrl, params: params});
        }
    }

    return this;
};


/**
 * Sends Stats Data to the Flyptech Servers
 *
 * @private
 * @this Flipbook.Stats
 * @return {Object} A reference to the Stats Object for Method Chaining
 */
Flipbook.Stats.prototype.sendToFlyptech = function(data) {
    var i, prop, $scriptEl = null, params = '',
        trackingUrl = this.app.config.urls.server;

    if (!this.app.config.enableTracking) { return this; }

    // Debug Message
    Flipbook.log('stats-send-to-flyptech');

    // Build Params List for Stats
    params = this.flipbookId;
    if (data.closedPages !== undefined) {
        params += '/' + data.closedPages.join(',');
    }
    if (data.pageTimeSpent !== undefined) {
        params += '/' + data.pageTimeSpent;
    }
    if (data.openedPages !== undefined) {
        params += '/' + data.openedPages.join(',');
    }
    if (data.zoomPoint !== undefined) {
        params += '/' + data.zoomPoint.x + ',' + data.zoomPoint.y;
    }
    if (data.shareType !== undefined) {
        params += '/' + data.shareType + '/' + data.flipbookPage;
    }
    if (data.widgetId !== undefined) {
        params += '/' + data.widgetId + '/' + data.pageId;
    }

    // Flipbook Opened Event:
    if (data.flipbookOpened !== undefined && data.flipbookOpened) {
        trackingUrl += 'read/flipbook4stats_open/' + params;
    }

    // Flipbook Page Opened Event:
    //  Note: This only happens when Portrait Mode is Rotated to Landscape Mode
    //        and Landscape Mode reveals a second page; the second page gets tracked
    //        as Opened.  The Time Spent will be the same as the first page of the
    //        two page spread.
    else if (data.pageOpened !== undefined && data.pageOpened) {
        trackingUrl += 'read/flipbook4stats_pageOpen/' + params;
    }

    // Flipbook Page Changed Event:
    else if (data.pageChanged !== undefined && data.pageChanged) {
        trackingUrl += 'read/flipbook4stats_flip/' + params;
    }

    // Flipbook Page Zoomed Event:
    else if (data.zoomPoint !== undefined) {
        trackingUrl += 'read/flipbook4stats_zoom/' + params;
    }

    // Flipbook Social-Share Event:
    else if (data.shareType !== undefined) {
        trackingUrl += 'read/flipbook4stats_social/' + params;
    }

    // Flipbook Widget Interaction Event:
    else if (data.widgetId !== undefined) {
        trackingUrl += 'read/flipbook4stats_widget/' + params;
    }

    // Send Stats to Server via IMG Tag
    (new Image()).src = trackingUrl + '?t=' + (new Date().getTime());
    return this;
};

/**
 * Sends the Current Page URL to Google Analytics for PageView Tracking
 *
 * @private
 * @this Flipbook.Stats
 * @return {Object} A reference to the Stats Object for Method Chaining
 */
Flipbook.Stats.prototype.sendToGoogle = function(data) {
    var i, j, requestUrl,
        codes = this.app.config.analyticsCodes,
        issueId = this.app.config.issueData.id;

    if(!this.app.config.enableTracking || Flipbook.root.ga === undefined || data.openedPages === undefined) { return this; }

    // Debug Message
    Flipbook.log('stats-send-to-google');

    // Lets check that GA is fully initialized before sending anything
    if(window.gtag) {
        // Track All Opened Pages
        // Skip last (most recent) page as analytics will automatically send a page view when the url changes via history.replaceState
        for (i = 0; i < data.openedPageFLs.length-1; i++) {
            // Build Request URL
            requestUrl = this.app.config.stats.baseUrl + '/' + issueId + '/' + data.openedPageFLs[i];

            // Execute GA Tracker
            for (j = 0; j < codes.length - 1; j++) {
                gtag('event', 'page_view', {
                    'send_to': codes[j],
                    'page_location': requestUrl,
                });
            }
        }
    }
    return this;
};

/**
 * Sends the Current Page URL to Google Tag Manager for PageView Tracking
 *
 * @private
 * @this Flipbook.Stats
 * @return {Object} A reference to the Stats Object for Method Chaining
 */
Flipbook.Stats.prototype.sendToGoogleTagManager = function(data) {
    var i, requestUrl,
        issueId = this.app.config.issueData.id;

    if(!this.app.config.enableTracking || Flipbook.root.dataLayer === undefined || data.openedPages === undefined) { return this; }

    // Debug Message
    Flipbook.log('stats-send-to-google-tag-manager');

    // Track All Opened Pages
    for (i = 0; i < data.openedPageFLs.length; i++) {
        // Build Request URL
        requestUrl = this.app.config.stats.baseUrl + '/' + issueId + '/' + data.openedPageFLs[i];

        Flipbook.root.dataLayer.push({
            'event': 'flipbookVirtualPageView',
            'flipbookVirtualPageUrl': requestUrl
        });
    }
    return this;
};

/**
 * Sends Flipbook page views message to parent, which will send events to Uberflip Analytics
 *   Assumes this.getCurrentPages().getCurrentPageFLs() has already been called
 *
 * @private
 * @this Flipbook.stats
 * @return {Object} A reference to the Stats Object for Method Chaining
 */
Flipbook.Stats.prototype.trackPageViews = function() {
    if (this.app.config.source === Flipbook.SOURCE_HUB) {
        window.parent.postMessage(
            {
                type: Shared.MESSAGE_TYPES.FLIPBOOK_EVENT,
                eventType: 'view_flipbook_pages',
                flipbookId: this.flipbookId,
                pageIds: this.currentPageIds,
                pageNumbers: this.currentPageFLs
            },
            getParentOrigin()
        );
    }

    return this;
};
