/**
 * Flyp Technologies Inc. - Flipbook v4
 * 
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/navigation.js - Flipbook.Navigation
 * @author Robert J. Secord, B.Sc.
 */
import 'jquery-migrate';
import $ from 'jquery';
import Flipbook from './core';

/**
 * Flipbook Navigation
 *
 * @class Navigation
 * @classdesc Application Carousel-Navigation Controls
 * @namespace Flipbook
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.Navigation = function(app, $container) {

    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize    = null;
    var buildElements = null;
    var attachEvents  = null;

    
    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    this.app              = app;
    this.$container       = $container;
    this.$navButtonsLeft  = null;
    this.$navButtonsRight = null;
    this.controlWidth     = 0;
    this.toolbarWidth     = 0;
    this.forceShow        = false;  // Toggled by User with Single Tap on Flipbook
    this.hideTimer        = 0;
    

    /* **************************************************************************************** */
    /* * Private Methods/Members                                                              * */
    /* **************************************************************************************** */
    /**
     * Initialize the Navigation Controls
     *
     * @private
     * @this Flipbook.Navigation
     * @return undefined
     * @constructs
     */
    initialize = $.proxy(function() {
        // Debug Message
        Flipbook.log('navigation-init');

        // Build the Navigation Control Elements
        buildElements();
        
        // Attach Events to Navigation Controls
        attachEvents();
    }, this);
    
    /**
     * Build the Navigation Control Elements
     *
     * @private
     * @this Flipbook.Navigation
     * @return undefined
     */
    buildElements = $.proxy(function() {
        // Debug Message
        Flipbook.log('navigation-build-elements');
        
        // Build Navigation Controls from Template
        Flipbook.buildFromTemplate({'template': this.app.config.navigation.template, 'appendTo': this.$container});
        
        // Store Reference to Element Container
        this.$navButtonsLeft = $('.carousel-nav-buttons.left');
        this.$navButtonsRight = $('.carousel-nav-buttons.right');
    }, this);

    /**
     * Attach Events to the Navigation Control Elements
     *   
     * @private
     * @this Flipbook.Navigation
     * @return undefined
     */
    attachEvents = $.proxy(function() {
        var ns = 'Navigation';
        
        // Debug Message
        Flipbook.log('navigation-attach-events');
        
        // Attach Click Events to Navigation Controls
        Flipbook.onClickTap(this.app, this.$navButtonsLeft.find('.nav-first'),    this.handleClickEvent('first'),     this, ns);
        Flipbook.onClickTap(this.app, this.$navButtonsLeft.find('.nav-previous'), this.handleClickEvent('previous'),  this, ns);
        Flipbook.onClickTap(this.app, this.$navButtonsRight.find('.nav-next'),    this.handleClickEvent('next'),      this, ns);
        Flipbook.onClickTap(this.app, this.$navButtonsRight.find('.nav-last'),    this.handleClickEvent('last'),      this, ns);
    }, this);


    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    initialize();
    
    return this;
};
// End of Flipbook.Navigation


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Resize the Navigation Elements
 *      
 * @public
 * @this Flipbook.Navigation
 * @return undefined
 */
Flipbook.Navigation.prototype.resize = function() {
    // Debug Message
    Flipbook.log('navigation-resize');
    
    // Get Width of Toolbar
    this.toolbarWidth = 0;
    if (this.app.config.toolbar.enabled && !Flipbook.isFullscreen(this.app) && this.app.config.toolbar.position === Flipbook.TOOLBAR_POS_LEFT) { 
        this.toolbarWidth = this.app.config.toolbar.size[this.app.viewport.breakpoint][this.app.config.toolbar.position].closed;
    }
    
    // Store Width of Navigation Control
    this.controlWidth = this.$navButtonsLeft.outerWidth() + 5;  // +5 for Drop-Shadow
    
    // Move Left Navigation Control to account for Toolbar
    this.$navButtonsLeft.css({'left': this.toolbarWidth});
    
    // Navigation Controls should always be Displayed for Desktop
    if (Flipbook.isDesktop(this.app)) {
        this.tapped();
    }
};

/**
 * Toggles the Force View Mode of the Navigation Controls
 *   - When the Carousel is Tapped once, the Navigation Controls are Displayed until the Carousel is Tapped once again
 *      
 * @public
 * @this Flipbook.Navigation
 * @return undefined
 */
Flipbook.Navigation.prototype.tapped = function() {
    // Debug Message
    Flipbook.log('navigation-tapped');
    
    // Toggle Forced State (always show on Desktop)
    this.forceShow = Flipbook.isDesktop(this.app) || !this.forceShow;
    
    // Clear Hide Timer
    if (this.hideTimer) {
        Flipbook.root.clearTimeout(this.hideTimer);
    }
    
    // Toggle Display of Navigation
    this.toggleViewMode(this.forceShow, true);
};

/**
 * Toggles the View Mode of the Navigation Controls
 *   - Hidden after a Short Delay unless the "forceShow" flag is true
 *      
 * @public
 * @this Flipbook.Navigation
 * @param {Boolean} state The Display State to Toggle the View Mode into
 * @param {Boolean} animate Whether to Animate the Display/Hide or not
 * @return {Object} A reference to the Navigation Object for Method Chaining
 */
Flipbook.Navigation.prototype.toggleViewMode = function(state, animate) {
    var hideLeft = $.proxy(function() {
        this.$navButtonsLeft.css({'display': 'none'});
    }, this);
    
    var hideRight = $.proxy(function() {
        this.$navButtonsRight.css({'display': 'none'});
    }, this);
    
    // Debug Message
    Flipbook.log('navigation-toggle');
    
    // Can only show Navigation Controls in Default View Mode (Not Zoomed In or Out)
    if (state === undefined || this.app.carousel.viewMode !== Flipbook.CAROUSEL_SLIDER) {
        state = false;
    }
    if (animate === undefined) {
        animate = false;
    }
    
    // Display Nav Controls
    if (state) {
        // Display Left Nav Controls only if not on First Page
        if (this.app.carousel.current.sheet > 0) {
            if (this.$navButtonsLeft.css('display') !== 'block') {
                // Prepare Display State
                this.$navButtonsLeft.css({'display': 'block', 'left': animate ? this.toolbarWidth-this.controlWidth : this.toolbarWidth});
                
                // Animate into View
                if (animate) {
                    this.$navButtonsLeft.transition({'left': this.toolbarWidth}, 250);
                }
            }
        } else {
            // We're on First Page, Hide Left Nav Controls if Displayed
            if (this.$navButtonsLeft.css('display') === 'block') {
                this.$navButtonsLeft.transition({'left': this.toolbarWidth-this.controlWidth}, 250, hideLeft);
            }
        }
        
        // Display Right Nav Controls only if not on Last Page
        if (this.app.carousel.current.sheet < this.app.carousel.totalSheets[this.app.viewport.orientation] - 1) {
            if (this.$navButtonsRight.css('display') !== 'block') {
                // Prepare Display State
                this.$navButtonsRight.css({'display': 'block', 'right': animate ? -this.controlWidth : 0});
                
                // Animate into View
                if (animate) {
                    this.$navButtonsRight.transition({'right': 0}, 250);
                }
            }
        } else {
            // We're on Last Page, Hide Right Nav Controls if Displayed
            if (this.$navButtonsRight.css('display') === 'block') {
                this.$navButtonsRight.transition({'right': -this.controlWidth}, 250, hideRight);
            }
        }
    } 
    
    // Hide Nav Controls
    else {
        // Hide Left Nav Controls
        if (this.$navButtonsLeft.css('display') === 'block') {
            if (animate) {
                this.$navButtonsLeft.transition({'left': this.toolbarWidth-this.controlWidth}, 250, hideLeft);
            } else {
                hideLeft();
            }
        }

        // Hide Right Nav Controls
        if (this.$navButtonsRight.css('display') === 'block') {
            if (animate) {
                this.$navButtonsRight.transition({'right': -this.controlWidth}, 250, hideRight);
            } else {
                hideRight();
            }
        }
    }
    
    // Trigger Delayed Hide
    if (state && !this.forceShow) {
        if (this.hideTimer) {
            Flipbook.root.clearTimeout(this.hideTimer);
        }
        this.hideTimer = Flipbook.root.setTimeout($.proxy(function() {
            this.toggleViewMode(false, animate);
        }, this), this.app.config.navigation.hideDelay);
    }
    return this;
};

/**
 * Handles Click Event on Navigation Controls
 *   
 * @private
 * @this Flipbook.Navigation
 * @return Event Handler
 */
Flipbook.Navigation.prototype.handleClickEvent = function(direction) {
    return function(e) {
        // Debug Message
        Flipbook.log('navigation-click');
        
        switch (direction) {
            case 'first':
                this.app.carousel.sliderMoveFirst();
                break;
            case 'previous':
                this.app.carousel.sliderMovePrev();
                break;
            case 'next':
                this.app.carousel.sliderMoveNext();
                break;
            case 'last':
                this.app.carousel.sliderMoveLast();
                break;
        }
    };
};
