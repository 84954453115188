/**
 * Flyp Technologies Inc. - Flipbook v4
 *
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/tools/help.js - Flipbook.HelpTool
 * @author Robert J. Secord, B.Sc.
 */
import 'jquery-migrate';
import $ from 'jquery';
import Flipbook from '../core';
import Q from '/app/libs/promise/q';

/**
 * Flipbook Help Tool
 *
 * @class HelpTool
 * @classdesc Application Help Tool
 * @namespace Flipbook
 * @inherits Flipbook.ToolsBase
 * @param {Object} toolbar A reference to the Toolbar Control
 * @param {Object} toolData Configuration Settings for the Tool
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.HelpTool = function(toolbar, toolData) {
    // Call Parent Constructor
    Flipbook.ToolsBase.apply(this, [toolbar, toolData]);
    
    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize = null;

    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    this.helpUrl = '';

    /* **************************************************************************************** */
    /* * Private Methods/Members Definitions                                                  * */
    /* **************************************************************************************** */
    /**
     * Initialize the Help Tool
     *
     * @private
     * @this Flipbook.HelpTool
     * @return {Object} A reference to the App Object for Method Chaining
     * @constructs
     */
    initialize = $.proxy(function() {
        // Build Help URL
        if (this.app.config.device.isOffline) {
            this.helpUrl = this.app.config.urls.server + '../flipbook/pages/help.html';
        } else {
            this.helpUrl = this.app.config.urls.server + 'read/flipbook_help_4/';
        }
        
        return this;
    }, this);
    
    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize();
};
Flipbook.HelpTool.prototype = new Flipbook.ToolsBase();
Flipbook.HelpTool.prototype.constructor = Flipbook.HelpTool;
// End of Flipbook.HelpTool


/* ******************************************************************************************** */
/* * Overridden Base Methods                                                                  * */
/* ******************************************************************************************** */

/**
 * Override Abstract Base Method; Called when the Tool Panel is Opened
 *
 * @public
 * @this Flipbook.HelpTool
 * @return undefined
 */
Flipbook.HelpTool.prototype.panelOpened = function() {
    // Replace Panel Content
    this.replacePanelContent(this.helpUrl, true);
};


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */


/**
 * Replaces the Panel Content with New Content and Hooks Required Links within
 *
 * @public
 * @this Flipbook.HelpTool
 * @return {Object} A Promise
 */
Flipbook.HelpTool.prototype.replacePanelContent = function(contentUrl, loading) {
    var deferred = Q.defer();
    
    // Cancel any pending AJAX Requests
    Flipbook.cancelLastAjax();
    
    // Request HTML for Panel Content via AJAX
    Flipbook.qAjax({'url': contentUrl, 'dataType': 'html'})
    
        .then($.proxy(function(responseData) {
            // Base: Replace Panel Contents
            this.panelReplaceHtml(responseData, loading).then($.proxy(function(promiseValue) {
                // Hook Panel Links
                this.hookPanelLinks();
                
                // Resolve Promise
                deferred.resolve(promiseValue);
            }, this));
        }, this))

        .fail($.proxy(function(error) {
            // Base: Display Error Message
            this.panelDisplayError(error);
        }, this));
    
    return deferred.promise;
};

/**
 * Finds and Hooks Links within the Panel
 *
 * @public
 * @this Flipbook.HelpTool
 * @return {Object} A reference to the HelpTool Object for Method Chaining
 */
Flipbook.HelpTool.prototype.hookPanelLinks = function() {
    var $panel = this.getPanel();
    var $anchoredLinks = $panel.find('[href^="#"][data-internal="false"]:not(.hooked)');
    
    _.forEach($anchoredLinks, $.proxy(function(link) {
        var $link = $(link);

        // Mark Link as Hooked
        $link.addClass('hooked');

        // Attach Click/Tap Event to Anchored Links
        Flipbook.onClickTap(this.app, $link, this.scrollToAnchor($link), this, 'HelpLink');
    }, this));
    
    return this;
};

/**
 * Scrolls the Panel to an Anchored Element
 *
 * @public
 * @this Flipbook.HelpTool
 * @return {Function} A closure function for the event handler
 */
Flipbook.HelpTool.prototype.scrollToAnchor = function($link) {
    return function(e) {
        var targetName = $link.attr('href').slice(1);
        var $panel = this.getPanel();
        var $scroller = this.getPanelScrollbar();
        var $scrollTo = $panel.find('[name="'+targetName+'"][data-internal="false"]');
        
        if (Flipbook.hasTouch(this.app)) {
            e.gesture.preventDefault();
        } else {
            e.preventDefault();
        }
        
        // Scroll to Element
        if ($scrollTo.length) {
            $scroller.scrollToElement($scrollTo[0], 750, null, null, IScroll.utils.ease.elastic);
        }
        return false;
    };
};
