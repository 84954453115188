/**
 * Flyp Technologies Inc. - Flipbook v4
 *
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/tools/contents.js - Flipbook.ContentsTool
 * @author Robert J. Secord, B.Sc.
 */
import 'jquery-migrate';
import $ from 'jquery';
import Flipbook from '../core';
import Q from '/app/libs/promise/q';

/**
 * Flipbook Contents Tool
 *
 * @class ContentsTool
 * @classdesc Application Contents Tool
 * @namespace Flipbook
 * @inherits Flipbook.ToolsBase
 * @param {Object} toolbar A reference to the Toolbar Control
 * @param {Object} toolData Configuration Settings for the Tool
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.ContentsTool = function(toolbar, toolData) {
    // Call Parent Constructor
    Flipbook.ToolsBase.apply(this, [toolbar, toolData]);
    
    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize = null;


    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    this.contentsUrl = '';
    this.$contentsList = null;


    /* **************************************************************************************** */
    /* * Private Methods/Members Definitions                                                  * */
    /* **************************************************************************************** */
    /**
     * Initialize the Contents Tool
     *
     * @private
     * @this Flipbook.ContentsTool
     * @return {Object} A reference to the ContentsTool Object for Method Chaining
     * @constructs
     */
    initialize = $.proxy(function() {
        // Build Contents URL
        this.contentsUrl = this.app.config.urls.server + 'read/flipbook4_contents/' + 
            this.app.config.issueData.id + '/' +
            this.app.config.titleData.page_num_offset + '/' +
            this.app.viewport.breakpoint + '/';
        
        // Update Label of Tool
        if (!this.app.config.titleData.show_toc && this.app.config.titleData.show_links) {
            this.$tool.find('.tool > span').html('Links List');
        }
        
        return this;
    }, this);
    
    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize();
};
Flipbook.ContentsTool.prototype = new Flipbook.ToolsBase();
Flipbook.ContentsTool.prototype.constructor = Flipbook.ContentsTool;
// End of Flipbook.ContentsTool


/* ******************************************************************************************** */
/* * Overridden Base Methods                                                                  * */
/* ******************************************************************************************** */

/**
 * Override Abstract Base Method; Called when the Tool Panel is Opened
 *
 * @public
 * @this Flipbook.ContentsTool
 * @return undefined
 */
Flipbook.ContentsTool.prototype.panelOpened = function() {
    // Replace Panel Content
    this.replacePanelContent(this.contentsUrl, true).then($.proxy(function() {
        // Enable Tabs
        this.hookTabLinks();
        
        // Hook Content Links
        this.hookContentLinks();
    }, this));
};


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Replaces the Panel Content with New Content and Hooks Required Links within
 *
 * @public
 * @this Flipbook.ContentsTool
 * @return {Object} A Promise
 */
Flipbook.ContentsTool.prototype.replacePanelContent = function(contentUrl, loading) {
    var deferred = Q.defer();
    
    // For offline;
    var $pageContainer = null;
    var $tabContentTOC = null;
    var itemTpl = '', itemHtml = '';
    var i, j, pageFl, pageText;

    // Offline Mode
    if (this.app.config.device.isOffline) {
        // Build Container from Template
        $pageContainer = Flipbook.buildFromTemplate({'template': this.toolOptions.offlineTpl.container, 'returnAs': 'element'});
        
        // Get Handle to Panel Tabs
        $tabContentTOC = $pageContainer.find('#tab-content-toc');

        // Update Panel Body with Articles
        if (this.app.config.titleData.show_toc) {
            // Get Article Template
            itemTpl = Flipbook.buildFromTemplate({'template': this.toolOptions.offlineTpl.tocItem, 'returnAs': 'string'});
            
            // Build Tab Content
            for (i = 0; i < this.app.parsedArticleData.length; i++) {
                itemHtml = itemTpl.slice(0);

                pageFl = this.app.parsedArticleData[i].page;
                pageText = this.app.parsedArticleData[i].text;

                itemHtml = itemHtml.replace('{{page_fl}}', pageFl);
                itemHtml = itemHtml.replace('{{page_num}}', parseInt(pageFl, 10) + parseInt(this.app.config.titleData.page_num_offset, 10));
                itemHtml = itemHtml.replace('{{page_thumb}}', this.app.parsedArticleData[i].thumb + '-0.jpg');
                itemHtml = itemHtml.replace('{{page_title}}', this.app.parsedArticleData[i].title);
                
                if (pageText.length) {
                    pageText = '<blockquote>' + pageText + '</blockquote>';
                }
                itemHtml = itemHtml.replace('{{page_text}}', pageText);
                
                // Append Article to Tab
                $tabContentTOC.append(itemHtml);
            }
        }
        
        // Base: Replace Panel Contents
        this.panelReplaceHtml($pageContainer, true).then($.proxy(function(promiseValue) {
            // Resolve Promise
            deferred.resolve(promiseValue);
        }, this));
    }
    
    // Online Mode
    else {
        // Cancel any pending AJAX Requests
        Flipbook.cancelLastAjax();
        
        // Request HTML for Panel Content via AJAX
        Flipbook.qAjax({'url': contentUrl, 'dataType': 'html'})
        
            .then($.proxy(function(responseData) {
                // Base: Replace Panel Contents
                this.panelReplaceHtml(responseData, loading).then($.proxy(function(promiseValue) {
                    // Resolve Promise
                    deferred.resolve(promiseValue);
                }, this));
            }, this))

            .fail($.proxy(function(error) {
                // Base: Display Error Message
                this.panelDisplayError(error);
            }, this));
    }
    
    return deferred.promise;
};

/**
 * Finds and Hooks Tab-Links within the Panel
 *
 * @public
 * @this Flipbook.ContentsTool
 * @return {Object} A reference to the ContentsTool Object for Method Chaining
 */
Flipbook.ContentsTool.prototype.hookTabLinks = function() {
    var $panel = this.getPanel();
    var $tabLinks = $panel.find('.panel-content-heading .tab-link[data-tab]:not(.hooked)');
    
    var switchTab = function(e) {
        var tabId = '';
        var $tab = null;
        var $tabLink = $(e.currentTarget);
        if (!$tabLink.length) { return; }
        
        // Get ID of Tab to Switch to
        tabId = $tabLink.attr('data-tab');
        $tab = $panel.find('#' + tabId);
        if (!$tab.length) { return; }
        
        // Remove Active Class from all Tabs & Tab Links
        $panel.find('.panel-content-heading .tab-link.active').removeClass(this.app.config.toolbar.activeClass);
        $panel.find('.panel-content-body .tab-content.active').removeClass(this.app.config.toolbar.activeClass);
        
        // Add Active Class to Selected Tab
        $tabLink.addClass(this.app.config.toolbar.activeClass);
        $tab.addClass(this.app.config.toolbar.activeClass);

        // Update Scrollbar in Panel
        this.updatePanelScrollbar();
    };
    
    // Iterate over Tab Links that are not already Hooked
    $tabLinks.each($.proxy(function(idx, link) {
        var $link = $(link);
        
        // Mark Link as Hooked
        $link.addClass('hooked');

        // Attach Click/Tap Event to Paging Links
        Flipbook.onClickTap(this.app, $link, switchTab, this, this.toolName + 'TabLink' + idx);
    }, this));
    
    return this;
};

/**
 * Finds and Hooks Links within the Table of Contents section of the Panel
 *
 * @public
 * @this Flipbook.ContentsTool
 * @return {Object} A reference to the ContentsTool Object for Method Chaining
 */
Flipbook.ContentsTool.prototype.hookContentLinks = function() {
    var $panel = this.getPanel();
    var $links = $panel.find('.toc-item[data-load-page]:not(.hooked)');
    
    var linkClicked = function(e) {
        var $el = $(e.currentTarget);
        var targetPage = parseInt($el.attr('data-load-page'), 10);
        
        // Move to Sheet
        this.moveToSheet(Flipbook.getSheetFromPage(this.app, targetPage), 100);
        
        // Close Toolbar and Restore Content
        this.restoreContent();
    };
    
    // Iterate over Paging Links that are not already Hooked
    $links.each($.proxy(function(idx, link) {
        var $link = $(link);
        
        // Mark Link as Hooked
        $link.addClass('hooked');

        // Attach Click/Tap Event to Paging Links
        Flipbook.onClickTap(this.app, $link, linkClicked, this, this.toolName + 'TOCLink' + idx);
        
    }, this));
    
    return this;
};
