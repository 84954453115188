/**
 * Flyp Technologies Inc. - Flipbook v4
 *
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/tools/text.js - Flipbook.TextTool
 * @author Robert J. Secord, B.Sc.
 */
import 'jquery-migrate';
import $ from 'jquery';
import Flipbook from '../core';

/**
 * Flipbook Text Tool
 *
 * @class TextTool
 * @classdesc Application Text Tool
 * @namespace Flipbook
 * @inherits Flipbook.ToolsBase
 * @param {Object} toolbar A reference to the Toolbar Control
 * @param {Object} toolData Configuration Settings for the Tool
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.TextTool = function(toolbar, toolData) {
    // Call Parent Constructor
    Flipbook.ToolsBase.apply(this, [toolbar, toolData]);
    
    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize = null;


    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    this.pageTextUrl = '';
    this.sheetAdjust = 0;
    this.fontSizeAdjust = 0;
    

    /* **************************************************************************************** */
    /* * Private Methods/Members Definitions                                                  * */
    /* **************************************************************************************** */
    /**
     * Initialize the Text Tool
     *
     * @private
     * @this Flipbook.App
     * @return {Object} A reference to the App Object for Method Chaining
     * @constructs
     */
    initialize = $.proxy(function() {
        // Build Page-Text URL
        this.pageTextUrl = this.app.config.urls.server + 'read/flipbook4_pageText/' + 
            this.app.config.issueData.id + '/' + 
            this.app.config.titleData.page_num_offset + '/';
        
        return this;
    }, this);
    
    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize();
};
Flipbook.TextTool.prototype = new Flipbook.ToolsBase();
Flipbook.TextTool.prototype.constructor = Flipbook.TextTool;
// End of Flipbook.TextTool


/* ******************************************************************************************** */
/* * Overridden Base Methods                                                                  * */
/* ******************************************************************************************** */

/**
 * Override Abstract Base Method; Called when the Tool Panel is Opened
 *
 * @public
 * @this Flipbook.TextTool
 * @return undefined
 */
Flipbook.TextTool.prototype.panelOpened = function() {
    // Reset Settings
    this.sheetAdjust = 0;
    this.fontSizeAdjust = 0;
    
    // Load Page Text
    this.loadPageText();
};


/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Get the Page Number(s) for the Current Sheet
 *
 * @public
 * @this Flipbook.TextTool
 * @return {Array} The Page Number(s) of the Current Sheet
 */
Flipbook.TextTool.prototype.getPageNumbers = function() {
    var totalSheets = this.getTotalSheets();
    var currentSheet = this.getCurrentSheet();
    var currentPage = 0;
    var pages = [];
    
    if (currentSheet + this.sheetAdjust < 0) { 
        this.sheetAdjust = 0 - currentSheet;
    }
    if (currentSheet + this.sheetAdjust > totalSheets[this.app.viewport.orientation] - 1) {
        this.sheetAdjust = totalSheets[this.app.viewport.orientation] - currentSheet - 1; 
    }
    
    currentSheet += this.sheetAdjust;
    currentPage = Flipbook.getPageFromSheet(this.app, currentSheet);
    pages.push(currentPage);

    if (Flipbook.sheetHasTwoPages(this.app, totalSheets, currentSheet)) {
        pages.push(currentPage + 1);
    }
    
    return pages;
};

/**
 * Get the Page-Text URL for AJAX Requests
 *
 * @public
 * @this Flipbook.TextTool
 * @return {String} The Page-Text URL for AJAX
 */
Flipbook.TextTool.prototype.getPageTextUrl = function() {
    return this.pageTextUrl + this.getPageNumbers().join('/');
};

/**
 * Finds and Hooks Links within the Panel
 *
 * @public
 * @this Flipbook.TextTool
 * @return {Object} A reference to the TextTool Object for Method Chaining
 */
Flipbook.TextTool.prototype.loadPageText = function() {
    // For offline;
    var $pageContainer = null;
    var $scrollContent = null;
    var i, pageNums = [];
    var pageTextTpl = '';
    var pageTextHtml = '';
    var pageText, pageFl;
    
    var afterPageLoad = $.proxy(function() {
        // Hook Panel Links
        this.hookPagingLinks().hookSizingLinks();
        
        // Update the Font Size of the Page Text
        this.updateFontSize();
    }, this);
    
    
    // Offline Mode
    if (this.app.config.device.isOffline) {
        // Build Results Container from Template
        $pageContainer = Flipbook.buildFromTemplate({'template': this.toolOptions.pageTextTpl, 'returnAs': 'element'});
        $scrollContent = $pageContainer.find('.scrollable-content-wrapper');
        
        // Get Page Text Template from Full Template
        pageTextTpl = $scrollContent.html();

        // Empty Panel Content
        $scrollContent.empty();
        
        // Build Page Content 
        pageNums = this.getPageNumbers();
        for (i = 0; i < pageNums.length; i++) {
            // Copy HTML Template
            pageTextHtml = pageTextTpl.slice(0);
            
            // Get Text for Page
            pageText = pages[pageNums[i]].Page.text;
            if (Flipbook.EMPTY.test(pageText)) {
                pageText = '<em>no text available for this page</em>';
            }
            
            // Get Page FL
            pageFl = pages[pageNums[i]].Page.page_fl;
            
            // Replace Template Tags with Content
            pageTextHtml = pageTextHtml.replace('{{page_text}}', pageText);
            pageTextHtml = pageTextHtml.replace('{{page_num}}', parseInt(pageFl, 10) + parseInt(this.app.config.titleData.page_num_offset, 10));
            pageTextHtml = pageTextHtml.replace('{{thumb_image}}', this.app.parsedPageData[pageFl].thumb + '-0.jpg');
            
            // Update Content Block
            $scrollContent.append(pageTextHtml);
        }
        
        // Base: Replace Panel Contents
        this.panelReplaceHtml($pageContainer, true).then(afterPageLoad);
    }
    
    // Online Mode
    else {
        // Cancel any pending AJAX Requests
        Flipbook.cancelLastAjax();
        
        // Request HTML for Panel Content via AJAX
        Flipbook.qAjax({'url': this.getPageTextUrl(), 'dataType': 'html'})
        
            .then($.proxy(function(responseData) {
                // Base: Replace Panel Contents
                this.panelReplaceHtml(responseData, true).then(afterPageLoad);
            }, this))
            
            .fail($.proxy(function(error) {
                // Base: Display Error Message
                this.panelDisplayError(error);
            }, this));
    }
};

/**
 * Finds and Hooks Paging Links within the Panel
 *
 * @public
 * @this Flipbook.TextTool
 * @return {Object} A reference to the TextTool Object for Method Chaining
 */
Flipbook.TextTool.prototype.hookPagingLinks = function() {
    var $panel = this.getPanel();
    var $prevPg = $panel.find('.current-page > .prev:not(.hooked)');
    var $nextPg = $panel.find('.current-page > .next:not(.hooked)');
    
    var totalSheets = this.getTotalSheets();
    var currentSheet = this.getCurrentSheet();
    
    var moveToPage = $.proxy(function(direction) {
        return $.proxy(function(e) {
            if ($(e.currentTarget).hasClass('disabled')) { return; }
            
            // Adjust Current Sheet
            this.sheetAdjust += direction;
            
            // Load Page Text
            this.loadPageText();
        }, this);
    }, this);
    
    // Mark Links as Hooked
    $prevPg.addClass('hooked');
    $nextPg.addClass('hooked');
    
    // Disable Directional Button when at end
    if (currentSheet + this.sheetAdjust <= 0) { 
        $prevPg.addClass('disabled');
    }
    if (currentSheet + this.sheetAdjust >= totalSheets[this.app.viewport.orientation] - 1) {
        $nextPg.addClass('disabled');
    }
    
    // Hook Directional Buttons
    Flipbook.onClickTap(this.app, $prevPg, moveToPage(-1), this, this.toolName + 'PrevPageLink');
    Flipbook.onClickTap(this.app, $nextPg, moveToPage(+1), this, this.toolName + 'NextPageLink');
    
    return this;
};

/**
 * Finds and Hooks Font-Sizing Links within the Panel
 *
 * @public
 * @this Flipbook.TextTool
 * @return {Object} A reference to the TextTool Object for Method Chaining
 */
Flipbook.TextTool.prototype.hookSizingLinks = function() {
    var $panel = this.getPanel();
    var $increaseFont = $panel.find('.current-page > .font-size > .inc:not(.hooked)');
    var $decreaseFont = $panel.find('.current-page > .font-size > .dec:not(.hooked)');
    
    var adjustFontSize = $.proxy(function(adjustment) {
        return $.proxy(function(e) {
            var fontSize = this.toolOptions.fontSize.initial;
            
            // Store Font Size Adjustment
            this.fontSizeAdjust += adjustment;
            
            // Ensure Font Size is within Min/Max
            if (fontSize + this.fontSizeAdjust > this.toolOptions.fontSize.max) {
                this.fontSizeAdjust = this.toolOptions.fontSize.max - fontSize;
            }
            if (fontSize + this.fontSizeAdjust < this.toolOptions.fontSize.min) {
                this.fontSizeAdjust = this.toolOptions.fontSize.min - fontSize;
            }
            
            // Update the Font Size of the Page Text
            this.updateFontSize();
        }, this);
    }, this);
    
    // Mark Links as Hooked
    $increaseFont.addClass('hooked');
    $decreaseFont.addClass('hooked');
    
    // Hook Sizing Buttons
    Flipbook.onClickTap(this.app, $increaseFont, adjustFontSize(+1), this, this.toolName + 'IncFontLink');
    Flipbook.onClickTap(this.app, $decreaseFont, adjustFontSize(-1), this, this.toolName + 'DecFontLink');
};

/**
 * Updates the Font-Size of the Page Text
 *
 * @public
 * @this Flipbook.TextTool
 * @return undefined
 */
Flipbook.TextTool.prototype.updateFontSize = function() {
    var $panel = this.getPanel();
    var $increaseFont = $panel.find('.current-page > .font-size > .inc');
    var $decreaseFont = $panel.find('.current-page > .font-size > .dec');
    var $para = $panel.find('.page-text-container > p');
    if (!$para.length) { return; }
    
    var fontSize = this.toolOptions.fontSize.initial + this.fontSizeAdjust;
    
    // Update Font in Paragraph
    $para.css({'font-size': fontSize});
    
    // Disable Sizing Button when at end
    $decreaseFont[fontSize <= this.toolOptions.fontSize.min ? 'addClass' : 'removeClass']('disabled');
    $increaseFont[fontSize >= this.toolOptions.fontSize.max ? 'addClass' : 'removeClass']('disabled');
};
