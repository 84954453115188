/**
 * Flyp Technologies Inc. - Flipbook v4
 * 
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/loading.js - Flipbook.Loading
 * @author Robert J. Secord, B.Sc.
 */
import 'jquery-migrate';
import $ from 'jquery';
import Flipbook from './core';
import Shared from './shared_util';
import Q from '/app/libs/promise/q';

/**
 * Flipbook Loading Screen
 *
 * @class Loading
 * @classdesc Application Loading-Screen Controller
 * @namespace Flipbook
 * @return {Object} The Class Instance
 * @constructor
 * @mixin
 */
Flipbook.Loading = function() {};


/* **************************************************************************************** */
/* * Public Properties                                                                    * */
/* **************************************************************************************** */
// Loading Screen
Flipbook.Loading.prototype.$loadingScreen = null;
Flipbook.Loading.prototype.loadingScreenVisible = false;

/* ******************************************************************************************** */
/* * Public Methods                                                                           * */
/* ******************************************************************************************** */

/**
 * Initializes the Loading Screen
 *
 * @private
 * @this Flipbook.App
 * @return undefined
 */
Flipbook.Loading.prototype.initLoadingScreen = function() {
    // Get a Handle to the Loading Screen
    this.$loadingScreen = $(this.config.loadingScreen.selector);
    if (!this.$loadingScreen.length) { return; }
    
    // Debug Message
    Flipbook.log('app-add-loading');
    
    // Track State of Loading Screen
    this.loadingScreenVisible = true;
};

/**
 * Hides the Loading Screen
 *
 * @private
 * @this Flipbook.App
 * @return undefined
 */
Flipbook.Loading.prototype.hideLoadingScreen = function(delay) {
    return Q.Promise($.proxy(function(resolve, reject, notify) {
        
        var afterClosure = this.afterLoadingScreen(resolve, reject, notify);
        var hide = $.proxy(function() {
            if (this.$loadingScreen.length) {
                this.$loadingScreen.transition({'opacity': 0}, 250, afterClosure);
            } else {
                afterClosure();
            }
        }, this);
        
        // Debug Message
        Flipbook.log('app-remove-loading');
        
        if (delay && delay > 10) {
            this.$loadingScreen.addClass('loaded');
            Q.delay(delay).then(hide);
        } else {
            hide();
        }
    }, this));
};

/**
 * After the Loading Screen has been Hidden
 *
 * @private
 * @this Flipbook.App
 * @return undefined
 */
Flipbook.Loading.prototype.afterLoadingScreen = function(resolve, reject, notify) {
    return $.proxy(function() {
        if (!this.loadingScreenVisible) { return; }
        this.loadingScreenVisible = false;
        
        // Debug Message
        Flipbook.log('app-after-loading-screen');
        
        // Remove Loading Screen
        this.$loadingScreen.remove();
        
        // Flash Navigation Controls
        this.navigation.toggleViewMode(true, true);

        // Display Welcome Popup
        this.displayWelcomeMsg().fin($.proxy(function() {
            // Flash Overlays
            this.overlayer.flash();
            
            // Resolve Promise
            resolve('loaded');
        }, this));
    }, this);
};

/**
 * Displays the Flipbook Welcome Popup Message
 *   A promise is returned which gets resolved when the Popup Message is dismissed
 *
 * @private
 * @this Flipbook.App
 * @return {Object} - A Promise
 */
Flipbook.Loading.prototype.displayWelcomeMsg = function() {
    var promise = null;
    var timeout = 0;

    // Check for Welcome Message Popup
    this.config.widgetAd = $.parseJSON(this.config.widgetAd);
    
    if (_.isPlainObject(this.config.widgetAd)) {
        timeout = _.parseInt(this.config.widgetAd.timeout, 10);

        // Display Welcome Message Popup
        promise = this.modal.content(this.config.widgetAd.text, {
            'width'    : _.parseInt(this.config.widgetAd.width, 10),
            'height'   : _.parseInt(this.config.widgetAd.height, 10),
            'align'    : 'left',
            'closable' : true,
            'padding'  : 10
        });
        
        // Close Welcome Message after Timeout
        if (timeout > 0) {
            Shared.onNextEventLoop(function() {
                this.modal.hide();
            }, this, timeout * 1000);
        }
    } else {
        // If there has been no promise created, let's create one, so that we can maintain
        // asynchronous code.  The callee expects a promise returned anyway, so in this
        // scenario we create and immediately resolve a promise for the callee.
        if (promise === null) {
            promise = Q('no-welcome-msg'); // self-resolving promise
        }
    }
    return promise;
};
