// Check for iFrame Parent Origin, instead of *

const getParentOrigin = () => {
  let parentOrigin;

  if (window.location.ancestorOrigins && window.location.ancestorOrigins.length > 0) {
    parentOrigin = window.location.ancestorOrigins[0];
  } else if (document.referrer) {
    let referrerUrl = new URL(document.referrer); // Fallback - Parent origin from the referrer
    parentOrigin = referrerUrl.origin;
  }

  if (parentOrigin === '' || parentOrigin === null || parentOrigin === undefined) {
    parentOrigin = '*';
  }

  return parentOrigin;
};

export default getParentOrigin;
