/**
 * Flyp Technologies Inc. - Flipbook v4
 * 
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/tools/base.js - Flipbook.ToolsBase
 * @author Robert J. Secord, B.Sc.
 */
import Flipbook from '../core';

/**
 * Flipbook ToolsBase
 *
 * @class ToolsBase
 * @classdesc Application Tools Base Class
 * @namespace Flipbook
 * @param {Object} toolbar A reference to the Toolbar Control
 * @param {Object} toolData Configuration Settings for the Tool
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.ToolsBase = function(toolbar, toolData) {

    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize   = null;
    var buildTool    = null;
    var attachEvents = null;


    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    this.app         = null;
    this.toolbar     = null;
    this.$container  = null;
    this.toolName    = 'ToolsBase';
    this.toolOptions = null;
    this.$tool       = null;
    
    this.lastXHR = null;
    

    /* **************************************************************************************** */
    /* * Private Methods/Members Definitions                                                  * */
    /* **************************************************************************************** */
    /**
     * Initialize the Tool
     *
     * @private
     * @this Flipbook.ToolsBase
     * @return {Object} A reference to the ToolsBase Object for Method Chaining
     * @constructs
     */
    initialize = $.proxy(function(toolbar, toolData) {
        if (toolbar === undefined) { return; }

        // Store Reference to Toolbar & App
        this.toolbar = toolbar;
        this.app     = toolbar.app;
        
        // Store Reference to Tools Container
        this.$container = toolbar.$toolsScrollContainer;
        
        // Store Tool Options
        this.toolOptions = $.extend(true, {}, Flipbook.ToolsBase.defaultToolOptions, toolData);
        
        // Set Tool Name
        this.toolName = toolData.name;
        
        // Debug Message
        Flipbook.log({'msg': 'toolbar-tool-initialize', 'args': {'tool': this.toolName}});
        
        // Build Tool Elements and Attach Standard Events
        buildTool();
        attachEvents();
        
        return this;
    }, this);

    /**
     * Build the Tool Elements
     *
     * @private
     * @this Flipbook.ToolsBase
     * @return undefined
     */
    buildTool = $.proxy(function() {
        // Debug Message
        Flipbook.log({'msg': 'toolbar-tool-build-elements', 'args': {'tool': this.toolName}});
        
        // Build Tool from Template
        if (this.toolOptions.inMenu) {
            this.$tool = Flipbook.buildFromTemplate({'template': this.toolOptions.template, 'appendTo': this.$container, 'returnAs': 'element'});
        } else {
            this.$tool = this.toolbar.$container.find('.' + this.toolOptions.classname);
        }
        
        // Add Label to Tool
        this.$tool.find('.tool > span').html(this.toolOptions.label);
        
        // Add Tool Classname
        if (this.toolOptions.classname.length && !this.$tool.hasClass(this.toolOptions.classname)) {
            this.$tool.addClass(this.toolOptions.classname);
        }
    }, this);

    /**
     * 
     *
     * @private
     * @this Flipbook.ToolsBase
     * @return undefined
     */
    attachEvents = $.proxy(function() {
        // Debug Message
        Flipbook.log({'msg': 'toolbar-tool-attach-events', 'args': {'tool': this.toolName}});
        
        // Attach Click/Tap Event to Tool Menu Item
        Flipbook.onClickTap(this.app, this.$tool, function(e) {
            // Stop Event Propagation
            //  - prevents the toolbar from opening on its own;
            //    showPanel() forces the toolbar open if it is closed
            e.stopPropagation();

            // Notify Tool of Click Event
            this.toolClicked(e);
            
            // Open Toolbar/Content Panel?
            if (this.toolOptions.panel !== false && this.toolOptions.panel.autoOpen) {
                if (Flipbook.isComponentEnabled(this.app, toolData.panel.enabled || true)) {
                    this.showPanel();       // Open Toolbar + Open Content Panel
                } else {
                    this.shiftContent();    // Open Toolbar Only
                }
            }
        }, this, 'ToolsBase');
    }, this);


    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize(toolbar, toolData);
};
// End of Flipbook.ToolsBase


/* ******************************************************************************************** */
/* * Abstract Methods                                                                         * */
/* ******************************************************************************************** */

/**
 * Abstract Base Method; Called when the Tool Panel is Opened
 *   - note: override is optional
 *
 * @public
 * @abstract
 * @this Flipbook.ToolsBase
 * @return undefined
 */
Flipbook.ToolsBase.prototype.toolClicked = function(e) {};

/**
 * Abstract Base Method; Called when the Content is Shifted
 *   - note: override is optional
 *
 * @public
 * @abstract
 * @this Flipbook.ToolsBase
 * @return undefined
 */
Flipbook.ToolsBase.prototype.contentShifted = function() {};

/**
 * Abstract Base Method; Called when the Content is Restored
 *   - note: override is optional
 *
 * @public
 * @abstract
 * @this Flipbook.ToolsBase
 * @return undefined
 */
Flipbook.ToolsBase.prototype.contentRestored = function() {};

/**
 * Abstract Base Method; Called when the Tool Panel is Opened
 *   - note: override is optional
 *
 * @public
 * @abstract
 * @this Flipbook.ToolsBase
 * @return undefined
 */
Flipbook.ToolsBase.prototype.panelOpened = function() {};

/**
 * Abstract Base Method; Called when the Tool Panel is Opened
 *   - note: override is optional
 *
 * @public
 * @abstract
 * @this Flipbook.ToolsBase
 * @return undefined
 */
Flipbook.ToolsBase.prototype.panelClosed = function() {};

/**
 * Abstract Base Method; Called when the Tool Panel is Opened
 *   - note: override is optional
 *
 * @public
 * @abstract
 * @this Flipbook.ToolsBase
 * @return undefined
 */
Flipbook.ToolsBase.prototype.panelScrolled = function(scrollLeft, scrollTop) {};


/* ******************************************************************************************** */
/* * Public Helper Methods                                                                    * */
/* ******************************************************************************************** */

/**
 * getPanelScrollbar
 * 
 * Helper: Gets a reference to the Toolbar Content Panel Scrollbar
 *
 * @public
 * @this Flipbook.ToolsBase
 * @return {Object} Toolbar Panel
 */
Flipbook.ToolsBase.prototype.getPanelScrollbar = function() {
    return this.toolbar.getPanelScrollbar();
};

/**
 * getPanel
 * 
 * Helper: Gets a reference to the Toolbar Content Panel
 *
 * @public
 * @this Flipbook.ToolsBase
 * @return {Object} Toolbar Panel
 */
Flipbook.ToolsBase.prototype.getPanel = function() {
    return this.toolbar.getPanel();
};

/**
 * updatePanelScrollbar
 * 
 * Helper: Updates the Toolbar Content Panel Scrollbar Control
 *
 * @public
 * @this Flipbook.ToolsBase
 * @return {Object} Toolbar Panel Scrollbar
 */
Flipbook.ToolsBase.prototype.updatePanelScrollbar = function() {
    return this.toolbar.updatePanelScrollbar();
};

/**
 * panelReplaceHtml
 * 
 * Helper: Shows the Toolbar Content Panel
 *
 * @public
 * @this Flipbook.ToolsBase
 * @return {Object} A Promise
 */
Flipbook.ToolsBase.prototype.panelReplaceHtml = function(html, loading) {
    return this.toolbar.panelReplaceHtml(html, loading, this.toolOptions.panel.scrollable);
};

/**
 * panelAppendHtml
 * 
 * Helper: Shows the Toolbar Content Panel
 *
 * @public
 * @this Flipbook.ToolsBase
 * @return undefined
 */
Flipbook.ToolsBase.prototype.panelAppendHtml = function(html, selector) {
    return this.toolbar.panelAppendHtml(html, selector);
};

/**
 * panelDisplayError
 * 
 * Helper: Displays an Error Message on the Panel
 *
 * @public
 * @this Flipbook.ToolsBase
 * @return undefined
 */
Flipbook.ToolsBase.prototype.panelDisplayError = function(msg) {
    return this.toolbar.panelDisplayError(msg);
};

/**
 * showPanel
 * 
 * Helper: Shows the Toolbar Content Panel
 *
 * @public
 * @this Flipbook.ToolsBase
 * @return {Object} A Promise
 */
Flipbook.ToolsBase.prototype.showPanel = function() {
    return this.toolbar.showPanel(this, this.toolOptions.panel);
};

/**
 * hidePanel
 * 
 * Helper: Hides the Toolbar Content Panel
 *
 * @public
 * @this Flipbook.ToolsBase
 * @return {Object} A Promise
 */
Flipbook.ToolsBase.prototype.hidePanel = function() {
    return this.toolbar.hidePanel();
};

/**
 * shiftContent
 * 
 * Helper: Shows the Toolbar
 *
 * @public
 * @this Flipbook.ToolsBase
 * @return {Object} A Promise
 */
Flipbook.ToolsBase.prototype.shiftContent = function() {
    return this.toolbar.shiftContent();
};

/**
 * restoreContent
 * 
 * Helper: Hides the Toolbar
 *
 * @public
 * @this Flipbook.ToolsBase
 * @return {Object} A Promise
 */
Flipbook.ToolsBase.prototype.restoreContent = function() {
    return this.toolbar.restoreContent();
};

/**
 * moveToSheet
 * 
 * Helper: Moves the Carousel to a Specific Page/Sheet
 *
 * @public
 * @this Flipbook.ToolsBase
 * @return {Object} A Promise
 */
Flipbook.ToolsBase.prototype.moveToSheet = function(sheetIndex, speed) {
    return this.toolbar.moveToSheet(sheetIndex, speed);
};

/**
 * getCurrentSheet
 * 
 * Helper: Gets the Current Sheet we are on
 *
 * @public
 * @this Flipbook.ToolsBase
 * @return 
 */
Flipbook.ToolsBase.prototype.getCurrentSheet = function() {
    return this.toolbar.getCurrentSheet();
};

/**
 * getTotalSheets
 * 
 * Helper: Gets the Total Sheets count
 *
 * @public
 * @this Flipbook.ToolsBase
 * @return 
 */
Flipbook.ToolsBase.prototype.getTotalSheets = function() {
    return this.toolbar.getTotalSheets();
};

/**
 * trackSocialShareEvent
 * 
 * Helper: Track Flipbook Events
 *      
 * @public
 * @this Flipbook.ToolsBase
 * @return undefined
 */
Flipbook.ToolsBase.prototype.trackSocialShareEvent = function(shareType, flipbookId, flipbookPage) {
    return this.toolbar.trackSocialShareEvent(shareType, flipbookId, flipbookPage);
};

/**
 * trackPdfDownloadEvent
 * 
 * Helper: Track Flipbook Events
 *      
 * @public
 * @this Flipbook.ToolsBase
 * @return undefined
 */
Flipbook.ToolsBase.prototype.trackPdfDownloadEvent = function(flipbookId) {
    return this.toolbar.trackPdfDownloadEvent(flipbookId);
};


/* ******************************************************************************************** */
/* * Static Public Members                                                                    * */
/* ******************************************************************************************** */

Flipbook.ToolsBase.defaultToolOptions = {
    'enabled'   : false,
    'inMenu'    : true,
    'template'  : '#tpl-toolbar-tool',
    'classname' : '',
    'label'     : 'Unknown Tool',
    'panel' : {
        'id' : '',
        'closeViaMask' : true
    }
};
