/**
 * Flyp Technologies Inc. - Flipbook v4
 *
 * @overview HTML5 Flipbook Application
 * @copyright (c) 2014 Flyp Technologies Inc., all rights reserved.
 * @namespace Flipbook
 * @file /src/js/flipbook/tools/pdf.js - Flipbook.PdfTool
 * @author Robert J. Secord, B.Sc.
 */
import Flipbook from '../core';

/**
 * Flipbook PDF Tool
 *
 * @class PdfTool
 * @classdesc Application PDF-Download Tool
 * @namespace Flipbook
 * @inherits Flipbook.ToolsBase
 * @param {Object} toolbar A reference to the Toolbar Control
 * @param {Object} toolData Configuration Settings for the Tool
 * @return {Object} The Class Instance
 * @constructor
 */
Flipbook.PdfTool = function(toolbar, toolData) {
    // Call Parent Constructor
    Flipbook.ToolsBase.apply(this, [toolbar, toolData]);
    
    /* **************************************************************************************** */
    /* * Private Methods/Members Declarations                                                 * */
    /* **************************************************************************************** */
    var initialize = null;


    /* **************************************************************************************** */
    /* * Public Properties                                                                    * */
    /* **************************************************************************************** */
    this.pdfUrl = '';
    this.pdfSize = 0;
    

    /* **************************************************************************************** */
    /* * Private Methods/Members Definitions                                                  * */
    /* **************************************************************************************** */
    /**
     * Initialize the Sharing Tool
     *
     * @private
     * @this Flipbook.PdfTool
     * @return {Object} A reference to the PdfTool Object for Method Chaining
     * @constructs
     */
    initialize = $.proxy(function() {
        // Build PDF URL
        this.pdfUrl = this.app.config.urls.files + 'files/' + this.app.config.$titleSettings.find('#pdf_download_uri').text();
        
        // Get Size of PDF
        this.pdfSize = parseFloat(this.app.config.issueData.size_in || 0);
        
        return this;
    }, this);
    
    /* **************************************************************************************** */
    /* * Entry Point                                                                          * */
    /* **************************************************************************************** */
    return initialize();
};
Flipbook.PdfTool.prototype = new Flipbook.ToolsBase();
Flipbook.PdfTool.prototype.constructor = Flipbook.PdfTool;
// End of Flipbook.PdfTool


/* ******************************************************************************************** */
/* * Overridden Base Methods                                                                  * */
/* ******************************************************************************************** */

/**
 * Override Abstract Base Method; Called when the Tool is Clicked
 *
 * @public
 * @this Flipbook.PdfTool
 * @return undefined
 */
Flipbook.PdfTool.prototype.toolClicked = function(e) {
    // Handles the Downloading of the PDF File
    var initiateDownload = $.proxy(function() {
        if (Flipbook.isMobile.Android5()) {
            Flipbook.root.location.href = this.pdfUrl;
        } else {
            var popupWin = Flipbook.root.open(this.pdfUrl, '_blank');
            popupWin.opener = null;
            
            // Force Close the Popup on Desktop
            if (!Flipbook.isMobile.any(this.app)) {
                Flipbook.root.setTimeout(function() {
                    if (!popupWin.location || popupWin.location.href === 'about:blank') {
                        popupWin.close();
                    }
                }, 1000);
            } 
            
            // Check for Popup Blockers
            if (popupWin === null || popupWin === undefined) {
                this.modal.alert('Please disable your pop-up blocker and try downloading the PDF again');
                return;
            }
        }
        
        // Track the PDF Download Event
        this.trackPdfDownloadEvent(this.app.config.stats.id);
    }, this);
    
    // Check if the PDF should be downloaded
    //   - Mobile Device?
    //   - PDF Size is greater than 150mb?
    if (Flipbook.isMobile.any(this.app) && this.toolOptions.mobileLimit > 0 && this.pdfSize > this.toolOptions.mobileLimit) {
        this.app.modal.confirm(this.toolOptions.mobileMessage, {'header' : 'Warning: Large PDF File'}).then(initiateDownload);
    } else {
        initiateDownload();
    }
};
