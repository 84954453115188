import Shared from './shared_util';

/**
 * Shared Profiler
 *
 * @class Profiler
 * @classdesc Profiles a Code Blocks Execution Time
 * @namespace Shared
 * @constructor
 */
Shared.Profiler = Shared.Profiler || function() {};


/* ******************************************************************************************** */
/* * Private Static Members                                                                   * */
/* ******************************************************************************************** */
Shared.Profiler.profiles = {};
Shared.Profiler.enabled = true;


/* ******************************************************************************************** */
/* * Public Static Methods                                                                    * */
/* ******************************************************************************************** */
/**
 * Starts a Profiler on a Block of Code marked by a Name
 *   
 * @public
 * @this Shared.Profiler
 * @param {String} name The name of the Profile for reference to its Execution Time
 * @return undefined
 */
Shared.Profiler.start = function(name) {
    if (!Shared.Profiler.enabled) { return; }
    
    // Store Profiling Data for Start Time
    Shared.Profiler.profiles[name] = {'start': new Date().getTime(), 'end': 0};
};

/**
 * Ends a Profiler on a Block of Code marked by a Name
 *   - Note: Calculation of Execution Time is done later to avoid taking up further Execution Time within the Script
 *   
 * @public
 * @this Shared.Profiler
 * @param {String} name The name of the Profile for reference to its Execution Time
 * @return undefined
 */
Shared.Profiler.end = function(name) {
    if (!Shared.Profiler.enabled || Shared.Profiler.profiles[name] === undefined) {return;}
    
    // Store Profiling Data for End Time
    Shared.Profiler.profiles[name].end = new Date().getTime();
};

/**
 * Logs a Named Profile to display its Execution Time 
 *   
 * @public
 * @this Shared.Profiler
 * @param {String} name The name of the Profile for reference to its Execution Time
 * @return undefined
 */
Shared.Profiler.outputProfile = function(name) {
    if (!Shared.Profiler.enabled || Shared.Profiler.profiles[name] === undefined || Shared.Logger === undefined) {return;}
    
    // Output Profile Data to Log
    Shared.Logger.profile(name, (Shared.Profiler.profiles[name].end - Shared.Profiler.profiles[name].start));
};

/**
 * Logs all Named Profiles to display their Execution Times 
 *   
 * @public
 * @this Shared.Profiler
 * @return undefined
 */
Shared.Profiler.outputAll = function() {
    var profileName;
    if (!Shared.Profiler.enabled || Shared.Logger === undefined) {return;}
    
    // Output All Profile Data to Log
    for (profileName in Shared.Profiler.profiles) {
        if (Shared.Profiler.profiles.hasOwnProperty(profileName) && Shared.Profiler.profiles[profileName].end > 0) {
            Shared.Logger.profile(profileName, (Shared.Profiler.profiles[profileName].end - Shared.Profiler.profiles[profileName].start));
        }
    }
};
